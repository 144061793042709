// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon98IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon98Icon(props: Icon98IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 24 24"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M11.827 15.002a.779.779 0 01-.303-.067.9.9 0 01-.272-.193L8 11.42a.672.672 0 01-.198-.488.66.66 0 01.096-.351.708.708 0 01.25-.26.692.692 0 01.866.132l2.809 2.896 2.812-2.896a.715.715 0 01.519-.228.66.66 0 01.351.096.71.71 0 01.25.26.661.661 0 01.097.351c0 .19-.067.353-.202.488l-3.252 3.322a.89.89 0 01-.268.198.747.747 0 01-.303.062z"
        }
        fill={"#666"}
      ></path>
    </svg>
  );
}

export default Icon98Icon;
/* prettier-ignore-end */
