import { useFeatureStyle } from '../../../../jotai/atoms/features';
import { NumericId } from '../../../common/types';
import { updateFeatureStyleCatalogue } from '../../api';
import {
  FeatureStyleCatalogueResponse,
  LayerStyle,
} from '../../types/layer.types';

const useUpdateFeatureStyle = () => {
  const [, setFeatureStyleCatalogue] = useFeatureStyle();

  const updateStyle = async (
    featureId: NumericId,
    styleId: NumericId,
    styleObj: LayerStyle
  ) => {
    try {
      const response: FeatureStyleCatalogueResponse =
        await updateFeatureStyleCatalogue(featureId, styleId, styleObj);
      setFeatureStyleCatalogue(response);
    } catch (error) {
      console.error('Error updating feature style:', error);
    }
  };

  return { updateStyle };
};

export default useUpdateFeatureStyle;
