// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type SettingsIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function SettingsIcon(props: SettingsIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 20 20"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M9.07 5.732c.236-.976 1.624-.976 1.86 0 .154.63.876.93 1.43.592.857-.523 1.839.459 1.316 1.316a.958.958 0 00.592 1.43c.976.236.976 1.624 0 1.86a.958.958 0 00-.592 1.43c.523.857-.459 1.839-1.316 1.316a.958.958 0 00-1.43.592c-.236.976-1.624.976-1.86 0a.958.958 0 00-1.43-.592c-.857.523-1.839-.459-1.316-1.316a.958.958 0 00-.592-1.43c-.976-.236-.976-1.624 0-1.86a.958.958 0 00.592-1.43c-.523-.857.459-1.839 1.316-1.316a.958.958 0 001.43-.592z"
        }
        stroke={"currentColor"}
        strokeWidth={".833"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      ></path>

      <path
        d={"M11.667 10a1.667 1.667 0 11-3.334 0 1.667 1.667 0 013.334 0z"}
        stroke={"currentColor"}
        strokeWidth={".833"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      ></path>
    </svg>
  );
}

export default SettingsIcon;
/* prettier-ignore-end */
