import useCurrentView from '../../../../jotai/atoms/views/useCurrentView';
import useViewList from '../../../../jotai/atoms/views/useViewList';
import { ViewType } from '../../../common/types';
import { HierarchicalView } from '../../types/view.types';
import useViewMap from './useViewMap';

const useViewUtility = () => {
  const { viewList } = useViewList();
  const { viewIdMap, viewMap } = useViewMap();
  const { currentView } = useCurrentView();

  /** Check if a viewId exists in the map */
  const isViewInMap = (viewId: number) => {
    return viewIdMap.hasOwnProperty(viewId);
  };

  /** Check if a view has 'estimation' sub-views */
  const hasEstimationViewByViewId = (viewId: number) => {
    const subViews =
      viewList.find((view) => view.viewId === viewId)?.subViews || [];
    return subViews.some(
      (subView: HierarchicalView) => subView?.viewType === ViewType.ESTIMATION
    );
  };

  /** Check if a view has 'estimation' or 'dynamic' sub-views */
  const hasEstimationOrDynamicViewsByViewId = (viewId: number) => {
    const subViews =
      viewList.find((view) => view.viewId === viewId)?.subViews || [];
    return subViews.some((subView: HierarchicalView) =>
      [ViewType.DYNAMIC, ViewType.ESTIMATION].includes(subView?.viewType)
    );
  };

  /** Get complete array of 'estimation' or 'dynamic' sub-views */
  const getEstimationOrDynamicSubViewsByViewId = (
    viewId: number
  ): HierarchicalView[] => {
    const subViews =
      viewList.find((view) => view.viewId === viewId)?.subViews || [];
    return subViews.filter((subView: HierarchicalView) =>
      [ViewType.DYNAMIC, ViewType.ESTIMATION].includes(subView?.viewType)
    );
  };

  /** Returns view details by view id */
  const getViewByViewId = (viewId: number) => {
    /** Search in the main views */
    for (const mainView of viewList) {
      if (mainView.viewId === viewId) {
        return mainView;
      }

      /** Search in the sub-views of the main view */
      for (const subView of mainView.subViews) {
        if (subView.viewId === viewId) {
          return subView;
        }
      }
    }

    return null;
  };

  const getDefaultView = () => {
    for (const view of viewList) {
      if (view.isDefault) {
        return view;
      }

      for (const subView of view.subViews) {
        if (subView.isDefault) {
          return subView;
        }
      }
    }

    return null;
  };

  const getCurrentView = () => currentView;

  /** Returns the main view id that the given sub viewId is linked to */
  const getLinkedMainView = (viewId: number) => {
    for (const [mainViewId, subViewIds] of Object.entries(viewIdMap)) {
      if (subViewIds.includes(viewId)) {
        return getViewByViewId(Number(mainViewId));
      }
    }
    return null;
  };

  /** Return the latest sub-view of the specified type by main view id */
  const getLinkedLatestSubView = (viewId: number, viewType: ViewType) => {
    const subViews = viewMap[viewId];
    if (!subViews) return null;

    const filteredViews = subViews.filter(
      (subView) => subView.viewType === viewType
    );
    if (filteredViews.length === 0) return null;

    filteredViews.sort(
      (a, b) =>
        new Date(b.lastFetchedTime).getTime() -
        new Date(a.lastFetchedTime).getTime()
    );

    return filteredViews[0];
  };

  const getSiblingLatestEstViewByDynView = (viewId: number) => {
    const mainViewId = getLinkedMainView(viewId);
    if (!mainViewId) return null;

    const subViews = viewMap[mainViewId];
    if (!subViews) return null;

    const estimationViews = subViews.filter(
      (subView) => subView.viewType === ViewType.ESTIMATION
    );
    if (estimationViews.length === 0) return null;

    estimationViews.sort(
      (a, b) =>
        new Date(b.lastFetchedTime).getTime() -
        new Date(a.lastFetchedTime).getTime()
    );

    return estimationViews[0];
  };

  return {
    isViewInMap,
    hasEstimationViewByViewId,
    getDefaultView,
    hasEstimationOrDynamicViewsByViewId,
    getEstimationOrDynamicSubViewsByViewId,
    getViewByViewId,
    getCurrentView,
    getLinkedMainView,
    getLinkedLatestSubView,
    getSiblingLatestEstViewByDynView,
  };
};

export default useViewUtility;
