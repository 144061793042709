import { NotifyError } from '@/helpers/notification-utils';
import * as turf from '@turf/turf';
import moment from 'moment';
import { Draw } from 'ol/interaction';
import {
  BULK_INTERACTION,
  DRAW_END,
  DRAW_INTERACTION,
  DRAW_START,
  MODIFY_INTERACTION,
} from '../../constants/mapTools';
import {
  CUT_SPLIT_STYLE_MODE,
  cutInteractionStyle,
} from '../../mapGlobals/styles';
import { addCrossLayerSnap, resetCrossLayerSnap } from './snapping';
import {
  addFeaturesToMap,
  createDrawnPolygon,
  featureToPolygon,
  generateTempId,
} from './utilities';
import { generatePropertiesFromFeature } from '../autoSave/utils';
import omit from 'lodash/omit';

const MULTI_POLYGON = 'MultiPolygon';
const POLYGON = 'Polygon';
let doughnutInteraction;
let parcelLayer;

// TODO_R2C
const DOUGHNUT_STROKE_PROPS = {
  color: '#FFD02B',
  lineDash: [10],
  width: 1,
};

export const doughnutInteractionActive = (
  sourceLayer,
  mapRef,
  type,
  callback,
  allLayerRefsObj,
  hasToolStart,
  args
) => {
  parcelLayer = sourceLayer;

  if (doughnutInteraction) {
    mapRef.removeInteraction(doughnutInteraction);
    resetCrossLayerSnap(mapRef);
  }

  doughnutInteraction = new Draw({
    // source: parcelSource,
    type: POLYGON,
    style: cutInteractionStyle,
  });

  mapRef.addInteraction(doughnutInteraction);
  addCrossLayerSnap(mapRef, allLayerRefsObj);
  if (parcelLayer && type) {
    /*parcelLayer.setStyle(CUT_STYLE_MODE(type, parcelStyle)); // ? What For?*/
    parcelLayer.setStyle(CUT_SPLIT_STYLE_MODE);
  }
  //This function will be used to maintain Point level history
  args.interactionsDrawingPointLevelTracking(doughnutInteraction);
  onDrawStart(doughnutInteraction, hasToolStart);
  onDrawEnd(doughnutInteraction, mapRef, callback, type, hasToolStart, args);
};

export const removeDoughnutInteraction = (mapRef) => {
  mapRef.getInteractions().forEach((interaction) => {
    if (interaction === doughnutInteraction) {
      // TODO_R2C: Shallow Or Deep Equality?
      mapRef.removeInteraction(doughnutInteraction);
    }
  });
};

const onDrawStart = (doughnutInteraction, hasToolStart) => {
  doughnutInteraction.on(DRAW_START, (event) => {
    hasToolStart(true);
  });
};

const onDrawEnd = (
  doughnutInteraction,
  mapRef,
  callback,
  type,
  hasToolStart
) => {
  doughnutInteraction.on(DRAW_END, (event) => {
    const timeStamp = moment();

    // Drawn polygon object creation
    const drawnPolygon = createDrawnPolygon(event.feature);

    // Check if drawn polygon is invalid
    const kinks = turf.kinks(drawnPolygon);
    let isKinky = false; // :)
    if (kinks.features.length !== 0) {
      isKinky = true;
      NotifyError('Cannot draw self intersecting polygons', {
        tool: 'doughnut',
      });
    }

    // Remove complete map layer
    mapRef.removeLayer(parcelLayer);
    const listOfPolygons = [];
    let listOfIntersectingPolygons = [];
    const callbackReqData = [];
    let vectorSource = null;
    let isError = null;
    let initialParcelLayerFeatures = parcelLayer.getSource().getFeatures();

    initialParcelLayerFeatures.forEach((feature) => {
      let old_properties = { ...feature.getProperties() };
      const parcelPolygon = featureToPolygon(feature);
      if (turf.booleanIntersects(parcelPolygon, drawnPolygon) && !isKinky) {
        listOfIntersectingPolygons.push(feature);
      } else {
        let new_polygon = turf.feature(parcelPolygon.geometry, {
          ...old_properties,
        });
        listOfPolygons.push(new_polygon);
      }
    });

    listOfIntersectingPolygons.forEach((feature, idx) => {
      const properties = { ...feature.getProperties() };
      delete properties.geometry;
      try {
        const parcelPolygon = featureToPolygon(feature);

        if (turf.booleanContains(drawnPolygon, parcelPolygon)) {
          // When Drawn Polygon Contains Parcel Polygon
          listOfPolygons.push(parcelPolygon);
          return;
        }

        const difference = turf.difference(parcelPolygon, drawnPolygon);
        if (difference) {
          if (
            turf.booleanOverlap(parcelPolygon, drawnPolygon) ||
            turf.booleanContains(parcelPolygon, drawnPolygon)
          ) {
            const intersection = turf.intersect(drawnPolygon, parcelPolygon);
            if (
              turf.booleanOverlap(parcelPolygon, drawnPolygon) &&
              !intersection
            ) {
            }
            // Drawn Polygon Is Overlapping Or Making A Hole In The Parcel Polygon

            // ##### Difference #####
            if (difference?.geometry?.type === MULTI_POLYGON) {
              difference.geometry.coordinates.forEach((coord, index) => {
                const tempId = generateTempId();
                const componentId = !index
                  ? properties.actualComponentId ?? properties.componentId
                  : tempId;

                let polygonT = turf.polygon(
                  coord,
                  !index
                    ? {
                        ...properties,
                        componentId,
                        ID: componentId,
                        id: componentId,
                      }
                    : {
                        ...properties,
                        name: undefined,
                        temp_id: tempId,
                        tempId: tempId,
                        componentId: componentId || tempId,
                        ID: componentId || tempId,
                        id: componentId || tempId,
                      }
                );

                polygonT.properties = {
                  ...polygonT.properties,
                  ...generatePropertiesFromFeature(polygonT),
                };
                polygonT = updateGeoJsonPropertiesInFeature(polygonT);

                listOfPolygons.push(polygonT);
                const reqData = {
                  componentId,
                  geoJson: polygonT,
                  timeStamp,
                  type: !index ? MODIFY_INTERACTION : DRAW_INTERACTION,
                };

                if (callback) {
                  callbackReqData.push(reqData);
                }
              });
            } else {
              const componentId =
                properties.actualComponentId ?? properties.componentId;
              let polygonD = turf.feature(difference.geometry, {
                ...properties,
                componentId,
                ID: componentId,
                id: componentId,
                ...generatePropertiesFromFeature(difference),
              });

              polygonD = updateGeoJsonPropertiesInFeature(polygonD);
              listOfPolygons.push(polygonD);

              const reqDataD = {
                geoJson: difference,
                timeStamp,
                componentId,
                type: MODIFY_INTERACTION,
              };

              if (callback) {
                callbackReqData.push(reqDataD);
              }
            }

            // ##### Intersection #####
            if (intersection) {
              if (intersection.geometry.type === MULTI_POLYGON) {
                intersection.geometry.coordinates.forEach((coord) => {
                  const tempId = generateTempId();
                  const componentId = tempId;
                  let polygonI = turf.polygon(coord, {
                    ...properties,
                    componentId,
                    ID: componentId,
                    id: componentId,
                    tempId: componentId,
                    temp_id: componentId,
                  });

                  polygonI.properties = {
                    ...polygonI.properties,
                    ...generatePropertiesFromFeature(polygonI),
                  };
                  polygonI = updateGeoJsonPropertiesInFeature(polygonI);

                  listOfPolygons.push(polygonI);

                  const reqDataI = {
                    geoJson: polygonI,
                    timeStamp,
                    componentId,
                    type: DRAW_INTERACTION,
                  };

                  if (callback) {
                    callbackReqData.push(reqDataI);
                  }
                });
              } else {
                const tempId = generateTempId();
                const componentId = tempId;
                let polygonI = turf.feature(intersection.geometry, {
                  ...properties,
                  tempId: componentId,
                  temp_id: componentId,
                  componentId,
                  ID: componentId,
                  id: componentId,
                  ...generatePropertiesFromFeature(intersection),
                });

                polygonI = updateGeoJsonPropertiesInFeature(polygonI);
                listOfPolygons.push(polygonI);

                const reqDataI = {
                  geoJson: intersection,
                  timeStamp,
                  componentId,
                  type: DRAW_INTERACTION,
                };

                if (callback) {
                  callbackReqData.push(reqDataI);
                }
              }
            }
          }
        }
      } catch (e) {
        parcelLayer.getSource().clear();
        NotifyError(
          `Something went wrong with ${properties.componentIndexing}`,
          { tool: 'doughnut' }
        );
      }
    });
    if (isError) {
      NotifyError(isError?.name);
    }
    if (callback && callbackReqData.length) {
      callback(BULK_INTERACTION, callbackReqData, null, true);
      hasToolStart(false);
    }
    addFeaturesToMap(listOfPolygons, parcelLayer, CUT_SPLIT_STYLE_MODE);
    mapRef.addLayer(parcelLayer);
  });
};

export const updateGeoJsonPropertiesInFeature = (feature) => {
  if (feature.geoJson && typeof feature.geoJson === 'string') {
    const tempGeoJson = JSON.parse(feature.geoJson);
    tempGeoJson.properties = omit({ ...feature.properties }, 'geoJson');
    feature.geoJson = JSON.stringify(tempGeoJson);
  }

  if (feature.geoJson && typeof feature.geoJson === 'object') {
    const tempGeoJson = { ...feature.geoJson };
    tempGeoJson.properties = omit({ ...feature.properties }, 'geoJson');
    feature.geoJson = tempGeoJson;
  }

  if (
    feature.properties?.geoJson &&
    typeof feature.properties.geoJson === 'string'
  ) {
    const tempGeoJson = JSON.parse(feature.properties.geoJson);
    tempGeoJson.properties = omit({ ...feature.properties }, 'geoJson');
    feature.properties.geoJson = JSON.stringify(tempGeoJson);
  }

  if (
    feature.properties?.geoJson &&
    typeof feature.properties?.geoJson === 'object'
  ) {
    const tempGeoJson = { ...feature.geoJson };
    tempGeoJson.properties = omit({ ...feature.properties }, 'geoJson');
    feature.properties.geoJson = tempGeoJson;
  }

  return feature;
};
