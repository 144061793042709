import React from 'react';
import NewLayer from '../../../feature-attributes/add-feature';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../store/types';
import {
  IMapEditorAddLayerModal,
  MapEditorModalType,
} from '../../../../store/map-editor';
import { PropertyView } from '../../../../modules/property/types/view.types';
import { Modal } from 'antd';
import MrNewTakeoffLayerModal from '../../../PlasmicComponents/MrNewTakeoffLayerModal';
import { setAddLayerModalData } from '../../../../store/map-editor/actions';
import { cloneView } from '../../../../modules/property/api';
import useRouterPlus from '../../../../modules/router-plus/hooks/use-router-plus';
import { isValidViewId } from './helpers';
import {
  LayerWithComponents,
  ViewListItem,
} from '../../../../modules/property/api/types';
import useViewList from '../../../../jotai/atoms/views/useViewList';
import { useSwitchView } from '../../../../modules/property/hooks/view/useSwitchView';
import { setOrderLayers } from '@store/order/actions';
import useLayerRender from '../../../../modules/property/hooks/takeoff/useLayerRender';
import { useRole } from '../../../../modules/auth/guards/RoleGuard';

/** TODO: Refactor this component, the "redirect" and "orderViews" logic should not be here */
const MapEditorAddLayerModal = () => {
  const currentView = useSelector<IStore, PropertyView | undefined>(
    (state) => state.order.currentViewData
  );
  const { isEstimator } = useRole();

  const { switchView } = useSwitchView();
  const { addLayer } = useLayerRender();

  const dispatch = useDispatch();
  const data = useSelector<IStore, IMapEditorAddLayerModal>(
    (state) => state.mapEditor.modals[MapEditorModalType.ADD_LAYER]
  );

  const layers = useSelector<IStore, LayerWithComponents[]>(
    (state) => state.order.layerList
  );

  const handleClose = () => {
    dispatch(
      setAddLayerModalData({
        open: false,
        layerId: null,
      })
    );
  };

  const handleCreateNewView = async () => {
    try {
      const { data } = await cloneView(currentView!.viewId, {
        name: `Draft ${Math.random().toString().substring(0, 4)}`,
      });

      if (data.viewId) {
        switchView(data.viewId);
      }
    } catch (error) {}
  };

  const handleAddLayerData = (layer: LayerWithComponents) => {
    layer.isEditable = true;
    layer.components = [];
    const updatedLayers = [...layers, layer];

    dispatch(setOrderLayers(updatedLayers));
    addLayer(layer);
  };

  if (!currentView || !data.open) return null;

  if (!currentView.isEditable && !isEstimator) {
    /** Current view is not editable. So, user need to create a new view for adding a new Layer */
    return null;
  }

  return (
    <NewLayer
      loadLayer={() => {}}
      // TODO: Set following
      loadFeatures={() => {}}
      addSingleLayerData={handleAddLayerData}
      // TODO: Set following
      onSaveAndApplyFeature={() => {}}
      layerData={layers}
      isBaseView={currentView.isBaseView}
      isEditableView={currentView.isEditable}
      toggleAddNewLayer={handleClose}
      updateFeatureListInfo={() => {}}
    />
  );
};

export default MapEditorAddLayerModal;
