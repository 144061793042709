import { FC } from 'react';
import { NumericId, ViewType } from '../../../common/types';
import useRouterPlus from '../../../router-plus/hooks/use-router-plus';
import { isValidViewId } from '../../../../components/pages/project/projectComponents/helpers';
import useViewList from '../../../../jotai/atoms/views/useViewList';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../store/types';
import { HierarchicalView, PropertyView } from '../../types/view.types';
import {
  baseView,
  currentView as setCurrentView,
  editableView,
  setAttributePanelVisibility,
  setVisibleAttributesInLayerPanel,
} from '@/store/order/actions';
import useViewUtility from './useViewUtility';
import { removeDragBoxInteraction } from '@helpers/mapUtils/tools/multiSelecteTool';
import { removeCutInteraction } from '@helpers/mapUtils/tools/cutTool';
import { removeLineSliceInteraction } from '@helpers/mapUtils/tools/lineSliceTool';
import { removeDoughnutInteraction } from '@helpers/mapUtils/tools/doughnutTool';
import { removeDrawInteraction } from '@helpers/mapUtils/tools/drawTool';
import { removeMeasureInteraction } from '@helpers/mapUtils/tools/measurementTool';
import { removeModifyInteraction } from '@helpers/mapUtils/tools/modifyTool';
import { removeSelectInteraction } from '@helpers/mapUtils/featuresUtils';
import { removeSnipInteraction } from '@helpers/mapUtils/tools/snipTool';
import { resetCrossLayerSnap } from '@helpers/mapUtils/tools/snapping';
import { useMap } from '../useMap';
import { MapTool } from '../../../project/types';
import { setActiveTool, setMapClassnames } from '@store/user/actions';
import OlMap from 'ol/Map';
import { useOrderStatus } from '../../../project/guards/OrderStatus';
import useCurrentView from '../../../../jotai/atoms/views/useCurrentView';
import omit from 'lodash/omit';
import { removeAllLayersExceptDefault } from '../takeoff/useLayerRender';
import { trackEvents } from '../../../../helpers/utilities';
import { CreateOrderEvt } from '../../../../segment';
import { deleteViewByViewId } from '../../api';
import { showNotification } from '../../../../components/storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../../../components/storybook/NotificationToast/types';
import { useSwitchView } from './useSwitchView';
import { deactivateEstimationSheet } from '../../../../components/pages/project/projectComponents/ViewPanel/helper';
import { updateViewsByOrderId } from '../../../../store/order/thunks';
import { useFetchViewList } from './useFetchViewList';
import { useWidgetBarTabContext } from '../useWidgetBar';
import { WidgetBarTabs } from '../../../../components/types';

export const useDeleteView = () => {
  const { getViewByViewId, getLinkedMainView, getDefaultView } =
    useViewUtility();

  const { switchView } = useSwitchView();

  const { refetch } = useFetchViewList();

  const { currentView } = useCurrentView();

  const {
    handleWidgetBarTabChange
  } = useWidgetBarTabContext();

  const deleteView = async (viewId: NumericId) => {
    const view = getViewByViewId(viewId);

    if (!view || !currentView) return;

    trackEvents(CreateOrderEvt.ViewPanelDeleteView, {
      orderId: view.orderId,
      viewId: viewId,
      isBaseView: view.isBaseView,
    });

    try {
      await deleteViewByViewId(view.orderId, viewId);

      showNotification(
        NOTIFICATIONS_TYPES.SUCCESS,
        'View deleted successfully'
      );

      if (view.viewId === currentView.viewId) {
        if ([ViewType.DYNAMIC, ViewType.ESTIMATION].includes(view.viewType)) {
          const mainView = getLinkedMainView(view.viewId);
          if (!mainView) return;

          switchView(mainView.viewId);
        } else {
          const defaultView = getDefaultView();
          handleWidgetBarTabChange(WidgetBarTabs.LAYERS)
          if (!defaultView) return;

          switchView(defaultView.viewId);
        }
      }

      if (currentView.linkedView === view.viewId) {
        const defaultView = getDefaultView();
        if (!defaultView) return;
        switchView(defaultView.viewId);
        handleWidgetBarTabChange(WidgetBarTabs.LAYERS)
      }

      if (view.viewType === ViewType.ESTIMATION) {
        try {
          await deactivateEstimationSheet(view.viewId, view.orderId);
        } catch (error) {
          console.error('Error deactivating estimation sheet:', error);
        }
      }

      refetch();
    } catch (error) {
      console.error('Error deleting view:', error);
    }
  };

  return { deleteView };
};

export const withDeleteView = (Component: any) => {
  const WrappedComponent: FC<any> = (props) => {
    const { deleteView } = useDeleteView();
    return <Component deleteView={deleteView} {...props} />;
  };

  return WrappedComponent;
};
