import { AnyAction } from 'redux';
import { UndoableMapCommand } from '../../modules/property/commands/types';
import { MapEditorActionTypes } from './action.types';
import {
  IMapEditorAddLayerModal,
  IMapEditorBaseViewEditRestrictionModal,
  IMapEditorCopyModal,
  IMapEditorDeleteConfirmationModal,
} from './index';

export const addToUndoStack = (data: UndoableMapCommand): AnyAction => ({
  type: MapEditorActionTypes.ADD_TO_UNDO_STACK,
  payload: data,
});

export const popFromUndoStack = (): AnyAction => ({
  type: MapEditorActionTypes.POP_FROM_UNDO_STACK,
});

export const addToRedoStack = (data: UndoableMapCommand): AnyAction => ({
  type: MapEditorActionTypes.ADD_TO_REDO_STACK,
  payload: data,
});

export const popFromRedoStack = (): AnyAction => ({
  type: MapEditorActionTypes.POP_FROM_REDO_STACK,
});

export const setCopyModalData = (data: IMapEditorCopyModal): AnyAction => ({
  type: MapEditorActionTypes.SET_COPY_MODAL_DATA,
  payload: data,
});

export const setDeleteConfirmationModalData = (
  data: IMapEditorDeleteConfirmationModal
): AnyAction => ({
  type: MapEditorActionTypes.SET_DELETE_CONFIRMATION_MODAL_DATA,
  payload: data,
});

export const setAddLayerModalData = (
  data: IMapEditorAddLayerModal
): AnyAction => ({
  type: MapEditorActionTypes.SET_ADD_LAYER_MODAL_DATA,
  payload: data,
});

export const setBaseViewEditRestrictionModalData = (
  data: IMapEditorBaseViewEditRestrictionModal
): AnyAction => ({
  type: MapEditorActionTypes.SET_BASE_VIEW_EDIT_RESTRICTION_MODAL_DATA,
  payload: data,
});
