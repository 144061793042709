// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon93IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon93Icon(props: Icon93IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 17 16"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M7.274 2.479c.611-1.088 2.177-1.088 2.789 0l4.464 7.936a1.6 1.6 0 01-1.395 2.385H4.204a1.6 1.6 0 01-1.395-2.385L7.274 2.48zm2.194 7.92a.8.8 0 11-1.6 0 .8.8 0 011.6 0zm-.8-6.4a.8.8 0 00-.8.8v2.4a.8.8 0 101.6 0V4.8a.8.8 0 00-.8-.8z"
        }
        fill={"#FBBF24"}
      ></path>
    </svg>
  );
}

export default Icon93Icon;
/* prettier-ignore-end */
