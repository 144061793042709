import { useCallback } from 'react';
import { ViewType } from '../../common/types';
import { OrderView } from '../../../components/pages/project/projectComponents/types';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { IStore } from '../../../store/types';
import { replaceParams } from '../../../helpers/utilities/linkUtils';
import { updateViewsByOrderId } from '../../../store/order/thunks';
import { showNotification } from '../../../components/storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../../components/storybook/NotificationToast/types';
import { axiosInstance } from '../../../components/pages/feed/api';
import * as API_ENDPOINTS from '@/helpers/constants/APIEndpoints';
import { useSwitchView } from './view/useSwitchView';
import { useFetchViewList } from './view/useFetchViewList';
import { HierarchicalView } from '../types/view.types';
import { useEstimationViewContext } from '../context/EstimationView/context';

const useViewSelectionOrCreationUtil = () => {
  const dispatch = useDispatch();
  const { refetch } = useFetchViewList();
  const { switchView } = useSwitchView();
  const { setData: setServiceItemsData } = useEstimationViewContext();

  const currentViewData = useSelector<IStore, OrderView>(
    (state) => state.order.currentViewData
  );
  const viewsList = useSelector<IStore, OrderView[]>(
    (state) => state.order.orderViews
  );

  // const handleViewClick = async (view: HierarchicalView) => {
  //   if (
  //     view.viewId !== currentView.viewId &&
  //     [ViewType.ESTIMATION, ViewType.DYNAMIC].includes(view.viewType)
  //   )
  //     setServiceItemsData(null);

  //   switchView(view.viewId);
  // };

  const getLatestEstimationView = (view) => {
    if (!view) return null;

    const linkedEstimationViews = viewsList.filter(
      (v) => v.viewType === ViewType.ESTIMATION && v.linkedView === view.viewId
    );
    return linkedEstimationViews.length > 0
      ? linkedEstimationViews.reduce((prev, curr) =>
          prev.viewId > curr.viewId ? prev : curr
        )
      : null;
  };

  const getLinkedStaticView = (view: OrderView) => {
    if (!view) return null;

    return viewsList.find((v) => v.viewId === view.linkedView);
  };

  /** CHANGE VIEW */
  const changeView = (view: OrderView): void => {
    switchView(view.viewId);
  };

  const getNextViewName = (
    viewType: ViewType,
    viewsList: OrderView[],
    staticName: string,
    dynamicName: string
  ): string => {
    const categoryViews = viewsList.filter((view: OrderView) =>
      viewType === ViewType.STATIC
        ? view.viewType === ViewType.STATIC
        : [ViewType.DYNAMIC, ViewType.ESTIMATION].includes(view.viewType)
    );

    const viewNumbers: number[] = categoryViews
      .map((view) => {
        const regex =
          viewType === ViewType.STATIC
            ? /Draft\s*(\d+)/i
            : /Estimation View\s*(\d+)/i;
        const match = view.name.match(regex);
        return match ? parseInt(match[1], 10) : null;
      })
      .filter((number): number is number => number !== null);

    const highestNumber = viewNumbers.length > 0 ? Math.max(...viewNumbers) : 0;
    return `${viewType === ViewType.STATIC ? staticName : dynamicName} ${
      highestNumber + 1
    }`;
  };

  function getLastStaticViewId(data) {
    // Filter for views with "static" viewType
    // const staticViews = data.filter(view => view.viewType === currentView.viewType);

    const staticViews = data.filter(
      (view) => view.viewType === ViewType.STATIC
    );

    console.log(">>> staticViews: ", staticViews)

    // If there are no static views, return null
    if (staticViews.length === 0) {
      return null;
    }

    // Sort the static views by the createdAt field in descending order
    staticViews.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    // Return the viewId of the latest static view
    return staticViews[0];
  }

  function getLatestEstimationViewId(data) {
    // Filter for views with "estimation" viewType
    const estimationViews = data.filter(
      (view) => view.viewType === ViewType.ESTIMATION
    );

    // If there are no estimation views, return null
    if (estimationViews.length === 0) {
      return null;
    }

    // Sort the estimation views by the createdAt field in descending order
    estimationViews.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    // Return the viewId of the latest estimation view
    return estimationViews[0];
  }

  const handleCreateNewView = async (viewType: ViewType, makeCopy?: boolean) => {
    const viewName = getNextViewName(
      viewType,
      viewsList,
      'Draft',
      'Estimation View'
    );

    let url: string;
    if (viewType === ViewType.ESTIMATION) {
      if (makeCopy) {
        url = API_ENDPOINTS.COPY_ESTIMATION_VIEW;
      } else {
        url = API_ENDPOINTS.CLONE_ESTIMATION_VIEW;
      }
    } else {
      url = API_ENDPOINTS.CLONE_VIEW;
    }

    let viewToBeCloned =
      currentViewData?.viewType === ViewType.STATIC
        ? currentViewData
        : getLinkedStaticView(currentViewData);

    if (viewType === ViewType.ESTIMATION && currentViewData.viewId) {
      viewToBeCloned = currentViewData;
    }

    try {
      const { data: updatedView } = await axiosInstance.post(
        replaceParams(url, { ':viewId': viewToBeCloned?.viewId }),
        { name: viewName },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      );

      console.log(">>> updatedView: ", updatedView)
      console.log(">>> viewType: ", viewType)

      console.log(">>> x1")
      showNotification(
        NOTIFICATIONS_TYPES.SUCCESS,
        'New view created successfully'
      );
      console.log(">>> x2")

      let copyNewView = {};


      console.log(">>> x2 updatedView: ", updatedView)
      // if (makeCopy) {
      //   copyNewView = updatedView.data.find(
      //     (view: OrderView) => view.name === viewName
      //   );
      // }

      // const newView = makeCopy ? copyNewView : updatedView;

      setServiceItemsData(null);

      let viewId = null;
      let viewData = null;

      if (Array.isArray(updatedView.data)) {
        if (viewType === ViewType.ESTIMATION) {
          viewData = getLatestEstimationViewId(updatedView.data);
          if (viewData) {
            viewId = viewData.viewId;
          }
        }
        else {
          viewData = getLastStaticViewId(updatedView.data)
          if (viewData) {
            viewId = viewData.viewId;
          }
        }
      } else {
        viewId = updatedView.viewId;
        if (viewId) {
          viewData = updatedView.data;
        }
      }
      
      await refetch();

      if (viewId) {
        switchView(viewId);
      }
      dispatch(
        updateViewsByOrderId(viewData?.orderId ?? currentViewData.orderId)
      );
    } catch (error: any) {
      if (error.errorCode === 'SR-BIZ-ERROR-22') {
        showNotification(NOTIFICATIONS_TYPES.ERROR, error.errorDesc);
      }
    }
  };

  const handleEstimationViewClick = useCallback(() => {
    if (!currentViewData) return;

    if (currentViewData.viewType === ViewType.STATIC) {
      // Case 1: You're on a static view
      const latestLinkedEstimationView =
        getLatestEstimationView(currentViewData);

      if (latestLinkedEstimationView) {
        changeView(latestLinkedEstimationView);
      } else {
        // Case 3: You're on a static view with no linked estimation view
        handleCreateNewView(ViewType.ESTIMATION);
      }
    } else if (currentViewData.viewType === ViewType.DYNAMIC) {
      // Case 2: You're on a dynamic view
      const linkedStaticView = getLinkedStaticView(currentViewData);

      if (linkedStaticView) {
        const linkedEstimationView = getLatestEstimationView(linkedStaticView);
        if (linkedEstimationView) {
          changeView(linkedEstimationView);
        } else {
          // Case 4: You're on a dynamic view whose static view is not linked to any estimation views
          // Call the necessary function(s) to create a new estimation view
          handleCreateNewView(ViewType.ESTIMATION);
        }
      }
    }
  }, [
    currentViewData,
    getLatestEstimationView,
    getLinkedStaticView,
    handleCreateNewView,
    changeView,
  ]);

  const handleStaticViewClick = useCallback(() => {
    if (!currentViewData) return;

    if (
      [ViewType.ESTIMATION, ViewType.DYNAMIC].includes(currentViewData.viewType)
    ) {
      // Case 5: When you're on estimation tab and go to layer panel tab
      const linkedStaticView = getLinkedStaticView(currentViewData);

      if (linkedStaticView) {
        // Switch to the linked static view
        // Call the necessary function(s) to switch to the linked static view
        changeView(linkedStaticView);
      } else {
        // No linked static view found, create a new one
        handleCreateNewView(ViewType.STATIC);
      }
    }
  }, [currentViewData, getLinkedStaticView, handleCreateNewView, changeView]);

  return {
    handleEstimationViewClick,
    handleStaticViewClick,
    getLinkedStaticView,
    handleCreateNewView,
  };
};

export default useViewSelectionOrCreationUtil;
