import { NotifyError } from '@/helpers/notification-utils';
import * as turf from '@turf/turf';
import moment from 'moment';
import { Draw } from 'ol/interaction';
import { DRAWING_MODE_STYLE } from '../../mapGlobals';
import { addCrossLayerSnap, resetCrossLayerSnap } from './snapping';
import {
  addFeaturesToMap,
  convertFeatureToJson,
  createDrawnPolygon,
  featureToLine,
  generateTempId,
} from './utilities';
import { cutInteractionStyle } from '@/helpers/mapGlobals/styles';
import { generatePropertiesFromFeature } from '../autoSave/utils';
import { updateGeoJsonPropertiesInFeature } from './doughnutTool';

let parcelLayer;
let lineSliceInteraction;
let snap_draw;

export const lineSliceInteractionActive = (
  sourceLayer,
  mapRef,
  type,
  callback,
  allLayerRefsObj,
  hasToolStart,
  args
) => {
  parcelLayer = sourceLayer;
  parcelLayer.setStyle(DRAWING_MODE_STYLE);
  if (lineSliceInteraction) {
    mapRef.removeInteraction(lineSliceInteraction);
    resetCrossLayerSnap(mapRef);
  }
  lineSliceInteraction = new Draw({
    type: type,
    style: cutInteractionStyle,
    condition: (e) => {
      return e.originalEvent.buttons === 1;
    },
  });

  mapRef.addInteraction(lineSliceInteraction);
  addCrossLayerSnap(mapRef, allLayerRefsObj);
  //This function will be used to maintain Point level history
  args?.interactionsDrawingPointLevelTracking(lineSliceInteraction);
  onDrawStart(lineSliceInteraction, hasToolStart);
  onDrawEnd(lineSliceInteraction, mapRef, callback, hasToolStart);
};

export const removeLineSliceInteraction = (mapRef) => {
  if (lineSliceInteraction) {
    mapRef.getInteractions().forEach((interaction) => {
      if (interaction === lineSliceInteraction) {
        mapRef.removeInteraction(lineSliceInteraction);
      }
    });
    mapRef.getInteractions().forEach((interaction) => {
      if (interaction === snap_draw) {
        mapRef.removeInteraction(snap_draw);
      }
    });
  }
};
const onDrawStart = (lineSliceInteraction, hasToolStart) => {
  lineSliceInteraction.on('drawstart', (event) => {
    hasToolStart(true);
  });
};

const onDrawEnd = (lineSliceInteraction, mapRef, callback, hasToolStart) => {
  lineSliceInteraction.on('drawend', (event) => {
    let timeStamp = moment();
    let drawnGeometry = createDrawnPolygon(event.feature);

    if (!callback) {
      return;
    }

    // Check if drawn line is invalid
    const kinks = turf.kinks(drawnGeometry);
    if (kinks.features.length !== 0) {
      NotifyError('Cannot use self-intersecting lines for snipping');
      return;
    }

    mapRef.removeLayer(parcelLayer);
    let listOfLines = [];
    let listOfIntersectingLines = [];
    let callbackReqData = [];
    let newParcelLayer;

    let initialParcelLayerFeatures = parcelLayer.getSource().getFeatures();

    initialParcelLayerFeatures.forEach((feature) => {
      let old_properties = { ...feature.getProperties() };
      const parcelLine = featureToLine(feature);
      if (turf.booleanIntersects(parcelLine, drawnGeometry)) {
        listOfIntersectingLines.push(feature);
      } else {
        let new_line = turf.feature(parcelLine.geometry, {
          ...old_properties,
        });
        listOfLines.push(new_line);
      }
    });

    const listOfIntersectingLinesConverted = convertFeatureToJson(
      listOfIntersectingLines
    );

    listOfIntersectingLinesConverted.forEach((featuree, index) => {
      let properties = { ...featuree.properties };
      delete properties.geometry;

      try {
        let lineSplit = turf.lineSplit(featuree, drawnGeometry);

        if (!Array.isArray(lineSplit.features) || !lineSplit.features.length)
          return;

        // Delete old line
        callbackReqData.push({
          geoJson: turf.feature(featuree.geometry, {
            ...properties,
          }),
          timeStamp,
          type: 'deleteInteraction',
          componentId: properties.actualComponentId ?? properties.componentId,
          undoStackUpdateNotRequired: false,
        });
        turf.geomEach(lineSplit, (geometry) => {
          const tempId = generateTempId();
          const componentId = tempId;
          // Add new lines
          let line = turf.feature(geometry, {
            ...properties,
            temp_id: tempId,
            tempId: tempId,
            componentId: componentId,
            id: componentId,
            ID: componentId,
          });
          line.properties = {
            ...line.properties,
            ...generatePropertiesFromFeature(line),
          };
          line = updateGeoJsonPropertiesInFeature(line);
          listOfLines.push(line);
          let reqData = {
            geoJson: line,
            timeStamp,
            componentId: componentId,
            type: 'drawInteraction',
          };
          callbackReqData.push(reqData);
        });
      } catch (e) {
        parcelLayer.getSource().clear();
        NotifyError(
          `Something went wrong with ${properties.componentIndexing}`,
          { tool: 'line-slice' }
        );
      }
    });
    if (callbackReqData.length) {
      callback('bulkInteraction', callbackReqData, null, true);
    }
    hasToolStart(false);
    newParcelLayer = addFeaturesToMap(
      listOfLines,
      parcelLayer,
      DRAWING_MODE_STYLE
    );
    mapRef.addLayer(newParcelLayer);
  });
};
