import { MapCommandHook } from '../types';
import { WidgetBarTabs } from '../../../../components/types';
import { NumericId, ViewType } from '../../../common/types';
import useViewUtility from '../../hooks/view/useViewUtility';

export enum SwitchViewSource {
  WidgetBar = 'widgetBar',
  ViewPanel = 'viewPanel',
  FeaturePanel = 'featurePanel',
}

export interface CommandOptions {
  currentTab: WidgetBarTabs;

  targetTab: WidgetBarTabs;

  targetViewId: NumericId;

  source: SwitchViewSource;
}

export const useSwitchViewCommand: MapCommandHook<CommandOptions> = (
  map,
  viewId
) => {
  let _options: CommandOptions;

  const {
    getViewByViewId,
    getLinkedMainView,
    getLinkedLatestSubView,
    getSiblingLatestEstViewByDynView,
    getCurrentView,
  } = useViewUtility();

  const execute = async (): Promise<void> => {
    if (!_options) return;

    const { currentTab, targetTab, targetViewId, source } = _options;

    const currentView = getCurrentView();
    const clickedView = getViewByViewId(targetViewId);

    if (!currentView || !clickedView) return;

    const handleSwitchToEstimation = (mainViewId) => {
      const latestEstimationView = getLinkedLatestSubView(
        mainViewId,
        ViewType.ESTIMATION
      );
      const latestDynamicView = getLinkedLatestSubView(
        mainViewId,
        ViewType.DYNAMIC
      );

      if (latestEstimationView) {
        return switchToView(latestEstimationView.viewId);
      } else if (latestDynamicView) {
        return switchToView(latestDynamicView.viewId);
      } else {
        return createNewEstimationView(mainViewId);
      }
    };

    switch (source) {
      case SwitchViewSource.WidgetBar:
        if (
          currentTab !== WidgetBarTabs.ESTIMATION &&
          targetTab === WidgetBarTabs.ESTIMATION
        ) {
          const mainViewId = getLinkedMainView(currentView.viewId);
          if (mainViewId) {
            return handleSwitchToEstimation(mainViewId);
          }
        }

        if (
          currentTab === WidgetBarTabs.ESTIMATION &&
          targetTab === WidgetBarTabs.LAYERS
        ) {
          const parentViewId = getLinkedMainView(currentView.viewId);
          if (parentViewId) {
            return switchToView(parentViewId);
          }
        }
        break;

      case SwitchViewSource.ViewPanel:
        const isSwitchingToStaticMain =
          currentView.viewType === ViewType.STATIC &&
          clickedView.viewType === ViewType.STATIC;
        const isSwitchingToLinkedSubView =
          clickedView.linkedView === currentView.viewId;
        const isSwitchingToDifferentLinkedMain =
          clickedView.linkedView !== currentView.linkedView;

        if (isSwitchingToStaticMain) {
          return refreshView(clickedView.viewId);
        }

        if (isSwitchingToLinkedSubView) {
          return updateUrl(clickedView.viewId);
        }

        if (isSwitchingToDifferentLinkedMain) {
          const clickedMainViewId = getLinkedMainView(clickedView.viewId);
          if (clickedMainViewId) {
            return refreshView(clickedMainViewId);
          }
        }
        break;

      case SwitchViewSource.FeaturePanel:
        if (currentView.viewType === ViewType.ESTIMATION) {
          const mainViewId = getLinkedMainView(currentView.viewId);
          if (mainViewId) {
            return switchToView(mainViewId);
          }
        }

        if (currentView.viewType === ViewType.DYNAMIC) {
          const siblingEstimationView = getSiblingLatestEstViewByDynView(
            currentView.viewId
          );
          if (siblingEstimationView) {
            return switchToView(siblingEstimationView.viewId);
          }
        }
        break;

      default:
        return;
    }
  };

  return {
    init: (options) => {
      _options = options;
    },

    execute,
  };
};

const refreshView = (viewId: number) => {};

const switchToView = (viewId: number) => {};

const updateUrl = (viewId: number) => {};

const createNewEstimationView = (viewId: number) => {};
