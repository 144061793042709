import {
  ArgumentTypes,
  CommandType,
  ExecuteFunction,
  MapCommandFunction,
  MapCommandStack,
  UndoableMapCommand,
  UndoableMapCommandFunction,
} from '../commands/types';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../store/types';
import { NumericId } from '../../common/types';
import { useContext } from 'react';
import MapContext from '@components/pages/project/MapContext';
import {
  addToRedoStack,
  addToUndoStack,
  popFromRedoStack,
  popFromUndoStack,
} from '../../../store/map-editor/actions';
import { DeleteComponentCommand } from '../commands/component/DeleteComponentCommand';
import { DuplicateComponentCommand } from '../commands/component/DuplicateComponentCommand';
import { CopyLayerCommand } from '../commands/layer/CopyLayerCommand';
import { DeleteLayerCommand } from '../commands/layer/DeleteLayerCommand';
import {
  hookCommandMap,
  HookCommandMapValues,
  useHookCommands,
} from '../commands/useHookCommands';
import { MoveComponentToLayerCommand } from '../commands/component/MoveComponentToLayerCommand';
import { ChangeLayerStyleCommand } from '../commands/layer/ChangeLayerStyleCommand';

const functionalCommandMap: Partial<
  Record<
    Partial<CommandType>,
    MapCommandFunction<any> | UndoableMapCommandFunction<any>
  >
> = {
  [CommandType.DUPLICATE]: DuplicateComponentCommand,
  [CommandType.DELETE]: DeleteComponentCommand,
  [CommandType.COPY_LAYER]: CopyLayerCommand,
  [CommandType.DELETE_LAYER]: DeleteLayerCommand,
  [CommandType.MOVE_TO_LAYER]: MoveComponentToLayerCommand,
  [CommandType.CHANGE_LAYER_STYLE]: ChangeLayerStyleCommand,
};

const commandMap = { ...functionalCommandMap, ...hookCommandMap };

export const useMapEditor = () => {
  const map = useContext(MapContext);
  const dispatch = useDispatch();
  const hookCommands = useHookCommands();

  const undoStack = useSelector<IStore, MapCommandStack>(
    (state) => state.mapEditor?.undoStack
  );

  const redoStack = useSelector<IStore, MapCommandStack>(
    (state) => state.mapEditor?.redoStack
  );

  const currentViewId = useSelector<IStore, NumericId>(
    (state) => state.order.currentViewData?.viewId
  );

  /** TODO: Add type inference for arguments */
  const execute: ExecuteFunction = async (type, args, undoable = true) => {
    try {
      const commandFunction = commandMap[type];
      if (!commandFunction) return;

      const command =
        typeof commandFunction === 'string'
          ? /** If the matching value for a given type in commandMap is a string, we need to call that command from hook rather than direct function */
            hookCommands[commandFunction as HookCommandMapValues]
          : commandFunction(map, currentViewId);

      command.init(args);
      await command.execute();

      if ('unExecute' in command && undoable) {
        dispatch(addToUndoStack(command as UndoableMapCommand));
      }
    } catch (error) {
      console.error(`Error while executing command: ${type}`, error);
    }
  };

  const undo = async () => {
    if (undoStack.length === 0) return;

    const commandToUndo = undoStack.pop()!;
    await commandToUndo.unExecute();
    dispatch(addToRedoStack(commandToUndo));
    dispatch(popFromUndoStack());
  };

  const redo = async () => {
    if (redoStack.length === 0) return;

    const commandToRedo = redoStack.pop()!;
    await commandToRedo.execute();
    dispatch(addToUndoStack(commandToRedo));
    dispatch(popFromRedoStack());
  };

  return { execute, undo, redo };
};
