import { FeatureStyle, PlasmicStyleOption } from '../types/layer.types';
import { LayerWithComponents, Style } from '../api/types';
import { NumericId } from '../../common/types';
import { FeatureType } from '../../../components/feature-attributes/types';

export const compareFeatureStyles = (
  style1: Style,
  style2: Style,
  featureType: FeatureType
): boolean => {
  switch (featureType) {
    case FeatureType.POLYGON:
      return (
        style1.color === style2.color &&
        style1.stroke === style2.stroke &&
        style1.opacity === style2.opacity &&
        style1.fillColor === style2.fillColor
      );
    case FeatureType.LINE:
      return style1.stroke === style2.stroke && style1.color === style2.color;
    case FeatureType.POINT:
      return (
        style1.symbolSize === style2.symbolSize &&
        style1.symbolType === style2.symbolType &&
        style1.symbolColor === style2.symbolColor &&
        style1.opacity === style2.opacity
      );
    case FeatureType.PATH:
      return (
        style1.arrowSize === style2.arrowSize &&
        style1.arrowType === style2.arrowType &&
        style1.arrowColor === style2.arrowColor &&
        style1.stroke === style2.stroke &&
        style1.fillColor === style2.fillColor &&
        style1.color === style2.color
      );
    default:
      return false;
  }
};

export const transformToPlasmicStyleOptions = (
  styles: FeatureStyle[] | null,
  layer: LayerWithComponents,
  styleToBeEdited: NumericId | null
): PlasmicStyleOption[] => {
  if (!styles) {
    return [];
  }

  let isFound = false;
  const styleInEditMode = styles.find((style) => style.isEditing);

  const transformed: any[] = styles
    .map((featureStyle) => {
      const checkIfSelected = () => {
        if (isFound) return false;

        if (styleToBeEdited) {
          return featureStyle.styleId === styleToBeEdited;
        }

        if (!!styleInEditMode) {
          return featureStyle.isEditing;
        }

        return compareFeatureStyles(
          featureStyle.style,
          layer.style,
          layer.featureType
        );
      };

      const isSelected = checkIfSelected();

      if (isSelected) {
        isFound = true;
      }

      return {
        ...featureStyle,
        isLegacy: false,
        isSelected,
      };
    })
    .sort((a, b) => {
      if (!a.styleId || !b.styleId) return 1;
      return a.styleId - b.styleId;
    });

  if (!isFound && layer.style) {
    transformed.push({
      style: layer.style,
      isLegacy: true,
      isSelected: true,
    });
  }

  return transformed;
};
