// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon115IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon115Icon(props: Icon115IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 20 20"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M9.037 6.905h3.66c.364 0 .713.15.97.418.258.268.402.631.402 1.01v5.238c0 .38-.144.743-.402 1.01a1.346 1.346 0 01-.97.419h-3.66c-.364 0-.714-.15-.97-.418a1.459 1.459 0 01-.403-1.01v-.477h.458v.476c0 .253.096.495.268.674a.897.897 0 00.647.279h3.66c.242 0 .475-.1.647-.28a.972.972 0 00.268-.673V8.333a.972.972 0 00-.268-.673.897.897 0 00-.647-.28h-3.66a.895.895 0 00-.647.28.972.972 0 00-.268.673v2.381h-.458v-2.38c0-.38.145-.743.402-1.01.257-.269.607-.42.97-.42zM7.207 5h4.575v.476H7.207c-.243 0-.476.1-.647.28a.972.972 0 00-.268.673v4.285c0 .253.096.495.268.674a.897.897 0 00.647.279h3.317l-1.03-1.072.304-.357 1.601 1.667-1.601 1.666-.304-.357 1.03-1.071H7.207c-.364 0-.714-.15-.971-.419a1.459 1.459 0 01-.402-1.01V6.43c0-.38.145-.743.402-1.01.257-.268.607-.419.97-.419z"
        }
        fill={"#999"}
        stroke={"#999"}
        strokeWidth={".5"}
      ></path>
    </svg>
  );
}

export default Icon115Icon;
/* prettier-ignore-end */
