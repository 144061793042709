export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE';

export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAILURE = 'UPDATE_ORDER_FAILURE';

export const ORDER_ACTIONS = {
  SET_LAYER_REQUEST: 'SET_LAYER_REQUEST',
  UPDATE_LAYER_REFS: 'UPDATE_LAYER_REFS',
  CREATE_ORDER_REQUEST: 'CREATE_ORDER_REQUEST',
  CREATE_ORDER_SUCCESS: 'CREATE_ORDER_SUCCESS',
  CREATE_ORDER_FAILURE: 'CREATE_ORDER_FAILURE',
  UPDATE_ORDER_REQUEST: 'UPDATE_ORDER_REQUEST',
  UPDATE_ORDER_SUCCESS: 'UPDATE_ORDER_SUCCESS',
  UPDATE_ORDER_FAILURE: 'UPDATE_ORDER_FAILURE',
  GET_ORDER_DETAIL_REQUEST: 'GET_ORDER_DETAIL_REQUEST',
  GET_ORDER_DETAIL_SUCCESS: 'GET_ORDER_DETAIL_SUCCESS',
  GET_ORDER_DETAIL_FAILURE: 'GET_ORDER_DETAIL_FAILURE',
  SET_PARCEL: 'SET_PARCEL',
  SET_PARCEL_STYLE: 'SET_PARCEL_STYLE',
  SET_AREA: 'SET_AREA',
  UPDATE_NEAR_MAP_DATES: 'UPDATE_NEAR_MAP_DATES',
  GET_NEARMAP_INFO: 'GET_NEARMAP_INFO',
  GET_SAMPLE_ORDERS: 'GET_SAMPLE_ORDERS',
  UPDATE_MAP_TOOLS: 'UPDATE_MAP_TOOLS',
  RESET_NEXTHASH_HOME: 'RESET_NEXTHASH_HOME',
  RED_VIEWS: 'RED_VIEWS',
  RESET_ORDER_DATA: 'RESET_ORDER_DATA',
  IS_PAGE_LOADED: 'IS_PAGE_LOADED',
  STORE_SNAP: 'STORE_SNAP',
  SNAP_LOADER: 'SNAP_LOADER',
  SNAP_GET_LOADER: 'SNAP_GET_LOADER',
  FEATURE_LIST: 'FEATURE_LIST',
  ORDER_BAR: 'ORDER_BAR',
  SHOW_ME_LATER: 'SHOW_ME_LATER',
  RESET_SNAP_LIST: 'RESET_SNAP_LIST',
  CURRENT_VIEW: 'CURRENT_VIEW',
  EDIT_REDUX: 'EDIT_REDUX',
  SET_ORDER_STATUS: 'SET_ORDER_STATUS',
  SET_ORDER_VIEWS: 'SET_ORDER_VIEWS',
  SET_ORDER_VIEW_MODAL: 'SET_ORDER_VIEW_MODAL',
  SET_ORDER_ORGANISATION: 'SET_ORDER_ORGANISATION',
  SET_ORDER_FEATURES: 'SET_ORDER_FEATURES',
  BASE_VIEW: 'BASE_VIEW',
  LAYER_COMPONENTS: 'LAYER_COMPONENTS',
  LAYERS_DATA: 'LAYERS_DATA',
  SHARED_ORDER: 'SHARED_ORDER',
  ADD_TAGS: 'ADD_TAGS',
  ADD_AVAILABLE_TAGS: 'ADD_AVAILABLE_TAGS',
  RELOAD_ORDERS: 'RELOAD_ORDERS',
  ADD_ORDER_ID_FOR_TAG: 'ADD_ORDER_ID_FOR_TAG',
  ADD_SUGGESTED_TAGS: 'ADD_SUGGESTED_TAGS',
  PROVIDE_PEOPLE_DATA_TO_MODAL: 'PROVIDE_PEOPLE_DATA_TO_MODAL',
  ADD_LIST_OF_PEOPLE: 'ADD_LIST_OF_PEOPLE',
  EMPTY_TAG_ARRAY: 'EMPTY_TAG_ARRAY',
  ROTATE_MAP: 'ROTATE_MAP',
  UPDATE_ORDER_TAGS: 'UPDATE_ORDER_TAGS',
  UPDATE_PROPERTY_NAME: 'UPDATE_PROPERTY_NAME',
  UPDATE_ORDER_DETAILS: 'UPDATE_ORDER_DETAILS',
  UPDATE_ORDER_IMAGERY_DATE: 'UPDATE_ORDER_IMAGERY_DATE',
  IS_BULK_TAG: 'IS_BULK_TAG',
  UPDATE_TOPOLOGY_WARNING: 'UPDATE_TOPOLOGY_WARNING',
  REMOVE_COMPONENT_TOPOLOGY_WARNING: 'REMOVE_COMPONENT_TOPOLOGY_WARNING',
  SELECT_DESELECT_FEATURE: 'selectDeselectFeatures',
  IS_EDITABLE_VIEW: 'IS_EDITABLE_VIEW',
  UPLOAD_LAYER_ON_MAP_MODAL_OPEN: 'UPLOAD_LAYER_ON_MAP_MODAL_OPEN',
  UPLOAD_LAYER_ON_MAP_MODAL_CLOSE: 'UPLOAD_LAYER_ON_MAP_MODAL_CLOSE',
  USER_ACCESS_RIGHT: 'USER_ACCESS_RIGHT',
  CREATOR_ID: 'CREATOR_ID',
  SET_ATTRIBUTE_PANEL_VISIBILITY: 'SET_ATTRIBUTE_PANEL_VISIBILITY',
  SET_VISIBLE_ATTRIBUTES_IN_LAYER_PANEL:
    'SET_VISIBLE_ATTRIBUTES_IN_LAYER_PANEL',
  SET_SELECTED_NEARMAP: 'SET_SELECTED_NEARMAP',
  SET_MODIFY_PARCEL_STATUS: 'SET_MODIFY_PARCEL_STATUS',
  SET_PURCHASE_MISSING_STATUS: 'SET_PURCHASE_MISSING_STATUS',
  TOGGLE_SNAPSHOT_V1_FLOW: 'TOGGLE_SNAPSHOT_V1_FLOW',
  TOGGLE_SNAPSHOT_V2_FLOW: 'TOGGLE_SNAPSHOT_V2_FLOW',
  TOGGLE_PROPERTY_REPORT: 'TOGGLE_PROPERTY_REPORT',
  SET_IS_DELETING_PARCEL: 'SET_IS_DELETING_PARCEL',
  SET_IS_DRAFT_NEARMAP_LIMIT_EXHAUSTED: 'SET_IS_DRAFT_NEARMAP_LIMIT_EXHAUSTED',
  SET_DRAFT_NEARMAP_REQUIRED_LIMIT: 'SET_DRAFT_NEARMAP_REQUIRED_LIMIT',
  SET_LOADING: 'SET_LOADING',
  SET_EM_VIEW_CHECKED_ITEMS: 'SET_EM_VIEW_CHECKED_ITEMS',
  SET_HIGHLIGHTED_COMPONENTS: 'SET_HIGHLIGHTED_COMPONENTS',
  REMOVE_LAYER_BY_ID: 'REMOVE_LAYER_BY_ID',
  REMOVE_LAYER_COMPONENTS_BY_ID: 'REMOVE_LAYER_COMPONENTS_BY_ID',
  ADD_LAYER_COMPONENTS: 'ADD_LAYER_COMPONENTS',
};
