// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Delete2IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Delete2Icon(props: Delete2IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 20 20"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M8.02 5.9h-.103a.102.102 0 00.104-.1v.1h3.958v-.1c0 .055.047.1.104.1h-.104v.9h.938v-1c0-.441-.374-.8-.834-.8H7.917c-.46 0-.834.359-.834.8v1h.938v-.9zm6.563.9H5.417A.408.408 0 005 7.2v.4c0 .055.047.1.104.1h.787l.321 6.538c.021.426.388.762.832.762h5.912c.445 0 .81-.335.832-.762l.321-6.538h.787A.102.102 0 0015 7.6v-.4c0-.221-.186-.4-.417-.4zm-1.728 7.3h-5.71l-.316-6.4h6.342l-.316 6.4z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Delete2Icon;
/* prettier-ignore-end */
