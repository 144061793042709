import * as turf from '@turf/turf';
import { Feature } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import { LineString } from 'ol/geom';
import Polygon from 'ol/geom/Polygon';

export const generatePropertiesFromFeature = function (
  featurejson,
  prevProps = {}
) {
  try {
    switch (featurejson?.geometry?.type) {
      case 'Polygon':
        const area = Number(calculateAreaFromJson(featurejson));
        const perimeter = Number(calculatePerimeterFromJson(featurejson));
        delete prevProps.Perimeter;

        return {
          ...prevProps,
          'Surface Area': area,
          area: area,
          perimeter: perimeter,
          Length: null,
          Area: area,
          unit: 'sq ft',
        };
      case 'LineString':
        delete prevProps.length;
        const length = Number(calculateLengthFromJson(featurejson));
        return {
          ...prevProps,
          length,
          Length: length,
          unit: 'ft',
          perimeter: length,
        };
      case 'Point':
        return {
          ...prevProps,
          unit: 'sq ft',
          'Ring Area': 12.56,
          area: 12.56,
          perimeter: null,
        };
      default:
        return { ...prevProps };
    }
  } catch (e) {}
};

export const calculateAreaFromJson = function (featurejson) {
  let feature = new Feature({
    geometry: new Polygon(featurejson.geometry.coordinates),
  });
  let coordArr = featureToCoordArr(feature);
  let polygon = turf.polygon(coordArr);
  return (turf.area(polygon) * 10.7639).toFixed(2);
};

export const calculateLengthFromJson = function (featurejson) {
  let feature = new Feature({
    geometry: new LineString(featurejson.geometry.coordinates),
  });
  let coordArr = featureToCoordArr(feature);
  let polygon = turf.lineString(coordArr);
  return (turf.length(polygon) * 3280.84).toFixed(2);
};

export const calculatePerimeterFromJson = function (featurejson) {
  let feature = new Feature({
    geometry: new Polygon(featurejson.geometry.coordinates),
  });
  let coordArr = featureToCoordArr(feature);
  let polygon = turf.polygon(coordArr);
  let boundary = turf.polygonToLine(polygon);
  return (turf.length(boundary) * 3280.84).toFixed(2);
};

const featureToCoordArr = (feature) => {
  let geojson_geom = new GeoJSON();
  let projGeometry = feature.getGeometry().clone();
  let featurePolygon = geojson_geom.writeGeometry(projGeometry);
  let featureGeojson = JSON.parse(featurePolygon);
  return featureGeojson.coordinates;
};
