import { NotifyError } from '@/helpers/notification-utils';
import { getUserDetailThunk } from '../user/thunks';
import {
  deleteInvitationFailure,
  deleteInvitationRequest,
  deleteInvitationsuccess,
  getOrgListFailure,
  getOrgListRequest,
  getOrgListSuccess,
  getUserListFailure,
  getUserListRequest,
  getUserListsuccess,
  getUserRoles,
  invitedUserListFailure,
  invitedUserListRequest,
  invitedUserListsuccess,
  joinRequestSuccess,
  sendInvitationFailure,
  sendInvitationRequest,
  sendInvitationsuccess,
} from './actions';

import { _capitalize } from '@/helpers/utilities/lodashUtils';
import { isPageLoaded } from '../order/actions';
import { catchError, getAxiosInstance } from '@/helpers/utilities/api-utils';
import {
  getIdsArrayFromObjArray,
  getWorkspaceTokenInfo,
  identifyEvent,
  trackEvents,
} from '@/helpers/utilities';
import { showNotification } from '../../components/storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../components/storybook/NotificationToast/types';

const axiosInstance = getAxiosInstance();

export const getUserListThunk = (payload) => (dispatch) => {
  dispatch(getUserListRequest());

  axiosInstance
    .get(`/api/v1/organization/users/${payload.organisationId}`, {
      params: { psz: 50 },
    })
    .then(async (response) => {
      if (response.status === 200) {
        const data = response.data.map((user) => {
          if (!user.order_creation_role_id) {
            user.order_creation_role_id = 4;
          }

          if (!user.role_id) {
            user.role_id = 4;
          }
          return user;
        });
        dispatch(getUserListsuccess(data));
        // NEED TO REMOVE CAUSE ITS CLEARING ALL BULK ORDERS DON't KNOW WHY IT's BEING IMPLEMENTED
        // dispatch(bulkOrderReduxCleanup())
        // dispatch(resetOrderData())
        dispatch(isPageLoaded(true));
      }
    })
    .catch((err) => {
      NotifyError(err.message);
      dispatch(getUserListFailure());
    });
};

export const getOrgListThunk =
  (email, shouldUpdateToken = false) =>
  (dispatch) => {
    dispatch(getOrgListRequest());

    axiosInstance
      .get('/api/idam/v1/workspace', {
        params: { psz: 50, includeToken: shouldUpdateToken },
      })
      .then(async (response) => {
        if (response.status === 200) {
          const data = response.data;
          if (shouldUpdateToken) {
            const allWorkspaceTokens = getWorkspaceTokenInfo(data.data);
            localStorage.setItem(
              'sr_workspaces',
              JSON.stringify(allWorkspaceTokens)
            );
          }
          dispatch(getOrgListSuccess(data.data));
          const allWorkspaceIds = getIdsArrayFromObjArray(data.data);
          const allWorkspaceIdsStr = allWorkspaceIds.map(String);
          identifyEvent(email, { workspaceId: allWorkspaceIdsStr });
        }
      })
      .catch((err) => {
        NotifyError(err?.message);
        dispatch(getOrgListFailure());
      });
  };

/** Not being used */
export const sendInvitationThunk = (payload) => (dispatch) => {
  dispatch(sendInvitationRequest());
  axiosInstance
    .post(`/api/v1/organization/invite-users/${payload.organisationId}`, {
      emails: payload.emails,
    })
    .then(async (response) => {
      if (response.status === 200) {
        dispatch(sendInvitationsuccess(payload.organisationId));
        dispatch(getUserListThunk({ organisationId: payload.organisationId }));
        dispatch(
          getInvitedUserThunk({
            organisationId: payload.organisationId,
          })
        );
      } else {
        NotifyError(response.data.data.msg);
        dispatch(sendInvitationFailure());
      }
    })
    .catch((err) => {
      NotifyError(err.response.data.msg);
      dispatch(sendInvitationFailure());
    });
};

export const membersInviteThunk = (payload) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(sendInvitationRequest());
    axiosInstance
      .post(
        `/api/idam/v1/workspace/invite-users/${payload.orgId}`,
        payload.formData
      )
      .then(async (response) => {
        if (response.status === 200) {
          showNotification(
            NOTIFICATIONS_TYPES.SUCCESS,
            _capitalize(response.data.msg || 'Member invited succesfully')
          );
          dispatch(sendInvitationsuccess(payload.orgId));
          dispatch(getUserListThunk({ organisationId: payload.orgId }));
          dispatch(
            getInvitedUserThunk({
              organisationId: payload.orgId,
            })
          );
          resolve(response.data);
        } else {
          NotifyError(_capitalize(response.data.msg || 'Something went wrong'));
          dispatch(sendInvitationFailure());
          reject();
        }
      })
      .catch((err) => {
        showNotification(
          NOTIFICATIONS_TYPES.ERROR,
          _capitalize(err.detail || 'Something went wrong')
        );
        dispatch(sendInvitationFailure());
        reject(err);
      });
  });
};

export const getInvitedUserThunk = (payload) => (dispatch) => {
  dispatch(invitedUserListRequest());
  axiosInstance
    .get(`/api/v1/organization/invite-userlist/${payload.organisationId}`, {
      params: { psz: 50 },
    })
    .then(async (response) => {
      const data = response.data.map((user) => {
        if (!user.order_creation_role_id) {
          user.order_creation_role_id = 4;
        }

        if (!user.role_id) {
          user.role_id = 4;
        }
        return user;
      });
      dispatch(invitedUserListsuccess(data));
    })
    .catch((err) => {
      NotifyError(err.message);
      dispatch(invitedUserListFailure());
    });
};

export const getUserRolesList = () => (dispatch) => {
  axiosInstance
    .get('/api/v1/role/')
    .then(async (response) => {
      if (response.status === 200) {
        const data = response.data;
        dispatch(getUserRoles(data.data));
      }
    })
    .catch((err) => {
      NotifyError(err.message);
    });
};

/** Not being used */
export const updateUserRoleThunk = (payload) => (dispatch) => {
  axiosInstance
    .put(`/api/v1/user-role/${payload.userId}/${payload.roleId}`)
    .then(async (response) => {
      if (response.status === 200) {
        dispatch(getUserDetailThunk());
        dispatch(
          getUserListThunk({
            organisationId: payload.organisationId,
          })
        );
      } else {
        NotifyError('Failed');
      }
    })
    .catch((err) => {
      NotifyError(err.message);
    });
};

/** Not being used */
export const updateUserDataAccessThunk = (payload) => (dispatch) => {
  const data = { data_access_role: payload.na };
  axiosInstance
    .patch(`/api/idam/v1/workspace/${payload.userId}`, data)
    .then(async (response) => {
      if (response.status === 200) {
        dispatch(getUserDetailThunk());
        dispatch(
          getUserListThunk({
            organisationId: payload.organisationId,
          })
        );
      } else {
        NotifyError('Failed');
      }
    })
    .catch((err) => {
      NotifyError(err.message);
    });
};

/** Not being used */
export const activateDeletedUser = (payload) => (dispatch) => {
  axiosInstance
    .put(`/api/v1/users/${payload.userId}`)
    .then(async (response) => {
      if (response.status === 200) {
        trackEvents('team-management__members_reactivated', {
          email: payload.email,
          userId: payload.userId,
        });
        dispatch(
          getUserListThunk({
            organisationId: payload.organisationId,
          })
        );
        showNotification(
          NOTIFICATIONS_TYPES.SUCCESS,
          response.data.msg ||
            response.data.message ||
            'User Activated successfully'
        );
        dispatch(getUserDetailThunk());
      } else {
        NotifyError('Failed');
      }
    })
    .catch((err) => {
      NotifyError(err.message);
    });
};

export const deleteLoggedUserThunk = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`/api/v1/users/${payload.userId}`)
      .then(async (response) => {
        if (response.status === 200) {
          showNotification(
            NOTIFICATIONS_TYPES.SUCCESS,
            'You have successfully exited the workspace.'
          );
          resolve();
        } else {
          NotifyError('Failed');
        }
      })
      .catch((err) => {
        NotifyError(err.message);
        reject();
      });
  });
};

/** Not being used */
export const deleteUserFromOrganisation = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`/api/v1/users/${payload.userId}`)
      .then(async (response) => {
        if (response.status === 200) {
          trackEvents('team-management__members_deactivated', {
            email: payload.email,
            userId: payload.userId,
          });
          dispatch(
            getUserListThunk({
              organisationId: payload.organisationId,
            })
          );
          showNotification(
            NOTIFICATIONS_TYPES.SUCCESS,
            `${payload.username} has been removed from the workspace.`
          );
          dispatch(getUserDetailThunk());
          resolve();
        } else {
          NotifyError('Failed');
        }
      })
      .catch((err) => {
        NotifyError(err.message);
        reject();
      });
  });
};

/** Not being used */
export const deleteInvitationThunk = (payload) => (dispatch) => {
  dispatch(deleteInvitationRequest());
  axiosInstance
    .delete(
      `/api/v1/organization/invite-users/${payload.organisationId}/${payload.inviteId}`
    )
    .then(async (response) => {
      showNotification(
        NOTIFICATIONS_TYPES.SUCCESS,
        response.data.msg || 'User Invite Canceled'
      );
      trackEvents('team-management__members_invitation-revoked', {
        inviteId: payload.inviteId,
        email: payload.email,
      });
      dispatch(deleteInvitationsuccess(payload.organisationId));
      dispatch(getUserListThunk({ organisationId: payload.organisationId }));
      dispatch(
        getInvitedUserThunk({
          organisationId: payload.organisationId,
        })
      );
    })
    .catch((err) => {
      NotifyError(err.message);
      dispatch(deleteInvitationFailure());
    });
};

export const getJoinRequestThunk = (payload) => (dispatch) => {
  axiosInstance
    .get(`/api/user/joinRequest/${payload.organisationId}`, {
      psz: 50,
    })
    .then(async (response) => {
      const data = response.data;
      dispatch(joinRequestSuccess(data.data));
    })
    .catch((err) => {
      NotifyError(err.message);
    });
};

export const acceptRejectThunk = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var formData = new FormData();
    formData.append('userId', payload.userId);
    formData.append('orgId', payload.orgId);

    axiosInstance
      .post(
        payload.status
          ? '/api/user/approveJoinRequest/'
          : '/api/user/rejectJoinRequest/',
        formData
      )
      .then((res) => {
        const data = res.data;
        if (data.status === 200) {
          showNotification(NOTIFICATIONS_TYPES.SUCCESS, data.success);
        } else {
          NotifyError(data.error);
        }
      })
      .catch(catchError);
  });
};
