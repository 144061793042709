import { useSelector } from 'react-redux';
import { IStore } from '@/store/types';
import { ViewListItem } from '../api/types';

/** Returns current view. If view is loading returns "null" */
const useCurrentView = () => {
  return useSelector<IStore, ViewListItem | null>(
    (state) =>
      (state.order.currentViewData as ViewListItem)
  );
};

export default useCurrentView;
