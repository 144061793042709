import React, { useMemo } from 'react';
import { Row } from 'antd';
import { useHoveredComponentId } from '../../../../../../jotai/atoms/property/estimation/service-item';
import { Typography } from '@siterecon/sr-styles/dist/elements';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../../../store/types';
import { LayerWithComponents } from '../../../../../../modules/property/api/types';
import { FeatureType } from '../../../../../../components/feature-attributes/types';
import { getLayerUnit } from '../../../../../../helpers/utils';

const HoverQuickTip: React.FC = () => {
  const [componentId] = useHoveredComponentId();

  const layers = useSelector<IStore, LayerWithComponents[]>(
    (state) => state.order.layerList
  );

  const componentData = useMemo(() => {
    if (!componentId || !layers) return null;

    const getMeasurement = (component: any, featureType: FeatureType) => {
      if (
        [FeatureType.LINE, FeatureType.PATH].includes(
          featureType as FeatureType
        )
      ) {
        return (
          component.length ??
          component.properties['Length'] ??
          component.area ??
          0
        );
      } else {
        return component.area ?? component.Area ?? 0;
      }
    };

    const getComponentData = (
      componentId: number,
      layers: LayerWithComponents[]
    ) => {
      for (const layer of layers) {
        for (const component of layer.components) {
          if (component.componentId === componentId) {
            return {
              layerName: layer.name ?? 'Unknown Layer',
              componentName: component.componentName ?? 'Unknown Component',
              area: getMeasurement(component, layer.featureType)?.toFixed(1),
              unit:
                component.unit === 'N'
                  ? 'sf'
                  : component.unit ??
                    getLayerUnit(layer.featureType) ??
                    'units',
            };
          }
        }
      }
      return null;
    };

    return getComponentData(componentId, layers);
  }, [componentId, layers]);

  if (!componentData) return null;

  const { layerName, componentName, area, unit } = componentData;

  return (
    <Row>
      <Typography
        fontSize={10}
        fontWeight={500}
        lineHeight={'16px'}
        className='mr-1'
      >
        {layerName} {componentName}:
      </Typography>
      <Typography fontSize={10} fontWeight={400} lineHeight={'16px'}>
        {area} {unit}
      </Typography>
    </Row>
  );
};

export default HoverQuickTip;
