import { useContext, useEffect, useRef } from 'react';
import MapContext from '@/components/pages/project/MapContext';
import OlMap from 'ol/Map';
import { useSelector } from 'react-redux';
import { IStore } from '../../../store/types';
import { OrderData } from '../../../store/order/state';
import { renderParcelLayer } from '@/helpers/mapUtils/parcelLayerUtils';
import { useLocation } from 'react-router-dom';
import BaseLayer from 'ol/layer/Base';

export const useParcelLayerToggle = () => {
  const mapRef: OlMap = useContext(MapContext);
  const location = useLocation();

  const configurations = useSelector<IStore, any>(
    (state) => state.configurations
  );
  const orderSummary = useSelector<IStore, OrderData | null>(
    (state) => state.order.orderData
  )!;

  const isParcelEnabled = useSelector<IStore, Boolean>(
    (state) => state.ui.isParcelEnabled
  );

  const handleToggle = (checked: boolean) => {
    let parcelLayer: BaseLayer | null = null;

    mapRef.getLayers().forEach((layer) => {
      if (layer.getProperties().id === 'PARCEL') {
        parcelLayer = layer;
      }
    });

    if (checked) {
      if (parcelLayer) {
        mapRef.removeLayer(parcelLayer);
      }

      renderParcelLayer(
        mapRef,
        JSON.parse(orderSummary.parcelJson),
        JSON.parse(orderSummary?.parcel_style?.style),
        orderSummary,
        configurations,
        location.search
      );

      return;
    }

    /** We need to remove PARCEL layer */
    if (parcelLayer) {
      mapRef.removeLayer(parcelLayer);
    }
  };

  useEffect(() => {
    /** Trigger parcel re-render when the style changes */
    if (orderSummary?.parcel_style?.style && isParcelEnabled) {
      handleToggle(true);
    }
  }, [orderSummary?.parcel_style?.style]);

  return { handleToggle };
};
