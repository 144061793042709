import { ReactNode } from 'react';
import { FeatureType } from '../../../../../components/feature-attributes/types';
import { Nullable } from '../../../../common/types';

export enum TakeoffActionCenterType {
  LAYER = 'layer',
  SINGLE = 'single',
  MULTI = 'multi',
}

export interface LayerInfo {
  type: TakeoffActionCenterType;
  id: number;
  name: string;
  area: number;
  perimeter: Nullable<number>;
  featureType: FeatureType;
  selected: number;
  total: number;
}

export interface LayerAction {
  disable?: boolean;
  onClick: () => void;
  disableTooltip: string;
}

export interface LayerStyleObject {
  fillColor?: string;
  strokeColor?: string;
  symbolType?: string;
  symbolColor?: string;
}

interface LayerListItem {
  id: number;
  name: string;
  styleObj: LayerStyleObject;
}

export interface MoveToLayer {
  disable: boolean;
  disableTooltip: string;
  layerList: LayerListItem[];
  onSelect: (layerId: number) => void;
}

export interface PlasmicTakeoffActionCenterProps {
  layerInfo: LayerInfo;
  componentName?: string;
  layerSettings: LayerAction;
  copyLayer: LayerAction;
  deleteLayer: LayerAction;
  copyGeoJson: LayerAction;
  moveToLayer: MoveToLayer;
  duplicateItem: LayerAction;
  mergeItems: LayerAction;
  defaultColorOptions: LayerStyleObject[];
  layerOpacity: number;
  strokeSize: number;
  showLabels: boolean;
  showVertices: boolean;
  isLayerStylingOpen: boolean;
  onLayerStylingToggle: (open: boolean) => void;
  serviceAssignmentSlot: ReactNode;
}

export enum AcDropdown {
  LayerStyle = 'layerStyle',
  ServiceCatalogue = 'serviceCatalogue',
  None = 'none'
}