import { FC } from 'react';
import { NumericId, ViewType } from '../../../common/types';
import useRouterPlus from '../../../router-plus/hooks/use-router-plus';
import { isValidViewId } from '../../../../components/pages/project/projectComponents/helpers';
import useViewList from '../../../../jotai/atoms/views/useViewList';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../store/types';
import { HierarchicalView, PropertyView } from '../../types/view.types';
import {
  baseView,
  currentView as setCurrentView,
  editableView,
  setAttributePanelVisibility,
  setVisibleAttributesInLayerPanel,
} from '@/store/order/actions';
import useViewUtility from './useViewUtility';
import { removeDragBoxInteraction } from '@helpers/mapUtils/tools/multiSelecteTool';
import { removeCutInteraction } from '@helpers/mapUtils/tools/cutTool';
import { removeLineSliceInteraction } from '@helpers/mapUtils/tools/lineSliceTool';
import { removeDoughnutInteraction } from '@helpers/mapUtils/tools/doughnutTool';
import { removeDrawInteraction } from '@helpers/mapUtils/tools/drawTool';
import { removeMeasureInteraction } from '@helpers/mapUtils/tools/measurementTool';
import { removeModifyInteraction } from '@helpers/mapUtils/tools/modifyTool';
import { removeSelectInteraction } from '@helpers/mapUtils/featuresUtils';
import { removeSnipInteraction } from '@helpers/mapUtils/tools/snipTool';
import { resetCrossLayerSnap } from '@helpers/mapUtils/tools/snapping';
import { useMap } from '../useMap';
import { MapTool } from '../../../project/types';
import { setActiveTool, setMapClassnames } from '@store/user/actions';
import OlMap from 'ol/Map';
import { useOrderStatus } from '../../../project/guards/OrderStatus';
import useCurrentView from '../../../../jotai/atoms/views/useCurrentView';
import omit from 'lodash/omit';
import { removeAllLayersExceptDefault } from '../takeoff/useLayerRender';

export const useSwitchView = () => {
  const map = useMap();
  const { getViewByViewId } = useViewUtility();
  const orderStatus = useOrderStatus();
  const { setCurrentView: setAtomCurrentView } = useCurrentView();

  // const { selectedTab, handleWidgetBarTabChange } = useWidgetBarTabContext();

  const dispatch = useDispatch();

  const { currentView: atomCurrentView } = useCurrentView();
  const currentView = useSelector<IStore, PropertyView | undefined>(
    (state) => state.order.currentViewData
  );

  const changeView = (view: HierarchicalView) => {
    if (
      view.viewType === ViewType.ESTIMATION &&
      currentView &&
      currentView.viewType === ViewType.STATIC
    ) {
      dispatch(setActiveTool(MapTool.Select));
      dispatch(setMapClassnames('select'));
      // removeMapInteractions(map);
    }

    if (
      currentView &&
      !(
        currentView.viewId === view.viewId ||
        /** Currently selected view is a static view and user selects it's estimation or dynamic view */
        (currentView.viewType === ViewType.STATIC &&
          view.viewType !== ViewType.STATIC &&
          (view.linkedView === currentView?.viewId ||
            view.viewId === currentView?.viewId)) ||
        /** Currently selected view is a estimation view and user selects other estimation or parent view  */
        ((currentView.viewType === ViewType.ESTIMATION ||
          currentView.viewType === ViewType.DYNAMIC) &&
          (view.linkedView === currentView.linkedView ||
            view.viewId === currentView.linkedView))
      )
    ) {
      removeAllLayersExceptDefault(map);
    }

    setAtomCurrentView(omit(view, 'subViews'));
    /** TODO: Remove the usage of current view from redux and use the `jotai` atom at all the places in future */
    dispatch(setCurrentView(omit(view, 'subViews')));

    // if (
    //   [ViewType.ESTIMATION, ViewType.DYNAMIC].includes(view.viewType) &&
    //   orderStatus !== OrderStatus.Draft
    // ) {
    //   handleWidgetBarTabChange(WidgetBarTabs.ESTIMATION, selectedTab);
    // }
    //
    // if (
    //   view.viewType === ViewType.STATIC &&
    //   orderStatus !== OrderStatus.Draft
    // ) {
    //   handleWidgetBarTabChange(WidgetBarTabs.LAYERS, selectedTab);
    // }

    dispatch(baseView({ isBaseView: view.isBaseView }));
    dispatch(editableView({ isEditableView: view.isEditable }));

    dispatch(setAttributePanelVisibility(false));
    dispatch(setVisibleAttributesInLayerPanel([]));
  };

  const { viewList } = useViewList();

  const { redirect } = useRouterPlus(
    'viewId',
    {
      onChange: (viewId, prevViewId) => {
        if (
          !viewId ||
          !isValidViewId(viewId) ||
          (viewList && !getViewByViewId(Number(viewId)))
        ) {
          /** If viewId is null or undefined, it means url does not have any viewId yet.
           *  We have to add currentSelectedView (default) to the url
           **/

          if (currentView && currentView.viewId) {
            redirect(String(currentView.viewId), true);
          }

          return;
        }

        if (
          currentView?.viewId &&
          atomCurrentView?.viewId &&
          currentView.viewId === Number(viewId) &&
          atomCurrentView.viewId === Number(viewId)
        ) {
          return;
        }

        const view = getViewByViewId(Number(viewId));
        if (view) {
          changeView(view);
        }
      },
    },
    [viewList?.length, currentView?.viewId, atomCurrentView?.viewId]
  );

  const switchView = (viewId: NumericId) => {
    redirect(String(viewId), true);
  };

  return { switchView };
};

export const withSwitchView = (Component: any) => {
  const WrappedComponent: FC<any> = (props) => {
    const { switchView } = useSwitchView();
    return <Component switchView={switchView} {...props} />;
  };

  return WrappedComponent;
};

const removeMapInteractions = (map: OlMap) => {
  removeDragBoxInteraction(map);
  removeCutInteraction(map);
  removeLineSliceInteraction(map);
  removeDoughnutInteraction(map);
  removeDrawInteraction(map);
  removeMeasureInteraction(map);
  removeModifyInteraction(map);
  removeSelectInteraction(map);
  removeSnipInteraction(map);
  resetCrossLayerSnap(map);
};
