import { GeoJSON } from 'ol/format';
import * as turf from '@turf/turf';
import { Vector as VectorSource } from 'ol/source';

export const generateTempId = () => {
  return `temp_${Math.random()}`;
};

export const checkIfCoordinatesIdentical = (
  coordinatesArray,
  type = 'line'
) => {
  if (coordinatesArray && coordinatesArray.length === 0) return false;

  const [firstLat, firstLng] = coordinatesArray[0];

  const compareCoordinates =
    type === 'polygon' ? coordinatesArray.slice(0, -1) : coordinatesArray;

  return compareCoordinates.every(
    ([lat, lng]) => lat === firstLat && lng === firstLng
  );
};

export const featureToPolygon = (feature) => {
  let geoJSONGeom = new GeoJSON();
  const parcelGeometry = feature['values_']['geometry'].clone();
  const parcelCoordinates = geoJSONGeom.writeGeometry(
    parcelGeometry.transform('EPSG:3857', 'EPSG:4326')
  );

  try {
    return turf.cleanCoords(
      turf.polygon(JSON.parse(parcelCoordinates).coordinates)
    );
  } catch {
    return turf.polygon(JSON.parse(parcelCoordinates).coordinates);
  }
};

export const featureToLine = (feature) => {
  let geoJSONGeom = new GeoJSON();
  const parcelGeometry = feature['values_']['geometry'].clone();
  const parcelCoordinates = geoJSONGeom.writeGeometry(
    parcelGeometry.transform('EPSG:3857', 'EPSG:4326')
  );
  return turf.cleanCoords(
    turf.lineString(JSON.parse(parcelCoordinates).coordinates)
  );
};

export const convertFeatureToJson = (geometries = []) => {
  if (geometries && Array.isArray(geometries) && geometries.length > 0) {
    return geometries.map((geometryItem) => {
      const properties = geometryItem.getProperties();
      const geomType = geometryItem.getGeometry().getType();
      const geomCoordinates =
        geomType === 'LineString'
          ? featureToLine(geometryItem).geometry.coordinates
          : featureToPolygon(geometryItem).geometry.coordinates;

      return {
        type: 'Feature',
        properties: properties,
        geometry: { type: geomType, coordinates: geomCoordinates },
      };
    });
  }
  return [];
};

export const addFeaturesToMap = (listOfFeatures, parcelLayer, modeStyle) => {
  let vectorSource = null;
  let featureCollection = turf.featureCollection(listOfFeatures);
  vectorSource = new VectorSource({
    features: new GeoJSON().readFeatures(featureCollection, {
      featureProjection: 'EPSG:3857',
    }),
    crossOrigin: 'Anonymous',
  });
  parcelLayer.setSource(vectorSource);
  parcelLayer.setStyle(modeStyle);
  return parcelLayer;
};

export const createDrawnPolygon = (feature) => {
  let geoJSONGeom = new GeoJSON();
  const parcelGeometry = feature['values_']['geometry'].clone();
  const parcelCoordinates = geoJSONGeom.writeGeometry(
    parcelGeometry.transform('EPSG:3857', 'EPSG:4326')
  );
  return JSON.parse(parcelCoordinates);
};

export const createVectorSourceFromFeatColl = (featureCollection) => {
  return new VectorSource({
    features: new GeoJSON().readFeatures(featureCollection, {
      featureProjection: 'EPSG:3857',
    }),
    crossOrigin: 'Anonymous',
  });
};
