export const ENV = process.env.REACT_APP_ENV;

export const IS_LOGGING_ENABLED = true;
export const AREA_ID = process.env.REACT_APP_AREA_ID;
export const PERIMETER_ID = process.env.REACT_APP_PERIMETER_ID;
export const ANNUAL_HOLIDAYS = process.env.REACT_APP_HOLIDAYS;

export const BASE_URL = process.env.REACT_APP_BACKEND;
export const PENDO_API_KEY = process.env.REACT_PENDO_API_KEY;

export const REACT_APP_BOLT_TURF_PER_UNIT_CHARGE = process.env.REACT_APP_BOLT_TURF_PER_UNIT_CHARGE;
export const REACT_APP_BOLT_PL_PER_UNIT_CHARGE = process.env.REACT_APP_BOLT_PL_PER_UNIT_CHARGE;
export const REACT_APP_ACCURATE_MEASUREMENT_FEATURE_COLLECTION = process.env.REACT_APP_ACCURATE_MEASUREMENT_FEATURE_COLLECTION;

export const REACT_APP_SHOW_MOBILE_CARD = process.env.REACT_APP_SHOW_MOBILE_CARD === "true";

export const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const MILLISECONDS_IN_ONE_MINUTE = 60000;

export const ROCKET_ID = process.env.REACT_APP_LOG_ROCKET_API_ID
export const REACT_APP_USER_PILOT = process.env.REACT_APP_USER_PILOT
export const IS_LOG_ROCKET_ENABLED = process.env.REACT_APP_IS_LOG_ROCKET_ENABLED === "true"
export const IS_USER_PILOT_ENABLED = process.env.REACT_APP_USER_PILOT_ENABLED === 'true'
export const REACT_APP_GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID
export const REACT_APP_MICROSOFT_CLIENT_ID = process.env.REACT_APP_MICROSOFT_CLIENT_ID

export const NEARMAP_PUBLIC_KEY = process.env.REACT_APP_NEARMAP_PUBLIC_KEY

export const LAYER_PANEL_ATTRIBUTE_COL_WIDTH = 115;
export const BING_MAPS_KEY = 'Aj3E4nOuLRMJzi0JNE7KYCkcbxNLvYR4CchEkAkZS8pGoCg7AYneDlbHBe71q0bG';
export const DEFAULT_APP_TITLE = 'Automate Takeoffs with SiteRecon';
