import { SHARE_MAP_PAGE } from '../../../../components/constants';
import { NumericId } from '../../../common/types';
import useFetchFeatureStyleCatalogue from './useFetchFeatureStyleCatalogue';

const useFetchFeatureStyle = (featureId?: NumericId) => {
  
  const isShareMapPage = window.location.pathname.includes(SHARE_MAP_PAGE);
  if(isShareMapPage) return null;

  const { featureStyleCatalogueMap } = useFetchFeatureStyleCatalogue();

  if (!featureId) return null;

  return featureStyleCatalogueMap[featureId];
};

export default useFetchFeatureStyle;
