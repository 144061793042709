import { useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'antd';
import * as API_ENDPOINTS from '../../../helpers/constants/APIEndpoints';
import { replaceParams } from '../../../helpers/utilities/linkUtils';
import { _get } from '../../../helpers/utilities/lodashUtils';
import { getAxiosInstance } from '@/helpers/utilities/api-utils';
import { showNotification } from '../../storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../storybook/NotificationToast/types';
import MrCopyLayerModal from '../../PlasmicComponents/MrCopyLayerModal';
import { NotifyError } from '@/helpers/notification-utils';
import { ViewType } from '../../../modules/common/types';
import './style.less';
import useViewList from '../../../jotai/atoms/views/useViewList';

const axiosInstance = getAxiosInstance();

const CopyLayer = ({
  isOpen,
  selectedView,
  selectedLayer,
  handleModalOpen,
}) => {
  const [replace, setReplace] = useState(false);

  const { viewList } = useViewList();

  const handleCopyView = useCallback(
    (view) => {
      if (!view) {
        NotifyError('Please select view');
        return;
      }

      const reqData = {
        targetViewId: view.id,
      };

      axiosInstance
        .post(
          replaceParams(
            replace
              ? API_ENDPOINTS.REPLACE_LAYER_VIEW
              : API_ENDPOINTS.COPY_LAYER_VIEW_CLONE,
            {
              ':viewId': _get(selectedView, 'viewId'),
              ':layerId': _get(selectedLayer, 'id'),
              ':replace': replace,
            }
          ),
          reqData
        )
        .then(() => {
          showNotification(
            NOTIFICATIONS_TYPES.SUCCESS,
            `${replace ? 'Replace' : 'Copy'} layer successfully`
          );
          setReplace(false);
          handleModalOpen('isCopyLayer', false);
        })
        .catch(() => setReplace(true));
    },
    [replace, selectedView, selectedLayer, handleModalOpen]
  );

  const filteredViewList = useMemo(
    () =>
      viewList
        .filter(
          (view) =>
            view.viewType === ViewType.STATIC &&
            !view.isBaseView &&
            view.isEditable
        )
        .map((view) => ({
          id: view.viewId,
          viewName: view.name,
        })),
    [viewList]
  );

  return (
    <Modal
      visible={isOpen}
      closable={false}
      footer={null}
      className='copy-layer'
    >
      <MrCopyLayerModal
        onCancel={() => handleModalOpen('isCopyLayer', false)}
        onCopyLayer={handleCopyView}
        viewList={filteredViewList}
        layerName={_get(selectedLayer, 'name')}
        replace={replace}
      />
    </Modal>
  );
};

export default CopyLayer;
