import { MapCommandHook } from '../types';
import { MapTool } from '../../../project/types';
import useActiveTool from '../../../../jotai/atoms/tools/useActiveTool';
import { useDispatch } from 'react-redux';
import { setActiveTool as setReduxActiveTool } from '@store/user/actions';

interface CommandOptions {
  tool: MapTool;
}

export const useChangeToolCommand: MapCommandHook<CommandOptions> = () => {
  let _options: CommandOptions;

  const dispatch = useDispatch();
  const { setActiveTool } = useActiveTool();

  return {
    init: (options) => {
      _options = options;
    },

    execute: async () => {
      if (!_options) return;

      /** TODO: Remove `setActiveTool` dispatch for redux */
      dispatch(setReduxActiveTool(_options.tool));
      setActiveTool(_options.tool);
    },
  };
};
