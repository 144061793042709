import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Modal, Spin, Tabs } from 'antd';
import { Box } from '@siterecon/sr-styles/dist/elements';
import EstimationLayerPanel from './EmLayerPanel';
import './styles.less';
import { useEstimationViewContext } from '../../../context/EstimationView/context';
import { useElementPositioningContext } from '../../../../../Contexts/ElementPositioningContext';
import {
  EstimationTabs,
  EstimationSheetStatus,
} from '../../../../../components/pages/project/projectComponents/types';
import { NumericId, ViewType } from '@/modules/common/types';
import useViewType from '../../../../project/guards/ViewGuard/useViewType';
import EmBetaBreakdownTab from '../../../../../components/PlasmicComponents/EmBetaBreakdownTab';
import { WidgetBarTabs } from '../../../../../components/types';
import { useWidgetBarTabContext } from '../../../hooks/useWidgetBar';
import { CTAType, notifyCTA } from '../../../api';
import useAuth from '../../../../auth/hooks';
import useOrganization from '../../../../auth/hooks/organization';
import { showNotification } from '../../../../../components/storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../../../../components/storybook/NotificationToast/types';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../../store/types';
import { FFEstimationView } from '../../../../../components/pages/project/projectComponents/FFEstimationView/FFEstimationView';
import { toEstimationFFData } from '../../../transformers/service-item.transformers';
import EmServiceMapZeroCta from '../../../../../components/PlasmicComponents/EmServiceMapZeroCta';
import EmZeroServiceItem from '../../../../../components/PlasmicComponents/EmZeroServiceItem';
import { BetaFeature } from '../../../../common/types';
import { useBetaRelease } from '@/modules/common/guards/BetaRelease';
import CoEstimationPaywall from '../../../../../components/PlasmicComponents/CoEstimationPaywall';
import RequestReceivedModal from '../../../../../components/pages/project/projectComponents/RequestReceivedModal';
import { trackEvents } from '../../../../../helpers/utilities';
import { CreateOrderEvt } from '../../../../../segment';
import { useDispatch } from 'react-redux';

import {
  LOCK_VIEW,
  LOCK_VIEW_PRICING_TEAM,
  MARK_ORDER_AS_PRICING_IN_PROGRESS,
  UNLOCK_VIEW,
  UNLOCK_VIEW_PRICING_TEAM,
} from '../../../../../helpers/constants/APIEndpoints';
import { getAxiosInstance } from '../../../../../helpers/utilities/api-utils';
import { replaceParams } from '../../../../../helpers/utilities/linkUtils';
import { setBaseViewEditRestrictionModalData } from '../../../../../store/map-editor/actions';
import { getPlasmicOrderStatus } from '../../../../../components/pages/project/utils/helpers';

import {
  currentView,
  redViews,
  setEditingRedux,
  setOrderViewModal,
  setOrderViews,
} from '../../../../../store/order/actions';
import { getSubscription } from '../../../../../store/subscription/thunk';
import { useRole } from '../../../../auth/guards/RoleGuard';
import useCurrentView from '../../../../../jotai/atoms/views/useCurrentView';
import { useFetchViewList } from '../../../hooks/view/useFetchViewList';

interface EstimationTabsPanelProps {
  viewsList?: any;
  currentViewData?: any;
  showViewSelectionPanel?: () => void;
  setLastSavedTimeText: (text: string) => void;
  activeEstimationTab: EstimationTabs;
  estimationSheetExpanded: boolean;
  setActiveEstimationTab: (text: EstimationTabs) => void;
  setShowLegacyEstimationViewAlert: (show: boolean) => void;
  openFPanel: boolean;
  toggleViewpanelPin: (val: boolean) => void;
  linkedView: string;
  switchToLinkedViewHandler: () => void;
  orderSummary: any;
  orderTileData: any;
}

export interface TabObj {
  value: string;
  icon?: any;
  label?: string;
}

const { TabPane } = Tabs;

const LEARN_MORE_DOC: string =
  'https://siterecon.notion.site/NEW-Estimator-in-a-box-115ab44698b44e9e99576cb1288d8e11?pvs=4';
const BOOK_A_DEMO: string = 'https://meetings.hubspot.com/utkarsh18';

const EstimationTabsPanel: React.FC<EstimationTabsPanelProps> = ({
  showViewSelectionPanel,
  // currentViewData,
  viewsList,
  setLastSavedTimeText,
  activeEstimationTab,
  setActiveEstimationTab,
  estimationSheetExpanded,
  setShowLegacyEstimationViewAlert,
  openFPanel,
  toggleViewpanelPin,
  linkedView,
  switchToLinkedViewHandler,
  isServiceItemAssignmentLocked,
  orderSummary,
  orderTileData,
}) => {
  const axiosInstance = getAxiosInstance();
  const dispatch = useDispatch();
  const { currentView: currentViewData, setCurrentView } = useCurrentView();
  const { refetch } = useFetchViewList();

  const currentUserEmail = useSelector((state) => state.user.info.email);
  const currentUserName = useSelector((state) => state.user.info.first_name);
  const isEstimationsEnabled = useBetaRelease(BetaFeature.Estimations);
  const currentViewType = useViewType();
  const { isEstimator } = useRole();

  enum TabWidth {
    SERVICE_MAP = '456px',
    ESTIMATION = '868px',
    BREAKDOWN = '868px',
    INVOICE = 'calc(100vw - 56px)',
    EXPANDED = 'calc(100vw - 56px)',
    EXPANDED_AND_PINNED = 'calc(100vw - 56px - 246px)',
  }

  const [panelWidth, setPanelWidth] = useState(TabWidth.SERVICE_MAP);
  const [activeFFPage, setActiveFFPage] = useState(0);

  const {
    data: serviceItemData,
    layers,
    isServiceItemsListFetching,
    isLayerDataFetching,
  } = useEstimationViewContext();

  const { handleWidgetBarTabChange, selectedTab } = useWidgetBarTabContext();

  const [isRequestReceivedPopupVisible, setRequestReceivedPopupVisible] =
    useState(false);

  const {
    setEstimationTabBreakdownTabOpen,
    setEstimationTabInvoiceTabOpen,
    setEstimationViewEstimationTabOpen,
    setEstimationViewServiceMapTabOpen,
    setNormalLayerPanelOpen,
    isViewPinned
  } = useElementPositioningContext();

  useEffect(() => {
    setPanelWidth(
      estimationSheetExpanded 
      ? (isViewPinned ? TabWidth.EXPANDED_AND_PINNED : TabWidth.EXPANDED)
      : TabWidth.BREAKDOWN
    );
  }, [estimationSheetExpanded, isViewPinned]);

  useEffect(() => {
    const handleFlutterMessage = (event) => {
      if (event.data && typeof event.data === 'object') {
        const { identifier, data } = event.data;
        // parsing stringified json data
        let eventData;
        if (data) {
          eventData = JSON.parse(data);
        }
        if (identifier === 'buttonEstimationOrderFlow') {
        } else if (identifier === 'buttonOnEstimationCopy') {
          dispatch(setBaseViewEditRestrictionModalData({ open: true }));
        } else if (identifier === 'buttonOnEstimationlockClick') {
          lockUnlockView(true, eventData.isLockedByEstimator);
        } else if (identifier === 'buttonOnEstimationUnlockClick') {
          if (eventData.isLockedByEstimator && orderTileData.estimationStatus === "estimation_delivered") {
            estimatorMarkOrderInProgress();
          }
          lockUnlockView(false, eventData.isLockedByEstimator);
        } else if (identifier === 'columnBodyContainerClick') {
          if (eventData.isEstimationLocked) {
            dispatch(setBaseViewEditRestrictionModalData({ open: true }));
          }
        } else if (identifier === 'onOrderShipSuccess') {
          dispatch(getSubscription());
          let updatedCurrentView = viewsList.find(
            (obj) => obj.viewId === currentViewData.viewId
          );
          if (updatedCurrentView) {
            updatedCurrentView.isPricingLocked = true;
          }
          dispatch(currentView(updatedCurrentView));
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else if (identifier === 'onApproveClick') {
          lockUnlockView(true, eventData.isLockedByEstimator)
        } else if (identifier === 'bidShippingError') {
          if (eventData.errorCode === "SR-BIZ-ERR-EMP-006") {
            showNotification(
              NOTIFICATIONS_TYPES.ERROR,
              `Resolve all open feedback before shipping the order!`
            );
          } else if (eventData.errorCode === 'SR-BIZ-ERR-BMS-004') {
            showNotification(
              NOTIFICATIONS_TYPES.ERROR,
              `Insufficient Bid Available!`
            );
          } else {
            showNotification(
              NOTIFICATIONS_TYPES.ERROR,
              `Something went wrong.`
            );
          }
        }
        console.log(`ff_msg_${identifier} was clicked!`, {
          eventData,
          currentViewData,
        });
      } else {
        console.warn(
          'Unexpected message format received from Flutter:',
          event.data
        );
      }
    };
    window.addEventListener('message', handleFlutterMessage);
    return () => {
      window.removeEventListener('message', handleFlutterMessage);
    };
  }, [currentViewData, viewsList]);

  useEffect(() => {
    if (activeEstimationTab === EstimationTabs.ESTIMATION) {
      setActiveFFPage(0);
    } else if (activeEstimationTab === EstimationTabs.INVOICE) {
      setActiveFFPage(2);
    } else if (activeEstimationTab === EstimationTabs.BREAKDOWN) {
      setActiveFFPage(1);
    } else {
      setActiveFFPage(0);
    }
  }, [activeEstimationTab]);

  const user = useAuth();
  const { role } = useRole();
  const org = useOrganization();

  useEffect(() => {
    return () => {
      setEstimationViewServiceMapTabOpen(false);
      setEstimationTabInvoiceTabOpen(false);
      setEstimationViewEstimationTabOpen(false);
      setEstimationTabBreakdownTabOpen(false);
      setActiveFFPage(0);
    };
  }, []);

  useEffect(() => {
    setEstimationViewServiceMapTabOpen(true);
    setNormalLayerPanelOpen(false);
    handleWidgetBarTabChange(WidgetBarTabs.ESTIMATION, selectedTab);
    if (serviceItemData === null) {
      setLastSavedTimeText('');
    }
  }, [currentViewData?.viewId, serviceItemData]);

  useEffect(() => {
    handleTabChange(activeEstimationTab);
    if (activeEstimationTab === EstimationTabs.SERVICE_MAP) {
      setPanelWidth(TabWidth.SERVICE_MAP);
    }
  }, [activeEstimationTab]);

  useEffect(() => {
    /** When view is changed, we want to reset the tab to SERVICE_MAP */
    if (activeEstimationTab !== EstimationTabs.SERVICE_MAP) {
      handleTabChange(EstimationTabs.SERVICE_MAP);
    }
  }, [selectedTab]);

  const showLegacyEstimationViewAlertHandler = () => {
    if (currentViewType === ViewType.DYNAMIC) {
      setShowLegacyEstimationViewAlert(true);
      return;
    }
  };

  const estimatorMarkOrderInProgress = () => {
    let url = MARK_ORDER_AS_PRICING_IN_PROGRESS;
    const reqData = {};
    axiosInstance
      .post(
        replaceParams(url, {
          ':orderId': orderSummary.orderId,
        }),
        reqData
      )
      .then(async (res) => { })
      .catch((error) => {
        showNotification(NOTIFICATIONS_TYPES.ERROR, `$Something went wrong!`);
      });
  };

  const lockUnlockView = (lock, isLockedByEstimator) => {
    let url = isLockedByEstimator ? UNLOCK_VIEW_PRICING_TEAM : UNLOCK_VIEW;
    if (lock) {
      url = isLockedByEstimator ? LOCK_VIEW_PRICING_TEAM : LOCK_VIEW;
    }
    const reqData = {};

    axiosInstance
      .post(
        replaceParams(url, {
          ':viewId': currentViewData.viewId,
        }),
        reqData
      )
      .then(async (res) => {
        const viewsList = res.data;
        const updatedCurrentView = viewsList.find(obj => obj.viewId === currentViewData.viewId);
        // updating jotai atom
        setCurrentView(updatedCurrentView);
        await refetch();
        // lagacy redux state
        dispatch(currentView(updatedCurrentView));
        dispatch(setOrderViews(viewsList))
        dispatch(redViews(viewsList))
        showNotification(
          NOTIFICATIONS_TYPES.SUCCESS,
          `${currentViewData.name} ${lock ? 'locked' : 'unlocked'
          } successfully!`
        );
      })
      .catch((error) => {
        showNotification(NOTIFICATIONS_TYPES.ERROR, `$Something went wrong!`);
      });
  };

  const handleTabChange = (key: EstimationTabs) => {
    const resetTabs = () => {
      setEstimationViewServiceMapTabOpen(false);
      setEstimationTabInvoiceTabOpen(false);
      setEstimationViewEstimationTabOpen(false);
      setEstimationTabBreakdownTabOpen(false);
    };

    const setTabsAndWidth = (
      serviceMapOpen,
      estimationOpen,
      breakdownOpen,
      invoiceOpen,
      width
    ) => {
      resetTabs();
      setEstimationViewServiceMapTabOpen(serviceMapOpen);
      setEstimationViewEstimationTabOpen(estimationOpen);
      setEstimationTabBreakdownTabOpen(breakdownOpen);
      setEstimationTabInvoiceTabOpen(invoiceOpen);
      if (currentViewType !== ViewType.DYNAMIC) setPanelWidth(width);
    };

    trackEvents(CreateOrderEvt.EstimationTabClicked, { tab: key });

    switch (key) {
      case EstimationTabs.SERVICE_MAP:
        setTabsAndWidth(true, false, false, false, TabWidth.SERVICE_MAP);
        break;
      case EstimationTabs.ESTIMATION:
        showLegacyEstimationViewAlertHandler();
        if (!estimationSheetExpanded)
          setTabsAndWidth(false, true, false, false, TabWidth.ESTIMATION);
        break;
      case EstimationTabs.BREAKDOWN:
        showLegacyEstimationViewAlertHandler();
        if (!estimationSheetExpanded)
          setTabsAndWidth(false, false, true, false, TabWidth.BREAKDOWN);
        break;
      case EstimationTabs.INVOICE:
        showLegacyEstimationViewAlertHandler();
        const isInvoiceTabOpen = isEstimationsEnabled;
        setTabsAndWidth(
          false,
          !isInvoiceTabOpen,
          false,
          isInvoiceTabOpen,
          isEstimationsEnabled ? TabWidth.INVOICE : TabWidth.ESTIMATION
        );
        toggleViewpanelPin(false);
        break;
      default:
        break;
    }

    if (currentViewType !== ViewType.DYNAMIC) {
      setActiveEstimationTab(key);
    }
  };
  /** Creating this function so that it can be passed to antd onChange without TS warning */
  const handleTabChangeWrapper = (activeKey: string) => {
    const tab = activeKey as EstimationTabs;
    handleTabChange(tab);
  };

  const notifyMeHandler = async (cta: CTAType, showToast: boolean = true) => {
    if (!user || !org) return;

    try {
      await notifyCTA(cta);
      if (showToast) {
        showNotification(NOTIFICATIONS_TYPES.SUCCESS, 'Notification sent!');
      } else {
        setRequestReceivedPopupVisible(true);
      }
    } catch (err) {
      console.error(err);
      if (showToast) {
        showNotification(NOTIFICATIONS_TYPES.ERROR, 'Notification failed!');
      }
    }
  };

  const loader: ReactNode = (
    <Box flex align='middle' justify='center' placeholder={undefined}>
      <Spin size='large' />
    </Box>
  );

  const estimationLayerPanelContent = () => {
    if (isServiceItemsListFetching || isLayerDataFetching) {
      return loader;
    } else if (layers && layers.length === 0) {
      return (
        <EmServiceMapZeroCta
          linkedView={linkedView}
          onGoToLinkedTakeOffView={switchToLinkedViewHandler}
          className='px-12'
        />
      );
    } else if (!serviceItemData) {
      return (
        <EmZeroServiceItem
          onGoToLinkedTakeoff={switchToLinkedViewHandler}
          onRequestACallback={() =>
            notifyMeHandler(CTAType.RequestCatalogueUpdate, false)
          }
        />
      );
    } else {
      return <EstimationLayerPanel />;
    }
  };

  const estimationPaywall = (
    <CoEstimationPaywall
      onLearnMore={() => window.open(LEARN_MORE_DOC, '_blank')}
      onBookADemo={() => window.open(BOOK_A_DEMO, '_blank')}
    />
  );

  const isEstimatedByPricingTeam = orderTileData.isEstimationOrder && currentViewData.estimationSheetStatus === EstimationSheetStatus.EstimationDelivered;
  const ffOrderDetail = {
    orderId: orderSummary.orderId,
    orderStatus: getPlasmicOrderStatus(orderSummary.status),
    serviceIds: orderTileData.serviceIds,
    orderEstStatus: orderTileData.estimationStatus,
    isEstimatedByPricingTeam: isEstimatedByPricingTeam,
    orderIsEstInProgress: orderTileData.isEstimationOrder && [EstimationSheetStatus.EstimationPending, EstimationSheetStatus.EstimationInProgress].includes(orderTileData.estimationStatus),
    isFElocked: currentViewData.isPricingLocked,
    customerOrderNote: orderTileData.ordernote,
    lockedByUserName: currentViewData?.siLockDetails?.lockedBy,
    lockSource: currentViewData?.siLockDetails?.lockSource,
    isEstimationReshipped: orderTileData.isEstimationReshipped,
    lockedByUserEmail: currentViewData?.siLockDetails?.lockedByEmail || '',//!isEstimatedByPricingTeam && currentViewData?.siLockDetails?.lockedByEmail || '',
  };

  return (
    <Box
      className='sr-est-view-panel'
      style={{ width: openFPanel ? panelWidth : 0, height: '100%' }}
      placeholder={undefined}
    >
      <Tabs
        activeKey={activeEstimationTab}
        onChange={handleTabChangeWrapper}
        centered={false}
        className='left-aligned'
        tabBarGutter={8}
      >
        <TabPane tab='Service Map' key={EstimationTabs.SERVICE_MAP}>
          {estimationLayerPanelContent()}
        </TabPane>
        <TabPane tab='Estimation' key={EstimationTabs.ESTIMATION}>
          {!isEstimationsEnabled && estimationPaywall}
        </TabPane>
        <TabPane tab='Analytics' key={EstimationTabs.BREAKDOWN}>
          {!isEstimationsEnabled && estimationPaywall}
        </TabPane>
        <TabPane tab='Export' key={EstimationTabs.INVOICE}>
          {!isEstimationsEnabled && estimationPaywall}
        </TabPane>
      </Tabs>
      {isRequestReceivedPopupVisible && (
        <RequestReceivedModal
          visible={isRequestReceivedPopupVisible}
          setVisible={setRequestReceivedPopupVisible}
        />
      )}

      {serviceItemData
        ? isEstimationsEnabled && (
          <FFEstimationView
            isServiceItemAssignmentLocked={isServiceItemAssignmentLocked}
            assetBase={process.env.PUBLIC_URL + '/flutter/'}
            src={process.env.PUBLIC_URL + '/flutter/main.dart.js'}
            propertyId={currentViewData?.orderId}
            estimateViewId={currentViewData?.viewId}
            linkedTakeoffViewId={currentViewData?.linkedView}
            setActiveEstimationTab={setActiveEstimationTab}
            serviceItemMeasurementList={
              toEstimationFFData(serviceItemData, layers)[0]
            }
            featureDataList={toEstimationFFData(serviceItemData, layers)[1]}
            activePage={activeFFPage}
            orderDetail={ffOrderDetail}
            userDetail={{
              userEmail: currentUserEmail,
              userName: currentUserName,
              workspaceUserName: currentUserName,
              role: role,
            }}
            estimatorUser={{
              email: isEstimator && user.email,
            }}
            showFlutterApp={[
              EstimationTabs.ESTIMATION,
              EstimationTabs.INVOICE,
              EstimationTabs.BREAKDOWN,
            ].includes(activeEstimationTab)}
            setLastSavedTimeText={setLastSavedTimeText}
          />
        )
        : activeEstimationTab !== EstimationTabs.SERVICE_MAP
          ? loader
          : null}
    </Box>
  );
};

export default EstimationTabsPanel;
