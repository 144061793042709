import { Reducer } from 'redux';
import { INITIAL_STATE } from './state';
import { MapEditorActionTypes } from './action.types';
import { produce } from 'immer';
import { MapEditorModalType, MapEditorStore } from './index';

export const reducer: Reducer<MapEditorStore> = (
  state = INITIAL_STATE,
  { type, payload }
) =>
  produce(state, (draft) => {
    switch (type) {
      case MapEditorActionTypes.ADD_TO_UNDO_STACK:
        draft.undoStack.push(payload);
        break;

      case MapEditorActionTypes.POP_FROM_UNDO_STACK:
        draft.undoStack.pop();
        break;

      case MapEditorActionTypes.ADD_TO_REDO_STACK:
        draft.redoStack.push(payload);
        break;

      case MapEditorActionTypes.POP_FROM_REDO_STACK:
        draft.redoStack.pop();
        break;

      case MapEditorActionTypes.SET_COPY_MODAL_DATA:
        draft.modals[MapEditorModalType.COPY] = payload;
        break;

      case MapEditorActionTypes.SET_DELETE_CONFIRMATION_MODAL_DATA:
        draft.modals[MapEditorModalType.DELETE] = payload;
        break;

      case MapEditorActionTypes.SET_ADD_LAYER_MODAL_DATA:
        draft.modals[MapEditorModalType.ADD_LAYER] = payload;
        break;

      case MapEditorActionTypes.SET_BASE_VIEW_EDIT_RESTRICTION_MODAL_DATA:
        draft.modals[MapEditorModalType.BASE_VIEW_EDIT_RESTRICTION] = payload;
        break;
    }
  });
