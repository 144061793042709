// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ColorSelectionIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ColorSelectionIcon(props: ColorSelectionIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M11.2 16h1.6v-3.2H16v-1.6h-3.2V8h-1.6v3.2H8v1.6h3.2V16zm.8 4a7.79 7.79 0 01-3.12-.63 8.079 8.079 0 01-2.54-1.71 8.08 8.08 0 01-1.71-2.54A7.79 7.79 0 014 12c0-1.107.21-2.147.63-3.12.42-.973.99-1.82 1.71-2.54a8.079 8.079 0 012.54-1.71A7.79 7.79 0 0112 4a7.79 7.79 0 013.12.63c.973.42 1.82.99 2.54 1.71s1.29 1.567 1.71 2.54c.42.973.63 2.013.63 3.12a7.79 7.79 0 01-.63 3.12 8.08 8.08 0 01-1.71 2.54 8.08 8.08 0 01-2.54 1.71A7.79 7.79 0 0112 20zm0-1.6c1.787 0 3.3-.62 4.54-1.86 1.24-1.24 1.86-2.753 1.86-4.54s-.62-3.3-1.86-4.54C15.3 6.22 13.787 5.6 12 5.6s-3.3.62-4.54 1.86C6.22 8.7 5.6 10.213 5.6 12s.62 3.3 1.86 4.54C8.7 17.78 10.213 18.4 12 18.4z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default ColorSelectionIcon;
/* prettier-ignore-end */
