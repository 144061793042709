import React, { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { MapTool, MapToolItemData, MapToolProps } from '../MapTool/MapTool';
import { ToolbarMenu } from '../ToolbarMenu/ToolbarMenu';
import { ToolbarSettings } from '../ToolbarSettings/ToolbarSettings';
import QuickTips from '../QuickTips/QuickTips';
import UndoRedo from '../UndoRedo/UndoRedo';
import { Col, Divider, Menu, Popover, Row, Tooltip } from 'antd';
import './style.less';
import { Box, Typography } from '@siterecon/sr-styles/dist/elements';
import { StyleCircleProps } from '../../common/style-circle';
import { StyleCircle } from '../../../../../feature-attributes/components/style-circle';
import TopologyErrors from '../TopologyErrors/TopologyErrors';

import PathTool from '../../assets/tools/PathTool';
import Down from '../../assets/icons/DownArrow';
import { trackEvents } from '../../../../../../helpers/utilities';
import { FeatureType } from '../../../../../feature-attributes/types';
import {
  DEFAULT_PINNED_TOOLS,
  NO_LAYER_POPUP_TOOLS,
  TOOLS_DATA,
} from '../constant';
import { ToolKey, ToolList } from '../types';
import classNames from 'classnames';
import ModifyManualSyncTip from '../QuickTips/ModifyManualSyncTip';
import { timeAgo } from '../../../../../timeago';
import {
  executeModification,
  resetModification,
} from '../../../../../../helpers/mapUtils/tools/modifyTool.js';
import { useDispatch, useSelector } from 'react-redux';
import {
  ModifySyncState,
  ViewType,
} from '../../../../../../modules/common/types';
import {
  modifySyncState as setModifySyncState,
  setModifyDraftEvents,
  setLastSyncedOn,
} from '@/store/map/actions';
import { Prompt } from 'react-router-dom';
import useManualSyncTipVisibility from './hooks/useManualSyncTipVisibility';
import useAutoSyncWhileInactive from './hooks/useAutoSyncWhileInactive';
import useSyncOnOutsideClick from './hooks/useSyncOnOutsideClick';
import useSyncOnActiveToolChange from './hooks/useSyncOnActiveToolChange';
import useSyncOnLayerChange from './hooks/useSyncOnLayerChange';
import ResetUnsyncedEditsModal from '../QuickTips/ResetUnsyncedEditsModal';
import { showLoadingCursor, hideLoadingCursor } from '@/components/utils';
import useViewType from '../../../../../../modules/project/guards/ViewGuard/useViewType';
import omit from 'lodash/omit';
import EmTakeOffLayerChips2 from '../../../../../PlasmicComponents/EmTakeOffLayerChips2';

interface MapToolbarProps {
  setPinnedTools: (tools: string[]) => void;
  activeTool: string;
  handleSelectedTool: (e) => void;
  onChangeHandle: () => void;
  handleUndoRedo: (type: string) => Promise<void>;
  selectedLayer: any;
  undoDisable: boolean;
  redoDisable: boolean;
  lastEditedLayers: StyleCircleProps[];
  layerList: any;
  onExpandTree: (layerId: number, area: number) => void;
  addLayerHandler: () => void;
  isEssential: boolean;
  showPaywall: (feature: string, modal?: any) => void;
  disableAllTools: boolean;
  disableAllToolsTooltip: string;
  SubscriptionChip: any;
  topologyObjects: {
    topologyWarning: any;
    topologyWarningStringsArray: any;
    layerComponents: any;
    ignoreTopoChecks: any;
    displayComponentName: any;
  };
  onResetModifications: any;
  maptoolBarWidth: any;
}

const MapToolbar = ({
  activeTool,
  handleSelectedTool,
  onChangeHandle,
  handleUndoRedo,
  selectedLayer,
  undoDisable,
  redoDisable,
  lastEditedLayers,
  layerList,
  onExpandTree,
  addLayerHandler,
  isEssential,
  showPaywall,
  disableAllTools,
  disableAllToolsTooltip,
  SubscriptionChip,
  topologyObjects,
  setPinnedTools,
  maptoolBarWidth,
  onResetModifications,
}: MapToolbarProps) => {
  const dispatch = useDispatch();
  const [allTools, setAllTools] = useState<MapToolItemData[]>(TOOLS_DATA);
  const [editedLayersPopupOpen, setEditedLayersPopupOpen] =
    useState<boolean>(false);
  const [showResetModal, setShowResetModal] = useState<boolean>(false);
  const isManualSyncTipVisible = useManualSyncTipVisibility({
    activeTool,
    isResetModificationsModalVisible: showResetModal,
  });

  // BETA release additions
  useEffect(() => {
    if (!allTools.map((ele) => ele.keyVal).includes(ToolKey.PATH)) {
      setAllTools(
        allTools.splice(5, 0, {
          title: ToolList.PATH,
          icon: <PathTool />,
          keyVal: ToolKey.PATH,
          viableLayers: [FeatureType.PATH],
        })
      );
    }
  }, []);

  let defaultPinned = JSON.parse(localStorage.getItem('default_pinned'))
    ? JSON.parse(localStorage.getItem('default_pinned'))
    : DEFAULT_PINNED_TOOLS;

  const unSyncedModifiedChanges = useSelector(
    (state) => state.map.modifyDraftEvents
  );
  const lastSyncedOn = useSelector((state: any) => state.map.lastSyncedOn);
  const modifySyncState = useSelector((state) => state.map.modifySyncState);
  const currentViewType = useViewType();

  const hasUnSyncedChanges = useMemo(() => {
    return activeTool == ToolKey.EDIT && unSyncedModifiedChanges.length > 0;
  }, [activeTool, unSyncedModifiedChanges]);

  // external props that define what tools are pinned for the user
  const [pinned, setPinned] = useState<string[]>(defaultPinned);

  // tools going into the pinned bar
  const [tools, setTools] = useState<MapToolProps[]>(
    allTools.filter((ele) => {
      return pinned.includes(ele.keyVal);
    })
  );

  // tools going into the more tab
  const [moreTools, setMoreTools] = useState<MapToolProps[]>(
    allTools.filter((ele) => {
      return !pinned.includes(ele.keyVal);
    })
  );

  const [wrongLayer, setWrongLayer] = useState<null | string>(null);
  const [wrongSubLayer, setWrongSubLayer] = useState<null | string>(null);
  const [selectedTool, setSelectedTool] = useState(activeTool);
  const [expandTools, setExpandTools] = useState(true);
  const toolbarWidthWatcher = useRef(null);
  const [chosenLayer, setChosenLayer] = useState(
    layerList.find((ele) => ele.name == selectedLayer)
  );

  useEffect(() => {
    if (modifySyncState === ModifySyncState.SYNCING) {
      showLoadingCursor();
      return;
    }

    hideLoadingCursor();
  }, [modifySyncState]);

  useEffect(() => {
    setChosenLayer(layerList.find((ele) => ele.name == selectedLayer));
  }, [layerList, selectedLayer]);

  useEffect(() => {
    setSelectedTool(activeTool);
  }, [activeTool]);

  const [editedLayers, setEditedLayers] =
    useState<StyleCircleProps[]>(lastEditedLayers);

  useEffect(() => {
    setEditedLayers(lastEditedLayers);
  }, [lastEditedLayers]);

  const handlePinning = (key: string) => {
    let current = pinned;
    if (pinned.includes(key)) {
      current = pinned.filter((element) => element !== key);
      trackEvents('map-tools__unpin', {
        currentTool: key,
      });
    } else {
      current = pinned.concat([key]);
      trackEvents('map-tools__pin', {
        currentTool: key,
      });
    }

    setPinned(current);

    setAllTools(
      allTools.sort((a, b) => {
        const indexA = current.indexOf(a.keyVal);
        const indexB = current.indexOf(b.keyVal);
        return indexA - indexB;
      })
    );

    localStorage.setItem('default_pinned', JSON.stringify(current));
    setPinnedTools(current);
  };

  const handleUndo = async () => {
    try {
      showLoadingCursor();
      await handleUndoRedo('undo');
    } finally {
      setTimeout(() => {
        hideLoadingCursor();
      }, 500);
    }
  };

  // listening to local storage for pinning logic
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'default_pinned') {
        // Handle the change in localStorage

        const newValue = event.newValue;

        if (pinned.length == newValue.length) {
          for (let i = 0; i < pinned.length; i++) {
            const pinEle = pinned[i];
            const newEle = newValue[i];

            if (pinEle && newEle) {
              if (pinEle == newEle) {
                continue;
              } else {
                return;
              }
            } else {
              continue;
            }
          }
        }

        let current = newValue;
        setAllTools(
          allTools.sort((a, b) => {
            const indexA = current.indexOf(a.keyVal);
            const indexB = current.indexOf(b.keyVal);
            return indexA - indexB;
          })
        );
        setPinned(current);
      }
    };

    // Add the storage event listener when the component mounts
    window.addEventListener('storage', handleStorageChange);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    let sorted = allTools.sort((a, b) => {
      const indexA = pinned.indexOf(a.keyVal);
      const indexB = pinned.indexOf(b.keyVal);
      return indexA - indexB;
    });
    setAllTools(sorted);
    setTools(
      sorted.filter((ele) => {
        return pinned.includes(ele.keyVal);
      })
    );
    setMoreTools(
      sorted.filter((ele) => {
        return !pinned.includes(ele.keyVal);
      })
    );
  }, [pinned]);

  const selectChosenLayer = (item) => {
    let clicked = layerList.find((layer) => {
      return layer.layerId == item.layerId;
    });
    onExpandTree(clicked.layerId, 0);
  };

  const LastEditedLayers = (
    <Box className='last-edited-layers'>
      <Typography
        fontSize={14}
        fontWeight={400}
        lineHeight={1.5}
        className='w-100 px-3'
        color='neutral-400'
      >
        Last Edited Layers
      </Typography>

      <Box className='edited-layers'>
        {editedLayers.length > 0 ? (
          editedLayers.map((ele) => {
            return (
              <Box
                className='layer'
                onClick={() => selectChosenLayer(ele)}
                key={ele.name}
              >
                <Row className='w-100'>
                  <Col
                    span={3}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <EmTakeOffLayerChips2
                      featureType={ele.layerType}
                      styleObject={{
                        color: ele.strokeColor,
                        fillColor: ele.fillColor,
                        symbolColor: ele.symbolColor,
                        symbolType: ele.icon,
                        arrowColor: ele.strokeColor,
                      }}
                    />
                  </Col>
                  <Col span={21}>
                    <Typography fontSize={14} fontWeight={500} lineHeight={1.5}>
                      {ele.name}
                    </Typography>
                  </Col>
                </Row>
              </Box>
            );
          })
        ) : (
          <Box className='no-layers'>
            <Typography
              fontSize={12}
              fontWeight={400}
              lineHeight={1.5}
              color='neutral-666'
              style={{ fontStyle: 'italic' }}
            >
              Once you make changes to any layers, they will appear here.
            </Typography>
          </Box>
        )}
      </Box>

      {layerList
        .filter(
          (item) =>
            !editedLayers.some(
              (filterItem) => filterItem.layerId === item.layerId
            )
        )
        .filter((item) => item.isEditable).length > 0 && (
        <>
          <Typography
            fontSize={14}
            fontWeight={500}
            lineHeight={1.5}
            className='w-100 px-3'
            color='neutral-666'
          >
            Other layers in the view
          </Typography>
          <Box className='other-layers'>
            {layerList
              .filter(
                (item) =>
                  !editedLayers.some(
                    (filterItem) => filterItem.layerId === item.layerId
                  )
              )
              .filter((item) => item.isEditable)
              .map((ele) => {
                return (
                  <Box
                    className='layer'
                    onClick={() => {
                      selectChosenLayer(ele);
                    }}
                    key={ele.name}
                  >
                    <Row className='w-100'>
                      <Col
                        span={3}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <EmTakeOffLayerChips2
                          featureType={ele.featureType}
                          styleObject={{
                            color: ele.style.color,
                            fillColor: ele.style.fillColor,
                            symbolColor: ele.style.symbolColor,
                            symbolType: ele.style.symbolType,
                            arrowColor: ele.style.strokeColor,
                          }}
                        />
                      </Col>
                      <Col span={21}>
                        <Typography
                          fontSize={14}
                          fontWeight={500}
                          lineHeight={1.5}
                        >
                          {ele.name}
                        </Typography>
                      </Col>
                    </Row>
                  </Box>
                );
              })}
          </Box>
        </>
      )}
    </Box>
  );

  const handleSaveUnSyncedModifications = () => {
    dispatch(setLastSyncedOn(new Date()));
    dispatch(setModifySyncState(ModifySyncState.SYNCING));
    executeModification();
  };

  const handleResetUnSyncedModifications = () => {
    if (!chosenLayer?.layerId) return;

    const unSyncedChangesCount = unSyncedModifiedChanges.length ?? 0;
    if (unSyncedChangesCount === 0) return;

    onResetModifications(chosenLayer.layerId);
    dispatch(setModifySyncState(ModifySyncState.SYNCED));
    resetModification(unSyncedChangesCount);
    dispatch(setModifyDraftEvents([]));
  };

  const handleResetConfirm = () => {
    handleResetUnSyncedModifications();
    setTimeout(() => setShowResetModal(false), 250);
  };

  const handleResetClose = () => {
    setTimeout(() => setShowResetModal(false), 250);
  };

  useEffect(() => {
    /** When user is trying to leave the page but there are unsaved changed, we need to warn user for unsaved changed */
    const onBeforeUnload = (event) => {
      if (unSyncedModifiedChanges.length > 0) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    if (activeTool == ToolKey.EDIT && unSyncedModifiedChanges.length > 0) {
      window.addEventListener('beforeunload', onBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [activeTool, unSyncedModifiedChanges]);

  const handleMenuClick = (e: any) => {
    const toolKey = e.key;

    setWrongLayer(null);

    if (toolKey == wrongLayer || toolKey === wrongSubLayer) {
      /** User has clicked on something in the popover */
      return;
    }

    if (
      [
        ToolKey.SPLIT,
        ToolKey.LINE_SPLIT,
        ToolKey.LASSO_SPLIT,
        ToolKey.MENU,
        ToolKey.SETTINGS,
      ].includes(toolKey)
    ) {
      return;
    }

    let layer = chosenLayer;
    if (!layer.isEditable && !NO_LAYER_POPUP_TOOLS.includes(toolKey)) {
      setWrongLayer(toolKey);
      return;
    }

    let clickedTool = allTools.find((i) => i.keyVal == toolKey);

    if (clickedTool?.viableLayers.includes(layer.featureType)) {
      if (NO_LAYER_POPUP_TOOLS.includes(toolKey)) {
        setWrongLayer(null);
      } else {
        setWrongLayer(toolKey);
      }
      handleSelectedTool(e);
    } else {
      setWrongLayer(toolKey);
    }
  };

  useEffect(() => {
    const element = toolbarWidthWatcher.current;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width } = entry.contentRect;
        const eleWidth =
          document.getElementsByClassName('toolbar')[0]?.offsetWidth;
        // 45 i the width of the settings button, pinned has one added to it for the more item
        // and 113 is the max width of any map tool
        const pinned_data =
          (pinned.length + (moreTools.length > 0 ? 1 : 0)) * 113;

        if (pinned_data > (eleWidth ?? width)) {
          setExpandTools(false);
        } else {
          setExpandTools(tools.length <= 9);
        }
      }
    });

    if (element) {
      resizeObserver.observe(element);
    }

    return () => {
      if (element) {
        resizeObserver.unobserve(element);
      }
    };
  }, []);

  useEffect(() => {
    setExpandTools(tools.length <= 9);
    const eleWidth = document.getElementsByClassName('toolbar')[0].offsetWidth;
    // 45 i the width of the settings button, pinned has one added to it for the more item
    // and 113 is the max width of any map tool
    const pinned_data = (pinned.length + (moreTools.length > 0 ? 1 : 0)) * 113;

    if (pinned_data > eleWidth) {
      setExpandTools(false);
    } else {
      setExpandTools(tools.length <= 9);
    }
  }, [pinned, maptoolBarWidth, tools]);

  const handleEditedLayerVisibility = (newOpen: boolean) => {
    setEditedLayersPopupOpen(newOpen);
  };

  useSyncOnActiveToolChange({ activeTool, handleSaveUnSyncedModifications });

  useSyncOnLayerChange({
    activeTool,
    chosenLayerId: chosenLayer?.layerId,
    handleSaveUnSyncedModifications,
  });

  useSyncOnOutsideClick();

  useAutoSyncWhileInactive({
    isResetModificationsModalVisible: showResetModal,
  });

  return (
    <Box>
      <Box>
        <nav className='toolbar' ref={toolbarWidthWatcher}>
          <Menu
            mode='horizontal'
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              height: '100%',
            }}
            onClick={handleMenuClick}
            onOpenChange={onChangeHandle}
          >
            {tools &&
              tools.map((ele) => {
                return (
                  <Menu.Item
                    onClick={() => {
                      if (selectedTool == ele.keyVal) {
                        // setSelectedTool('select');
                      } else {
                        // setSelectedTool(ele.keyVal);
                      }
                    }}
                    key={ele.keyVal}
                    style={{
                      height: '44px',
                      display: 'flex',
                      margin: 0,
                      borderBottom: 'transparent',
                    }}
                    disabled={disableAllTools}
                  >
                    <MapTool
                      disableTool={disableAllTools}
                      sub={ele.sub}
                      expand={expandTools}
                      title={ele.title}
                      keyVal={ele.keyVal}
                      icon={ele.icon}
                      selected={selectedTool}
                      chosenLayer={chosenLayer}
                      clickAction={(key) => handleSelectedTool({ key: key })}
                      changeLayer={ele.keyVal == wrongLayer}
                      changeSubLayer={
                        !wrongLayer && ele.keyVal === wrongSubLayer
                      }
                      layers={layerList.filter((item) => {
                        return ele.viableLayers.includes(item.featureType);
                      })}
                      allLayers={layerList}
                      changeLayerClick={(layer, key) => {
                        onExpandTree(layer.layerId, 0);
                        setWrongLayer(null);
                        setWrongSubLayer(null);

                        const toolKey = ele.sub ? key : ele.keyVal;
                        handleSelectedTool({ key: toolKey });
                      }}
                      viableLayers={ele.viableLayers}
                      addLayerHandler={addLayerHandler}
                      SubscriptionChip={SubscriptionChip}
                      isEssential={isEssential}
                    />
                  </Menu.Item>
                );
              })}
            {moreTools.length > 0 && (
              <Menu.Item
                disabled={disableAllTools}
                key='menu'
                style={{
                  display: 'flex',
                  margin: 0,
                  marginLeft: 0,
                  borderBottom: 'transparent',
                }}
              >
                <ToolbarMenu
                  disableTool={disableAllTools}
                  expand={expandTools}
                  options={moreTools}
                  selected={selectedTool}
                  clickAction={(key) => handleSelectedTool({ key: key })}
                  activeTool={activeTool}
                  isEssential={isEssential}
                  chosenLayer={chosenLayer}
                  layers={layerList}
                  changeLayerClick={(layer, key) => {
                    onExpandTree(layer.layerId, 0);
                    setWrongLayer(null);
                    setWrongSubLayer(null);
                    handleSelectedTool({ key: key });
                  }}
                  addLayerHandler={addLayerHandler}
                  SubscriptionChip={SubscriptionChip}
                />
              </Menu.Item>
            )}
            <Menu.Item
              key='settings'
              className='setting-item'
              disabled={disableAllTools}
            >
              <ToolbarSettings
                disableTool={disableAllTools}
                handlePinning={handlePinning}
                pinned={pinned}
                SubscriptionChip={SubscriptionChip}
                isEssential={isEssential}
              />
            </Menu.Item>
          </Menu>
        </nav>
        {currentViewType === ViewType.STATIC && (
          <nav className='lower-toolbar'>
            <Box className='lower-toolbar-inner'>
              <Row align={'middle'} className='w-100'>
                <Box className='undo-editing-section'>
                  <UndoRedo
                    disableUndoButton={undoDisable || hasUnSyncedChanges}
                    disableRedoButton={redoDisable || hasUnSyncedChanges}
                    redoClick={() => handleUndoRedo('redo')}
                    undoClick={handleUndo}
                  />

                  <Divider type='vertical' />

                  <Popover
                    placement='bottomLeft'
                    trigger='click'
                    content={LastEditedLayers}
                    onVisibleChange={handleEditedLayerVisibility}
                  >
                    <Box
                      className={classNames('currently-editing', {
                        'currently-editing--active': editedLayersPopupOpen,
                      })}
                      flex
                      justify='space-around'
                    >
                      <Typography
                        fontSize={10}
                        fontWeight={400}
                        lineHeight={'15px'}
                      >
                        Editing:
                      </Typography>
                      <Tooltip title={selectedLayer}>
                        <Typography
                          fontSize={10}
                          fontWeight={500}
                          lineHeight={'15px'}
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '80px',
                          }}
                        >
                          {selectedLayer}
                        </Typography>
                      </Tooltip>
                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <Down />
                      </Box>
                    </Box>
                  </Popover>
                  <Divider type='vertical' />
                </Box>
                {isManualSyncTipVisible ? (
                  <ModifyManualSyncTip
                    unSyncedChangedCount={unSyncedModifiedChanges.length}
                    lastSyncTime={timeAgo(lastSyncedOn)}
                    onSave={handleSaveUnSyncedModifications}
                    onReset={() => setShowResetModal(true)}
                  />
                ) : topologyObjects.topologyWarning[chosenLayer?.layerId]
                    ?.count > 0 ? (
                  <TopologyErrors
                    topologyWarningStringsArray={
                      topologyObjects.topologyWarningStringsArray
                    }
                    topologyWarning={topologyObjects.topologyWarning}
                    chosenLayer={chosenLayer}
                    layerComponents={topologyObjects.layerComponents}
                    ignoreTopoChecks={topologyObjects.ignoreTopoChecks}
                    displayComponentName={topologyObjects.displayComponentName}
                  />
                ) : (
                  <QuickTips activeTool={activeTool} />
                )}
              </Row>
            </Box>
          </nav>
        )}
        <Prompt
          message='There are unsaved changed on this page - Are you sure want to leave?'
          when={hasUnSyncedChanges}
        />

        {showResetModal && (
          <ResetUnsyncedEditsModal
            visible={showResetModal}
            onClose={handleResetClose}
            onReset={handleResetConfirm}
          />
        )}
      </Box>
    </Box>
  );
};
export default MapToolbar;
