// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type CopyIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function CopyIcon(props: CopyIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 20 20"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M9.036 6.905h3.66c.364 0 .713.15.97.418.258.268.403.631.403 1.01v5.238c0 .38-.145.743-.402 1.01a1.346 1.346 0 01-.97.419H9.035c-.364 0-.713-.15-.97-.418a1.459 1.459 0 01-.403-1.01v-.477h.458v.476c0 .253.096.495.268.674a.897.897 0 00.647.279h3.66c.243 0 .476-.1.647-.28a.972.972 0 00.268-.673V8.333a.972.972 0 00-.268-.673.897.897 0 00-.647-.28h-3.66a.897.897 0 00-.647.28.972.972 0 00-.268.673v2.381h-.458v-2.38c0-.38.145-.743.402-1.01.258-.269.607-.42.97-.42zM7.206 5h4.575v.476H7.206c-.243 0-.476.1-.647.28a.972.972 0 00-.268.673v4.285c0 .253.096.495.268.674a.897.897 0 00.647.279h3.317l-1.03-1.072.304-.357 1.602 1.667-1.602 1.666-.304-.357 1.03-1.071H7.206a1.34 1.34 0 01-.97-.419 1.459 1.459 0 01-.403-1.01V6.43c0-.38.145-.743.402-1.01.258-.268.607-.419.97-.419z"
        }
        fill={"currentColor"}
        stroke={"currentColor"}
        strokeWidth={".417"}
      ></path>
    </svg>
  );
}

export default CopyIcon;
/* prettier-ignore-end */
