import { MapEditorStore } from './index';

export const INITIAL_STATE: MapEditorStore = {
  undoStack: [],
  redoStack: [],

  modals: {
    copy: {
      open: false,
      layerId: null,
    },

    delete: {
      open: false,
      layerId: null,
    },

    addLayer: {
      open: false,
      layerId: null,
    },

    baseViewEditRestriction: {
      open: false,
    },
  },
};
