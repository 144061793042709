import { useAtom as useBaseAtom } from 'jotai/react';
import { Atom, atom as baseAtom } from 'jotai/vanilla';

export const useAtom = <Value>(atom: Atom<Value>) => {
  return useBaseAtom(atom);
};

export const getWritableAtom = <Value>(atom: Atom<Value>) => {
  return baseAtom(null, (get, set, callbackOrValue: any) => {
    if (typeof callbackOrValue === 'function') {
      /** We need to use the return value from callback */
      set(atom, (atomValue) => callbackOrValue(atomValue));
    } else {
      set(atom, callbackOrValue);
    }
  });
};

/** TODO: Finalize following */
export const useWritableAtom = <Value>(atom: Atom<Value>) => {
  const [_, setValue] = useAtom(
    baseAtom(null, (get, set, callback: any) => {
      set(atom, callback(get(atom)));
    })
  );
};
