import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FixedSizeList as List } from 'react-window';
import { Row, Spin } from 'antd';
import OlMap from 'ol/Map';
import {
  getComponentServiceItemColumnData,
  getLayerServiceItemColumnData,
  toPlasmicEstimationLayerPanel,
} from '../../../../transformers/service-item.transformers';
import {
  LayerWithComponents,
  SelectedComponent,
  ServiceItem,
  SingleComponent,
} from '../../../../api/types';
import { IStore } from '../../../../../../store/types';
import MapContext from '@/components/pages/project/MapContext';
import {
  AccessRight,
  NumericId,
  UserRoleId,
} from '../../../../../common/types';
import { OrderData } from '../../../../../../store/order/state';
import { getFeatureList } from '@/store/order/thunks';
import { trackEvents } from '../../../../../../helpers/utilities';
import { MapControlsEvt } from '../../../../../../segment';
import {
  toggleBasemap,
  toggleNearmap,
  createTileDebugLayer,
  removeTileDebugLayer,
} from '../../../../../../helpers/mapUtils/mapInit';
import {
  showHideBasemap,
  showHideNearmap,
  showHideParcel,
} from '../../../../../../store/ui/actions';
import { useParcelLayerToggle } from '../../../../hooks/useParcelLayerToggle';
import { useComponentHighlight } from '../../../../hooks/estimation/useComponentHighlight';
import { getMMMMDYYYYFormattedDate } from '../../../../../../helpers/utils';
import MrLayerPanel from '../../../../../../components/PlasmicComponents/TakeoffLayerPanel';
import MrLayerRow from '../../../../../../components/PlasmicComponents/MrLayerRow';
import MrComponentRow from '../../../../../../components/PlasmicComponents/MrComponentRow';
import { produce } from 'immer';
import { vhMinusPx } from '../../../../../../components/pages/project/projectComponents/helpers';
import { Box, Typography } from '@siterecon/sr-styles/dist/elements';
import useLayers from '../../../../hooks/takeoff/useLayers';
import { copyToClipboard } from '../../../../../../helpers/utilities/clickBoardUtils';
import { useServiceItems } from '../../../../hooks/estimation/useServiceItems';
import { useMapEditor } from '../../../../hooks/useMapEditor';
import { FeatureType } from '@/components/feature-attributes/types';
import {
  setAddLayerModalData,
  setBaseViewEditRestrictionModalData,
} from '../../../../../../store/map-editor/actions';
import { setAttributeModalVisiblity } from '@store/attributeFeature/actions';
import useCurrentView from '../../../../../../jotai/atoms/views/useCurrentView';
import './style.less';
import {
  TakeoffMapToolBar,
  TakeoffMapToolBarProps,
} from '../TakeoffMapToolBar';
import { showNotification } from '../../../../../../components/storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../../../../../components/storybook/NotificationToast/types';
import { useLayerItemHover } from '../../../../hooks/takeoff/useLayerItemHover';
import {
  useCheckedComponents,
  useCheckedLayers,
  useComponentsToBeDeleted,
  useExpandedLayers,
  useSelectedComponents,
  useSelectedLayerId,
  useTopologyWarnings,
  writableCheckedComponentsAtom,
  writableCheckedLayersAtom,
  writableSelectedComponentsAtom,
} from '../../../../../../jotai/atoms/property/takeoff/layer-panel';
import MrTakeoffLayerServiceRowChipCollection from '../../../../../../components/PlasmicComponents/MrTakeoffLayerServiceRowChipCollection';
import { center } from '@turf/turf';
import { useAtom } from 'jotai/react';
import useActiveTool from '../../../../../../jotai/atoms/tools/useActiveTool';
import { MapTool } from '../../../../../project/types';
import {
  setAttributePanelVisibility,
  setOrderLayers,
  setParcelStyle,
  setVisibleAttributesInLayerPanel,
} from '../../../../../../store/order/actions';
import { getFeatureByComponent } from '../../../../helpers/layer-render.helpers';
import { TopoData, TopologyType } from './types';
import { UPDATE_COMPONENT } from '@helpers/constants/APIEndpoints';
import { replaceParams } from '@helpers/utilities/linkUtils';
import { getAxiosInstance } from '@helpers/utilities/api-utils';
import useDragMultiSelect from '../../../../hooks/takeoff/useDragMultiSelect';
import { WidgetBarTabs } from '../../../../../../components/types';
import { useWidgetBarTabContext } from '../../../../hooks/useWidgetBar';
import { updateLayer, updateParcelStyle } from '../../../../api';
import { useTakeoffActionCenterLayerId } from '../../../../../../jotai/atoms/property/takeoff/action-center';
import usePrevious from '../../../../../../hooks/usePrevious';
import { ApiParcelStyle } from '../../../../types/order.types';
import { useAccess } from '../../../../../project/guards/AccessGuard';
import {
  DISABLE_TOOLTIP_TEXT,
  SHARE_MAP_PAGE,
} from '../../../../../../components/constants';
import { useRole } from '../../../../../auth/guards/RoleGuard';

type TakeOffLayerPanelProps = Omit<TakeoffMapToolBarProps, 'selectedLayerId'>;

const axiosInstance = getAxiosInstance();

function getAndIncrementComponentID(text) {
  // Use a regular expression to extract the number from the text
  const numberPart = text.match(/\d+/);

  if (numberPart) {
    // Convert the extracted number to an integer and increment it
    const incrementedNumber = parseInt(numberPart[0], 10) + 1;

    // Return only the incremented number
    return incrementedNumber;
  } else {
    // If no number is found, return null or handle as needed
    return null;
  }
}

const TakeOffLayerPanel = (props: TakeOffLayerPanelProps) => {
  const mapRef: OlMap = useContext(MapContext);
  const listRef = useRef({});
  const dispatch = useDispatch();

  const { highlight } = useComponentHighlight();
  const activeTool = useSelector<IStore, MapTool>(
    (state) => state.user.activeTool as unknown as MapTool
  );
  const { activeTabs } = useWidgetBarTabContext();
  const previousActiveTabs = usePrevious(activeTabs);
  const [, setTakeoffActionCenterLayerId] = useTakeoffActionCenterLayerId();

  const { execute } = useMapEditor();

  const { isEstimator } = useRole();

  const isShareMapPage = window.location.pathname.includes(SHARE_MAP_PAGE);

  const userRoleId = useSelector<IStore, UserRoleId>(
    (state) => state.user.info.user_role_id
  );

  const isNearmapDebugEnabled = useSelector<boolean>(
    (state) => state.ui.isNearmapDebugEnabled
  );

  const orderData = useSelector<IStore, OrderData | null>(
    (state) => state.order.orderData
  );

  const parcelStyle = JSON.parse(orderData?.parcel_style?.style);

  const featureListInfo = useSelector<IStore, any>(
    (state) => state.order.featureListInfo
  );

  /** default master checkbox state */
  const defaultMasterSelectedCheckboxState = useRef({});
  const defaultMasterUnSelectedCheckboxState = useRef({});

  /** default slave selected and unselected states */
  const defaultSlaveSelectedState = useRef({});
  const defaultSlaveUnSelectedState = useRef({});

  /** default slave checked and unchecked states */
  const defaultSlaveCheckedState = useRef({});
  const defaultSlaveUnCheckState = useRef({});

  /** default single selected master service item id */
  const [currentSelectedMaster, setCurrentSelectedMaster] =
    useSelectedLayerId();
  const [masterExpandState, setMasterExpandState] = useExpandedLayers();

  /** default current selection master checkout map which holds */
  const [currentSelectedMasterCheckbox] = useCheckedLayers();

  const [, setCurrentSelectedMasterCheckbox] = useAtom(
    writableCheckedLayersAtom
  );

  /** manage the unchecked toggle state of slaves */
  const [unCheckedMap, setUncheckedMap] = useState({});

  const [slaveCheckbox] = useCheckedComponents();
  const [slaveSelection] = useSelectedComponents();

  const [, setSlaveCheckbox] = useAtom(writableCheckedComponentsAtom);
  const [, setSlaveSelection] = useAtom(writableSelectedComponentsAtom);

  const [topologyWarnings, setTopologyWarnings] = useTopologyWarnings();
  const [, setComponentsToBeDeleted] = useComponentsToBeDeleted();
  const mapToolBarRef = useRef();

  const right = useAccess();

  const [sortValue, setSortValue] = useState(null);

  const attributePanelVisible = useSelector<IStore, boolean>(
    (state: any) => state.order.attributePanelVisible
  );
  const visibleAttributesInLayerPanel = useSelector<IStore>(
    (state: any) => state.order.visibleAttributesInLayerPanel
  );

  const isSIColumnVisible = useMemo(() => {
    return attributePanelVisible && visibleAttributesInLayerPanel?.length > 0;
  }, [attributePanelVisible, visibleAttributesInLayerPanel?.length]);

  const { serviceItems } = useServiceItems();

  const serviceItemsMap = useMemo(() => {
    const map: Record<NumericId, ServiceItem> = {};
    for (const serviceItem of serviceItems) {
      map[serviceItem.id] = serviceItem;
    }

    return map;
  }, [serviceItems]);

  useEffect(() => {
    if (!featureListInfo?.data?.length) {
      dispatch(getFeatureList());
    }
  }, []);

  useEffect(() => {
    if (!props.openFPanel) {
      handleServiceColumnOpen(false);
    }
  }, [props.openFPanel]);

  useEffect(() => {
    if (isNearmapDebugEnabled) {
      mapRef.addLayer(createTileDebugLayer());
    } else {
      removeTileDebugLayer(mapRef);
    }

  }, [isNearmapDebugEnabled]);

  const handleServiceColumnOpen = (visible: boolean) => {
    trackEvents('takeoff-map__service-item-column_visible', { visible });
    dispatch(setAttributePanelVisibility(visible));
    dispatch(setVisibleAttributesInLayerPanel(visible ? ['SERVICE_ITEM'] : []));
  };

  const { currentView } = useCurrentView();

  const selectedBasemap = useSelector<IStore, Boolean>(
    (state) => state.ui.selectedBasemap
  );

  const isBasemapEnabled = useSelector<IStore, Boolean>(
    (state) => state.ui.isBasemapEnabled
  );

  const isParcelEnabled = useSelector<IStore, Boolean>(
    (state) => state.ui.isParcelEnabled
  );

  const isNearmapEnabled = useSelector<IStore, Boolean>(
    (state) => state.ui.isNearmapEnabled
  );

  const { handleHover } = useLayerItemHover();

  const { handleToggle: handleParcelToggle } = useParcelLayerToggle();

  const {
    isFetching,
    layers,
    handleToggleLayer,
    handleToggleComponent,
    handleFeatureHighlight,
    handleLayerHighlight,
    handleAllFeatureUnHighlight,
  } = useLayers(currentView!.viewId, {
    onHighlightChange: (components) => {
      /** Scroll to view */
      // check the parent layer id
      if (components[0] && components[0].layerId) {
        const component = components[0];
        const layerId = components[0].layerId;
        let incId = getAndIncrementComponentID(component.componentName);

        setMasterExpandState({ [layerId]: true });
        setCurrentSelectedMaster(layerId);
        setTimeout(() => {
          // const number = getNumberFromId(components[0].componentIndexing);
          // we need to find a way to get that specific index of the element
          listRef.current[layerId].scrollToItem(incId, 'center');
          // we give some time for the list ref to get initizlized before scrolling
        }, 300);
      }
      setSlaveSelection((prevState) => {
        const newSelection = { ...prevState };

        for (const layerId of Object.keys(newSelection)) {
          const layerSelection = { ...newSelection[layerId] };

          for (const componentId of Object.keys(newSelection[layerId])) {
            layerSelection[componentId] = false;
          }

          newSelection[layerId] = layerSelection;
        }

        for (const component of components) {
          if (!newSelection[component.layerId]) {
            newSelection[component.layerId] = {};
          }

          newSelection[component.layerId][component.componentId] = true;
        }

        return newSelection;
      });
    },
  });

  const layerComponentsMap = useMemo(() => {
    const map: Record<NumericId, Record<NumericId, SingleComponent>> = {};

    for (const layer of layers) {
      for (const component of layer.components) {
        if (!map[layer.layerId]) {
          map[layer.layerId] = {};
        }

        map[layer.layerId][component.componentId] = component;
      }
    }

    return map;
  }, [layers]);

  /** TODO: Move this to some other place if required */
  useDragMultiSelect(
    mapRef,
    layers.find((layer) => layer.layerId === currentSelectedMaster) ?? undefined
  );

  // const selectedLayer = useMemo(() => {
  //   return (
  //     layers.find((layer) => layer.layerId === currentSelectedMaster) ?? null
  //   );
  // }, [currentSelectedMaster, layers]);

  const segmentPayload = {
    orderId: currentView?.orderId,
    viewId: currentView?.viewId,
    isBaseView: currentView?.isBaseView,
    isEditable: currentView?.isEditable,
  };

  const baseMapToggleHandler = (checked: boolean) => {
    trackEvents(MapControlsEvt.BaseMapToggleClicked);
    dispatch(showHideBasemap(checked));
    toggleBasemap(mapRef, checked, selectedBasemap);
  };

  const nearmapToggleHandler = (checked: boolean) => {
    trackEvents(MapControlsEvt.NearmapToggleClicked);
    dispatch(showHideNearmap(checked));
    toggleNearmap(mapRef, checked);
  };

  const parcelToggleHandler = (checked: boolean) => {
    trackEvents('layerpanel__parcel-toggle', {
      type: checked ? 'show' : 'hide',
      ...segmentPayload,
    });
    dispatch(showHideParcel(checked));
    handleParcelToggle(checked);
  };

  const parcelStyleChangeHandler = async (style: ApiParcelStyle) => {
    if (!orderData || !style || right !== AccessRight.Edit) return;

    dispatch(setParcelStyle(style));
    await updateParcelStyle(orderData.hashNumber.toString(), style);
  };

  useEffect(() => {
    dispatch(showHideParcel(true));
    handleParcelToggle(true);
  }, []);

  const masterCheckboxToggleHandler = (checked) => {
    trackEvents('layerpanel__master-layer-checked', {
      ...segmentPayload,
      checked: checked,
    });
    setCurrentSelectedMasterCheckbox(
      checked
        ? defaultMasterSelectedCheckboxState.current
        : defaultMasterUnSelectedCheckboxState.current
    );
    setSlaveCheckbox(
      checked
        ? defaultSlaveCheckedState.current
        : defaultSlaveUnCheckState.current
    );
    setUncheckedMap((prevState) =>
      produce(prevState, (draft) => {
        Object.keys(defaultMasterSelectedCheckboxState.current).forEach(
          (key) => {
            let item = defaultMasterSelectedCheckboxState.current[key];
            if (checked) {
              draft[key] = 0;
            } else {
              draft[key] = Object.keys(
                defaultSlaveCheckedState.current[key]
              ).length;
            }
          }
        );
      })
    );

    if (!checked) {
      for (const layer of layers) {
        handleToggleLayer(false, layer.layerId);
      }
    }

    setTimeout(() => {
      if (checked) {
        for (const layer of layers) {
          handleToggleLayer(true, layer.layerId);
        }
        return;
      }

      // setCheckedItems(
      //   toCheckedItems(data!).map((item) => ({ ...item, checkedComponents: [] }))
      // );
    }, 0);
  };

  const layersList = useMemo(() => {
    const layerPanelData = layers
      ? toPlasmicEstimationLayerPanel(
        layers,
        slaveCheckbox,
        currentSelectedMasterCheckbox,
        unCheckedMap
      )
      : null;

    if (!layerPanelData || !orderData) return null;

    // if (
    //   !currentSelectedMaster ||
    //   !layers.find((layer) => layer.layerId === currentSelectedMaster)
    // ) {
    //   // setCurrentSelectedMaster(layerPanelData.serviceItems[0]?.id);
    // }

    // setMasterExpandState(layerPanelData.masterExpandedState);
    defaultMasterSelectedCheckboxState.current =
      layerPanelData.masterCheckBoxCheckedState;
    defaultMasterUnSelectedCheckboxState.current =
      layerPanelData.masterCheckBoxUnCheckedState;

    setUncheckedMap(layerPanelData.unCheckedMapData);
    setCurrentSelectedMasterCheckbox(layerPanelData.masterCheckBoxState);

    defaultSlaveSelectedState.current = layerPanelData.slaveCheckBoxState;
    defaultSlaveUnSelectedState.current =
      layerPanelData.slaveCheckBoxUnCheckedState;

    defaultSlaveCheckedState.current = layerPanelData.slaveCheckBoxCheckedState;
    defaultSlaveUnCheckState.current =
      layerPanelData.slaveCheckBoxUnCheckedState;

    const finalList = {
      ...layerPanelData,
      nearmap: {
        date: getMMMMDYYYYFormattedDate(orderData.imagerydate),
        checked: isNearmapEnabled,
        disabled: !orderData.imagerydate,
      },
      basemap: {
        checked: isBasemapEnabled,
        disabled: false,
      },
      parcel: {
        color: parcelStyle.fillColor,
        measurement: orderData.parcelArea,
        checked: isParcelEnabled,
        disabled: !orderData.parcelJson,
      },
    };

    return finalList;
  }, [layers, isNearmapEnabled, isBasemapEnabled, isParcelEnabled]);

  const firstLayerId = layersList?.serviceItems[0]?.id;

  useEffect(() => {
    if (!layersList) return;
    const firstLayerId = layersList.serviceItems[0]?.id;
    if (!firstLayerId) return;

    setCurrentSelectedMaster(firstLayerId);
    setSlaveSelection(layersList.slaveSelectionState);
    setSlaveCheckbox(layersList.slaveCheckBoxState);

    const layer = layers.find((layer) => layer.layerId === firstLayerId);
    if (!layer) return;

    setTakeoffActionCenterLayerId(layer.layerId);
  }, [layersList?.serviceItems.length, firstLayerId, currentView?.viewId]);

  useEffect(() => {
    if (!previousActiveTabs && activeTabs?.includes(WidgetBarTabs.LAYERS)) {
      for (const layer of layers) {
        if (layer.viewId === currentView?.viewId) {
          handleToggleLayer(true, layer.layerId);
        }
      }
    }
  }, [activeTabs]);

  const copyMeasurementHandler = (area: number) => {
    copyToClipboard(area, false);
    showNotification(NOTIFICATIONS_TYPES.SUCCESS, 'Copied to Clipboard');
  };

  const handleComponentTopologyWarningsIgnore = async (
    layerId: NumericId,
    componentId: NumericId
  ) => {
    try {
      trackEvents('layerpanel__topocheck_ignore-single');
      await axiosInstance.patch(
        replaceParams(UPDATE_COMPONENT, {
          ':viewId': currentView.viewId,
          ':layerId': layerId,
          ':componentId': componentId,
        }),
        { warningStatus: 'ignored' }
      );

      const updatedLayers = layers.map((layer) => {
        if (layer.layerId === layerId) {
          return {
            ...layer,
            components: layer.components.map((component) => {
              if (component.componentId === componentId) {
                return { ...component, warningStatus: 'ignored' };
              }
              return component;
            }),
          };
        }

        return layer;
      });

      dispatch(setOrderLayers(updatedLayers));
      removeTopologyWarnings(layerId, componentId);
    } catch (error) {
      console.error(error);
    }
  };

  const handleLayerTopologyWarningsIgnore = async (layerId: NumericId) => {
    try {
      trackEvents('layerpanel__topocheck_ignore-multiple');
      await updateLayer(currentView.viewId, layerId, {
        warningStatus: 'ignored',
      });

      const updatedLayers = layers.map((layer) => {
        if (layer.layerId === layerId) {
          return {
            ...layer,
            warningStatus: 'ignored',
            components: layer.components.map((component) => ({
              ...component,
              warningStatus: 'ignored',
            })),
          };
        }

        return layer;
      });

      dispatch(setOrderLayers(updatedLayers));
      removeTopologyWarnings(layerId);
    } catch (error) {
      console.error(error);
    }
  };

  const removeTopologyWarnings = (
    layerId: NumericId,
    componentId?: NumericId
  ) => {
    const newTopologyWarnings = { ...topologyWarnings };

    const removeComponentWarning = (componentId: NumericId) => {
      delete newTopologyWarnings.overlapping[componentId];
      delete newTopologyWarnings.self_intersecting[componentId];
      delete newTopologyWarnings.null_geometry[componentId];
      delete newTopologyWarnings.duplicate[componentId];
    };

    if (componentId) {
      removeComponentWarning(componentId);
      setTopologyWarnings(newTopologyWarnings);
      return;
    }

    /** User clicked "Ignore All" */
    const componentIds =
      layers
        .find((layer) => layer.layerId === layerId)
        ?.components?.map((component) => component.componentId) ?? [];

    if (componentIds.length > 0) {
      for (const id of componentIds) {
        removeComponentWarning(id);
      }
      setTopologyWarnings(newTopologyWarnings);
    }
  };

  const handleSelect = ({ id, selectedComponents }: SelectedComponent) => {
    const serviceItem = layers!.find((item) => {
      return item.hasOwnProperty('layerId')
        ? item.layerId === id
        : item.id === id;
    });

    if (!serviceItem) return;

    if (selectedComponents.length === 0) {
      // setActionCenterData(null);
      highlight([]);
      return;
    }
    const components = serviceItem.components.filter((component) =>
      selectedComponents.includes(component.componentId)
    );

    let finalComponents = components;

    // if component is still empty that means it is removed so adding default components to the list
    if (finalComponents.length === 0) {
      finalComponents = serviceItem.components;
    }

    highlight(finalComponents);
  };

  const handleAddNewLayer = () => {
    if (!currentView) return;

    if (currentView.isEditable || userRoleId === UserRoleId.Estimator) {
      dispatch(
        setAddLayerModalData({
          open: true,
          layerId: null,
        })
      );

      dispatch(setAttributeModalVisiblity(true));
      return;
    }

    dispatch(setBaseViewEditRestrictionModalData({ open: true }));
    /** TODO: Add function to open base view not editable modal here. Also replace all `toggleAddAfterLayer` in codebase to that logic */
  };

  const noServiceItemSlot = (
    <Row justify='start'>
      <Typography
        fontSize={10}
        lineHeight={1.5}
        fontWeight={500}
        className='italic'
        style={{ color: '#999999' }}
      >
        No Service items
      </Typography>
    </Row>
  );

  /** Each component 'ID' of each layer */
  const ComponentRow = ({ data, index, style }) => {
    const measurement = data[index].measurement;
    const name = data[index].name;
    const parentId = data[index].parentId;
    const componentId = data[index].id;
    const featureType = data[index].featureType;

    const component: SingleComponent = layerComponentsMap[parentId]?.[
      componentId
    ] ?? {
      ...data[index],
      componentId,
      layerId: parentId,
    };

    const toggleCount = slaveCheckbox[data[index].parentId][data[index].id]
      ? 1
      : 0;
    const isSelected = slaveSelection[data[index].parentId][data[index].id];

    const componentToggleHandler = (a, b, checked) => {
      let totalUpdates = [];
      setSlaveCheckbox((prevState) =>
        produce(prevState, (draft) => {
          if (!draft[parentId]) {
            draft[parentId] = {
              [componentId]: checked,
            };
          } else if (!draft[parentId][componentId]) {
            draft[parentId][componentId] = checked;
          }
          Object.keys(draft).forEach((key) => {
            if (draft[key][componentId] !== undefined) {
              draft[key][componentId] = checked;
              totalUpdates.push({
                parentId: key,
                componentId,
              });
            }
          });
        })
      );
      setUncheckedMap((prevState) =>
        produce(prevState, (draft) => {
          totalUpdates.forEach((data) => {
            const { parentId, componentId } = data;
            if (draft[parentId] === undefined) {
              if (checked) {
                draft[parentId] = null;
              } else {
                draft[parentId] = 1;
              }
            } else {
              if (checked) {
                draft[parentId] = draft[parentId] - 1;
              } else {
                draft[parentId] = draft[parentId] + 1;
              }
            }
          });
        })
      );

      setTimeout(() => {
        handleToggleComponent(checked, parentId, componentId);
      }, 0);
    };

    const singleComponentSelectHandler = () => {
      if (activeTool !== MapTool.Select) return;

      if (currentSelectedMaster !== parentId) {
        setCurrentSelectedMaster(parentId);
      }
      setSlaveSelection((prevState) =>
        produce(defaultSlaveUnSelectedState.current, (draft) => {
          if (!draft[parentId]) {
            draft[parentId] = {};
          }
          draft[parentId][componentId] = !isSelected;
        })
      );

      handleFeatureHighlight(component, parentId, !isSelected);

      if (!isSelected) {
        if (typeof component.geoJson !== 'string') {
          component.geoJson = JSON.stringify(component.geoJson);
        }
        const feature = getFeatureByComponent(component);

        /** When a component is selected on layer panel, zoom map to that component */
        mapRef.getView().animate({
          center: center(feature).geometry.coordinates,
          duration: 500,
        });
      }
    };

    const multipleComponentsSelectHandler = (...args) => {
      if (activeTool !== MapTool.Select) return;

      setSlaveSelection((prevState) =>
        produce(prevState, (draft) => {
          if (!draft[parentId]) {
            draft[parentId] = {};
          }
          draft[parentId][componentId] = !isSelected;
        })
      );

      // let selectedComponents = Object.keys(slaveSelection[parentId])
      //   .filter((key) => slaveSelection[parentId][key] === true)
      //   .map((id) => Number(id));

      handleFeatureHighlight(component, parentId, !isSelected, true);
    };

    const componentHoverHandler = (id: number, info: string) => {
      if (activeTool !== MapTool.Select) return;

      handleHover({ id, info });
    };

    const componentDeleteHandler = (component: SingleComponent) => {
      trackEvents('action-center__delete', {
        ...segmentPayload,
        geometryType: featureType,
        layerName: name,
      });

      setComponentsToBeDeleted([component]);
    };

    const componentToposList: TopoData[] = [];
    for (let topo in topologyWarnings) {
      componentToposList.push({
        type: topo as TopologyType,
        componentNames: topologyWarnings[topo][componentId],
      });
    }

    const serviceItemRowChipData = getComponentServiceItemColumnData(
      component,
      serviceItemsMap
    );

    return (
      <div style={style}>
        <MrComponentRow
          onHover={componentHoverHandler}
          onComponentToggle={componentToggleHandler}
          onComponentSingleSelect={singleComponentSelectHandler}
          onComponentMultiSelect={multipleComponentsSelectHandler}
          onCopyMeasurement={copyMeasurementHandler}
          isActiveSelect={isSelected}
          toggleCount={toggleCount}
          mesurement={measurement}
          elementId={name}
          componentId={componentId}
          featureType={featureType}
          deleteComponent={{
            disable:
              (currentView?.isBaseView || !currentView?.isEditable) &&
              !isEstimator,
            onClick: () => componentDeleteHandler(component),
          }}
          chipCollection={
            serviceItemRowChipData.length > 0 ? (
              <MrTakeoffLayerServiceRowChipCollection
                serviceItemRowChipData={serviceItemRowChipData}
              />
            ) : (
              noServiceItemSlot
            )
          }
          openServiceItemColumn={isSIColumnVisible}
          // openServiceItemColumn={attributePanelVisible}
          warningTopo={{
            visible: componentToposList.some(
              (item) => item.componentNames && item.componentNames.length > 0
            ),
            featureType,
            topos: componentToposList,
          }}
          onIgnore={() =>
            handleComponentTopologyWarningsIgnore(parentId, componentId)
          }
        />
      </div>
    );
  };

  const getListItemHeight = (len: number) => {
    let height = len * 32;
    if (height > 300) {
      return 300;
    } else {
      return height;
    }
  };

  const getLayerItemSlot = () => {
    return layersList?.serviceItems.map((serviceItem) => {
      const {
        id,
        showBaseViewHeader,
        type,
        name,
        measurement,
        featureType,
        components,
        style,
        unit,
      } = serviceItem;
      let len = components.length;

      const unCheckedElementCount = unCheckedMap[id];

      let finalUnCheckedElementCount = Object.keys(
        slaveCheckbox[id] ?? {}
      ).filter((componentId) => !!slaveCheckbox[id][componentId]).length;

      // finalUnCheckedElementCount = len - finalUnCheckedElementCount;

      if (finalUnCheckedElementCount > len) {
        finalUnCheckedElementCount = len;
      }

      // const currentState = masterExpandState === id;
      const currentState = !!masterExpandState[id];
      const isActive = currentSelectedMaster === id;
      const isCheckBox = currentSelectedMasterCheckbox[id];


      if (len === 0 && finalUnCheckedElementCount === 0) {
        // len = 1;
        finalUnCheckedElementCount = 0;

        if (isCheckBox) {
          // len = 1;
          finalUnCheckedElementCount = 1;
        }
      }

      const isSortingRequired = currentState === true && sortValue !== null;

      const layerHoverHandler = (id: number, info: string) => {
        if (activeTool !== MapTool.Select) return;

        handleHover({ id, info });
      };

      const layerSelectHandler = () => {
        if (mapToolBarRef.current && !isActive) {
          mapToolBarRef.current.onExpandTree([id], {
            expanded: true,
            node: {
              key: `${id}`,
              area: 500,
              name: name,
            },
          });
        }

        if (activeTool !== MapTool.Select) return;

        const selected = !isActive;

        if (isActive === false) {
          setCurrentSelectedMaster(id);
          setSlaveSelection((prevState) =>
            produce(defaultSlaveUnSelectedState.current, (draft) => {
              const layer = layers.find((_layer) => _layer.layerId === id);

              if (!draft[id]) {
                draft[id] = {};
              }

              const selectedComponents: Record<NumericId, boolean> = {};
              if (layer) {
                layer.components.forEach((component) => {
                  selectedComponents[component.componentId] = true;
                });
              }

              draft[id] = layer
                ? selectedComponents
                : defaultSlaveSelectedState[id];
            })
          );
        } else {
          setCurrentSelectedMaster(null);
          setSlaveSelection((prevState) =>
            produce(prevState, (draft) => {
              if (!draft[id]) {
                draft[id] = {};
              }
              draft[id] = defaultSlaveUnSelectedState.current[id];
            })
          );
        }

        if (!selected) {
          setTakeoffActionCenterLayerId(null);
          handleAllFeatureUnHighlight();
          return;
        }

        const layerComponentsMap: Record<NumericId, SingleComponent> = {};
        for (const component of components) {
          layerComponentsMap[component.id] = {
            ...component,
            componentId: component.id,
            layerId: component.parentId,
          } as unknown as SingleComponent;
        }

        const selectedComponents = Object.keys(slaveSelection[id])
          .map((componentId) => layerComponentsMap[componentId])
          .filter((component) => !!component);

        handleLayerHighlight(id, selectedComponents);
      };

      const layerToggleHandler = (a, checked) => {
        let uncheckMap = { ...unCheckedMap };
        let masterSlavemap = {};

        trackEvents('layerpanel__layer-toggle', {
          type: checked ? 'show' : 'hide',
          geometry: featureType,
          layerName: name,
          ...segmentPayload,
        });

        setSlaveCheckbox((prevState) =>
          produce(prevState, (draft) => {
            if (!draft[id]) {
              draft[id] = {};
            }

            let currentItems = checked
              ? defaultSlaveCheckedState.current[id]
              : defaultSlaveUnCheckState.current[id];
            draft[id] = currentItems;

            let masterKeys = [];

            if (type === 'takeoff') {
              let keys = Object.keys(draft).filter(
                (key) => !String(key).startsWith('TAKEOFF')
              );
              masterKeys = keys;
            }

            if (type === 'serviceItem' || type === 'unassigned') {
              let keys = Object.keys(draft);
              masterKeys = keys;
            }

            Object.keys(currentItems).map((slaveKey, j) => {
              masterKeys.map((masterKey) => {
                if (draft[masterKey][slaveKey] !== undefined) {
                  if (masterSlavemap[masterKey] === undefined) {
                    masterSlavemap[masterKey] = {
                      ogCount: Object.keys(draft[masterKey]).length,
                      count: 0,
                    };
                  }

                  if (checked) {
                    if (draft[masterKey][slaveKey] === false) {
                      uncheckMap[masterKey] = uncheckMap[masterKey] - 1;
                    }
                    if (draft[masterKey][slaveKey] !== true) {
                      draft[masterKey][slaveKey] = true;
                    }
                    masterSlavemap[masterKey]['count'] -= 1;
                  } else {
                    if (draft[masterKey][slaveKey] === true) {
                      uncheckMap[masterKey] = uncheckMap[masterKey] + 1;
                    }
                    if (draft[masterKey][slaveKey] !== false) {
                      draft[masterKey][slaveKey] = false;
                    }
                    masterSlavemap[masterKey]['count'] += 1;
                  }
                }
              });
            });
          })
        );
        setCurrentSelectedMasterCheckbox((prevState) =>
          produce(prevState, (draft) => {
            if (!draft[id]) {
              draft[id] = {};
            }
            Object.keys(draft).map((key) => {
              if (masterSlavemap[key]) {
                let ogCount = masterSlavemap[key].ogCount;
                let count = masterSlavemap[key].count;
                if (ogCount === Math.abs(count)) {
                  if (count < 0) {
                    draft[key] = true;
                  } else {
                    draft[key] = false;
                  }
                }
              }
            });
            draft[id] = checked;
          })
        );
        setUncheckedMap((prevState) =>
          produce(uncheckMap, (draft) => {
            if (checked) {
              draft[id] = 0;
            } else {
              draft[id] = Object.keys(
                defaultSlaveCheckedState.current[id]
              ).length;
            }
          })
        );
        if (!isCheckBox) {
          let selectedComponents = Object.keys(
            defaultSlaveCheckedState.current[id]
          ).map((key) => Number(key));
          setTimeout(() => {
            // handleServiceItemCheckboxToggle(id, selectedComponents);
          }, 0);
        } else {
          setTimeout(() => {
            // handleServiceItemCheckboxToggle(id, []);
          }, 0);
        }

        handleToggleLayer(checked, id);
      };

      const expandLayerHandler = (state) => {
        setMasterExpandState(
          state ? { [id]: true } : { ...masterExpandState, [id]: false }
        );
      };

      const accordianStateHandler = () => {
        if (isActive === false) {
          setCurrentSelectedMaster(id);
          setSlaveSelection((prevState) =>
            produce(defaultSlaveUnSelectedState.current, (draft) => {
              if (!draft[id]) {
                draft[id] = {};
              }
              draft[id] = defaultSlaveSelectedState.current[id];
            })
          );
        } else {
          setCurrentSelectedMaster(null);
          setSlaveSelection((prevState) =>
            produce(prevState, (draft) => {
              if (!draft[id]) {
                draft[id] = {};
              }
              draft[id] = defaultSlaveUnSelectedState.current[id];
            })
          );
        }

        if (isActive === false) {
          let selectedComponents = Object.keys(slaveSelection[id]).map((key) =>
            Number(key)
          );
          handleSelect({ id, selectedComponents });
        } else {
          handleSelect({ id, selectedComponents: [] });
        }
      };

      let finalComponentList = serviceItem.components;
      if (isSortingRequired && sortValue === true) {
        finalComponentList = finalComponentList.sort(
          (a, b) => a.measurement - b.measurement
        );
      } else if (isSortingRequired && sortValue === false) {
        finalComponentList = finalComponentList.sort(
          (a, b) => b.measurement - a.measurement
        );
      }

      const serviceItemRowChipData = getLayerServiceItemColumnData(
        id,
        components as unknown as SingleComponent[],
        serviceItemsMap
      );

      const layerComponentIds = components.map((component) => component.id);
      const toposList: TopoData[] = [];
      let hasWarning = false;

      for (const type of Object.keys(topologyWarnings)) {
        const componentIds = layerComponentIds.filter(
          (componentId) => !!topologyWarnings[type][componentId]
        );

        if (componentIds.length > 0) {
          hasWarning = true;
        }

        toposList.push({
          type: type as TopologyType,
          componentNames: componentIds
            .map((componentId) => {
              const component: SingleComponent | undefined =
                layerComponentsMap[id][componentId];
              return component ? component.componentName : null;
            })
            .filter((component) => !!component) as string[],
        });
      }

      return (
        <>
          {/* Each layer row accordian with its components list */}
          <MrLayerRow
            totalCount={len === 0 ? 1 : len}
            toggledCount={finalUnCheckedElementCount}
            colorCode={serviceItem.color}
            styleCode={style}
            layerType={type}
            layerId={id}
            featureType={featureType}
            isFirstTakeoffLayer={showBaseViewHeader}
            unit={unit}
            onHover={layerHoverHandler}
            onServiceToggleChange={layerToggleHandler}
            onExpandedStateChange={expandLayerHandler}
            onOpenCloseAccordian={accordianStateHandler}
            onSelect={layerSelectHandler}
            isActive={isActive}
            expanded={currentState}
            measurement={featureType === FeatureType.POINT ? len : measurement}
            name={serviceItem.name}
            onCopyMeasurement={copyMeasurementHandler}
            openServiceItemColumn={isSIColumnVisible}
            chipCollection={
              serviceItemRowChipData.length > 0 ? (
                <MrTakeoffLayerServiceRowChipCollection
                  serviceItemRowChipData={serviceItemRowChipData}
                />
              ) : (
                noServiceItemSlot
              )
            }
            warningTopo={{
              visible: hasWarning,
              featureType: featureType,
              topos: toposList,
            }}
            onIgnoreAll={() => handleLayerTopologyWarningsIgnore(id)}
          />

          {/* List of components row for that layer */}
          {currentState === true && (
            <List
              style={{
                msOverflowStyle: 'none', // IE and Edge
                scrollbarWidth: 'none', // Firefox
                overflowY: 'scroll', // Enable vertical scrolling
                '&::-webkit-scrollbar': {
                  display: 'none', // Webkit browsers (Chrome, Safari)
                },
              }}
              onScroll={() => { }}
              ref={(ref) => (listRef.current[id] = ref)}
              className='List takeoff-layer-panel__component-list'
              height={getListItemHeight(len)}
              itemCount={len}
              itemData={finalComponentList}
              itemSize={32}
              width={'100%'}
            >
              {ComponentRow}
            </List>
          )}
        </>
      );
    });
  };

  const handleSorting = (type) => {
    trackEvents('layerpanel__sort-changed', {
      orderId: currentView?.orderId,
      viewId: currentView?.viewId,
      isBaseView: currentView?.isBaseView,
      isEditable: currentView?.isEditable,
      sortValue: type ? 'asc' : 'desc',
    });

    setSortValue(type);
  };

  const hasUncheckedMap = Object.keys(unCheckedMap).filter(
    (x) => unCheckedMap[x] > 0
  ).length;
  const totalLayersCount = Object.keys(currentSelectedMasterCheckbox).length;
  const totalToggledLayersCount = Object.keys(
    currentSelectedMasterCheckbox
  ).filter((key) => !!currentSelectedMasterCheckbox[key]).length;

  if (isFetching) {
    return (
      <div className='flex-center px-5 py-4'>
        <Spin />
      </div>
    );
  }

  const allToggleElementsCount =
    totalToggledLayersCount === 0
      ? 0
      : totalToggledLayersCount - hasUncheckedMap;

  return (
    <Box className='takeoff-layer-panel-box'>
      {
        /** If active tabs does not contain takeoff or estimation layer, don't show this */
        activeTabs?.some(
          (tab) =>
            tab === WidgetBarTabs.LAYERS || tab === WidgetBarTabs.ESTIMATION
        ) ? (
          <MrLayerPanel
            totalLayersCounts={totalLayersCount}
            allElementsCount={totalLayersCount}
            allToggleElementsCount={allToggleElementsCount}
            layerPanelHeight={vhMinusPx(100, 175)}
            layersList2={getLayerItemSlot()}
            onSortMeasurements={handleSorting}
            baseLayersData={layersList}
            onMasterCheck={masterCheckboxToggleHandler}
            onParcelToggle={parcelToggleHandler}
            onNearmapToggle={nearmapToggleHandler}
            onBasemapToggle={baseMapToggleHandler}
            onServiceColumnOpen={handleServiceColumnOpen}
            open={isSIColumnVisible}
            onParcelStyle={parcelStyleChangeHandler}
            parcelStyleObject={parcelStyle}
            addLayerBtn={{
              disabled: isShareMapPage,
              disableTooltip: DISABLE_TOOLTIP_TEXT,
              onClick: handleAddNewLayer,
            }}
            isSharePage={isShareMapPage}
          />
        ) : null
      }

      <TakeoffMapToolBar ref={mapToolBarRef} {...props} />
    </Box>
  );
};

export const getComponentById = (
  layers: LayerWithComponents[],
  componentId: NumericId,
  layerId: NumericId
) => {
  const layer = layers.find((layer) => layer.layerId === layerId);
  if (!layer) return null;

  return (
    layer.components.find(
      (component) => component.componentId === componentId
    ) ?? null
  );
};

export default TakeOffLayerPanel;
