import { MapCommandFunction } from '../types';
import { NumericId } from '../../../common/types';
import { Store } from '@/store';
import { setCopyModalData } from '../../../../store/map-editor/actions';

export interface CommandOptions {
  layerId: NumericId;
}

export const CopyLayerCommand: MapCommandFunction<CommandOptions> = () => {
  let _options: CommandOptions;

  return {
    init: (options) => {
      _options = options;
    },

    execute: async () => {
      if (!_options) return;

      Store.dispatch(
        setCopyModalData({
          open: true,
          layerId: _options.layerId,
        })
      );
    },
  };
};
