// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Frame1000006034IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Frame1000006034Icon(props: Frame1000006034IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M4.666 6.445a1.778 1.778 0 011.778-1.778h5.777A1.779 1.779 0 0114 6.445v5.777A1.777 1.777 0 0112.221 14H6.444a1.779 1.779 0 01-1.778-1.778V6.445zM9.332 8v2.667M7.998 9.334h2.667"
        }
        stroke={"currentColor"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      ></path>

      <path
        d={
          "M2.673 11.158A1.336 1.336 0 011.998 10V3.333C1.998 2.6 2.598 2 3.331 2h6.667c.5 0 .772.257 1 .667"
        }
        stroke={"currentColor"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      ></path>
    </svg>
  );
}

export default Frame1000006034Icon;
/* prettier-ignore-end */
