import { NotifyError } from '@/helpers/notification-utils';
import * as turf from '@turf/turf';
import moment from 'moment';
import { Draw } from 'ol/interaction';
import {
  CUT_SPLIT_STYLE_MODE,
  cutInteractionStyle,
} from '../../mapGlobals/styles';
import { DRAWING_MODE_STYLE } from '../../mapGlobals';
import { addCrossLayerSnap, resetCrossLayerSnap } from './snapping';
import {
  addFeaturesToMap,
  convertFeatureToJson,
  createDrawnPolygon,
  featureToPolygon,
  generateTempId,
} from './utilities';
import { generatePropertiesFromFeature } from '../autoSave/utils';
import { updateGeoJsonPropertiesInFeature } from './doughnutTool';

// Used https://jsfiddle.net/TomazicM/157s3Lmt/
// https://gis.stackexchange.com/questions/339622/splitting-feature-in-openlayers-5
const cutIdPrefix = 'cut_';
let parcelLayer;
let snipInteraction;
let snap_draw;

export const snipInteractionActive = (
  sourceLayer,
  mapRef,
  callback,
  allLayerRefsObj,
  hasToolStart,
  args
) => {
  parcelLayer = sourceLayer;
  let type = 'LineString';
  parcelLayer.setStyle(CUT_SPLIT_STYLE_MODE);
  if (snipInteraction) {
    mapRef.removeInteraction(snipInteraction);
    resetCrossLayerSnap(mapRef);
  }
  snipInteraction = new Draw({
    type: type,
    style: cutInteractionStyle,
    condition: (e) => {
      return e.originalEvent.buttons === 1;
    },
  });

  mapRef.addInteraction(snipInteraction);
  addCrossLayerSnap(mapRef, allLayerRefsObj);
  // This function will be used to maintain Point level history
  args?.interactionsDrawingPointLevelTracking(snipInteraction);
  onDrawStart(snipInteraction, hasToolStart);
  onDrawEnd(snipInteraction, mapRef, callback, hasToolStart);
};

export const removeSnipInteraction = (mapRef) => {
  if (snipInteraction) {
    mapRef.getInteractions().forEach((interaction) => {
      if (interaction === snipInteraction) {
        mapRef.removeInteraction(snipInteraction);
      }
    });
    mapRef.getInteractions().forEach((interaction) => {
      if (interaction === snap_draw) {
        mapRef.removeInteraction(snap_draw);
      }
    });
  }
};
const onDrawStart = (snipInteraction, hasToolStart) => {
  snipInteraction.on('drawstart', (event) => {
    hasToolStart(true);
  });
};

const onDrawEnd = (snipInteraction, mapRef, callback, hasToolStart) => {
  snipInteraction.on('drawend', (event) => {
    let timeStamp = moment();
    let drawnGeometry = createDrawnPolygon(event.feature);

    if (!callback) {
      return;
    }

    // Check if drawn polygon is invalid
    const kinks = turf.kinks(drawnGeometry);
    if (kinks.features.length !== 0) {
      NotifyError('Cannot use self-intersecting lines for snipping');
      return;
    }

    mapRef.removeLayer(parcelLayer);
    let listOfPolygons = [];
    let listOfIntersectingPolygons = [];
    let callbackReqData = [];
    let newParcelLayer;
    let initialParcelLayerFeatures = parcelLayer.getSource().getFeatures();

    initialParcelLayerFeatures.forEach((feature) => {
      let old_properties = { ...feature.getProperties() };
      const parcelPolygon = featureToPolygon(feature);
      if (turf.booleanIntersects(parcelPolygon, drawnGeometry)) {
        listOfIntersectingPolygons.push(feature);
      } else {
        let new_polygon = turf.feature(parcelPolygon.geometry, {
          ...old_properties,
        });
        listOfPolygons.push(new_polygon);
      }
    });

    const listOfIntersectingPolygonsConverted = convertFeatureToJson(
      listOfIntersectingPolygons
    );

    listOfIntersectingPolygonsConverted.forEach((feature) => {
      let properties = { ...feature.properties };
      delete properties.geometry;

      try {
        let cutPolygon = polygonCut(feature, drawnGeometry, cutIdPrefix);
        if (
          cutPolygon === null ||
          !Array.isArray(cutPolygon.features) ||
          !cutPolygon.features.length
        ) {
          listOfPolygons.push(feature);
          return;
        }

        // Delete old polygon
        callbackReqData.push({
          geoJson: turf.feature(feature.geometry, {
            ...properties,
          }),
          timeStamp,
          type: 'deleteInteraction',
          componentId: properties.actualComponentId ?? properties.componentId,
          undoStackUpdateNotRequired: false,
        });
        turf.geomEach(cutPolygon, (geometry) => {
          const tempId = generateTempId();
          const componentId = tempId;
          // Add new polygons
          let polygon = turf.feature(geometry, {
            ...properties,
            temp_id: tempId,
            tempId: tempId,
            componentId: componentId,
            id: componentId,
            ID: componentId,
          });
          polygon.properties = {
            ...polygon.properties,
            ...generatePropertiesFromFeature(polygon),
          };
          polygon = updateGeoJsonPropertiesInFeature(polygon);
          listOfPolygons.push(polygon);
          let reqData = {
            geoJson: polygon,
            timeStamp,
            componentId: tempId,
            type: 'drawInteraction',
          };
          callbackReqData.push(reqData);
        });
      } catch (e) {
        parcelLayer.getSource().clear();
        NotifyError(
          `Something went wrong with ${properties.componentIndexing}`,
          { tool: 'snip' }
        );
      }
    });
    if (callbackReqData.length && callback) {
      callback('bulkInteraction', callbackReqData, null, true);
    }
    hasToolStart(false);
    newParcelLayer = addFeaturesToMap(
      listOfPolygons,
      parcelLayer,
      DRAWING_MODE_STYLE
    );
    mapRef.addLayer(newParcelLayer);
  });
};

const polygonCut = (polygonWhole, line, idPrefix) => {
  const THICK_LINE_UNITS = 'kilometers';
  const THICK_LINE_WIDTH = 0.0001;
  let i, j, id, intersectPoints, forCut, forSelect;
  let thickLineString, thickLinePolygon, clipped, polyg, intersect;
  let polyCoords = [];
  let cutPolyGeoms = [];
  let cutFeatures = [];
  let offsetLine = [];
  let retVal = null;
  let polygon;
  if (polygonWhole.type === 'Feature') {
    polygon = turf.getGeom(polygonWhole.geometry);
  } else if (
    polygonWhole.type === 'Polygon' ||
    polygonWhole.type === 'MultiPolygon'
  ) {
    polygon = polygonWhole;
  }
  if (
    (polygon.type !== 'Polygon' && polygon.type !== 'MultiPolygon') ||
    line.type !== 'LineString'
  ) {
    return retVal;
  }

  if (typeof idPrefix === 'undefined') {
    idPrefix = '';
  }

  intersectPoints = turf.lineIntersect(polygon, line);

  // If no intersection with the polygon of the line then return null
  if (intersectPoints.features.length === 0) {
    return retVal;
  }

  let lineCoords = turf.getCoords(line);

  // First or last coordinate of the line is within the polygon them return null
  if (
    turf.booleanWithin(turf.point(lineCoords[0]), polygon) ||
    turf.booleanWithin(turf.point(lineCoords[lineCoords.length - 1]), polygon)
  ) {
    return retVal;
  }

  offsetLine[0] = turf.lineOffset(line, THICK_LINE_WIDTH, {
    units: THICK_LINE_UNITS,
  });
  offsetLine[1] = turf.lineOffset(line, -THICK_LINE_WIDTH, {
    units: THICK_LINE_UNITS,
  });

  for (i = 0; i <= 1; i++) {
    forCut = i;
    forSelect = (i + 1) % 2;
    polyCoords = [];
    for (j = 0; j < line.coordinates.length; j++) {
      polyCoords.push(line.coordinates[j]);
    }
    for (j = offsetLine[forCut].geometry.coordinates.length - 1; j >= 0; j--) {
      polyCoords.push(offsetLine[forCut].geometry.coordinates[j]);
    }
    polyCoords.push(line.coordinates[0]);

    thickLineString = turf.lineString(polyCoords);
    thickLinePolygon = turf.lineToPolygon(thickLineString);
    try {
      clipped = turf.difference(polygon, thickLinePolygon);
    } catch (e) {
      // We are finding this error when you create a linestring which is of
      // 0 area and intersects the polygon
      // i.e. one point outside the polygon, then one point inside the polygon
      // then again the last point at the same point outside the polygon
      // This way the created polygon is very small and gives us\
      // https://github.com/Turfjs/turf/issues/297
      console.error(e);
      continue;
    }

    cutPolyGeoms = [];
    for (j = 0; j < clipped.geometry.coordinates.length; j++) {
      // validity check from
      // https://github.com/Turfjs/turf/blob/105804cb120c51fc975981900520def61a3f0568/packages/turf-helpers/index.ts#L314
      // Our clipped Polygon is invalid when you draw a linestring grazing the
      // boundary of the polygon
      let validityOfClippedPolygon = true;
      for (const ring of clipped.geometry.coordinates[j]) {
        if (ring.length < 4) {
          validityOfClippedPolygon = false;
          break;
        }
      }
      if (!validityOfClippedPolygon) {
        continue;
      }
      polyg = turf.polygon(clipped.geometry.coordinates[j]);
      intersect = turf.lineIntersect(polyg, offsetLine[forSelect]);
      if (intersect.features.length > 0) {
        cutPolyGeoms.push(polyg.geometry.coordinates);
      }
    }

    cutPolyGeoms.forEach((geometry, index) => {
      id = idPrefix + (i + 1) + '.' + (index + 1);
      cutFeatures.push(turf.polygon(geometry, { id: id }));
      // cutFeatures.push(turf.polygon(geometry, polygonWhole.properties))
    });
  }

  if (cutFeatures.length > 0) retVal = turf.featureCollection(cutFeatures);
  return retVal;
};
