import OlMap from 'ol/Map';
import { ID, NumericId, ViewType } from '../../../common/types';
import { Store } from '../../../../store';
import { clickLayerHighlight } from '../../../../helpers/mapUtils/featuresUtils';
import { Layer, LayerWithComponents, SingleComponent } from '../../api/types';
import { DragBox, Interaction } from 'ol/interaction';
import { platformModifierKeyOnly } from 'ol/events/condition';
import VectorLayer from 'ol/layer/Vector';
import { message } from 'antd';
import { useEffect } from 'react';
import {
  convertFeatureToComponent,
  convertToComponentList,
  geometryType,
  hasIntersection,
  rawFeatureToJsonFeature,
  virtualRectangle,
} from '@helpers/mapUtils/tools/multiSelecteTool';
import { setHighlightedComponents } from '@store/order/actions';
import { findLayerById } from '@helpers/utilities';
import useActiveTool from '../../../../jotai/atoms/tools/useActiveTool';
import { MapTool } from '../../../project/types';
import { mapLayers } from '../../helpers/layer.data';
import { useAtom } from 'jotai/react';
import {
  useExpandedLayers,
  useSelectedLayerId,
  writableSelectedComponentsAtom,
} from '../../../../jotai/atoms/property/takeoff/layer-panel';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../store/types';
import { OrderView } from '../../../../components/pages/project/projectComponents/types';
import { store } from '../../../../jotai';
import {
  takeoffActionCenterLayerIdAtom,
  takeoffActionCenterTypeAtom,
} from '../../../../jotai/atoms/property/takeoff/action-center';

const getLayerList = () => Store.getState().order.layerList ?? [];

const getFeatureList = () => Store.getState().order.featureListInfo?.data ?? [];

const getHighlightedComponents = (): SingleComponent[] =>
  Store.getState().order.highlighted ?? [];

const highlight = (
  layer: VectorLayer,
  featureId: ID,
  highlight: boolean = true
) => {
  /** TODO: Add `clicked=true` property to Feature when it is highlighted and `clicked=false` when it's un-highlighted */
  clickLayerHighlight(
    layer,
    featureId,
    highlight,
    getLayerList(),
    getFeatureList()
  );
};

const useDragMultiSelect = (map: OlMap, selectedLayer: Layer) => {
  const { activeTool } = useActiveTool();
  const currentView = useSelector<IStore, OrderView>(
    (state) => state.order.currentViewData
  );
  const [, setLayerPanelSelectedComponents] = useAtom(
    writableSelectedComponentsAtom
  );
  const [, setLayerPanelExpandedLayers] = useExpandedLayers();
  const [, setLayerPanelSelectedLayerId] = useSelectedLayerId();

  const updateLayerPanelSelectedComponents = (
    components: SingleComponent[]
  ) => {
    setLayerPanelSelectedComponents((prevState) => {
      const newSelection = { ...prevState };

      for (const layerId of Object.keys(newSelection)) {
        const layerSelection = { ...newSelection[layerId] };

        for (const componentId of Object.keys(newSelection[layerId])) {
          layerSelection[componentId] = false;
        }

        newSelection[layerId] = layerSelection;
      }

      for (const component of components) {
        if (!newSelection[component.layerId]) {
          newSelection[component.layerId] = {};
        }

        newSelection[component.layerId][component.componentId] = true;
      }

      return newSelection;
    });
  };

  const multiSelectCallBack = (
    /* all components selected with current virtual box */
    components: SingleComponent[],
    componentsToUnSelect: SingleComponent[]
  ) => {
    if (!selectedLayer) {
      /** Currently there is no item selected from layer panel, so we need to determine the layer */
      const [takeoffLayer, selectedComponents] = getTakeoffLayerByComponents(
        getLayerList()!,
        components
      ) ?? [undefined];

      if (!takeoffLayer) return;

      for (const component of selectedComponents) {
        if (mapLayers[takeoffLayer.layerId]) {
          highlight(mapLayers[takeoffLayer.layerId], component.componentId);
        }
      }

      store.set(takeoffActionCenterLayerIdAtom, null);
      // store.set(takeoffActionCenterTypeAtom, null);
      Store.dispatch(setHighlightedComponents(selectedComponents));
      updateLayerPanelSelectedComponents(selectedComponents);
      setLayerPanelExpandedLayers({ [takeoffLayer.layerId]: true });
      setLayerPanelSelectedLayerId(takeoffLayer.layerId);

      return;
    }

    const componentsToUnSelectIds =
      componentsToUnSelect?.map((component) => component.componentId) ?? [];

    let selectedComponents = getHighlightedComponents();
    selectedComponents = selectedComponents.filter(
      (component) => !componentsToUnSelectIds.includes(component.componentId)
    );

    // the current selection may contain components that have already been selected previously
    // Hence this variable newlySelectedComponents keeps the list of newly selected components on that layer
    let newlySelectedComponents: SingleComponent[] = [];

    for (let component of components) {
      if (
        !selectedComponents.find(
          (_component) => _component.componentId === component.componentId
        )
      ) {
        newlySelectedComponents.push(component);
      }
    }

    // Finally concat the list of all selected components and push it to the store
    let finalList: SingleComponent[] = [];

    finalList.push(...selectedComponents, ...newlySelectedComponents);

    for (const component of newlySelectedComponents) {
      if (mapLayers[selectedLayer.layerId]) {
        highlight(mapLayers[selectedLayer.layerId], component.componentId);
      }
    }

    if (componentsToUnSelect?.length) {
      for (const component of componentsToUnSelect) {
        const sourceLayerOfComponent = findLayerById(component.layerId, map);
        highlight(sourceLayerOfComponent!, component.componentId, false);
      }
    }

    store.set(takeoffActionCenterLayerIdAtom, null);
    // store.set(takeoffActionCenterTypeAtom, null);
    Store.dispatch(setHighlightedComponents(finalList));
    updateLayerPanelSelectedComponents(finalList);
    setLayerPanelExpandedLayers({ [selectedLayer.layerId]: true });
    setLayerPanelSelectedLayerId(selectedLayer.layerId);
  };

  useEffect(() => {
    removeDragBoxInteraction(map);

    if (
      activeTool !== MapTool.Select ||
      currentView?.viewType !== ViewType.STATIC
    )
      return;

    initializeMultiSelectTool(map, selectedLayer?.layerId, multiSelectCallBack);
  }, [selectedLayer?.layerId, activeTool, currentView?.viewType]);
};

let dragBoxInteraction: any;

const initializeMultiSelectTool = (
  mapRef: OlMap,
  selectedLayerId: ID | undefined,
  multiSelectCallBack
) => {
  if (dragBoxInteraction) {
    mapRef.removeInteraction(dragBoxInteraction);
  }

  dragBoxInteraction = new DragBox({
    condition: platformModifierKeyOnly,
    className: 'multiSelectBox',
  });
  mapRef.addInteraction(dragBoxInteraction);
  dragBoxInteraction.setActive(true);

  onDrawEnd(dragBoxInteraction, mapRef, selectedLayerId, multiSelectCallBack);
};

const getLayerComponents = (layerId?: ID) => {
  if (!layerId) return undefined;

  const layer = getLayerList().find((layer: any) => layer.layerId === layerId);
  return layer?.components ?? [];
};

const onDrawEnd = (
  /* ol/Interaction: Map interaction reference */
  virtualBox: Interaction,
  /* ol/Map: Map instance reference */
  mapRef: OlMap,
  selectedLayerId: ID | undefined,
  callback
) => {
  virtualBox.on('boxend', (event) => {
    const allowedComponents = getLayerComponents(selectedLayerId);
    const allowedComponentIds =
      allowedComponents?.map((item) => item.componentId) ?? [];

    // Fetch the virtual box drawn
    let drawnPolygon = virtualRectangle(virtualBox);

    // Store the geometries where virtual box and layer items intersect
    let olFeatureObjectSelected: any[] = [];
    let olFeatureObjectUnSelected: any[] = [];

    const selectedComponents = getHighlightedComponents();
    const selectedComponentsMap: Record<NumericId, SingleComponent> = {};

    selectedComponents.forEach((_selectedComponent) => {
      selectedComponentsMap[_selectedComponent.componentId] =
        _selectedComponent;
    });

    // iterate through every feature in that vector layer and
    // see if it intersects the virtual box
    mapRef.getLayers().forEach((layer) => {
      if (!(layer instanceof VectorLayer)) {
        return;
      }

      layer.getSource().forEachFeature((feature) => {
        const featureComponentId =
          feature.getProperties().componentId ?? feature.getProperties().ID;

        if (
          allowedComponents &&
          !allowedComponentIds.includes(Number(featureComponentId))
        ) {
          return;
        }

        let layerComponent = rawFeatureToJsonFeature(feature);
        const layerComponentGeometry = geometryType(feature);

        try {
          if (
            hasIntersection(
              layerComponent,
              drawnPolygon,
              layerComponentGeometry
            )
          ) {
            const _component = convertFeatureToComponent(feature);

            if (
              _component.componentId &&
              selectedComponentsMap.hasOwnProperty(_component.componentId)
            ) {
              /** Component is selected again with drag, so we need to unselect that */
              olFeatureObjectUnSelected.push(feature);
            } else {
              olFeatureObjectSelected.push(feature);
            }
          }
        } catch (error: any) {
          console.error([error, error.name, feature]);
          message.error(error.name);
        }
      });
    });

    // Invoke the callback with selected components in the view layer
    if (callback) {
      callback(
        convertToComponentList(olFeatureObjectSelected).map(
          (featureJson) => featureJson.properties
        ),
        convertToComponentList(olFeatureObjectUnSelected).map(
          (featureJson) => featureJson.properties
        )
      );
    }
  });
};

const removeDragBoxInteraction = (mapRef: OlMap) => {
  if (dragBoxInteraction) {
    mapRef.getInteractions().forEach((interaction) => {
      if (interaction === dragBoxInteraction) {
        mapRef.removeInteraction(dragBoxInteraction);
      }
    });
  }
};

/** TODO: Check if `layerList` contains the layer components also. If not, need to get the components from some other place */
const getTakeoffLayerByComponents = (
  data: LayerWithComponents[],
  components: SingleComponent[]
) => {
  let maxComponentsInLayer = 0;

  let result: [Layer, SingleComponent[]] | null = null;

  const componentIds = components.map((component) => component.componentId);

  for (const item of data) {
    const matchingComponents = item.components.filter((component) =>
      componentIds.includes(component.componentId)
    );

    if (matchingComponents.length > maxComponentsInLayer) {
      maxComponentsInLayer = matchingComponents.length;
      result = [item, matchingComponents];
    }
  }

  return result!;
};

export default useDragMultiSelect;
