import { MapCommandStack } from '../../modules/property/commands/types';
import { reducer } from './reducer';
import { Nullable, NumericId } from '../../modules/common/types';

export enum MapEditorModalType {
  COPY = 'copy',
  DELETE = 'delete',
  ADD_LAYER = 'addLayer',
  BASE_VIEW_EDIT_RESTRICTION = 'baseViewEditRestriction',
}

export interface MapEditorModal {
  open: boolean;
}

export interface IMapEditorDeleteConfirmationModal extends MapEditorModal {
  layerId: Nullable<NumericId>;
}

export interface IMapEditorCopyModal extends MapEditorModal {
  layerId: Nullable<NumericId>;
}

export interface IMapEditorAddLayerModal extends MapEditorModal {
  layerId: Nullable<NumericId>;
}

export interface IMapEditorBaseViewEditRestrictionModal
  extends MapEditorModal {}

export interface MapEditorModals {
  [MapEditorModalType.COPY]: IMapEditorCopyModal;
  [MapEditorModalType.DELETE]: IMapEditorDeleteConfirmationModal;
  [MapEditorModalType.ADD_LAYER]: IMapEditorAddLayerModal;
  [MapEditorModalType.BASE_VIEW_EDIT_RESTRICTION]: IMapEditorBaseViewEditRestrictionModal;
}

export interface MapEditorStore {
  undoStack: MapCommandStack;
  redoStack: MapCommandStack;

  modals: MapEditorModals;
}

export { reducer };
