import OlMap from 'ol/Map';
import { useContext, useEffect } from 'react';
import MapContext from '@/components/pages/project/MapContext';
import {
  clickLayerHighlight,
  onClickLayer,
} from '@/helpers/mapUtils/featuresUtils';
import {
  EstimationLayerType,
  LayerPanelData,
  LayerPanelDataItem,
  SingleComponent,
} from '../../api/types';
import { Feature, MapBrowserEvent } from 'ol';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../store/types';
import {
  findLayerById,
  findLayerStyleFromComponent,
} from '@/helpers/utilities/index';
import { createGeoJsonFromFeature } from '@/helpers/mapUtils/mapInit';
import { ID, NumericId } from '../../../common/types';
import { clone, get } from 'lodash';
import {
  ActionCenterData,
  useEstimationViewContext,
} from '../../context/EstimationView/context';
import { Layer } from 'ol/layer';
import { Store } from '@/store';
import { setHighlightedComponents as setReduxHighlightedComponents } from '@store/order/actions';

let onClickActionCenterData: ActionCenterData | null = null;

let onClickLayerPanelData: LayerPanelData | null = null;

let onClickLayerList: any = [];

let onClickFeatureList: any = [];

/** Sometimes unwanted components are remaining highlighted even after clicking another component. To resolve that we are storing highlighted components in normal variable also rather than just relying on react state */
export let highlightedComponents = (): SingleComponent[] => {
  return Store.getState().order.highlighted ?? [];
};

export const setHighlightedComponents = (components: SingleComponent[]) => {
  Store.dispatch(setReduxHighlightedComponents(components));
};

export const useOnFeatureClick = () => {
  const mapRef: OlMap = useContext(MapContext);

  const { data, actionCenterData, setActionCenterData } =
    useEstimationViewContext();

  const layerList = useSelector<IStore>((state) => state.order.layerList);

  const featureList = useSelector<IStore>(
    (state) => state.order.featureListInfo?.data ?? []
  );

  useEffect(() => {
    onClickActionCenterData = actionCenterData;
  }, [actionCenterData]);

  useEffect(() => {
    onClickLayerPanelData = data;
  }, [data]);

  useEffect(() => {
    onClickLayerList = layerList;
  }, [layerList]);

  useEffect(() => {
    onClickFeatureList = featureList;
  }, [featureList]);

  const highlight = (
    layer: Layer,
    featureId: ID,
    highlight: boolean = true
  ) => {
    clickLayerHighlight(
      layer,
      featureId,
      highlight,
      onClickLayerList,
      onClickFeatureList
    );
  };

  const createFeatureGeoJsonWithLayerStyle = (
    feature: Feature,
    layerId: NumericId
  ) => {
    let featureJson = createGeoJsonFromFeature(feature);
    featureJson.style = findLayerStyleFromComponent(layerId, layerList);
    featureJson.componentId =
      featureJson.properties.actualComponentId ??
      featureJson.properties.componentId;
    return featureJson;
  };

  const updateActionCenterData = (
    serviceItem: LayerPanelDataItem,
    components: SingleComponent[]
  ) => {
    setActionCenterData(
      components.length > 0
        ? {
            activeItem: serviceItem,
            components: components,
          }
        : null
    );

    const popover = document.getElementById('map-popover');
    if (popover) popover.style.display = 'none';
  };

  const handleFeatureClick = (
    component: SingleComponent,
    event: MapBrowserEvent,
    feature: Feature
  ): void => {
    // When no feature is selected, do nothing
    if (!feature) {
      return;
    }

    // The component id that has been attempted to be selected
    const componentId = component.componentId;

    // The layer this component belongs to
    let sourceLayer = findLayerById(component.layerId, mapRef);
    // This is the key to a map object = layer_name&&layer_id&&uom

    const selectedComponents = onClickActionCenterData?.components ?? [];

    let isComponentFromDifferentServiceItem = false;

    const getServiceItem = () => {
      if (!onClickLayerPanelData) return null;

      if (onClickActionCenterData?.activeItem) {
        const { activeItem } = onClickActionCenterData!;

        const serviceItem = onClickLayerPanelData.find((item) => {
          return item.components.some((_component) => {
            const itemId = item.id ?? item.layerId;
            const activeItemId = activeItem.id ?? activeItem.layerId;

            return (
              _component.componentId === componentId && activeItemId === itemId
            );
          });
        });

        if (serviceItem) return serviceItem;
      }

      isComponentFromDifferentServiceItem = true;

      return onClickLayerPanelData.find((item) =>
        item.components.some(
          (component) =>
            component.componentId === componentId &&
            item.type === EstimationLayerType.TAKEOFF
        )
      );
    };

    const serviceItem = getServiceItem();

    if (!serviceItem || !sourceLayer) return;

    const isAlreadySelected = selectedComponents.find(
      (_component) => componentId === _component.componentId
    );

    // GeoJson of the selected item on layer
    let featureJson = createFeatureGeoJsonWithLayerStyle(
      feature,
      component.layerId
    );

    // dataToSet will collect all selected items on the layer into a single array
    // In case of single select, this array will be singleton
    let dataToSet: any[] = [];

    const unHighlightComponents = (components: SingleComponent[]) => {
      for (const _component of components) {
        const layerId = _component.layerId;
        let sourceLayerOfComponent = findLayerById(layerId, mapRef);

        if (sourceLayerOfComponent) {
          highlight(sourceLayerOfComponent, _component.componentId, false);
        }
      }
    };

    if (
      get(event, 'originalEvent.ctrlKey') ||
      get(event, 'originalEvent.metaKey')
    ) {
      /** User is trying to select multiple items */

      if (isAlreadySelected) {
        // if the component on that layer is already selected - unselect it
        for (const _component of selectedComponents) {
          if (_component.componentId === componentId) {
            /** We need to un-highlight the currently selected layer */
            highlight(sourceLayer, featureJson.id, false);

            continue;
          }

          dataToSet.push(_component);
        }

        /** Additional check to un-highlighted components if they are stored in `highlightedComponents()` */
        for (const _component of highlightedComponents()) {
          if (_component.componentId === componentId) {
            highlight(sourceLayer, featureJson.id, false);
          }
        }

        setHighlightedComponents(dataToSet);
        updateActionCenterData(serviceItem, dataToSet);
        return;
      }

      // if the component on that layer is not selected already - bucket it as selected
      dataToSet = isComponentFromDifferentServiceItem
        ? [clone(component)]
        : [...selectedComponents, clone(component)];

      if (isComponentFromDifferentServiceItem) {
        /** We need to un-highlight all the previous selections */
        unHighlightComponents(selectedComponents);
        unHighlightComponents(highlightedComponents());
      }

      highlight(sourceLayer, featureJson.id);
      setHighlightedComponents(dataToSet);
      updateActionCenterData(serviceItem, dataToSet);

      return;
    }

    // If the selection is single select
    // cross layer selection in single select is allowed
    // If it is a cross layer selection attempt, everything is deselected in previous layer
    // single item is selected in current layer

    if (isComponentFromDifferentServiceItem) {
      unHighlightComponents(selectedComponents);
      unHighlightComponents(highlightedComponents());

      dataToSet = [clone(component)];

      highlight(sourceLayer, featureJson.id);
      setHighlightedComponents(dataToSet);
      updateActionCenterData(serviceItem, dataToSet);

      return;
    }

    if (isAlreadySelected) {
      // Same layer and same component is attempted to be selected
      // It meams user is trying to unselect that item

      dataToSet = [];

      selectedComponents.forEach(function (_component) {
        highlight(sourceLayer, component.componentId, false);
      });

      unHighlightComponents(highlightedComponents());

      setHighlightedComponents(dataToSet);
      updateActionCenterData(serviceItem, dataToSet);
      return;
    }

    // Same layer and different component is attempted to be selected
    // previous item is deselected and current item is selected

    dataToSet = [clone(component)];

    selectedComponents.forEach(function (_component) {
      /** Un-highlight all the previous selections */
      highlight(sourceLayer, _component.componentId, false);
    });
    unHighlightComponents(highlightedComponents());

    highlight(sourceLayer, featureJson.id);
    setHighlightedComponents(dataToSet);
    updateActionCenterData(serviceItem, dataToSet);
    return;
  };

  useEffect(() => {
    const clickHandler = onClickLayer(mapRef, handleFeatureClick);

    return () => {
      mapRef.un('click', clickHandler);
    };
  }, []);
};
