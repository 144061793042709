import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { featureList } from '@/store/order/actions';
import { NotifyError } from '@/helpers/notification-utils';
import { callApi } from '../../../api/feature';
import { FEATURE, LAYER } from '../../../api/feature_endpoints';
import { AddLayer } from '../../components/add-layer-page';

import { Checkbox, Col, Modal, Row, Space, Tooltip } from 'antd';
import { trackEvents } from '../../../../helpers/utilities';
import {
  _filter,
  _intersectionBy,
} from '../../../../helpers/utilities/lodashUtils';
import {
  setAttributeModalVisiblity,
  setCreationFormValue,
  setFeatureToVisible,
} from '../../../../store/attributeFeature/actions';
import { getFeatureList } from '../../../../store/order/thunks';
import useAuth from '@/modules/auth/hooks';
import { useRole } from '@/modules/auth/guards/RoleGuard';
import { FeatureSubscriptionGuard } from '@/modules/subscription/guards/base';
import { SubscriptionFeature } from '@/modules/subscription/types';
import { customFeatureStyle } from '@/helpers/mapUtils';
import { showNotification } from '../../../storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../../storybook/NotificationToast/types';
import { CreateOrderEvt } from '../../../../segment';
import TRprimaryButton from '../../../PlasmicComponents/TRprimaryButton';
import TRsecondaryButton from '../../../PlasmicComponents/TRsecondaryButton';
import AddLayerSaveChangeModal from '../../../PlasmicComponents/AddLayerSaveChangeModal';
import './style.less';

const OLD = 'old';

export const CustomFeatureDesc = (props) => {
  const [type, onChangeType] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [submittingModal, setSubmittingModal] = useState(false);

  const dispatch = useDispatch();

  const { userId } = useAuth();
  const { isAdmin, isCreator, isEstimator } = useRole();
  const formValues = useSelector((state) => state.feature.featureFormValues);
  const featureListVal = useSelector((state) => state.order.featureListInfo);
  const currentView = useSelector((state) => state.order.currentViewData);

  // Create New Feature
  const saveNewFeature = async () => {
    const { customStyle } = props;

    let newFeatureStyle = customFeatureStyle(
      formValues.style?.symbolColor || customStyle.symbolColor,
      formValues.style
    );

    const body = {
      ...formValues,
      imageUrl: '',
      shortDesc: 'short description',
      style: newFeatureStyle,
    };
    if (type) {
      body.maintainer = isAdmin ? 'organization' : 'user';
    } else {
      body.maintainer = 'user';
    }
    trackEvents('featurepanel__create-feature', {
      createdBy: body.maintainer,
      defaultStyle: body.style,
      GeometryType: body.type,
      FeatureType: body.category,
      name: body.name,
      viewId: currentView?.viewId,
      isBaseView: currentView?.isBaseView,
      isEditable: currentView?.isEditable,
      orderId: currentView?.orderId,
    });

    await callApi('post', FEATURE, null, body)
      .then((result) => {
        showNotification(NOTIFICATIONS_TYPES.SUCCESS, 'Feature Created');
        addFeature({
          ...body,
          ...result.data,
        });
        props.loadFeatures(false);
        setTimeout(function () {
          props.setName(body.name, result.data?.featureId);
        }, 1000);
      })
      .catch((error) => {
        NotifyError(error['data']['errorDesc']);
      });
  };

  // Patch The Feature
  const saveApplyFeature = async () => {
    setSubmitting(true);
    setSubmittingModal(true);

    const res = await callApi(
      'patch',
      `${FEATURE}/${formValues.featureId}`,
      null,
      formValues
    );

    try {
      showNotification(NOTIFICATIONS_TYPES.SUCCESS, 'Feature Updated');
      await dispatch(getFeatureList());
      await props.updateFeatureListInfo();
      await updateFeature(formValues);
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      setSubmittingModal(false);
    }
  };

  const deleteFeature = async (featureId) => {
    await callApi(
      'delete',
      `${FEATURE}/${formValues.featureId}`,
      null,
      formValues
    );
    showNotification(NOTIFICATIONS_TYPES.SUCCESS, 'Feature deleted');
    removeFeature(featureId);
  };

  const removeFeature = (featureId) => {
    // First we remove the feature
    let filteredFeatureList = _filter(
      featureListVal?.data,
      (feature) => feature.status === 'active'
    );
    const featureIdx = filteredFeatureList.findIndex(
      (key) => key.featureId === featureId
    );
    filteredFeatureList.splice(featureIdx, 1);
    dispatch(
      featureList({
        ...featureListVal,
      })
    );
    // Second we make sure the display is updated
    let isLastItem = filteredFeatureList.length === featureIdx;
    props.setName(
      filteredFeatureList[isLastItem ? 0 : featureIdx].name,
      filteredFeatureList[isLastItem ? 0 : featureIdx].featureId
    );
    dispatch(
      setCreationFormValue({
        ...filteredFeatureList[isLastItem ? 0 : featureIdx],
      })
    );
  };

  const updateFeature = (newFeature) => {
    const featureIdx = featureListVal.data.map((el) => {
      if (el.featureId === newFeature.featureId) return newFeature;
      else return el;
    });
    dispatch(
      featureList({
        ...featureListVal,
        data: featureIdx,
      })
    );
  };

  const addFeature = (newFeature) => {
    dispatch(
      featureList({
        ...featureListVal,
        data: [...featureListVal.data, newFeature],
      })
    );
  };

  const addLayerToMap = async () => {
    const viewId = currentView?.viewId;

    const payload = {
      name: formValues.name,
      featureId: formValues.featureId,
    };

    await callApi('post', `${LAYER}/${viewId}/layer`, null, payload)
      .then((result) => {
        delete payload.name;
        props.addSingleLayerData({
          ...result?.data,
          ...payload,
        });
        // props.loadLayer(currentView.viewId);
        // props.loadFeatures();
        showNotification(NOTIFICATIONS_TYPES.SUCCESS, 'Layer Added To Map');
        trackEvents(CreateOrderEvt.AddLayerToMapSuccess, {
          layer: result?.data?.name,
          geometryType: result?.data?.featureType,
        });
        dispatch(setAttributeModalVisiblity(false));
        dispatch(setFeatureToVisible(null));
      })
      .catch((error) => NotifyError(error['data']['errorDesc']));
  };

  const tooltipMsg = isAdmin ? undefined : undefined;

  return (
    <>
      <AddLayer
        summary={formValues}
        type={props.type}
        heading={'Add Layer'}
        onDeleteFeature={deleteFeature}
        customStyle={props.customStyle}
        onSaveAndApply={saveApplyFeature}
        key={formValues}
      >
        {props.type === OLD ? (
          <Row>
            <Col span={24}>
              <Space>
                <Tooltip title={tooltipMsg} duration={1000}>
                  <TRsecondaryButton
                    textValue='Save & Apply'
                    onCancel={saveApplyFeature}
                    className='ml-2'
                    loading={submitting}
                    disable={
                      ((!isAdmin && !isCreator) ||
                        props.summary?.maintainer === 'siterecon' ||
                        !formValues) &&
                      userId !== formValues?.userId &&
                      !isEstimator
                    }
                    stretch={false}
                  />
                </Tooltip>

                <FeatureSubscriptionGuard
                  feature={SubscriptionFeature.CustomLayer}
                  or={formValues?.maintainer === 'siterecon'}
                  modal={{ title: 'Want to add layer to the map?' }}
                >
                  <TRprimaryButton
                    textValue='Add Layer To Map'
                    onButtonClick={addLayerToMap}
                    disable={
                      (!isEstimator && !props.isEditableView) ||
                      submitting ||
                      !formValues
                    }
                    stretch={false}
                  />
                </FeatureSubscriptionGuard>
              </Space>
            </Col>
          </Row>
        ) : (
          <>
            {isAdmin && (
              <Checkbox
                defaultChecked={type}
                onChange={(e) => onChangeType(e.target.checked)}
                style={{ paddingTop: 5 }}
              >
                Workspace wide layer
              </Checkbox>
            )}
            <Tooltip title={tooltipMsg} duration={1000}>
              <TRprimaryButton
                textValue='+ Save'
                onButtonClick={saveNewFeature}
                className='ml-2'
                stretch={false}
              />
            </Tooltip>
          </>
        )}
      </AddLayer>

      {submittingModal && (
        <Modal
          visible={submittingModal}
          className='save-apply-changes-modal'
          closable={false}
          footer={null}
          width={458}
          centered
        >
          <AddLayerSaveChangeModal
            onCancel={() => setSubmittingModal(false)}
            onRefresh={() => window.location.reload()}
            disableRefreshBtn={submitting}
          />
        </Modal>
      )}
    </>
  );
};
