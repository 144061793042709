// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Frame1000006504IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Frame1000006504Icon(props: Frame1000006504IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 3 11"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <ellipse
        cx={"1.286"}
        cy={"1.285"}
        rx={"1.286"}
        ry={"1.285"}
        transform={"rotate(-180 1.286 1.285)"}
        fill={"currentColor"}
      ></ellipse>

      <ellipse
        cx={"1.286"}
        cy={"5.4"}
        rx={"1.286"}
        ry={"1.285"}
        transform={"rotate(-180 1.286 5.4)"}
        fill={"currentColor"}
      ></ellipse>

      <ellipse
        cx={"1.286"}
        cy={"9.515"}
        rx={"1.286"}
        ry={"1.285"}
        transform={"rotate(-180 1.286 9.515)"}
        fill={"currentColor"}
      ></ellipse>
    </svg>
  );
}

export default Frame1000006504Icon;
/* prettier-ignore-end */
