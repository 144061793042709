import { MapCommandFunction } from '../types';
import { NumericId } from '../../../common/types';
import { Store } from '@/store';
import { setCopyModalData } from '../../../../store/map-editor/actions';
import {
  removeLayerById as removeReduxLayerById,
  setHighlightedComponents,
} from '../../../../store/order/actions';
import { deleteLayer } from '../../api';
import { removeLayerById } from '../../hooks/takeoff/useLayerRender';
import { showNotification } from '../../../../components/storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../../../components/storybook/NotificationToast/types';

export interface CommandOptions {
  layerId: NumericId;
}

export const DeleteLayerCommand: MapCommandFunction<CommandOptions> = (
  map,
  viewId
) => {
  let _options: CommandOptions;

  return {
    init: (options) => {
      _options = options;
    },

    execute: async () => {
      if (!_options) return;

      try {
        await deleteLayer(viewId, _options.layerId);
        showNotification(NOTIFICATIONS_TYPES.SUCCESS, 'Layer deleted successfully');
        Store.dispatch(setHighlightedComponents([]));

        /** We need to remove the layer from redux and map */
        removeLayerById(map, _options.layerId);
        Store.dispatch(removeReduxLayerById(_options.layerId));
      } catch (error) {
        console.error(`Error while deleting Layer: ${_options.layerId}`);
      }

      Store.dispatch(
        setCopyModalData({
          open: true,
          layerId: _options.layerId,
        })
      );
    },
  };
};
