import { useAtom } from 'jotai';
import { viewListAtom } from '.';
import { HierarchicalView } from '../../../modules/property/types/view.types';

/** Returns hierarchical view list */
const useViewList = () => {
  const [viewList, setViewList] = useAtom<HierarchicalView[]>(viewListAtom);

  return {
    viewList: viewList as HierarchicalView[],
    setViewList,
  };
};

export default useViewList;
