import {
  convertFeatureToComponent,
  convertToComponentList,
  geometryType,
  hasIntersection,
  rawFeatureToJsonFeature,
  virtualRectangle,
} from '@/helpers/mapUtils/tools/multiSelecteTool';
import { useContext, useEffect } from 'react';
import MapContext from '@/components/pages/project/MapContext';
import OlMap from 'ol/Map';
import {
  ActionCenterData,
  useEstimationViewContext,
} from '../../context/EstimationView/context';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../store/types';
import {
  EstimationLayerType,
  LayerPanelData,
  LayerPanelDataItem,
  SingleComponent,
} from '../../api/types';
import { useComponentHighlight } from './useComponentHighlight';
import { DragBox } from 'ol/interaction';
import { platformModifierKeyOnly } from 'ol/events/condition';
import { message } from 'antd';
import VectorLayer from 'ol/layer/Vector';
import omit from 'lodash/omit';
import { NumericId, ViewType } from '../../../common/types';
import { OrderView } from '../../../../components/pages/project/projectComponents/types';

let onClickActionCenterData: ActionCenterData | null = null;

let onClickLayerList: any[] = [];

export const useDragMultiSelect = () => {
  const mapRef: OlMap = useContext(MapContext);
  const { data, actionCenterData, setActionCenterData } =
    useEstimationViewContext();
  const { highlight, unHighlight } = useComponentHighlight();
  const currentView = useSelector<IStore, OrderView>(
    (state) => state.order.currentViewData
  );

  const layerList = useSelector<IStore, any[]>(
    (state) => state.order.layerList
  );

  useEffect(() => {
    onClickActionCenterData = actionCenterData;
  }, [actionCenterData]);

  useEffect(() => {
    onClickLayerList = layerList;
  }, [layerList]);

  const multiSelectCallBack = (
    /* all components selected with current virtual box */
    components: SingleComponent[],
    componentsToUnSelect: SingleComponent[]
  ) => {
    components = components.filter((component) => !!component.serviceItemIds);

    if (!actionCenterData?.activeItem) {
      /** Currently there is no item selected from layer panel, so we need to determine service item and set that to action center data */
      const [serviceItem, selectedComponents] = getTakeoffLayerItemByComponents(
        data!,
        components
      ) ?? [undefined];

      if (!serviceItem) return;

      highlight(selectedComponents);

      if (!selectedComponents?.length) {
        setActionCenterData(null);

        return;
      }

      setActionCenterData({
        activeItem: omit(serviceItem, 'components'),
        components: selectedComponents,
      });

      return;
    }

    const componentsToUnSelectIds =
      componentsToUnSelect?.map((component) => component.componentId) ?? [];

    let selectedComponents = onClickActionCenterData?.components ?? [];
    selectedComponents = selectedComponents.filter(
      (component) => !componentsToUnSelectIds.includes(component.componentId)
    );

    // the current selection may contain components that have already been selected previously
    // Hence this variable newlySelectedComponents keeps the list of newly selected components on that layer
    let newlySelectedComponents: SingleComponent[] = [];

    for (let component of components) {
      if (
        !selectedComponents.find(
          (_component) => _component.componentId === component.componentId
        )
      ) {
        newlySelectedComponents.push(component);
      }
    }

    // Finally concat the list of all selected components and push it to the store
    let finalList: SingleComponent[] = [];

    finalList.push(...selectedComponents, ...newlySelectedComponents);

    highlight(finalList);

    if (componentsToUnSelect?.length) {
      unHighlight(componentsToUnSelect);
    }

    setActionCenterData((prevState) => {
      if (!finalList.length) return null;

      return {
        activeItem: prevState!.activeItem,
        components: finalList,
      };
    });
  };

  useEffect(() => {
    removeDragBoxInteraction(mapRef);

    if (!data || currentView?.viewType === ViewType.STATIC) return;

    const serviceItemComponents = actionCenterData
      ? data.find((item) => {
          const id = item.layerId ?? item.id;
          const activeItemId =
            actionCenterData.activeItem.layerId ??
            actionCenterData.activeItem.id;

          return id === activeItemId;
        })?.components ?? []
      : undefined;

    initializeMultiSelectTool(
      mapRef,
      serviceItemComponents,
      multiSelectCallBack
    );
  }, [actionCenterData?.activeItem, currentView?.viewType]);
};

let dragBoxInteraction: any;

const initializeMultiSelectTool = (
  mapRef: OlMap,
  allowedComponents: SingleComponent[] | undefined,
  multiSelectCallBack
) => {
  if (dragBoxInteraction) {
    mapRef.removeInteraction(dragBoxInteraction);
  }

  dragBoxInteraction = new DragBox({
    condition: platformModifierKeyOnly,
    className: 'multiSelectBox',
  });
  mapRef.addInteraction(dragBoxInteraction);
  dragBoxInteraction.setActive(true);

  onDrawEnd(dragBoxInteraction, mapRef, allowedComponents, multiSelectCallBack);
};

const onDrawEnd = (
  /* ol/Interaction: Map interaction reference */
  virtualBox,
  /* ol/Map: Map instance reference */
  mapRef: OlMap,
  allowedComponents: SingleComponent[] | undefined,
  /* Function: Method with arguments - fn(component, selectedLayer) */
  callback
) => {
  virtualBox.on('boxend', (event) => {
    const allowedComponentIds =
      allowedComponents?.map((item) => item.componentId) ?? [];

    // Fetch the virtual box drawn
    let drawnPolygon = virtualRectangle(virtualBox);

    // Store the geometries where virtual box and layer items intersect
    let olFeatureObjectSelected: any[] = [];
    let olFeatureObjectUnSelected: any[] = [];

    const selectedComponents = onClickActionCenterData?.components ?? [];
    const selectedComponentsMap: Record<NumericId, SingleComponent> = {};

    selectedComponents.forEach((_selectedComponent) => {
      selectedComponentsMap[_selectedComponent.componentId] =
        _selectedComponent;
    });

    // iterate through every feature in that vector layer and
    // see if it intersects the virtual box
    mapRef.getLayers().forEach((layer) => {
      if (!(layer instanceof VectorLayer)) {
        return;
      }

      layer.getSource().forEachFeature((feature) => {
        const featureComponentId =
          feature.getProperties().componentId ?? feature.getProperties().ID;

        if (
          allowedComponents &&
          !allowedComponentIds.includes(Number(featureComponentId))
        ) {
          return;
        }

        let layerComponent = rawFeatureToJsonFeature(feature);
        const layerComponentGeometry = geometryType(feature);

        try {
          if (
            hasIntersection(
              layerComponent,
              drawnPolygon,
              layerComponentGeometry
            )
          ) {
            const _component = convertFeatureToComponent(feature);

            if (
              _component.componentId &&
              selectedComponentsMap.hasOwnProperty(_component.componentId)
            ) {
              /** Component is selected again with drag, so we need to unselect that */
              olFeatureObjectUnSelected.push(feature);
            } else {
              olFeatureObjectSelected.push(feature);
            }
          }
        } catch (error) {
          console.error([error, error.name, feature]);
          message.error(error.name);
        }
      });
    });

    // Invoke the callback with selected components in the view layer
    if (callback) {
      callback(
        convertToComponentList(olFeatureObjectSelected).map(
          (featureJson) => featureJson.properties
        ),
        convertToComponentList(olFeatureObjectUnSelected).map(
          (featureJson) => featureJson.properties
        )
      );
    }
  });
};

const removeDragBoxInteraction = (mapRef: OlMap) => {
  if (dragBoxInteraction) {
    mapRef.getInteractions().forEach((interaction) => {
      if (interaction === dragBoxInteraction) {
        mapRef.removeInteraction(dragBoxInteraction);
      }
    });
  }
};

const getTakeoffLayerItemByComponents = (
  data: LayerPanelData,
  components: SingleComponent[]
) => {
  let maxComponentsInLayer = 0;

  let result: [LayerPanelDataItem, SingleComponent[]] | null = null;

  const componentIds = components.map((component) => component.componentId);

  for (const item of data) {
    if (item.type !== EstimationLayerType.TAKEOFF) continue;

    const matchingComponents = item.components.filter((component) =>
      componentIds.includes(component.componentId)
    );

    if (matchingComponents.length > maxComponentsInLayer) {
      maxComponentsInLayer = matchingComponents.length;
      result = [item, matchingComponents];
    }
  }

  return result!;
};
