import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../../store/types';
import { MapTool } from '../../../../project/types';
import {
  kmlForLayerList,
  setActiveTool,
  setMapClassnames,
} from '@store/user/actions';
import {
  featureList as setFeatureList,
  removeComponentTopologyWarnings,
  setEditingRedux,
  setHighlightedComponents,
  setOrderLayers,
  updateOrdersTopologyWarnings,
} from '@store/order/actions';
import {
  AccessRight,
  ModifySyncState,
  NumericId,
  OrderStatus,
  SRUserTypes,
  UserRoleId,
  ViewType,
} from '../../../../common/types';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ApiTopologyWarning,
  LayerWithComponents,
  SingleComponent,
} from '../../../api/types';
import MapToolsPanel from '@components/containers/map-tools-panel/MapToolsPanel';
import {
  disableAllTools,
  disableAllToolsTooltip,
} from '../../../../../components/pages/project/projectComponents/helpers';
import { DEFAULT_PINNED_TOOLS } from '../../../../../components/containers/map-tools-panel/stories/Toolbar/constant';
import { catchError, getAxiosInstance } from '@helpers/utilities/api-utils';
import { useMap } from '../../../hooks/useMap';
import moment from 'moment/moment';
import {
  convertJSONStyleToOlStyle,
  getFeatureValue,
  getOffSet,
  removeFeatureByComponentId,
  setStyleToLayer,
} from '@helpers/mapUtils/layerUtils';
import { _filter, _get } from '@helpers/utilities/lodashUtils';
import {
  CUT_SPLIT_STYLE_MODE,
  DRAW_STYLE_MODE,
  drawPathStyleFunction,
  PARCEL_BASE_STYLE,
  updatePathStyleFunction,
} from '@helpers/mapGlobals/styles';
import useCurrentView from '../../../hooks/useCurrentView';
import { replaceParams } from '@helpers/utilities/linkUtils';
import {
  BULK_UPDATE_COMPONENT,
  COPY_COMPONENT,
  FEATURES,
  UPDATE_COMPONENT,
  VIEW_LAYER_DETAILS,
} from '@helpers/constants/APIEndpoints';
import { DrawSegment } from '@helpers/mapUtils/tools/toolSegment';
import {
  clickLayerHighlight,
  displayComponentName,
} from '@helpers/mapUtils/featuresUtils';
import {
  drawInteractionLastPointUndo,
  modifySyncState,
  setModifyDraftEvents,
} from '@store/map/actions';
import { generatePropertiesFromFeature } from '@/helpers/mapUtils/autoSave/utils';
import { FeatureType } from '../../../../../components/feature-attributes/types';
import { showNotification } from '../../../../../components/storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../../../../components/storybook/NotificationToast/types';
import { mapLayers } from '../../../helpers/layer.data';
import {
  setAttributeMultiPopupData,
  setCurrentLayer,
} from '@store/attributeFeature/actions';
import { FEATURE_STATUS, MAINTAINER } from '@helpers/constants/orderData';
import mergePolygons from '@project/projectComponents/MergePolygons';
import {
  addFeaturesToSource,
  newVectorLayer,
  newVectorSource,
} from '../../../../../helpers/mapUtils/layerUtils';
import { NotifyError } from '../../../../../helpers/notification-utils';
import { ToolKey } from '../../../../../components/containers/map-tools-panel/stories/Toolbar/types';
import { GeoJSON } from 'ol/format';
import * as turf from '@turf/turf';
import { transform } from 'ol/proj';
import {
  MAP_GEOMETRY_TYPE_LINE,
  MAP_GEOMETRY_TYPE_POINT,
  MAP_GEOMETRY_TYPE_POLYGON,
} from '@helpers/constants/mapConstants';
import { mapNavigation } from '@helpers/mapUtils';
import { cloneDeep, debounce } from 'lodash';
import { addMapLayer as _addMapLayer } from '../../../helpers/layer-render.helpers';
import { Store } from '@/store';
import { addFeaturesToMap } from '@helpers/mapUtils/tools/utilities';
import {
  DELETE_INTERACTION,
  DRAW_INTERACTION,
} from '@helpers/constants/mapTools';
import DraggableTakeoffActionCenter from '../../ActionCenter/Takeoff/DraggableTakeoffActionCenter';
import {
  checkedComponentsAtom,
  componentsToBeDeletedAtom,
  useCheckedComponents,
  useComponentsToBeDeleted,
  useExpandedLayers,
  useSelectedLayerId,
  writableCheckedComponentsAtom,
  writableCheckedLayersAtom,
  writableSelectedComponentsAtom,
} from '../../../../../jotai/atoms/property/takeoff/layer-panel';
import {
  getHighlightedComponents,
  highlight,
} from '../../../hooks/takeoff/useLayerHighlight';
import { useAtom } from 'jotai/react';
import { transformTopologyWarnings } from '../../../hooks/takeoff/useLayers';
import { updateTopologyWarnings } from '../../../hooks/useUpdateTopologyWarnings';
import { Feature } from 'ol';
import { trackEvents } from '@helpers/utilities';
import { hideLoadingCursor } from '@components/utils';
import { layerPanelStaticP } from '../../../helpers/position.constant';
import SRUserTypeAccessGuard from '@/modules/project/guards/SRUserTypeAccessGuard';
import OrderStatusGuard from '@/modules/project/guards/OrderStatus';
import {
  HIDDEN_MAP_TOOLS_PANEL_STATUS,
  HIDDEN_MAP_TOOLS_PANEL_STATUS_FOR_ESTIMATOR,
} from '../../../../../components/pages/project/utils/data';
import useAuth from '../../../../auth/hooks';
import { useOrderStatus } from '../../../../project/guards/OrderStatus';
import { useTakeoffActionCenterLayerId } from '../../../../../jotai/atoms/property/takeoff/action-center';
import { store } from '../../../../../jotai';
import pick from 'lodash/pick';
import { useKmlLayers } from '../../../../../jotai/atoms/property/takeoff/navbar';

export interface TakeoffMapToolBarProps {
  openFPanel: boolean;
  showApprovedOrderDetails: boolean;
  shortcutRef: any;
  selectedFeature: any;
  multiData: any;
  clickFeatureAction: any;
  setNoteActions: any;
  toggleAddAfterLayer: any;
  orderTileData: any;
  popoverRef: any;
  isViewPanelPinned: boolean;
  isOrderHistoryPanelVisible: boolean;
  updateLayerAreaInfo: any;
}

const axiosInstance = getAxiosInstance();

const layerRefs = mapLayers;

let pendingUndoRedoAction: 'undo' | 'redo' | null = null;

export const TakeoffMapToolBar = forwardRef(
  (
    {
      openFPanel,
      shortcutRef,
      selectedFeature,
      showApprovedOrderDetails,
      clickFeatureAction,
      setNoteActions,
      orderTileData,
      toggleAddAfterLayer,
      popoverRef,
      isViewPanelPinned,
      isOrderHistoryPanelVisible,
      updateLayerAreaInfo,
    }: TakeoffMapToolBarProps,
    ref
  ) => {
    const map = useMap();
    const dispatch = useDispatch();
    const layers = useSelector<IStore, LayerWithComponents[]>(
      (state) => state.order.layerList
    );
    const selectedView = useCurrentView();
    const { roleId: userRoleId } = useAuth();
    const [selectedLayerId, setSelectedLayerId] = useSelectedLayerId();
    const [, setMasterExpandState] = useExpandedLayers();
    const [layerPanelCheckedComponents] = useCheckedComponents();
    const [, setLayerPanelCheckedComponents] = useAtom(
      writableCheckedComponentsAtom
    );
    const [isModifying, setIsModifying] = useState<boolean>(false);
    const [, setLayerPanelCheckedLayers] = useAtom(writableCheckedLayersAtom);
    const [, setLayerPanelSelectedComponents] = useAtom(
      writableSelectedComponentsAtom
    );
    const [componentsToBeDeleted] = useComponentsToBeDeleted();
    const [kmlDownload, setKmlDownload] = useState({});
    const [,setKmlLayerList]=useKmlLayers()

    const activeTool = useSelector<IStore, MapTool>(
      (state) => state.user.activeTool
    );
    const userTypeId = useSelector<IStore>(
      (state) => state.user.info.sr_user_type_id
    );
    const multiData = useSelector<IStore>(
      (state) => state.feature.attributeMultiPopupData
    );

    const featureListInfo = useSelector<IStore>(
      (state) => state.order.featureListInfo?.data ?? []
    );

    const selectedLayer = useMemo(() => {
      const layer = layers.find((layer) => layer.layerId === selectedLayerId);
      if (layer) return layer;

      return layers.length > 0 ? layers[0] : null;
    }, [layers, selectedLayerId]);

    const layerComponents = useMemo(() => {
      const components: Record<NumericId, SingleComponent[]> = {};
      for (const layer of layers) {
        components[layer.layerId] = layer.components;
      }
      return components;
    }, [layers]);

    const [featureTypeObject, setFeatureTypeObject] = useState<any>({});
    const [operationsStack, setOperationsStack] = useState<any>({
      undoStack: {},
      redoStack: {},
    });

    const [state, setState] = useState({
      /** TOOD: Remove unnecessary states */
      layersData: [],
      layerComponents: {},
      loading: false,
      selectedLayer: null,
      treeCheckedKeys: [],
      layerRefs: {},
      selectedNodeKeys: [],
      parcelStyle: PARCEL_BASE_STYLE,
      isCopyLayer: false,
      featureType: {},
      deleteLayerLoading: false,
      tempComponentStorageForAutoSave: {
        //objects of component with key as componentId
      },
      factor: 0,
      isAllowedCal: false,
      expendedKeys: '',
      fixedValue: 0,
      treeCheckedName: [],
      treeCheckedStyle: null,
      layerComponentWithName: {},
      isDeleteComponent: null,
      componentDeleteLoading: false,
      undoStack: {},
      redoStack: {},
      stackOperationLoading: false,
      lastAutoSaved: {},
      autoSaveRequestTrack: {},
      masterLayerCheckState: { checked: true, indeterminate: false },
      updateAction: false,
      isParcelLayer: true,
      selectDeselectFeature: [],
      activeToolInteractionRef: null,
      appliedFilters: [],
      visibleAttributesInLayerPanel: [],
      attributePanelVisible: false,
      sort: 'false',
      siAttributeObj: {},
      serviceItemsMap: {},
    });

    const drawingFeaturePointHistoryRedoStack = useSelector<IStore>(
      (state) => state.map.drawingFeaturePointHistoryRedoStack
    );

    const unSyncedModifiedChanges = useSelector<IStore>(
      (state) => state.map.modifyDraftEvents
    );

    const drawingFeaturePointHistoryUndoStack = useSelector<IStore>(
      (state) => state.map.drawingFeaturePointHistoryUndoStack
    );

    const editingToolVal = useSelector<IStore>(
      (state) => state.order.editingToolVal
    );

    const selectedComponents = useSelector<IStore, SingleComponent[]>(
      (state) => state.order.highlighted
    );

    const isShared = useSelector<IStore>((state) => state.order.isSharedOrder);

    const orderStatus = useOrderStatus<OrderStatus>();

    const [, setTakeoffActionCenterLayerId] = useTakeoffActionCenterLayerId();

    useEffect(() => {
      loadFeatures();

      if (
        !isShared &&
        selectedView?.viewType !== ViewType.DYNAMIC &&
        !HIDDEN_MAP_TOOLS_PANEL_STATUS.includes(orderStatus)
      ) {
        applyShortCutOperation();
      }
    }, []);

    useEffect(() => {
      if (componentsToBeDeleted?.length) {
        const layerId = componentsToBeDeleted[0].layerId;
        deleteComponentWithKey(layerId, componentsToBeDeleted);
        setTimeout(() => store.set(componentsToBeDeletedAtom, null), 300);
      }
    }, [componentsToBeDeleted]);

    const handleSetPinnedTools = (toolData: any) => {
      axiosInstance
        .post('/api/templating/v1/tools', { tools: toolData })
        .then((res) => {
          let tools = toolData;
          DEFAULT_PINNED_TOOLS.map((item) => {
            if (!tools.includes(item)) {
              tools.push(item);
            }
          });
          localStorage.setItem('default_pinned', JSON.stringify(tools));
        })
        .catch(catchError);
    };

    const shortcutData = useRef({
      activeTool: null,
      unSyncedModifiedChanges: null,
      drawingFeaturePointHistoryUndoStack: [],
      drawingFeaturePointHistoryRedoStack: [],
      selectedLayer: null,
      activeToolInteractionRef: null,
      selectedComponents: [],
      selectedView: null,
      layers: [],
    });

    useEffect(() => {
      shortcutData.current = {
        activeTool,
        unSyncedModifiedChanges,
        drawingFeaturePointHistoryUndoStack,
        drawingFeaturePointHistoryRedoStack,
        selectedLayer,
        activeToolInteractionRef: state.activeToolInteractionRef,
        selectedComponents,
        selectedView,
        layers,
      };
    }, [
      activeTool,
      unSyncedModifiedChanges,
      drawingFeaturePointHistoryUndoStack,
      drawingFeaturePointHistoryRedoStack,
      selectedLayer,
      state.activeToolInteractionRef,
      selectedComponents,
      selectedView,
      layers,
    ]);

    const componentUpdatedEvent = async (
      componentId = null,
      updatedComponentObject,
      layerId,
      undoStackUpdateNotRequired = null,
      timeStamp = moment(),
      updateWithoutDelay = false,
      shouldReRender = false,
      options = {}
    ) => {
      /***
       * Use Only when component's geojson or geometry is updated.
       * ***/

      let arrayOfComponentObjects: any[] = [];
      if (!Array.isArray(updatedComponentObject) && updatedComponentObject) {
        arrayOfComponentObjects.push({
          ...updatedComponentObject,
          componentId,
          undoStackUpdateNotRequired,
        });
      } else {
        arrayOfComponentObjects = updatedComponentObject;
      }

      return new Promise(async (resolve) => {
        let componentsForStorage = {};
        arrayOfComponentObjects.forEach(function (componentObject) {
          let componentIdForStorage =
            componentObject.componentId || `temp_${Math.random()}`;

          const properties = generatePropertiesFromFeature(
            componentObject.geoJson,
            {
              ..._get(componentObject.geoJson, 'properties'),
            }
          );

          if (componentObject.properties) {
            componentObject.properties = properties;
          }

          componentsForStorage[componentIdForStorage] = {
            timeStamp,
            ...properties,
            ...componentObject,
            geoJson: JSON.stringify({
              ...componentObject.geoJson,
              properties: properties,
            }),
            tempId: componentIdForStorage,
            undoStackUpdateNotRequired:
              componentObject.undoStackUpdateNotRequired ||
              undoStackUpdateNotRequired,
          };
        });

        const updatedTempComponentsForAutoSave = {
          ...state.tempComponentStorageForAutoSave,
          [layerId]: {
            ...state.tempComponentStorageForAutoSave?.[layerId],
            ...componentsForStorage,
          },
        };

        setState(
          (prevState) => ({
            ...prevState,
            tempComponentStorageForAutoSave: updatedTempComponentsForAutoSave,
          })
          // let newAreaValue =
          //   prevState.layerAreaInfo[prevState.selectedLayer.id]?.area;
        );

        /** Wait for setState to complete. TODO: Find a better way for this */
        await new Promise((r) => setTimeout(r, 300));

        if (!updateWithoutDelay) {
          await syncUpdatedComponentToServers(
            updatedTempComponentsForAutoSave,
            shouldReRender
          );
        } else {
          await forcedSyncUpdatedComponentToServers(
            updatedTempComponentsForAutoSave,
            shouldReRender,
            options
          );
        }

        resolve(null);
      });
    };

    const prepareComponentToAddToMap = (
      component,
      layer = {},
      indexing,
      timeStamp = moment().startOf('year')
    ) => {
      let jsonObj = {
        ...JSON.parse(component.geoJson),
      };
      jsonObj.properties = jsonObj.properties || {};
      jsonObj.properties.attributes = component.attributes || {};
      jsonObj.properties.name = layer?.name;
      jsonObj.properties.layerId = layer?.layerId;
      jsonObj.properties.actualComponentId = component.componentId;
      jsonObj.properties.componentId = component.componentId;
      jsonObj.properties.layerName = component.name;
      jsonObj.properties.id = component.componentId;
      jsonObj.properties.componentName = component.componentName;
      jsonObj.properties.perimeter =
        component?.perimeter || component.properties?.perimeter || null;
      jsonObj.properties.serviceItemIds = component?.serviceItemIds ?? [];

      if (indexing !== null) {
        jsonObj.properties.componentIndexing = displayComponentName(
          indexing + 1
        );
        jsonObj.componentName = displayComponentName(indexing + 1);
      }

      const area =
        component.area ??
        jsonObj.properties.measurement ??
        Number(jsonObj.properties['Surface Area'] || 0);

      if (area) {
        jsonObj.properties.area = area;
      }

      if (jsonObj.properties.uom) {
        jsonObj.properties.unit = jsonObj.properties.uom;
      }

      const perimeterValue =
        component.perimeter ??
        component.Perimeter ??
        (Number(jsonObj.properties.perimeter) ||
          Number(jsonObj.properties.Perimeter) ||
          0);

      const lengthValue =
        component.length ??
        component.Length ??
        (jsonObj.properties.Length ? Number(jsonObj.properties.Length) : 0);

      return {
        ...component,
        layerId: layer?.layerId,
        name: layer?.name,
        visible: true,
        ...jsonObj,
        id: component.componentId,
        timeStamp,
        // initialize area if it doesn't have a value already
        area: component.area || Number(jsonObj.properties['Surface Area'] || 0),
        perimeter: perimeterValue,
        Perimeter: perimeterValue,
        Length: lengthValue,
      };
    };

    const syncUpdatedComponentToServers = async (
      components: any,
      shouldReRender: boolean
    ) => {
      /**8 This is the debounced method that will only be served when there is no wait for it.
       * Call the given forcedSyncUpdatedComponentToServers method directly if the server sync is required at once.
       */
      return forcedSyncUpdatedComponentToServers(components, shouldReRender);
    };

    const debouncedUpdateComponentsAfterDelete = useCallback(
      debounce((layerId: NumericId) => {
        return updateComponentsAfterDelete(layerId);
      }, 300),
      []
    );

    const forcedSyncUpdatedComponentToServers = async (
      components: any,
      shouldReRender = false,
      options = {}
    ) => {
      const shouldAutoSelectNewComponents =
        options.shouldAutoSelectNewComponents ?? false;
      let that = this;
      let componentsForSave = components ?? {};
      const { selectedLayer, selectedView } = shortcutData.current;

      setIsModifying(true);

      return new Promise((resolve, reject) => {
        setState((prevState) => {
          if (!components) {
            componentsForSave = prevState.tempComponentStorageForAutoSave;
          }

          return { ...prevState, tempComponentStorageForAutoSave: {} };
        });

        const layersMap: Record<NumericId, LayerWithComponents> = {};
        const currentLayers = Store.getState().order.layerList ?? layers;
        for (const layer of currentLayers) {
          layersMap[layer.layerId] = layer;
        }
        let updatedLayers = currentLayers ?? [];

        Object.keys(componentsForSave).forEach(function (layerId) {
          if (
            !Object.keys(componentsForSave[layerId]).length ||
            !layersMap[layerId]
          )
            return;
          let reqData: any = {
            undelete: [],
            delete: [],
            post: [],
            put: [],
          };
          let reqDataMap: any = {
            put: {},
            delete: {},
            undelete: {},
          };

          Object.keys(componentsForSave[layerId]).forEach(function (key) {
            if (
              componentsForSave[layerId][key]['type'] === 'deleteInteraction'
            ) {
              reqData.delete.push(key);
              reqDataMap.delete[key] = componentsForSave[layerId][key];
            } else if (
              componentsForSave[layerId][key]['type'] === 'unDeleteInteraction'
            ) {
              reqData.undelete.push(key);
              reqDataMap.undelete[key] = componentsForSave[layerId][key];
            } else if (key.split('_')[0] == 'temp') {
              reqData.post.push({ ...componentsForSave[layerId][key] });
            } else {
              reqData.put.push({
                geoJson: componentsForSave[layerId][key].geoJson,
                componentId: key,
              });
              reqDataMap.put[key] = componentsForSave[layerId][key];
            }
          });

          getAxiosInstance()
            .post(
              replaceParams(BULK_UPDATE_COMPONENT, {
                ':viewId': selectedView.viewId,
                ':layerId': layerId,
              }),
              reqData
            )
            .then(async (res) => {
              DrawSegment(
                reqData.post,
                selectedLayer,
                _get(featureTypeObject?.[selectedLayer?.name], 'type') ??
                  selectedLayer.featureType
              );

              setState(function (prevState) {
                const componentModifications = {
                  removed: [],
                  added: [],
                };

                let layerComponents =
                  Store.getState().order.layerList?.find(
                    (layer) => layer.layerId === Number(layerId)
                  )?.components ?? [];

                let updatedLayerComponents = cloneDeep(layerComponents);
                let undoStackItems: any = [];

                if (reqData.delete.length) {
                  layerComponents = updatedLayerComponents;
                  updatedLayerComponents = [];
                  layerComponents.forEach(function (layerComponent) {
                    if (reqDataMap.delete[layerComponent.componentId]) {
                      componentModifications.removed.push(
                        layerComponent.componentId
                      );
                    } else {
                      updatedLayerComponents.push(layerComponent);
                    }
                    if (
                      reqDataMap.delete[layerComponent.componentId] &&
                      !reqDataMap.delete[layerComponent.componentId]
                        .undoStackUpdateNotRequired
                    )
                      undoStackItems.push({
                        ...layerComponent,
                        ...reqDataMap.delete[layerComponent.componentId],
                        layerId: layerId,
                        componentId: Number(layerComponent.componentId),
                        geoJson:
                          reqDataMap.delete[layerComponent.componentId].geoJson,
                        ...JSON.parse(
                          reqDataMap.delete[layerComponent.componentId].geoJson
                        ),
                        type: 'deleteInteraction',
                      });
                  });
                }

                if (reqData.undelete.length) {
                  reqData.undelete.forEach(function (componentId) {
                    componentModifications.added.push(componentId);

                    updatedLayerComponents.push(
                      prepareComponentToAddToMap(
                        {
                          geoJson: reqDataMap.undelete[componentId].geoJson,
                          ...JSON.parse(
                            reqDataMap.undelete[componentId].geoJson
                          ),
                          componentId: Number(componentId),
                        },
                        {
                          layerId: selectedLayer?.layerId,
                          name: selectedLayer?.name,
                        },
                        null
                      )
                    );
                    if (
                      reqDataMap.undelete[componentId] &&
                      !reqDataMap.undelete[componentId]
                        .undoStackUpdateNotRequired
                    ) {
                      undoStackItems.push({
                        ...reqDataMap.undelete[componentId],
                        layerId: layerId,
                        componentId: Number(componentId),
                        geoJson: reqDataMap.undelete[componentId].geoJson,
                        ...JSON.parse(reqDataMap.undelete[componentId].geoJson),
                        type: 'drawInteraction',
                      });
                    }
                  });
                }
                if (reqData.put.length) {
                  /*** Case of Update
                   */
                  layerComponents = updatedLayerComponents;
                  updatedLayerComponents = [];
                  const updatedPutComponents: Record<
                    NumericId,
                    SingleComponent
                  > = {};
                  layerComponents.forEach(function (layerComponent) {
                    if (reqDataMap.put[layerComponent.componentId]) {
                      const updatedComponent = prepareComponentToAddToMap(
                        {
                          geoJson:
                            reqDataMap.put[layerComponent.componentId].geoJson,
                          ...JSON.parse(
                            reqDataMap.put[layerComponent.componentId].geoJson
                          ),
                          componentId: Number(layerComponent.componentId),
                          name: layerComponent.name,
                        },
                        {
                          layerId: selectedLayer?.layerId,
                          name: selectedLayer?.name,
                        },
                        null
                      );
                      updatedPutComponents[layerComponent.componentId] =
                        updatedComponent;
                      updatedLayerComponents.push(updatedComponent);

                      if (
                        !reqDataMap.put[layerComponent.componentId]
                          ?.undoStackUpdateNotRequired
                      )
                        undoStackItems.push({
                          ...reqDataMap.put[layerComponent.componentId],
                          prev: { ...layerComponent },
                          new: {
                            ...layerComponent,
                            layerId: layerId,
                            componentId: Number(layerComponent.componentId),
                            geoJson:
                              reqDataMap.put[layerComponent.componentId]
                                .geoJson,
                            ...JSON.parse(
                              reqDataMap.put[layerComponent.componentId].geoJson
                            ),
                          },
                          type: 'modifyInteraction',
                        });
                    } else {
                      updatedLayerComponents.push(layerComponent);
                    }
                  });

                  /** When new components are added for a layer, rendered features on map would only have `temp_id`. Once, the API request succeeds, we need to attach the response componentId to that feature */
                  if (mapLayers[selectedLayer?.layerId]) {
                    mapLayers[selectedLayer?.layerId]
                      .getSource()
                      .getFeatures()
                      .forEach((feature: Feature) => {
                        const componentId = feature.getProperties().componentId;

                        if (
                          reqDataMap.put[componentId] &&
                          updatedPutComponents[componentId]
                        ) {
                          const updatedComponent =
                            updatedPutComponents[componentId];

                          const finalComponent = {
                            ...updatedComponent?.properties,
                            ...updatedComponent,
                          };

                          feature.setProperties({
                            ...pick(finalComponent, [
                              'componentId',
                              'layerId',
                              'tempId',
                              'Surface Area',
                              'Area',
                              'area',
                              'perimeter',
                              'length',
                              'Length',
                            ]),
                            Perimeter: finalComponent.perimeter ?? 0,
                          });
                        }
                      });
                  }
                }
                if (reqData.post.length) {
                  /*** Case of New Features
                   */
                  let newComponentIdDict: Record<string, string | NumericId> =
                    {};
                  const newComponents: Record<NumericId, SingleComponent> = {};
                  res.data.post.forEach(function (item) {
                    newComponentIdDict[item.tempId] = Number(item.componentId);
                    componentModifications.added.push(item.componentId);
                  });
                  const serviceItemIds = res.data.serviceItemIds ?? [];
                  reqData.post.forEach(function (component) {
                    const componentData = prepareComponentToAddToMap(
                      {
                        ...component,
                        ...JSON.parse(component.geoJson),
                        componentId: Number(
                          newComponentIdDict[component.tempId]
                        ),
                        serviceItemIds,
                      },
                      {
                        layerId: selectedLayer?.layerId,
                        name: selectedLayer?.name,
                      },
                      null
                    );
                    delete componentData.ID;
                    delete componentData.componentIndexing;

                    newComponents[newComponentIdDict[component.tempId]] =
                      componentData;
                    updatedLayerComponents.push(componentData);
                    if (!component.undoStackUpdateNotRequired)
                      undoStackItems.push({
                        ...component,
                        componentId: Number(
                          newComponentIdDict[component.tempId]
                        ),
                        type: 'drawInteraction',
                      });
                  });

                  /** When new components are added for a layer, rendered features on map would only have `temp_id`. Once, the API request succeeds, we need to attach the response componentId to that feature */
                  if (mapLayers[layerId]) {
                    mapLayers[layerId]
                      .getSource()
                      .getFeatures()
                      .forEach((feature: Feature) => {
                        const getTempId = () => {
                          if (feature.getProperties().tempId) {
                            return feature.getProperties().tempId;
                          }
                          const featureCompId =
                            feature.getProperties().componentId;
                          if (
                            featureCompId &&
                            featureCompId?.toString().includes('temp')
                          ) {
                            return featureCompId;
                          }
                          return feature.getProperties().temp_id ||  null;
                        };
                        const tempId = getTempId();

                        if (
                          tempId &&
                          newComponentIdDict.hasOwnProperty(tempId)
                        ) {
                          const componentId = Number(
                            newComponentIdDict[tempId]
                          );
                          const newComponent = newComponents?.[componentId];
                          feature.setProperties({
                            ...newComponents?.[componentId],
                            ...feature.getProperties(),
                            ID: componentId,
                            componentId: componentId,
                            serviceItemIds: serviceItemIds,
                            layerId: Number(layerId),
                            layerName: selectedLayer?.name,
                            ...pick(newComponent, [
                              'Surface Area',
                              'Area',
                              'area',
                              'perimeter',
                              'length',
                              'Length',
                            ]),
                          });
                          feature.setId(componentId);
                        }
                      });
                  }
                }
                pushToUndoStack(null, undoStackItems, layerId);
                const updatedComponentNames: Record<NumericId, string> = {};
                updatedLayerComponents.forEach((lc, index) => {
                  const componentName = displayComponentName(index + 1);
                  lc.componentName = componentName;
                  if (lc.componentId) {
                    updatedComponentNames[lc.componentId] = componentName;
                  }
                });

                if (mapLayers[layerId]) {
                  mapLayers[layerId]
                    .getSource()
                    .getFeatures()
                    .forEach((feature: Feature) => {
                      const componentId = feature.getProperties().componentId;
                      if (updatedComponentNames[componentId]) {
                        feature.setProperties({
                          componentName: updatedComponentNames[componentId],
                          componentIndexing: updatedComponentNames[componentId],
                        });
                      }
                    });
                }

                updatedLayers = currentLayers.map((layer) => {
                  if (layer.layerId === Number(layerId)) {
                    return { ...layer, components: updatedLayerComponents };
                  }
                  return layer;
                });

                dispatch(setOrderLayers(updatedLayers));

                const layerPanelCheckedComponents = store.get(
                  checkedComponentsAtom
                );
                const updatedLayerPanelCheckedComponents = cloneDeep(
                  layerPanelCheckedComponents
                );

                componentModifications.removed.forEach((componentId) => {
                  if (
                    updatedLayerPanelCheckedComponents[layerId]?.[componentId]
                  ) {
                    delete updatedLayerPanelCheckedComponents[layerId][
                      componentId
                    ];
                  }
                });

                componentModifications.added.forEach((componentId) => {
                  if (updatedLayerPanelCheckedComponents[layerId]) {
                    updatedLayerPanelCheckedComponents[layerId][componentId] =
                      true;

                    for (const _componentId of Object.keys(
                      updatedLayerPanelCheckedComponents[layerId]
                    )) {
                      updatedLayerPanelCheckedComponents[layerId][
                        _componentId
                      ] = true;
                    }

                    for (const component of updatedLayerComponents) {
                      updatedLayerPanelCheckedComponents[layerId][
                        component.componentId
                      ] = true;
                    }
                  }
                });

                setLayerPanelCheckedComponents(
                  updatedLayerPanelCheckedComponents
                );

                setLayerPanelSelectedComponents((prevState) => {
                  const updatedSelection = cloneDeep(prevState);

                  componentModifications.removed.forEach((componentId) => {
                    if (updatedSelection[layerId]?.[componentId]) {
                      delete updatedSelection[layerId][componentId];
                    }
                  });

                  if (shouldAutoSelectNewComponents) {
                    componentModifications.added.forEach((componentId) => {
                      if (updatedSelection[layerId]) {
                        updatedSelection[layerId][componentId] = true;
                      }
                    });
                  }

                  return updatedSelection;
                });

                return {
                  ...prevState,
                  lastAutoSaved: {
                    ...prevState.lastAutoSaved,
                    [layerId]: moment(),
                  },
                };
              });

              /** Wait for setState to complete. TODO: Find a better way for this */
              await new Promise((r) => setTimeout(r, 300));
              if (shouldReRender) {
                await updateComponentsAfterDelete(layerId);
              }

              if (pendingUndoRedoAction) {
                handleUndoRedo(pendingUndoRedoAction, true);
                pendingUndoRedoAction = null;
              }

              setIsModifying(false);

              updateTopologyWarnings(
                _get(res.data, 'topologyWarning', {}),
                updatedLayers,
                Number(layerId)
              );

              dispatch(modifySyncState(ModifySyncState.SYNCED));
              dispatch(setModifyDraftEvents([]));
              resolve();
            })
            .catch((error) => {
              setState(function (prevState) {
                return {
                  ...prevState,
                  tempComponentStorageForAutoSave: {
                    ...prevState.tempComponentStorageForAutoSave,
                    [layerId]: componentsForSave[layerId],
                  },
                };
              });
              dispatch(modifySyncState(ModifySyncState.UNSYNCED));
              reject(error);
            });
        });
      });
    };

    const updateComponentsAfterDelete = async (
      layerId,
      forceUpdate = false
    ) => {
      Store.getState().order.layerList.forEach(function (layer) {
        if (layerId) {
          if (layer.layerId === Number(layerId) || layer.layerId === layerId) {
            let componentsForForged: any[] = [];
            let i = 0;
            layer.components.forEach(function (component, index) {
              if (!component.hiddenOfAttributeFilter) {
                componentsForForged.push(
                  prepareComponentToAddToMap(
                    component,
                    layer,
                    index
                    // Number(component.properties.componentIndexing.slice(2)) - 1
                  )
                );
              }
            });
            addMapLayer(layer, componentsForForged, forceUpdate);
          }
        } else {
          let componentsForForged: any[] = [];
          let i = 0;
          layer.components.forEach(function (component, index) {
            if (!component.hiddenOfAttributeFilter) {
              componentsForForged.push(
                prepareComponentToAddToMap(
                  component,
                  layer,
                  index
                  // Number(component.properties.componentIndexing.slice(2)) - 1
                )
              );
            }
          });
          addMapLayer(layer, componentsForForged, forceUpdate);
        }
      });

      /** Wait for setState to complete. TODO: Find a better way for this */
      await new Promise((r) => setTimeout(r, 200));

      if (shortcutData.current.activeTool === 'select') {
        resetLayerStyles(layerId, true);
      }
    };

    const resetLayerStyles = (layerId = null, allowed = false) => {
      const layers = Store.getState().order.layerList;
      // let { multiData, activeTool, featureListInfo, layerList } = this.props;
      if (allowed) {
        layers.forEach(function (layer) {
          const featureType = featureListInfo
            .filter((item) => item.featureId === layer.featureId)
            .map((item) => item.type)[0];
          if (!layerId || layer.layerId === +layerId) {
            if (featureType === FeatureType.PATH) {
              layerRefs[layer.layerId]?.setStyle(
                updatePathStyleFunction(
                  layerRefs[layer.layerId].getSource().getFeatures(),
                  layer.style
                )
              );
            } else {
              setStyleToLayer(
                layerRefs[layer.layerId],
                convertJSONStyleToOlStyle(featureType, layer.style)
              );
            }
          }
        });
      } else if (multiData) {
        let selectedComponent = Object.values(multiData)[0];
        for (let component of selectedComponent) {
          layerRefs[layerId] &&
            clickLayerHighlight(
              layerRefs[layerId],
              component.componentId,
              true,
              layers,
              featureListInfo
            );
        }
      } else if (
        activeTool === 'line' ||
        activeTool === 'polygon' ||
        activeTool === 'point' ||
        activeTool === 'modify'
      ) {
        let tempLayerData = _filter(layers, function (obj) {
          return obj.layerId === selectedLayer!.layerId;
        })?.[0];
        if (tempLayerData.featureType === FeatureType.POINT) {
          layerRefs[layerId]?.setStyle(
            DRAW_STYLE_MODE(
              'Point',
              layerRefs[layerId].getStyle(),
              tempLayerData?.style
            )
          );
        } else {
          const featureType =
            tempLayerData.featureType === 'point'
              ? 'Point'
              : tempLayerData.featureType === 'polygon'
              ? 'Polygon'
              : 'LineString';
          layerRefs[layerId]?.setStyle(
            DRAW_STYLE_MODE(featureType, layerRefs[layerId].getStyle())
          );
        }
      } else if (
        activeTool === 'slice' ||
        activeTool === 'cut' ||
        activeTool === 'doughnut'
      ) {
        layerRefs[layerId]?.setStyle(CUT_SPLIT_STYLE_MODE);
      } else if (activeTool === MapTool.Path) {
        let tempLayerData = _filter(layers, function (obj) {
          return obj.layerId === parseInt(selectedLayer.layerId);
        })?.[0];
        layerRefs[layerId]?.setStyle(
          drawPathStyleFunction(
            layerRefs[layerId].getSource().getFeatures(),
            tempLayerData?.style
          )
        );
      }
    };

    const handleLayerComponents = (layerId, newFeature) => {
      setState(function (prevState) {
        let componentsData: any[] = [];
        prevState.layerComponents[layerId].forEach(function (component) {
          componentsData.push({
            ...component,
          });
        });
        let lastObjID = componentsData[componentsData.length - 1];
        componentsData.push({
          visible: true,
          ...newFeature,
          layerIndex: _get(lastObjID, 'layerIndex') + 1,
          name: `ID${_get(lastObjID, 'layerIndex') + 1}`,
          componentId: _get(lastObjID, 'componentId') + 1,
        });
        return {
          ...prevState,
          layerComponents: {
            ...prevState.layerComponents,
            [layerId]: componentsData,
          },
          treeCheckedKeys: [
            ...prevState.treeCheckedKeys,
            layerId,
            ...componentsData.map((comp) => `${layerId}-${comp.componentId}`),
          ],
        };
      });
    };

    const deleteComponent = (
      data,
      hideNotification = false,
      undoStackUpdateNotRequired = null
    ) => {
      let that = this;
      let timeStamp = data.timeStamp || moment();
      const { options, layerId } = data;
      setState(function (prevState) {
        return {
          ...prevState,
          componentDeleteLoading: true,
          autoSaveRequestTrack: {
            ...prevState.autoSaveRequestTrack,
            [options.componentId]: true,
          },
        };
      });

      if (selectedView.isBaseView && userTypeId !== SRUserTypes.CartoAdmin) {
        // that.props.setShowAddNewViewAlertModal(true);
        return;
      }

      axiosInstance
        .post(
          replaceParams(BULK_UPDATE_COMPONENT, {
            ':viewId': selectedView.viewId,
            ':layerId': selectedLayer.layerId,
          }),
          { delete: [options.componentId] }
        )
        .then((res) => {
          // that.props.setAttributeMultiPopupData(null);
          deleteComponents(
            layerId,
            [options.componentId],
            timeStamp,
            undoStackUpdateNotRequired,
            _get(res.data, 'topologyWarning', {})
          );
          dispatch(setHighlightedComponents([]));
          // that.props.setAttributeMultiPopupData(null);
          // that.props.updateOrdersTopologyWarnings({
          //   ..._get(res.data, 'topologyWarning', {}),
          //   layerId: selectedLayer.id,
          // });

          if (!hideNotification)
            showNotification(
              NOTIFICATIONS_TYPES.SUCCESS,
              'Deleted successfully'
            );
        })
        .catch((error) => {
          console.error(error);
          setState(function (prevState) {
            return {
              ...prevState,
              componentDeleteLoading: false,
              autoSaveRequestTrack: {
                ...prevState.autoSaveRequestTrack,
                [options.componentId]: undefined,
              },
            };
          });
        });
    };

    const deleteComponents = async (
      layerId,
      componentId,
      timeStamp = moment(),
      undoStackUpdateNotRequired = null,
      topologyWarningsData = null
    ) => {
      componentId.forEach((ids) => {
        removeFeatureByComponentId(mapLayers[layerId], ids);
      });
      let updateStackComponent: any[] = [];
      setState(function (prevState) {
        const layers = shortcutData.current.layers;
        const selectedLayer = layers.find((layer) => layer.layerId === layerId);
        if (!selectedLayer) {
          return { ...prevState };
        }

        let updatedComponents = [];
        const components = selectedLayer.components;

        if (components.length) {
          let idCount = 0;

          components.map((component) => {
            if (!componentId.includes(component.componentId)) {
              idCount = idCount + 1;
              component.componentName = `ID${idCount}`;
              component.properties &&
                (component.properties.componentIndexing = `ID${idCount}`);
              updatedComponents.push(component);
            } else {
              updateStackComponent.push({
                ...component,
                timeStamp,
                type: 'deleteInteraction',
              });
            }
          });

          const currentLayers = Store.getState().order.layerList ?? layers;

          const updatedLayers = currentLayers.map((layer) => {
            if (layer.layerId === Number(selectedLayer.layerId)) {
              return { ...layer, components: updatedComponents };
            }
            return layer;
          });

          dispatch(setOrderLayers(updatedLayers));
          if (topologyWarningsData) {
            updateTopologyWarnings(
              topologyWarningsData,
              updatedLayers,
              selectedLayer.layerId
            );
          }
        }

        return {
          ...prevState,
          isDeleteComponent: null,
          componentDeleteLoading: false,
          lastAutoSaved: { ...prevState.lastAutoSaved, [layerId]: timeStamp },
          autoSaveRequestTrack: {
            ...prevState.autoSaveRequestTrack,
            [componentId]: undefined,
          },
        };
      });

      /** Wait for setState to complete. TODO: Find a better way for this */
      await new Promise((r) => setTimeout(r, 300));

      debouncedUpdateComponentsAfterDelete(layerId);
      if (!undoStackUpdateNotRequired) {
        pushToUndoStack(timeStamp, updateStackComponent, layerId);
      }
    };

    const setExtraState = (value) => {
      setState({ ...state, ...value });
    };

    const singleMultiPopup = async (
      componentData,
      layersData,
      event,
      onlySelectAllowed = false
    ) => {
      if (activeTool && activeTool !== 'select') return;
      const layerList = layers;
      let sourceLayer = layerRefs[layersData.layerId];
      let chosenLayer = layers.find((obj) => {
        return obj.layerId == layersData.layerId;
      });
      sourceLayer.isEditable = chosenLayer.isEditable;
      let val = createSelectionMapKey(componentData, layersData);
      const attributesMulti = multiData;
      let newData = attributesMulti?.[val] ? attributesMulti?.[val] : [];

      let dataToSet: any[] = [];
      if (event?.ctrlKey || event?.metaKey) {
        if (Array.isArray(componentData)) {
          if (!onlySelectAllowed) {
            dataToSet = [...newData];
          }
          for (let i = 0; componentData.length > i; i++) {
            const isExist = newData.find(
              (component) =>
                componentData[i].componentId === component.componentId
            );
            if (!isExist) {
              dataToSet = [...dataToSet, componentData[i]];
              sourceLayer &&
                clickLayerHighlight(
                  sourceLayer,
                  componentData[i].componentId,
                  true,
                  layerList,
                  featureListInfo
                );
            } else if (!onlySelectAllowed) {
              for (let j = 0; newData.length > j; j++) {
                if (
                  newData[j]['componentId'] !== componentData[i].componentId
                ) {
                  dataToSet.push(newData[j]);
                } else {
                  sourceLayer &&
                    clickLayerHighlight(
                      sourceLayer,
                      componentData[i].componentId,
                      false,
                      layerList,
                      featureListInfo
                    );
                }
              }
            } else {
              dataToSet = [...newData];
            }
          }
        } else {
          const isExist = newData.find(
            (component) => componentData.componentId === component.componentId
          );
          if (!isExist) {
            dataToSet = [...newData, componentData];
            sourceLayer &&
              clickLayerHighlight(
                sourceLayer,
                componentData.componentId,
                true,
                layerList,
                featureListInfo
              );
          } else if (!onlySelectAllowed) {
            for (let j = 0; newData.length > j; j++) {
              if (newData[j]['componentId'] !== componentData.componentId) {
                dataToSet.push(newData[j]);
              } else {
                sourceLayer &&
                  clickLayerHighlight(
                    sourceLayer,
                    componentData.componentId,
                    false,
                    layerList,
                    featureListInfo
                  );
              }
            }
          }
        }
      } else {
        const isExist = newData.find(
          (component) => componentData.componentId === component.componentId
        );
        if (!isExist) {
          dataToSet = [componentData];
          newData.forEach(function (feature) {
            sourceLayer &&
              clickLayerHighlight(
                sourceLayer,
                feature.componentId,
                false,
                layerList,
                featureListInfo
              );
          });
          sourceLayer &&
            clickLayerHighlight(
              sourceLayer,
              componentData.componentId,
              true,
              layerList,
              featureListInfo
            );
        } else {
          if (newData.length > 1) {
            dataToSet = [componentData];
            newData.forEach(function (feature) {
              sourceLayer &&
                clickLayerHighlight(
                  sourceLayer,
                  feature.componentId,
                  false,
                  layerList,
                  featureListInfo
                );
            });
            sourceLayer &&
              clickLayerHighlight(
                sourceLayer,
                componentData.componentId,
                true,
                layerList,
                featureListInfo
              );
          } else {
            clearAllMultiSelectedData();
            dataToSet = [];
            sourceLayer &&
              clickLayerHighlight(
                sourceLayer,
                componentData.componentId,
                false,
                layerList,
                featureListInfo
              );
          }
        }
      }
      newData = {
        [val]: dataToSet,
      };

      const dataWithServiceItemIds = {};

      Object.entries(newData).forEach(([key, components]) => {
        dataWithServiceItemIds[key] = components.map((component) => {
          const layerId = component.layerId ?? component.properties.layerId;

          if (component.serviceItemIds || !layerId) {
            return component;
          }

          const componentLayer = layerComponents[layerId];

          const matchingComponent = componentLayer?.find(
            (_component) =>
              Number(_component.componentId) === Number(component.componentId)
          );
          if (!matchingComponent) return component;

          return { ...component, ...matchingComponent };
        });
      });

      // this.props.setAttributeMultiPopupData(dataWithServiceItemIds);
      dispatch(setCurrentLayer(sourceLayer));
    };

    const clearAllMultiSelectedData = () => {
      (Store.getState().order.highlighted ?? []).forEach((component) => {
        highlight(mapLayers[component.layerId], component.componentId, false);
      });

      dispatch(setHighlightedComponents([]));
      setTakeoffActionCenterLayerId(null);
    };

    const createSelectionMapKey = (components, layer) => {
      let areaUnit;
      if (Array.isArray(components) && components.length) {
        areaUnit = components[0]?.properties?.unit;
      } else {
        areaUnit = components?.properties?.unit;
      }
      return layer.name + '&&' + layer.layerId + '&&' + areaUnit;
    };

    const onSetSelectedNode = (selectedNodeKeys) => {
      setState({ ...state, selectedNodeKeys });
    };

    const setActiveToolRef = (interaction) => {
      setState({
        ...state,
        activeToolInteractionRef: interaction,
      });
    };

    const performPointUndoOperation = (source = 'click') => {
      if (source === 'shortcut') {
        shortcutData.current.activeToolInteractionRef.removeLastPoint();
        dispatch(drawInteractionLastPointUndo());
        return;
      }

      if (state.activeToolInteractionRef) {
        state.activeToolInteractionRef.removeLastPoint();
        dispatch(drawInteractionLastPointUndo());
      }
    };

    const performPointRedoOperation = (source = 'click') => {
      if (source === 'shortcut') {
        if (
          shortcutData.current.activeToolInteractionRef &&
          shortcutData.current.drawingFeaturePointHistoryRedoStack?.length
        ) {
          shortcutData.current.activeToolInteractionRef.appendCoordinates([
            shortcutData.current.drawingFeaturePointHistoryRedoStack[
              shortcutData.current.drawingFeaturePointHistoryRedoStack.length -
                1
            ],
          ]);
          dispatch(drawInteractionLastPointUndo());
        }
        return;
      }

      if (
        state.activeToolInteractionRef &&
        drawingFeaturePointHistoryRedoStack?.length
      ) {
        state.activeToolInteractionRef.appendCoordinates([
          drawingFeaturePointHistoryRedoStack[
            drawingFeaturePointHistoryRedoStack.length - 1
          ],
        ]);
        dispatch(drawInteractionLastPointUndo());
      }
    };

    const ignoreTopoChecks = (layerId, componentId = null) => {
      const that = this;
      axiosInstance
        .patch(
          componentId
            ? replaceParams(UPDATE_COMPONENT, {
                ':viewId': _get(selectedView, 'viewId'),
                ':layerId': layerId,
                ':componentId': componentId,
              })
            : replaceParams(VIEW_LAYER_DETAILS, {
                ':viewId': _get(selectedView, 'viewId'),
                ':layerId': layerId,
              }),
          { warningStatus: 'ignored' }
        )
        .then(() => {
          if (componentId) {
            setState(function (prevState) {
              let newLayersComponentsData: any[] = [];
              prevState.layerComponents[layerId].forEach(function (component) {
                if (component.componentId === componentId) {
                  newLayersComponentsData.push({
                    ...component,
                    warningStatus: 'ignored',
                  });
                } else {
                  newLayersComponentsData.push(component);
                }
              });
              return {
                ...prevState,
                layerComponents: {
                  ...prevState.layerComponents,
                  [layerId]: newLayersComponentsData,
                },
              };
            });
            dispatch(removeComponentTopologyWarnings({ layerId, componentId }));
          } else {
            setState(function (prevState) {
              let newLayersData: any[] = [];
              prevState.layersData.forEach(function (layer) {
                if (layer.layerId === layerId) {
                  newLayersData.push({ ...layer, warningStatus: 'ignored' });
                } else {
                  newLayersData.push(layer);
                }
              });
              return {
                ...prevState,
                layersData: [...newLayersData],
              };
            });
            dispatch(updateOrdersTopologyWarnings({ layerId }));

          }
        })
        .catch(catchError);
    };

    const loadFeatures = (loadLayers = true) => {
      setState({ ...state, loading: true });
      axiosInstance
        .get(FEATURES, {
          params: {
            maintainer: MAINTAINER,
            status: FEATURE_STATUS,
            sendUserDetails: true,
          },
        })
        .then((res) => {
          const data = _get(res, 'data.data');
          dispatch(setFeatureList(res.data));
          let featureObject = {};
          data.forEach(function (feature) {
            featureObject[feature.name] = feature;
          });
          // if (selectedView && loadLayers) {
          //   setState(
          //     {
          //       ...state,
          //       featureType: featureObject,
          //       loading: false,
          //       layersData: [],
          //       layerComponents: {},
          //     },
          //     function () {
          //       const { viewId } = this.props.selectedView;
          //       that.loadLayers(viewId);
          //     }
          //   );
          // } else
          setFeatureTypeObject(featureObject);
          setState({
            ...state,
            loading: false,
          });
        })
        .catch((error) => {
          setState({ ...state, loading: false });
        });
    };

    const ANTD_TREE_NODE_ACTIVE_COLOR = '#FDEEBB';

    const ANTD_TREE_NODE_SELECTOR = '.ant-tree-treenode';

    const highlightActiveLayerTreeNode = (layerId, editable) => {
      const treeNodesObject = document.querySelectorAll(
        ANTD_TREE_NODE_SELECTOR
      );
      const treeNodesArr = Object.values(treeNodesObject);
      const activeTreeNode = treeNodesArr.find((node) => {
        return node.querySelector(`[id="${layerId}"]`);
      });

      if (activeTreeNode) {
        activeTreeNode.style.backgroundColor = editable
          ? ANTD_TREE_NODE_ACTIVE_COLOR
          : '#E5E7EB';
      }
    };

    const removeHighlightFromLayerTreeNodes = (keys, nodeData) => {
      const treeNodesObject = document.querySelectorAll(
        ANTD_TREE_NODE_SELECTOR
      );
      const treeNodesArr = Object.values(treeNodesObject);

      treeNodesArr.forEach((treeNode) => {
        const currBackgroundColor = treeNode.style.backgroundColor;
        if (currBackgroundColor) {
          treeNode.style.backgroundColor = '';
        }
      });
    };

    const expendedLayerReset = (expendedLayer) => {
      let that = this;
      const expandedLayerData = layers.find(
        (layer) => layer.layerId.toString() === expendedLayer?.key
      );
      if (!expandedLayerData) return;

      let layer = {
        layerId: expendedLayer?.key,
        name: expandedLayerData?.name ?? '',
      };

      const selectedLayer = layers.find(
        (layer) => layer.layerId.toString() === expendedLayer?.key
      );

      if (!selectedLayer) return;

      if (layerRefs) {
        layerRefs[expendedLayer?.key]?.getSource().clear();
        let componentsForForged = [];
        let i = 0;
        expandedLayerData.components?.forEach(function (component) {
          componentsForForged.push(
            prepareComponentToAddToMap(component, layer, i++)
          );
        });
        addMapLayer(selectedLayer, componentsForForged, false);
      }
    };

    const addMapLayer = async (
      layer,
      componentsData,
      updateRef = false,
      onlyClearSource = false
    ) => {
      
      if (layerRefs[layer.layerId] && !updateRef) {
        _addMapLayer(
          map,
          layer,
          componentsData.map((component) => component.componentId),
          layerRefs[layer.layerId]
        );
      } else {
        if (layerRefs[layer.layerId]) {
          map.removeLayer(layerRefs[layer.layerId]);
        }
        let source,
          layerRef = null;
        const features = featureListInfo;
        const featureType = features
          .filter((item) => item.featureId === _get(layer, 'featureId'))
          .map((item) => item.type)[0];
        if (onlyClearSource && layerRefs[layer.layerId]) {
          source = layerRefs[layer.layerId].getSource();
          source.clear();
          addFeaturesToSource(source, componentsData);
          layerRef = layerRefs[layer.layerId];
        } else {
          source = newVectorSource(componentsData);
          layerRef = newVectorLayer(source, [
            convertJSONStyleToOlStyle(featureType, layer.style),
          ]);
        }

        if (featureType === 'path') {
          layerRef.setStyle(
            updatePathStyleFunction(source.getFeatures(), layer.style)
          );
        }
        layerRef.set('id', layer.layerId);
        layerRef.set('className', 'SrLayer');
        switch (featureType) {
          case 'polygon':
            layerRef.setZIndex(70);
            break;
          case 'line':
            layerRef.setZIndex(80);
            break;
          case 'point':
            layerRef.setZIndex(100);
            break;
          case 'path':
            layerRef.setZIndex(90);
            break;
        }
        map.addLayer(layerRef);
        mapLayers[layer.layerId] = layerRef;
        // setKmlDownload(...kmlDownload, )
      }
      
    
      /** Wait for setState to complete. TODO: Find a better way for this */
      await new Promise((r) => setTimeout(r, 300));
      setKmlLayerList((prevState)=>{
        return {
          ...prevState,
          [layer.name]:layerRefs[layer.layerId]
        }
    })




      // dispatch(kmlForLayerList(state.kmlDownload));
      // that.props.setOrderLayerComponents({
      //   ...that.state.layerComponentWithName,
      //   [layer?.name]: componentsData,
      // });
      // updateLayerPanelCheckBox(layer, componentsData);
    };

    const treeChange = (layerId, layer) => {
      for (let lyr in layerRefs) {
        if (layerRefs[lyr] === layerRefs[layerId]) {
          layerRefs[layerId].setZIndex(10);
        } else {
          layerRefs[lyr].setZIndex(0);
        }
      }
    };

    const getTreeCheckedStyle = (layer) => {
      const layerFeatureType = layer.featureType;
      switch (layerFeatureType) {
        case 'polygon':
          return _get(layer, 'style.fillColor');
        case 'point':
          return _get(layer, 'style.symbolColor');
        default:
          return _get(layer, 'style.color');
      }
    };

    const onEditLayer = (layerId, layer) => {
      treeChange(layerId, layer);
      setEditingRedux(true); //editable mode open
      // onSelect([layerId]); // selected node
      setState({ ...state, expendedKeys: `${layerId}`, lastAutoSaved: null });
    };

    const onExpendTree = async (keys, nodeData) => {
      const { expanded: isTreeExpanded } = nodeData;
      const { layerAreaInfo } = state;

      let chosenLayer = layers.find((obj) => {
        return obj.layerId == nodeData.node.key;
      });
      if (!isTreeExpanded) {
        // When The Tree Is Expanded, It Is Automatically Highlighted. So, We Are Just Highlighting The Currently Active (But Un-Expanded) Tree Node
        highlightActiveLayerTreeNode(
          nodeData?.node?.key,
          chosenLayer.isEditable
        );
      } else {
        // When Tree Is Expanded, Remove The Previously Highlighted Layers
        removeHighlightFromLayerTreeNodes(keys, nodeData);
      }

      // this.props.setCurrentLayer(chosenLayer);
      expendedLayerReset(nodeData.node);
      clearAllMultiSelectedData();

      if (nodeData.expanded) {
        const layerId = Number(nodeData.node.key);

        setSelectedLayerId(layerId);
        setMasterExpandState({ [layerId]: true });

        const layerData = layers.find(
          (layer) => layer.layerId.toString() === layerId.toString()
        );

        if (layerData) {
          setLayerPanelCheckedComponents((prevState) => {
            const updatedCheckedComponents = cloneDeep(prevState);

            Object.keys(updatedCheckedComponents[layerId]).forEach(
              (componentId) => {
                updatedCheckedComponents[layerId][componentId] = true;
              }
            );

            for (const component of layerData.components) {
              updatedCheckedComponents[layerData.layerId][
                component.componentId
              ] = true;
            }

            return updatedCheckedComponents;
          });
        }

        setLayerPanelCheckedLayers((prevState) => ({
          ...prevState,
          [layerId]: true,
        }));
      }

      /** Wait for setState to complete. TODO: Find a better way for this */
      await new Promise((r) => setTimeout(r, 200));

      layers.forEach(function (layer) {
        if (
          layer.layerId === nodeData.node.key &&
          _get(selectedView, 'isEditable') &&
          nodeData.expanded
        ) {
          onEditLayer(layer.layerId, layer);
        }
      });

      if (nodeData.expanded) {
        let expendLayer = {
          ...layerAreaInfo?.[nodeData.node.key],
          name: nodeData.node.name,
        };
        dispatch(setEditingRedux(true));
        // that.onSelect([keys[1]]);
        setState((prevState) => {
          const layerName =
            nodeData.node.name || nodeData.node.heading || chosenLayer.name;

          // const treeCheckedName = [
          //   ...prevState.treeCheckedName,
          //   { [layerName]: true },
          // ];

          const treeCheckedStyle = {
            ...prevState.treeCheckedStyle,
            [layerName]: getTreeCheckedStyle(chosenLayer),
          };

          return {
            ...state,
            layerAreaInfo: {
              ...prevState.layerAreaInfo,
              [nodeData.node.key]: { ...expendLayer },
            },
            expendLayer,
            expendedKeys: nodeData.node.key.toString(),
            // treeCheckedKeys: [...prevState.treeCheckedKeys, nodeData.node.key],
            // treeCheckedName,
            treeCheckedStyle,
            selectedLayer: { id: nodeData.node.key, name: nodeData.node.name },
            fixedValue: nodeData.node.area?.toFixed(2),
          };
        });

        const layerId = Number(nodeData.node.key);

        setSelectedLayerId(layerId);
        setMasterExpandState({ [layerId]: true });
      } else {
        // this.props.setEditingRedux(true);
        setState((prevState) => {
          return {
            ...state,
            expendLayer: null,
            expendedKeys: '',
            treeCheckedKeys: [...prevState.treeCheckedKeys, nodeData.node.key],
          };
        });
      }
      updateLayerAreaInfo(state.layerAreaInfo);
    };

    const handleModalOpen = (type, value) => {
      setState({ ...state, [type]: value });
    };

    const layerNavigation = (direction, type, distance) => {
      let that = this;
      const { selectedNodeKeys, updateAction } = state;
      if (!selectedView?.isEditable) {
        if (updateAction) {
          updateActionStatus();
          return;
        }
        setState({ ...state, updateAction: true });
        NotifyError('Operation not Allowed!');
        return;
      }
      if (selectedNodeKeys && selectedNodeKeys.length && editingToolVal) {
        dispatch(setMapClassnames('drag'));
        let [keys] = selectedNodeKeys;
        let [layerId, componentId] = keys.split('-');
        let selectedComponent;
        let vectorSource;
        vectorSource = layerRefs[layerId].getSource();
        vectorSource.forEachFeature((feature) => {
          if (`${feature.getId()}` === componentId) {
            selectedComponent = feature;
          }
        });
        if (!selectedComponent) {
          return false;
        }
        let geojson_geom = new GeoJSON();
        let drawGeometry = selectedComponent.getGeometry().clone();
        let drawCoordinates = geojson_geom.writeGeometry(
          drawGeometry.transform('EPSG:3857', 'EPSG:4326')
        );
        let drawnPolygon = JSON.parse(drawCoordinates);
        let operationFeature = turf.feature(drawnPolygon);
        let poly;
        let translatedPoly;
        let coordinatesPolygon = [];
        switch (selectedComponent.getGeometry().getType()) {
          case MAP_GEOMETRY_TYPE_LINE:
            poly = turf.lineString(
              _get(operationFeature, 'geometry.coordinates', [])
            );
            switch (direction) {
              case 'left':
                translatedPoly = turf.transformTranslate(poly, distance, 270, {
                  units: type,
                });
                break;
              case 'right':
                translatedPoly = turf.transformTranslate(poly, distance, 90, {
                  units: type,
                });
                break;
              case 'top':
                translatedPoly = turf.transformTranslate(poly, distance, 0, {
                  units: type,
                });
                break;
              case 'bottom':
                translatedPoly = turf.transformTranslate(poly, distance, 180, {
                  units: type,
                });
                break;
              default:
                break;
            }
            for (
              let i = 0;
              i < _get(translatedPoly, 'geometry.coordinates', []).length;
              i++
            ) {
              let pointTransform = transform(
                _get(translatedPoly, 'geometry.coordinates', [])[i],
                'EPSG:4326',
                'EPSG:3857'
              );
              coordinatesPolygon.push(pointTransform);
            }
            selectedComponent.getGeometry().setCoordinates(coordinatesPolygon);
            break;
          case MAP_GEOMETRY_TYPE_POINT:
            poly = turf.point(
              _get(operationFeature, 'geometry.coordinates', [])
            );
            switch (direction) {
              case 'left':
                translatedPoly = turf.transformTranslate(poly, 8, 270, {
                  units: type,
                });
                break;
              case 'right':
                translatedPoly = turf.transformTranslate(poly, 8, 90, {
                  units: type,
                });
                break;
              case 'top':
                translatedPoly = turf.transformTranslate(poly, 8, 0, {
                  units: type,
                });
                break;
              case 'bottom':
                translatedPoly = turf.transformTranslate(poly, 8, 180, {
                  units: type,
                });
                break;
              default:
                break;
            }
            let pointTransform = transform(
              _get(translatedPoly, 'geometry.coordinates', []),
              'EPSG:4326',
              'EPSG:3857'
            );
            selectedComponent.getGeometry().setCoordinates(pointTransform);
            break;
          case MAP_GEOMETRY_TYPE_POLYGON:
            poly = turf.polygon([
              _get(operationFeature, 'geometry.coordinates', [])[0],
            ]);
            switch (direction) {
              case 'left':
                translatedPoly = turf.transformTranslate(poly, 8, 270, {
                  units: type,
                });
                break;
              case 'right':
                translatedPoly = turf.transformTranslate(poly, 8, 90, {
                  units: type,
                });
                break;
              case 'top':
                translatedPoly = turf.transformTranslate(poly, 8, 0, {
                  units: type,
                });
                break;
              case 'bottom':
                translatedPoly = turf.transformTranslate(poly, 8, 180, {
                  units: type,
                });
                break;
              default:
                break;
            }
            for (
              let i = 0;
              i < _get(translatedPoly, 'geometry.coordinates', [])[0].length;
              i++
            ) {
              let pointTransform = transform(
                _get(translatedPoly, 'geometry.coordinates', [])[0][i],
                'EPSG:4326',
                'EPSG:3857'
              );
              coordinatesPolygon.push(pointTransform);
            }
            selectedComponent
              .getGeometry()
              .setCoordinates([coordinatesPolygon]);
            break;
          default:
            break;
        }
        ///change projection of feature
        let geoJSON_geom = new GeoJSON();
        let shiftNewGeometry = selectedComponent.getGeometry().clone();
        let shiftNewCoordinates = geoJSON_geom.writeGeometry(
          shiftNewGeometry.transform('EPSG:3857', 'EPSG:4326')
        );
        let shiftNewPolygon = JSON.parse(shiftNewCoordinates);
        let reqFeature = turf.feature(shiftNewPolygon);
        let properties = selectedComponent.getProperties();
        let reqData = {
          geoJson: reqFeature,
        };

        layerFeatureNavigation(
          properties.actualComponentId ?? properties.componentId,
          { ...reqData, layerId: layerId },
          layerId
        );
      } else {
        layerORMapNavigation(direction);
      }
    };

    const layerORMapNavigation = (direction) => {
      mapNavigation(direction, map);
    };

    const updateActionStatus = useRef(
      debounce(() => {
        setState({ ...state, updateAction: false });
      })
    ).current;

    const layerFeatureNavigation = (properties, reqData, layerId) => {
      componentUpdatedEvent(properties, reqData, layerId);
    };

    const applyShortCutOperation = () => {
      //Create duplicate Layer
      shortcutRef.duplicateLayer = function () {
        if (selectedLayer) {
          handleModalOpen('isCopyLayer', true);
        }
      };
      //Layer Navigation slow
      shortcutRef.layerNavigationSlow = function (direction) {
        layerNavigation(direction, 'inches', 20);
      };
      //Layer Navigation fast
      shortcutRef.layerNavigationFast = function (direction) {
        layerNavigation(direction, 'metres', 10);
        // that.layerORMapNavigation(direction);
      };
      // Create Duplicate Component
      shortcutRef.duplicateComponent = function () {
        // that.props.setMapClassnames('drag duplicate');
        const components = shortcutData.current.selectedComponents;
        if (components.length > 0) {
          let ids = components.map((component) => component.componentId);
          onDuplicateComponent(ids);
        }
      };
      // Merge Components
      shortcutRef.mergeComponent = function () {
        const components = shortcutData.current.selectedComponents;
        if (components.length > 1) {
          let ids = components.map((component) => component.componentId);
          onMergeComponents(ids);
        }
      };
      // Undo with ctrl/command +z
      shortcutRef.undo = () => {
        if (
          shortcutData.current.activeTool === ToolKey.EDIT &&
          shortcutData.current.unSyncedModifiedChanges.length > 0
        ) {
          /** User is in "MODIFY" mode and there are un-synced changed. So, we don't want to allow "Undo" action  */
          showNotification(
            NOTIFICATIONS_TYPES.WARNING,
            'Undo action is not available when there are unsaved edits.'
          );
          return;
        }

        handleUndoRedo('undo', false, 'shortcut');
        // if (shortcutData.current.drawingFeaturePointHistoryUndoStack.length) {
        //   performPointUndoOperation('shortcut');
        // } else {
        //   popFromUndoStack(shortcutData.current.selectedLayer?.layerId);
        // }
      };

      // redo with ctrl/command +y
      shortcutRef.redo = function () {
        if (
          shortcutData.current.activeTool === ToolKey.EDIT &&
          shortcutData.current.unSyncedModifiedChanges.length > 0
        ) {
          /** User is in "MODIFY" mode and there are un-synced changed. So, we don't want to allow "Redo" action  */
          showNotification(
            NOTIFICATIONS_TYPES.WARNING,
            'Redo action is not available when there are unsaved edits.'
          );
          return;
        }

        handleUndoRedo('redo', false, 'shortcut');
        // if (shortcutData.current.drawingFeaturePointHistoryRedoStack.length)
        //   performPointRedoOperation('shortcut');
        // else popFromRedoStack(shortcutData.current.selectedLayer?.layerId);
      };
      shortcutRef.deleteComponent = function () {
        if (shortcutData.current.selectedLayer) {
          deleteComponentWithKey(shortcutData.current.selectedLayer);
        }
      };
    };

    const onDuplicateComponent = async (componentIds: NumericId[]) => {
      const { selectedLayer, selectedView, layers } = shortcutData.current;

      let offSetValue = getOffSet(map);
      let reqData = {
        componentId: componentIds,
        targetLayerId: selectedLayer.layerId,
        offsetX: offSetValue,
        offsetY: offSetValue,
      };
      let ext = {
        ctrlKey: false,
      };
      let newLayerComponents = [];
      let undoStackItems = [];
      axiosInstance
        .post(
          replaceParams(COPY_COMPONENT, {
            ':viewId': selectedView?.viewId,
            ':layerId': selectedLayer?.layerId,
          }),
          reqData
        )
        .then(async (result) => {
          const { data } = result.data;
          if (data.length) {
            // trackEvents('action-center__duplicate', {
            //   type: componentIds.length > 1 ? 'multiselect' : 'single_select',
            //   viewId: selectedView?.viewId,
            //   orderId: selectedView?.orderId,
            //   geometryType: featureType[selectedLayer?.name].type,
            //   layerName: selectedLayer?.name,
            //   featureOwner: featureType[selectedLayer?.name].maintainer,
            //   isBaseView: selectedView?.isBaseView,
            //   isEditable: selectedView?.isEditable,
            //   itemsSelected: selectedComponent.length,
            // });
            let updatedLayers = [];

            setState((prevState) => {
              const layerComponents =
                cloneDeep(selectedLayer?.components) ?? [];

              data?.forEach((item) => {
                let JsonObject = {
                  ...JSON.parse(item.geoJson),
                  id: item.componentId,
                  name: selectedLayer?.name,
                  componentId: item.componentId,
                  layerId: selectedLayer?.layerId,
                  ...item,
                };

                JsonObject.featureType =
                  JsonObject.featureType ?? selectedLayer.featureType;
                JsonObject.componentName = displayComponentName(
                  layerComponents.length + 1
                );

                JsonObject.properties = {
                  ...JsonObject.properties,
                  ...item,
                  id: item.componentId,
                  componentIndexing: JsonObject.componentName,
                };
                layerComponents.push(JsonObject as SingleComponent);
                newLayerComponents.push(JsonObject as SingleComponent);
                undoStackItems.push({
                  ...item,
                  componentId: item.componentId,
                  type: 'drawInteraction',
                });
              });

              updatedLayers = Store.getState().order.layerList.map((layer) => {
                if (layer.layerId === Number(selectedLayer.layerId)) {
                  return { ...layer, components: layerComponents };
                }
                return layer;
              });

              dispatch(setOrderLayers(updatedLayers));

              // TODO: Add to orderLayers
              return {
                ...prevState,
              };
            });

            /** Wait for setState to complete. TODO: Find a better way for this */
            await new Promise((r) => setTimeout(r, 300));
            pushToUndoStack(moment(), undoStackItems, selectedLayer?.layerId);
            updateComponentsAfterDelete(selectedLayer?.layerId);
            updateTopologyWarnings(
              _get(result.data, 'topologyWarning', {}),
              updatedLayers,
              selectedLayer?.layerId
            );

            newLayerComponents.forEach((component) => {
              highlight(
                mapLayers[selectedLayer?.layerId],
                component.componentId
              );
            });

            setLayerPanelCheckedComponents((prevState) => {
              const updatedLayerPanelCheckedComponents = cloneDeep(prevState);

              for (const component of newLayerComponents) {
                updatedLayerPanelCheckedComponents[component.layerId][
                  component.componentId
                ] = true;
              }

              /** Whenever a component is moved, we need to check all the components in target and source layer if they are unchecked */
              Object.keys(prevState[selectedLayer?.layerId])?.forEach((key) => {
                updatedLayerPanelCheckedComponents[selectedLayer!.layerId][
                  key
                ] = true;
              });

              return updatedLayerPanelCheckedComponents;
            });

            setLayerPanelSelectedComponents((prevState) => {
              const newSelection = cloneDeep(prevState);

              for (const layerId of Object.keys(newSelection)) {
                for (const componentId of Object.keys(newSelection[layerId])) {
                  newSelection[layerId][componentId] = false;
                }

                if (Number(layerId) === selectedLayer?.layerId) {
                  for (const component of newLayerComponents) {
                    newSelection[layerId][component.componentId] = true;
                  }
                }
              }

              return newSelection;
            });

            dispatch(setHighlightedComponents(newLayerComponents));
          }
          showNotification(NOTIFICATIONS_TYPES.SUCCESS, 'Copy successfully');
        })
        .catch((error) => {});
    };

    const MERGE_TOOL_MESSAGE =
      'Non-Overlapping or Non-adjacent polygons cannot be merged.';

    const onMergeComponents = async (componentIds: NumericId[] = []) => {
      if (componentIds.length === 0) {
        return;
      }

      const { selectedLayer, selectedView, layers } = shortcutData.current;
      const olLayer = layerRefs[selectedLayer.layerId];

      const initialOlLayerFeatures = olLayer.getSource().getFeatures();
      const selectedOlLayerFeatures = [];
      const otherPolygons = [];
      initialOlLayerFeatures.forEach((iPLFeature) => {
        if (componentIds.indexOf(iPLFeature.id_) > -1) {
          selectedOlLayerFeatures.push(iPLFeature);
        } else {
          otherPolygons.push(iPLFeature);
        }
      });
      const otherFinalPolygons = getPolygonsFromFeatures(otherPolygons);
      const selectedParcelLayerPolygons = getPolygonsFromFeatures(
        selectedOlLayerFeatures
      );
      const result = mergePolygons(...selectedParcelLayerPolygons);

      const deletePolygons = result.delete;
      const indifferentPolygons = result.indifferent;
      const unionPolygons = result.union;
      map.removeLayer(olLayer);
      addFeaturesToMap(
        [...unionPolygons, ...otherFinalPolygons],
        olLayer,
        olLayer.getStyle()
      );
      map.addLayer(olLayer);
      if (
        unionPolygons.length === 0 &&
        deletePolygons.length === 0 &&
        indifferentPolygons.length === selectedParcelLayerPolygons.length
      ) {
        NotifyError(MERGE_TOOL_MESSAGE);
      }

      const timestamp = moment();
      const deletePolygonsPrepared = prepareGeometriesForUpdate(
        deletePolygons,
        timestamp
      );
      const unionPolygonsPrepared = prepareGeometriesForUpdate(
        unionPolygons,
        timestamp,
        DRAW_INTERACTION
      );
      const operationPolygons = [
        ...deletePolygonsPrepared,
        ...unionPolygonsPrepared,
      ];
      if (operationPolygons.length > 0) {
        clearAllMultiSelectedData();
        await componentUpdatedEvent(
          null,
          operationPolygons,
          selectedLayer.layerId,
          null,
          timestamp,
          true,
          true,
          { shouldAutoSelectNewComponents: false }
        );
      } else if (indifferentPolygons.length > 0) {
        updateComponentsAfterDelete(selectedLayer.layerId);
      }
    };

    const prepareGeometriesForUpdate = (
      geometries = [],
      timestamp = moment(),
      interactionType = DELETE_INTERACTION
    ) => {
      if (geometries && Array.isArray(geometries) && geometries.length > 0) {
        return geometries.map((geometryItem) => {
          const { geometry, properties } = geometryItem;
          return {
            componentId:
              interactionType === DRAW_INTERACTION
                ? properties.temp_id ?? `temp_${Math.random()}`
                : properties.actualComponentId ?? properties.componentId,
            geoJson: {
              geometry,
              properties: { ...properties },
              type: geometryItem.type,
            },
            timestamp,
            type: interactionType,
          };
        });
      }
      return [];
    };

    // TODO: This Should Be A Helper Function!
    const getPolygonsFromFeatures = (features = []) => {
      if (Array.isArray(features) && features.length > 0) {
        return features.map((feature) => {
          const featureProperties = { ...feature.getProperties() };
          delete featureProperties.geometry;

          const featureGeoJSONGeom = new GeoJSON();
          const featureGeometry = _get(feature, 'values_.geometry');
          const featureCoordinates = featureGeoJSONGeom.writeGeometry(
            featureGeometry.transform('EPSG:3857', 'EPSG:4326')
          );
          return turf.polygon(JSON.parse(featureCoordinates).coordinates, {
            ...featureProperties,
          });
        });
      }
      return [];
    };

    const deleteComponentWithKey = (
      selectedLayer,
      componentsToBeDeleted?: SingleComponent[]
    ) => {
      const { selectedView, selectedComponents: reduxSelectedComponents } =
        shortcutData.current;
      const selectedComponents =
        componentsToBeDeleted || reduxSelectedComponents;

      const layerId =
        typeof selectedLayer === 'object'
          ? selectedLayer?.layerId
          : selectedLayer;
      const viewId = selectedView?.viewId;
      if (!selectedView?.isEditable || selectedComponents.length === 0) {
        NotifyError('Operation not allowed!');
        return;
      }

      let reqData = {
        componentId: selectedComponents?.map(
          (component) => component.componentId
        ),
      };
      axiosInstance
        .post(
          replaceParams(BULK_UPDATE_COMPONENT, {
            ':viewId': viewId,
            ':layerId': layerId,
          }),
          { delete: reqData.componentId }
        )
        .then((res) => {
          showNotification(NOTIFICATIONS_TYPES.SUCCESS, 'Deleted successfully');
          deleteComponents(
            layerId,
            selectedComponents.map((component) => component.componentId),
            moment(),
            null,
            _get(res.data, 'topologyWarning', {})
          );
          dispatch(setAttributeMultiPopupData(null));
          clearAllMultiSelectedData();
          // that.props.updateOrdersTopologyWarnings({
          //   ..._get(res.data, 'topologyWarning', {}),
          //   layerId: layerId,
          // });
        })
        .catch(catchError);
    };

    const pushToUndoStack = (timeObj = moment(), action, layerId) => {
      setOperationsStack(function (prevState) {
        if (Array.isArray(action)) {
          if (action.length === 0) {
            return { ...prevState };
          }
          let undoStack = prevState.undoStack?.[layerId]
            ? { ...prevState.undoStack?.[layerId] }
            : {};
          action.forEach(function (actionItem) {
            if (
              undoStack[moment(actionItem.timeStamp || timeObj).format('x')]
            ) {
              undoStack = {
                ...undoStack,
                [moment(actionItem.timeStamp || timeObj).format('x')]: [
                  ..._get(
                    undoStack,
                    `${moment(actionItem.timeStamp || timeObj).format('x')}`,
                    []
                  ),
                  { action: actionItem, layerId: layerId },
                ],
              };
            } else {
              undoStack = {
                ...undoStack,
                [moment(actionItem.timeStamp || timeObj).format('x')]: [
                  { action: actionItem, layerId: layerId },
                ],
              };
            }
          });
          return {
            undoStack: {
              ...prevState.undoStack,
              [layerId]: {
                ...prevState.undoStack?.[layerId],
                ...undoStack,
              },
            },
            redoStack: { ...prevState.redoStack, [layerId]: {} },
          };
        }
        if (
          _get(
            prevState.undoStack?.[layerId],
            `${moment(action.timeStamp || timeObj).format('x')}`
          )
        ) {
          return {
            undoStack: {
              ...prevState.undoStack,
              [layerId]: {
                ...prevState.undoStack?.[layerId],
                [moment(action.timeStamp || timeObj).format('x')]: [
                  ..._get(
                    prevState.undoStack?.[layerId],
                    `${moment(action.timeStamp || timeObj).format('x')}`
                  ),
                  {
                    action,
                    layerId: layerId,
                  },
                ],
              },
            },
            redoStack: { ...prevState.redoStack, [layerId]: {} },
          };
        } else {
          return {
            undoStack: {
              ...prevState.undoStack,
              [layerId]: {
                ...prevState.undoStack?.[layerId],
                [moment(action.timeStamp || timeObj).format('x')]: [
                  { action, layerId: layerId },
                ],
              },
            },
            redoStack: { ...prevState.redoStack, [layerId]: {} },
          };
        }
      });
    };

    const clearUndoStack = (layerId) => {
      setOperationsStack(function (prevState) {
        return {
          undoStack: { ...prevState.undoStack, [layerId]: {} },
          redoStack: { ...prevState.redoStack, [layerId]: {} },
        };
      });
    };

    const popFromUndoStack = async (layerId) => {
      return new Promise(async (resolve) => {
        let timeStamp = moment();
        let undoStackTimeKeys = [];
        let undoStack = {};

        setState((prevState) => ({
          ...prevState,
          stackOperationLoading: true,
        }));

        setOperationsStack(function (prevState) {
          undoStackTimeKeys = Object.keys(
            _get(prevState.undoStack, `${[layerId]}`, {})
          );

          undoStack = prevState.undoStack ?? {};

          if (undoStackTimeKeys.length === 0) {
            NotifyError('History not available for undo');

            setState((innerPrevState) => ({
              ...innerPrevState,
              stackOperationLoading: false,
            }));
            return { ...prevState };
          } else {
            let poppedUndoStack = {};
            let valuesForRedoStack = [];

            for (let index = 0; index < undoStackTimeKeys.length; index++) {
              const undoStackTimeObj = undoStackTimeKeys[index];

              if (index !== undoStackTimeKeys.length - 1) {
                poppedUndoStack[undoStackTimeObj] =
                  prevState.undoStack?.[layerId][undoStackTimeObj];
              } else {
                valuesForRedoStack =
                  prevState.undoStack?.[layerId][undoStackTimeObj];
              }
            }

            return {
              undoStack: {
                ...prevState.undoStack,
                [layerId]: poppedUndoStack,
              },
              redoStack: {
                ...prevState.redoStack,
                [layerId]: {
                  ...prevState.redoStack[layerId],
                  [timeStamp.format('x')]: valuesForRedoStack,
                },
              },
            };
          }
        });

        /** Wait for setState to complete. TODO: Find a better way for this */
        await new Promise((r) => setTimeout(r, 200));
        setState((prevState) => ({
          ...prevState,
          stackOperationLoading: false,
        }));

        /** Wait for setState to complete. TODO: Find a better way for this */
        await new Promise((r) => setTimeout(r, 300));
        if (undoStackTimeKeys.length > 0) {
          for (let index = 0; index < undoStackTimeKeys.length; index++) {
            const undoStackTimeObj = undoStackTimeKeys[index];

            if (index === undoStackTimeKeys.length - 1) {
              // Popping the last update according to timestamp keys
              await performUndoOperations(
                undoStack[layerId][undoStackTimeObj],
                timeStamp,
                layerId
              );
            }
          }
        }

        resolve();
      });
    };

    const performUndoOperations = async (operations, timeStamp, layerId) => {
      return new Promise(async (resolve) => {
        if (operations.length) {
          let componentStackForUndo: any[] = [];
          operations.forEach(function (operation) {
            if (operation.action.type === 'drawInteraction')
              componentStackForUndo.push({
                ...operation.action,
                undoStackUpdateNotRequired: true,
                // geoJson: JSON.parse(operation.action.geoJson),
                options: { componentId: operation.action.componentId },
                timeStamp,
                layerId,
                type: 'deleteInteraction',
              });
            else if (operation.action.type === 'modifyInteraction')
              componentStackForUndo.push({
                ...operation.action.prev,
                timeStamp,
                layerId,
                undoStackUpdateNotRequired: true,
                refreshRequired: true,
                componentId: operation.action.prev.componentId,
                geoJson: JSON.parse(operation.action.prev.geoJson),
              });
            else if (operation.action.type === 'deleteInteraction')
              componentStackForUndo.push({
                ...operation.action,
                undoStackUpdateNotRequired: true,
                geoJson: JSON.parse(operation.action.geoJson),
                options: { componentId: operation.action.componentId },
                timeStamp,
                layerId,
                type: 'unDeleteInteraction',
              });
          });

          await componentUpdatedEvent(
            null,
            componentStackForUndo,
            layerId,
            true,
            timeStamp,
            true,
            true
          );

          resolve();
        }
      });
    };

    const popFromRedoStack = async (layerId) => {
      let timeStamp = moment();
      setState({
        ...state,
        stackOperationLoading: true,
      });

      setOperationsStack(function (prevState) {
        let redoStackTimeKeys = Object.keys(
          _get(prevState.redoStack, `${[layerId]}`, {})
        );
        if (redoStackTimeKeys.length === 0) {
          NotifyError('History not available for redo');

          setState({
            ...state,
            stackOperationLoading: false,
          });

          return { ...prevState };
        } else {
          let poppedRedoStack = {};
          let valuesForUndoStack = [];
          redoStackTimeKeys.forEach(function (redoStackTimeObj, index) {
            if (index !== redoStackTimeKeys.length - 1) {
              poppedRedoStack[redoStackTimeObj] =
                prevState.redoStack[layerId][redoStackTimeObj];
            } else {
              // Popping the last update according to timestamp keys
              performRedoOperations(
                prevState.redoStack[layerId][redoStackTimeObj],
                timeStamp,
                layerId
              );
              valuesForUndoStack =
                prevState.redoStack[layerId][redoStackTimeObj];
            }
          });

          return {
            redoStack: { ...prevState.redoStack, [layerId]: poppedRedoStack },
            undoStack: {
              ...prevState.undoStack,
              [layerId]: {
                ...prevState.undoStack?.[layerId],
                [timeStamp.format('x')]: valuesForUndoStack,
              },
            },
          };
        }
      });

      /** Wait for setState to complete. TODO: Find a better way for this */
      await new Promise((r) => setTimeout(r, 300));
      setState({
        ...state,
        stackOperationLoading: false,
      });
    };

    const performRedoOperations = async (operations, timeStamp, layerId) => {
      if (operations.length) {
        let componentStackForRedo: any[] = [];
        operations.forEach(function (operation) {
          if (operation.action.type === 'drawInteraction')
            componentStackForRedo.push({
              ...operation.action,
              undoStackUpdateNotRequired: true,
              geoJson: JSON.parse(operation.action.geoJson),
              options: { componentId: operation.action.componentId },
              timeStamp,
              layerId,
              type: 'unDeleteInteraction',
            });
          else if (operation.action.type === 'modifyInteraction')
            componentStackForRedo.push({
              ...operation.action.new,
              undoStackUpdateNotRequired: true,
              refreshRequired: true,
              geoJson: JSON.parse(operation.action.new.geoJson),
              timeStamp,
              layerId,
              action: operation.action.new.componentId,
            });
          else if (operation.action.type === 'deleteInteraction')
            componentStackForRedo.push({
              ...operation.action,
              undoStackUpdateNotRequired: true,
              geoJson: JSON.parse(operation.action.geoJson),
              options: { componentId: operation.action.componentId },
              timeStamp,
              layerId,
              type: 'deleteInteraction',
            });
        });
        componentUpdatedEvent(
          null,
          componentStackForRedo,
          layerId,
          true,
          timeStamp,
          true,
          true
        );
      }
    };

    const onUpdateLayerComponents = async (
      viewId,
      layerId,
      targetLayerId,
      keys,
      targetLayerData: { layerId: NumericId; name: string },
      componentsToMove: SingleComponent[]
    ) => {
      let areaValue = 0;
      let perimeterValue;

      const sourceLayer = layers.find((layer) => layer.layerId === layerId);
      const targetLayer = layers.find(
        (layer) => layer.layerId === targetLayerData.layerId
      );

      if (!sourceLayer || !targetLayer) return;
      dispatch(setCurrentLayer(targetLayerId));

      getHighlightedComponents()?.forEach((component) => {
        highlight(mapLayers[component.layerId], component.componentId, false);
      });

      const newHighlightedComponents: SingleComponent[] = [];

      setState((prevState) => {
        let newSourceLayerComponents: SingleComponent[] = [];
        let newTargetLayerComponents = [...targetLayer.components];

        areaValue +=
          parseFloat(prevState.layerAreaInfo?.[targetLayerId]?.area) ?? 0;
        perimeterValue += parseFloat(
          prevState.layerAreaInfo?.[targetLayerId]?.perimeter
        );

        const sourceLayerComponents = cloneDeep(sourceLayer.components);

        for (const sourceLayerComponent of sourceLayerComponents) {
          let found = false;
          const updatedSourceLayerComponent = cloneDeep(sourceLayerComponent);

          for (const componentToMove of componentsToMove) {
            if (
              updatedSourceLayerComponent.componentId ===
              componentToMove.componentId
            ) {
              found = true;

              const componentName = `ID${newTargetLayerComponents.length + 1}`;

              updatedSourceLayerComponent.componentName = componentName;
              updatedSourceLayerComponent.properties &&
                (updatedSourceLayerComponent.properties.componentIndexing =
                  componentName);
              updatedSourceLayerComponent.layerId = targetLayerData.layerId;
              updatedSourceLayerComponent.serviceItemIds = [];

              newTargetLayerComponents.push(updatedSourceLayerComponent);
              newHighlightedComponents.push(updatedSourceLayerComponent);
              /** Add logic to update action center items to moved components to new layer */

              areaValue += getFeatureValue(updatedSourceLayerComponent);
              perimeterValue +=
                updatedSourceLayerComponent.properties?.perimeter ??
                updatedSourceLayerComponent.perimeter;
            }
          }

          if (!found) {
            newSourceLayerComponents.push(updatedSourceLayerComponent);
          }
        }

        const currentLayers = Store.getState().order.layerList ?? layers;
        const clonedDeepLayers = cloneDeep(currentLayers);

        const updatedLayers = clonedDeepLayers.map((layer) => {
          const updatedLayer = { ...layer };

          if (layer.layerId === sourceLayer.layerId) {
            updatedLayer.components = newSourceLayerComponents;
          }

          if (layer.layerId === targetLayer.layerId) {
            updatedLayer.components = newTargetLayerComponents;
          }

          return updatedLayer;
        });

        dispatch(setOrderLayers(updatedLayers));
        setSelectedLayerId(Number(targetLayerId));
        setMasterExpandState({ [targetLayerId]: true });

        // that.props.setAttributeMultiPopupData(newData);
        return {
          ...prevState,
          expendedKeys: `${targetLayerId}`,
          expendLayer: {
            ...prevState.layerAreaInfo?.[targetLayerId],
            name: targetLayer.name,
          },
          fixedValue: areaValue.toFixed(2),
        };
      });

      /** Wait for setState to complete. TODO: Find a better way for this */
      await new Promise((r) => setTimeout(r, 300));

      updateComponentsAfterDelete(layerId);
      updateComponentsAfterDelete(targetLayerId);

      dispatch(setHighlightedComponents(newHighlightedComponents));

      setLayerPanelSelectedComponents((prevState) => {
        const updatedSelection = cloneDeep(prevState);

        for (const component of newHighlightedComponents) {
          if (!updatedSelection[component.layerId]) {
            updatedSelection[component.layerId] = {};
          }
          updatedSelection[component.layerId][component.componentId] = true;
        }

        return updatedSelection;
      });

      const layerPanelCheckedComponents = store.get(checkedComponentsAtom);
      const updatedLayerPanelCheckedComponents = cloneDeep(
        layerPanelCheckedComponents
      );

      newHighlightedComponents.forEach((component) => {
        updatedLayerPanelCheckedComponents[targetLayerId][
          component.componentId
        ] = true;
      });

      /** Whenever a component is moved, we need to check all the components in target and source layer if they are unchecked */
      Object.keys(layerPanelCheckedComponents[targetLayerId]).forEach((key) => {
        updatedLayerPanelCheckedComponents[targetLayerId][key] = true;
      });

      Object.keys(layerPanelCheckedComponents[sourceLayer.layerId]).forEach(
        (key) => {
          updatedLayerPanelCheckedComponents[sourceLayer.layerId][key] = true;
        }
      );

      setLayerPanelCheckedComponents(updatedLayerPanelCheckedComponents);
      updateLayerAreaInfo(state.layerAreaInfo);

      /** Wait for setState to complete. TODO: Find a better way for this */
      await new Promise((r) => setTimeout(r, 100));

      if (mapLayers[targetLayerId]) {
        newHighlightedComponents.forEach((component) => {
          highlight(mapLayers[targetLayerId], component.componentId);
        });
      }
    };

    const handleUndoRedo = async (
      type: 'undo' | 'redo',
      force = false,
      source = 'click'
    ) => {
      let currentLayer =
        source === 'click' ? selectedLayer : shortcutData.current.selectedLayer;
      if (!currentLayer) return;

      if (isModifying && !force) {
        /** There is an API request in progress to store/updated the last edited element, So we'll wait till that action is finished and once API responds `forcedSyncUpdatedComponentToServers` would call `handleUndoRedo` again with "force=true" */
        pendingUndoRedoAction = type;
        return;
      }

      const drawingFeaturePointHistoryUndoStack =
        Store.getState().map.drawingFeaturePointHistoryUndoStack;
      const drawingFeaturePointHistoryRedoStack =
        Store.getState().map.drawingFeaturePointHistoryRedoStack;

      try {
        if (type === 'undo') {
          trackEvents('map-tools__undo');
          if (drawingFeaturePointHistoryUndoStack.length)
            performPointUndoOperation(source);
          else {
            clearAllMultiSelectedData();
            await popFromUndoStack(currentLayer.layerId);
          }
          return;
        } else if ('redo') {
          trackEvents('map-tools__redo');
          if (drawingFeaturePointHistoryRedoStack.length)
            performPointRedoOperation(source);
          else {
            clearAllMultiSelectedData();
            await popFromRedoStack(currentLayer.layerId);
          }
        } else {
          NotifyError('Something went wrong with undo redo');
        }
      } finally {
        setTimeout(() => {
          hideLoadingCursor();
        }, 500);
      }
    };

    useImperativeHandle(ref, () => {
      return {
        onExpandTree: onExpendTree,
      };
    });

    return (
      <>
        <OrderStatusGuard
          or={selectedView && selectedView.isBaseView === false}
          except={
            userRoleId === UserRoleId.Estimator
              ? HIDDEN_MAP_TOOLS_PANEL_STATUS_FOR_ESTIMATOR
              : HIDDEN_MAP_TOOLS_PANEL_STATUS
          }
        >
          <SRUserTypeAccessGuard right={AccessRight.Edit}>
            <MapToolsPanel
              userRoleId={userRoleId}
              setPinnedTools={handleSetPinnedTools}
              selectedLayer={
                selectedLayer
                  ? {
                      id: selectedLayer.layerId,
                      name: selectedLayer.name,
                      ...selectedLayer,
                    }
                  : undefined
              }
              disableAllTools={disableAllTools(
                selectedView,
                layers,
                orderTileData?.access_rights,
                userTypeId
              )}
              disableAllToolsTooltip={disableAllToolsTooltip(
                layers,
                orderTileData?.access_rights,
                userTypeId
              )}
              handleUndoRedo={handleUndoRedo}
              mapRef={map}
              layerRefs={layerRefs}
              handleLayerComponents={handleLayerComponents}
              selectedView={selectedView}
              componentUpdatedEvent={componentUpdatedEvent}
              deleteComponent={deleteComponent}
              forcedSyncUpdatedComponentToServers={
                forcedSyncUpdatedComponentToServers
              }
              resetLayerStyles={resetLayerStyles}
              setExtraState={setExtraState}
              openFPanel={openFPanel}
              featureType={featureTypeObject}
              showApprovedOrderDetails={showApprovedOrderDetails}
              stackOperationLoading={state.stackOperationLoading}
              popFromUndoStack={popFromUndoStack}
              popFromRedoStack={popFromRedoStack}
              shortCutRef={shortcutRef}
              layerComponents={layerComponents}
              selectedNodeKeys={{}}
              selectedFeature={selectedFeature}
              singleMultiPopup={singleMultiPopup}
              onSelect={onSetSelectedNode}
              clickFeatureAction={clickFeatureAction}
              toggleAddAfterLayer={toggleAddAfterLayer}
              layersData={layers}
              setNoteActions={setNoteActions}
              setActiveToolRef={setActiveToolRef}
              accessRight={orderTileData?.access_rights}
              popoverRef={popoverRef}
              panelWidth={layerPanelStaticP.width}
              attributePanelVisible={state.attributePanelVisible}
              performPointUndoOperation={performPointUndoOperation}
              performPointRedoOperation={performPointRedoOperation}
              clearAllMultiSelectedData={clearAllMultiSelectedData}
              undoStack={operationsStack.undoStack}
              redoStack={operationsStack.redoStack}
              autoSaveRequestTrack={state.autoSaveRequestTrack}
              setCurrentLayer={(...props) => dispatch(setCurrentLayer(props))}
              addLayerHandler={() => {}}
              onExpandTree={(layerId, area) => {
                onExpendTree([layerId], {
                  expanded: true,
                  node: {
                    key: `${layerId}`,
                    area: area,
                    name: layers.find((item) => item.layerId === layerId).name,
                  },
                });
              }}
              isBulkView={false}
              ignoreTopoChecks={ignoreTopoChecks}
              onResetModifications={updateComponentsAfterDelete}
              isViewPanelPinned={isViewPanelPinned}
              isOrderHistoryPanelVisible={isOrderHistoryPanelVisible}
            />
          </SRUserTypeAccessGuard>
        </OrderStatusGuard>
        {/* TODO: Move this component to FeaturePanel level once all the dependencies are refactored */}
        <DraggableTakeoffActionCenter
          onUpdateLayerComponents={onUpdateLayerComponents}
          onMergeComponents={onMergeComponents}
          onDuplicateComponent={onDuplicateComponent}
          onDeleteComponents={deleteComponentWithKey}
        />
      </>
    );
  }
);
