import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../store/types';
import {
  IMapEditorCopyModal,
  MapEditorModalType,
} from '../../../../store/map-editor';
import React from 'react';
import { Nullable } from '../../../../modules/common/types';
import { LayerWithComponents } from '../../../../modules/property/api/types';
import CopyLayerModal from '../../../containers/copy-layer/CopyLayer';
import { setCopyModalData } from '../../../../store/map-editor/actions';

const MapEditorCopyModal = () => {
  const dispatch = useDispatch();
  const data = useSelector<IStore, IMapEditorCopyModal>(
    (state) => state.mapEditor.modals[MapEditorModalType.COPY]
  );

  const currentView = useSelector<IStore>(
    (state) => state.order.currentViewData
  );

  const layer = useSelector<IStore, Nullable<LayerWithComponents>>((state) => {
    if (!data.layerId) return null;

    return (
      state.order.layerList.find((_layer) => _layer.layerId === data.layerId) ??
      null
    );
  });

  if (!data.open || !layer || !currentView) {
    return null;
  }

  const handleModalToggle = (_, open: boolean) => {
    if (!open) {
      dispatch(
        setCopyModalData({
          open: false,
          layerId: null,
        })
      );
    }
  };

  return (
    <CopyLayerModal
      isOpen={data.open}
      selectedView={currentView}
      selectedLayer={{ id: layer.layerId, name: layer.name }}
      handleModalOpen={handleModalToggle}
    />
  );
};

export default MapEditorCopyModal;
