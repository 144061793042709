import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@siterecon/sr-styles/dist/elements';
import MrTakeoffLayerActionCenter from '../../../../../components/PlasmicComponents/MrTakeoffLayerActionCenter';
import {
  PlasmicTakeoffActionCenterProps,
  TakeoffActionCenterType,
} from './types';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../../store/types';
import {
  Layer,
  LayerWithComponents,
  SingleComponent,
} from '../../../api/types';
import { NumericId, OrderStatus } from '../../../../common/types';
import { useMapEditor } from '../../../hooks/useMapEditor';
import { CommandType } from '../../../commands/types';
import { useOrderStatus } from '../../../../project/guards/OrderStatus';
import ServiceItemAssignment from './ServiceItemAssignment';
import { FeatureType } from '../../../../../components/feature-attributes/types';
import useCurrentView from '../../../hooks/useCurrentView';
import useAccess from '../../../../project/guards/AccessGuard/useAccess';
import {
  setAddLayerModalData,
  setDeleteConfirmationModalData,
} from '../../../../../store/map-editor/actions';
import {
  setAttributeModalVisiblity,
  setFeatureToVisible,
} from '@store/attributeFeature/actions';
import { moveComponentToLayer, updateLayer } from '../../../api';
import { showNotification } from '../../../../../components/storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../../../../components/storybook/NotificationToast/types';
import { updateOrdersTopologyWarnings } from '@store/order/actions';
import { _get } from '@helpers/utilities/lodashUtils';
import { NotifyError } from '../../../../../helpers/notification-utils';
import useSubscription from '../../../../subscription/hooks/useSubscription';
import { SubscriptionPlan } from '../../../../../components/subscription/helpers/enum';
import { openUrl } from '../../../../../components/utils';
import { EXPLORE_SUBSCRIPTION_PLANS } from '../../../../../components/subscription/helpers/urls';
import { setHighlightedComponents } from '../../../../../store/order/actions';
import useFetchFeatureStyle from '../../../hooks/feature/useFetchFeatureStyle';
import { transformToPlasmicStyleOptions } from '../../../transformers/feature-style.transformers';
import { LayerStyle, PlasmicStyleOption } from '../../../types/layer.types';
import useAddFeatureStyle from '../../../hooks/feature/useAddFeatureStyle';
import useUpdateFeatureStyle from '../../../hooks/feature/useUpdateFeatureStyle';
import {
  currentFeatureStyleAtom,
  useCurrentFeatureStyle,
  writableFeatureStyleCatalogueAtom,
} from '../../../../../jotai/atoms/features';
import { writableSelectedComponentsAtom } from '../../../../../jotai/atoms/property/takeoff/layer-panel';
import { debounce } from 'lodash';
import { showVertices as showLayerVertices } from '../../../helpers/layer-render.helpers';
import { store } from '../../../../../jotai';
import { useTakeoffActionCenterLayerId } from '../../../../../jotai/atoms/property/takeoff/action-center';
import { useActionCenterComponents } from './useActionCenterComponents';
import { useAtom } from 'jotai/react';
import { findLayerById, trackEvents } from '../../../../../helpers/utilities';
import { CreateOrderEvt } from '../../../../../segment';
import {
  SHARE_MAP_PAGE,
  DISABLE_TOOLTIP_TEXT,
} from '../../../../../components/constants';
import { useRole } from '../../../../auth/guards/RoleGuard';
import {
  getHighlightedComponents,
  highlight,
} from '../../../hooks/takeoff/useLayerHighlight';
import { useMap } from '../../../hooks/useMap';
import { updateTopologyWarnings } from '../../../hooks/useUpdateTopologyWarnings';
import { Store } from '@/store';

export interface TakeoffActionCenterProps {
  /** TODO: Remove following props and use Command to perform action center tasks */
  onUpdateLayerComponents: any;
  onMergeComponents: any;
  onDuplicateComponent: any;
  onDeleteComponents: any;
}

let refSelectedLayer: any = null;
let refSelectedComponents: SingleComponent[] = [];
let refShowVertices: boolean = false;

const TakeoffActionCenter = ({
  onUpdateLayerComponents,
  onMergeComponents,
  onDuplicateComponent,
  onDeleteComponents,
}: TakeoffActionCenterProps) => {
  const dispatch = useDispatch();
  const map = useMap();

  const orderStatus = useOrderStatus()!;
  const currentView = useCurrentView();
  const [takeoffActionCenterLayerId, setTakeoffActionCenterLayerId] =
    useTakeoffActionCenterLayerId();
  const [styleToBeEdited, setStyleToBeEdited] = useState<NumericId | null>(
    null
  );
  const access = useAccess();
  const { execute } = useMapEditor();

  const ENV = process.env.REACT_APP_ENV;

  const layers = useSelector<IStore, LayerWithComponents[]>(
    (state) => state.order.layerList
  );

  const {
    selectedComponents,
    selectedLayer: layerFromComponents,
    isEmptyLayerSelected,
  } = useActionCenterComponents();

  const globalAssetData = useSelector((state: any) => state.ui.globalAsset);

  const [layerStylingOpen, setLayerStylingOpen] = useState<boolean>(true);
  const [serviceAssignmentOpen, setServiceAssignmentOpen] =
    useState<boolean>(false);

  const [showVertices, setShowVertices] = useState<boolean>(false);

  const [isColorPaletteVisible, setIsColorPaletteVisible] =
    useState<boolean>(false);

  const { currentPlan } = useSubscription();

  const [isEditorLoading, setIsEditorLoading] = useState<boolean>(false);

  const selectedLayer = useMemo(() => {
    const layer = isEmptyLayerSelected
      ? layerFromComponents
      : layers.find(
          (_layer) => _layer.layerId === selectedComponents[0].layerId
        );

    if (layer) {
      return { ...layer, noOfComponents: layer.components.length };
    }
    return layer;
  }, [layers, selectedComponents, selectedComponents?.length]);

  useEffect(() => {
    refShowVertices = showVertices;
  }, [showVertices]);

  useEffect(() => {
    refSelectedLayer = selectedLayer;
    refSelectedComponents = selectedComponents;
  }, [selectedLayer, selectedComponents]);

  const toggleLayerStylingHandler = (collapsedState: boolean) => {
    setLayerStylingOpen(collapsedState);
    setIsColorPaletteVisible(!collapsedState);

    if (!collapsedState) {
      setServiceAssignmentOpen(false);
    }
  };

  const toggleServiceAssignmentHandler = (open: boolean) => {
    setServiceAssignmentOpen(open);
    if (open) {
      setLayerStylingOpen(true);
      setIsColorPaletteVisible(false);
    }
  };

  const featureStyleCatalogue = useFetchFeatureStyle(selectedLayer?.featureId);

  const { addStyle } = useAddFeatureStyle();

  const { updateStyle } = useUpdateFeatureStyle();

  const [, setFeatureStyleCatalogue] = useAtom(
    writableFeatureStyleCatalogueAtom
  );

  const [, setLayerPanelSelectedComponents] = useAtom(
    writableSelectedComponentsAtom
  );

  const isShareMapPage = window.location.pathname.includes(SHARE_MAP_PAGE);

  useEffect(() => {
    setStyleToBeEdited(null);
  }, [selectedLayer?.layerId]);

  const defaultStyles = useMemo(() => {
    return (
      transformToPlasmicStyleOptions(
        featureStyleCatalogue,
        selectedLayer,
        styleToBeEdited
      ) ?? []
    );
  }, [featureStyleCatalogue, selectedLayer, styleToBeEdited]);

  const [, setSelectedStyle] = useCurrentFeatureStyle();

  const selectionType = useMemo(() => {
    if (takeoffActionCenterLayerId || isEmptyLayerSelected) {
      return TakeoffActionCenterType.LAYER;
    }

    return selectedComponents && selectedComponents.length > 1
      ? TakeoffActionCenterType.MULTI
      : TakeoffActionCenterType.SINGLE;
  }, [
    selectedLayer,
    takeoffActionCenterLayerId,
    selectedLayer?.noOfComponents,
    selectedComponents,
  ]);

  const totalArea = useMemo(() => {
    return selectedComponents.reduce((sum, item) => {
      const area = Number(
        item['area'] ?? item['Surface Area'] ?? item['Area'] ?? 0
      );
      return sum + area;
    }, 0);
  }, [selectedComponents]);

  const totalPerimeter = useMemo(() => {
    return selectedComponents.reduce((sum, item) => {
      if (item.featureType === FeatureType.LINE) {
        return sum + Number(item['Length'] ?? item['length'] ?? 0);
      }
      return sum + Number(item['perimeter'] ?? item['Perimeter']);
    }, 0);
  }, [selectedComponents]);

  const segmentPayload = {
    viewId: currentView?.viewId,
    orderId: currentView?.orderId,
    geometryType: selectedLayer?.featureType,
    layerName: selectedLayer?.name,
    isBaseView: currentView?.isBaseView,
    isEditable: currentView?.isEditable,
  };

  const changeLayerStyleDebounced = useCallback(
    debounce(
      async ({
        layerId,
        styleOption,
      }: {
        layerId: NumericId;
        styleOption: PlasmicStyleOption;
      }) => {
        if (!refSelectedLayer) return;

        setStyleToBeEdited(styleOption.styleId);
        setFeatureStyleCatalogue((prevState) => {
          return prevState.map((featureStyles) => {
            if (featureStyles.featureId === refSelectedLayer!.featureId) {
              return {
                ...featureStyles,
                styles: featureStyles.styles.map((style) => {
                  if (style.styleId === styleOption.styleId) {
                    return { ...style, ...styleOption };
                  }

                  return style;
                }),
              };
            }

            return featureStyles;
          });
        });

        if (styleOption.styleId) {
          updateStyle(
            refSelectedLayer!.featureId,
            styleOption.styleId!,
            styleOption.style
          );
        }

        changeLayerStyleHandler(styleOption, false);

        const response = execute(CommandType.CHANGE_LAYER_STYLE, {
          layerId,
          style: styleOption.style,
        });

        if (refShowVertices) {
          showLayerVertices(layerId);
        }

        return response;
      },
      500
    ),
    []
  );

  const handleDuplicateItem = () => {
    const layerId = selectedComponents[0].layerId;
    const componentIds = selectedComponents.map(
      (component) => component.componentId
    );

    trackEvents('action-center__duplicate', {
      ...segmentPayload,
      type: componentIds.length > 1 ? 'multiselect' : 'single_select',
      layerName: selectedLayer?.name,
      itemsSelected: componentIds.length,
    });

    /** TODO: Use `CommandType.DUPLICATE` Command for this */
    // void execute(CommandType.DUPLICATE, {
    //   componentIds,
    //   layerId,
    // });

    onDuplicateComponent(componentIds);
  };

  const handleMergeItems = () => {
    if (!currentView!.isEditable) {
      NotifyError('Operation not allowed!');
      return;
    }

    const componentIds = selectedComponents.map(
      (component) => component.componentId
    );

    trackEvents('action-center__merge', {
      ...segmentPayload,
      itemsSelected: componentIds.length,
    });

    onMergeComponents(componentIds);
  };

  const handleMoveToLayer = async (targetLayer: Pick<Layer, 'layerId'>) => {
    /** TODO: Move all the logic from this function to `MoveComponentToLayerCommand` */
    const targetLayerId = targetLayer.layerId;
    const layerId = selectedComponents[0].layerId;
    const componentIds = selectedComponents.map(
      (component) => component.componentId
    );
    try {
      trackEvents('action-center__move', {
        ...segmentPayload,
        type: componentIds.length > 1 ? 'multiselect' : 'single_select',
        itemsSelected: componentIds.length,
      });

      const result = await moveComponentToLayer(currentView!.viewId, layerId, {
        componentId: componentIds,
        targetLayerId: targetLayerId,
      });

      await onUpdateLayerComponents(
        currentView!.viewId,
        layerId,
        targetLayerId,
        componentIds,
        { layerId: targetLayerId },
        selectedComponents
      );

      showNotification(NOTIFICATIONS_TYPES.SUCCESS, 'Moved successfully');

      dispatch(
        updateOrdersTopologyWarnings({
          ..._get(result.data, 'sourceLayer', {}),
          layerId: layerId,
        })
      );

      dispatch(
        updateOrdersTopologyWarnings({
          ..._get(result.data, 'targetLayer', {}),
          layerId: targetLayerId,
        })
      );

      updateTopologyWarnings(
        { ..._get(result.data, 'sourceLayer', {}) },
        Store.getState().order.layerList,
        layerId
      );

      /** Wait for previous atom data to be set */
      await new Promise((r) => setTimeout(r, 300));
      updateTopologyWarnings(
        { ..._get(result.data, 'targetLayer', {}) },
        Store.getState().order.layerList,
        targetLayerId
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteItem = () => {
    /** TODO: Move all the logic from this function to `DeleteComponentCommand` */
    onDeleteComponents(selectedComponents[0].layerId);
    const layerId = selectedComponents[0].layerId;
    const componentIds = selectedComponents.map(
      (component) => component.componentId
    );

    trackEvents('action-center__delete', {
      ...segmentPayload,
      type: componentIds.length > 1 ? 'multiselect' : 'single_select',
      itemsSelected: componentIds.length,
    });

    setTakeoffActionCenterLayerId(null);
    dispatch(setHighlightedComponents([]));
  };

  const handleDeleteLayer = () => {
    const layerId = isEmptyLayerSelected
      ? layerFromComponents.layerId
      : selectedComponents[0].layerId;

    dispatch(
      setDeleteConfirmationModalData({
        open: true,
        layerId,
      })
    );
  };

  const handleDeleteLayerOrItem = () => {
    if (selectionType === TakeoffActionCenterType.LAYER) {
      handleDeleteLayer();
      return;
    }

    handleDeleteItem();
  };

  const handleLayerSettings = () => {
    const layerId = isEmptyLayerSelected
      ? layerFromComponents.layerId
      : selectedComponents[0].layerId;

    const layer = layers.find((_layer) => _layer.layerId === layerId);
    if (!layer) return;

    dispatch(
      setAddLayerModalData({
        open: true,
        layerId: layerId,
      })
    );

    dispatch(
      setFeatureToVisible({
        name: layer.name,
        id: layer.featureId,
      })
    );

    dispatch(setAttributeModalVisiblity(true));
  };

  const handleCopyLayer = () => {
    const layerId = isEmptyLayerSelected
      ? layerFromComponents.layerId
      : selectedComponents[0].layerId;

    void execute(CommandType.COPY_LAYER, {
      layerId,
    });
  };

  const toggleVerticesHandler = (show: boolean) => {
    const selectedStyle = store.get(currentFeatureStyleAtom);
    setShowVertices(show);

    if (show) {
      showLayerVertices(selectedLayer.layerId);
      return;
    }

    const resetStyle =
      selectedStyle ?? defaultStyles.find((style) => style.isSelected);

    if (resetStyle) {
      execute(CommandType.CHANGE_LAYER_STYLE, {
        layerId: selectedLayer.layerId,
        style: resetStyle.style,
      });
    }
  };

  const toggleColorPalette = (visible: boolean) => {
    setIsColorPaletteVisible(visible);
  };

  const changeLayerStyleHandler = async (
    styleOption: PlasmicStyleOption,
    shouldChangeStyle: boolean = true
  ) => {
    if (!refSelectedLayer) return;

    setSelectedStyle(styleOption);

    trackEvents('layerpanel__style-change', {
      geometryType: refSelectedLayer.featureType,
      layerName: refSelectedLayer.name,
    });

    if (shouldChangeStyle) {
      setStyleToBeEdited(styleOption.styleId);

      await execute(CommandType.CHANGE_LAYER_STYLE, {
        layerId: refSelectedLayer.layerId,
        style: styleOption.style,
      });

      if (refShowVertices) {
        showLayerVertices(refSelectedLayer.layerId);
      }
    }

    await updateLayer(currentView!.viewId, refSelectedLayer.layerId, {
      style: styleOption.style,
    });
  };

  const addFeatureStyleHandler = async (style: LayerStyle) => {
    if (!refSelectedLayer) return;

    setIsEditorLoading(true);
    const newStyle = await addStyle(refSelectedLayer.featureId, style);

    const newStyleOption = {
      ...newStyle,
      isLegacy: false,
      isSelected: true,
      isEditing: true,
    };

    if (newStyle) {
      setIsEditorLoading(false);
      setStyleToBeEdited(newStyle.styleId);
      await changeLayerStyleHandler(newStyleOption, false);
    }

    if (refShowVertices) {
      showLayerVertices(refSelectedLayer.layerId);
    }
  };

  const editFeatureStyleHandler = async (styleOption: PlasmicStyleOption) => {
    if (!refSelectedLayer) return;

    setStyleToBeEdited(styleOption.styleId);

    changeLayerStyleDebounced({
      layerId: refSelectedLayer.layerId,
      styleOption: styleOption,
    });

    setSelectedStyle(styleOption);

    if (refShowVertices) {
      showLayerVertices(refSelectedLayer.layerId);
    }
  };

  const resetToDefaultHandler = async () => {
    const defaultStyle = defaultStyles.find((style) => style.isDefaultStyle);
    if (!defaultStyle) return;

    await changeLayerStyleHandler(defaultStyle);
  };

  const getSymbolList = () => {
    switch (selectedLayer?.featureType) {
      case FeatureType.POINT:
        return globalAssetData?.pointSymbol;
      case FeatureType.PATH:
        return globalAssetData?.arrowHead;
      default:
        return [];
    }
  };

  const moveToLayerList = useMemo(() => {
    return layers
      .filter(
        (layer) =>
          layer.isEditable &&
          layer.status === 'active' &&
          layer.featureType === selectedLayer?.featureType &&
          layer.layerId !== selectedLayer?.layerId
      )
      .map((layer) => ({
        id: layer.layerId,
        name: layer.name,
        styleObj: {
          fillColor: layer.style.fillColor,
          strokeColor: layer.style.color,
          symbolType: layer.style.symbolType,
          symbolColor: layer.style.symbolColor,
          color: layer.style.color,
        },
      }));
  }, [selectedLayer]);

  const handleClose = () => {
    const highlighted = getHighlightedComponents();

    const unHighlightComponents = (components: SingleComponent[]) => {
      for (const _component of components) {
        const layerId = _component.layerId;
        let sourceLayerOfComponent = findLayerById(layerId, map);

        if (sourceLayerOfComponent) {
          highlight(sourceLayerOfComponent, _component.componentId, false);
        }
      }
    };

    unHighlightComponents(highlighted);
    setTakeoffActionCenterLayerId(null);
    dispatch(setHighlightedComponents([]));

    setLayerPanelSelectedComponents((prevState) => {
      const newSelection = { ...prevState };

      for (const layerId of Object.keys(newSelection)) {
        const layerSelection = { ...newSelection[layerId] };

        for (const componentId of Object.keys(newSelection[layerId])) {
          layerSelection[componentId] = false;
        }

        newSelection[layerId] = layerSelection;
      }

      return newSelection;
    });

    return;
  };

  if (!currentView) return;

  if (!selectedLayer) {
    return null;
  }

  const canEdit = currentView.isEditable;
  const { isGuest } = useRole();

  const actionCenterProps: PlasmicTakeoffActionCenterProps = {
    layerInfo: {
      type: selectionType,
      id: selectedLayer.layerId,
      name: selectedLayer.name,
      area: selectedLayer.featureType !== FeatureType.LINE ? totalArea : null,
      perimeter:
        selectedLayer.featureType === FeatureType.POLYGON ||
        selectedLayer.featureType === FeatureType.LINE
          ? totalPerimeter
          : null,
      featureType: selectedLayer.featureType,
      selected: isEmptyLayerSelected ? 0 : selectedComponents.length,
      total: selectedLayer.noOfComponents,
    },
    componentName: isEmptyLayerSelected
      ? '0 IDs'
      : `${selectedComponents[0].componentName
          ?.replace(selectedComponents[0].name, '')
          .trim()}`,
    layerSettings: {
      disable: [
        OrderStatus.AwaitingApproval,
        OrderStatus.ResolvingFeedback,
      ].includes(orderStatus),
      onClick: handleLayerSettings,
      disableTooltip: isShareMapPage ? DISABLE_TOOLTIP_TEXT : 'Layer settings',
    },
    copyLayer: {
      disable: !canEdit,
      onClick: handleCopyLayer,
      disableTooltip: isShareMapPage ? DISABLE_TOOLTIP_TEXT : 'Copy layer',
    },
    deleteLayer: {
      disable: !canEdit,
      onClick: handleDeleteLayerOrItem,
      disableTooltip: isShareMapPage ? DISABLE_TOOLTIP_TEXT : 'Delete layer',
    },
    deleteComponent: {
      disable: !canEdit,
      onClick: handleDeleteLayerOrItem,
      disableTooltip: 'Delete item(s)',
    },
    copyGeoJson: {
      disable: ['app', 'prod'].includes(ENV),
      onClick: () => {},
      disableTooltip: 'Copy GeoJson',
    },
    moveToLayer: {
      disable: !canEdit,
      disableTooltip: `No other ${selectedLayer.featureType} layer exists`,
      layerList: moveToLayerList,
      onSelect: handleMoveToLayer,
    },
    duplicateItem: {
      disable: !canEdit,
      onClick: handleDuplicateItem,
      disableTooltip: 'Duplicate item',
    },
    mergeItems: {
      disable: !canEdit || selectedLayer.featureType !== FeatureType.POLYGON,
      onClick: handleMergeItems,
      disableTooltip: 'Merge items',
    },
    defaultStyleOptions: defaultStyles,
    symbolsList: getSymbolList(),
    onChangeStyle: changeLayerStyleHandler,
    onEditSelectedStyle: editFeatureStyleHandler,
    onAddStyle: addFeatureStyleHandler,
    onResetToDefaultStyle: resetToDefaultHandler,
    onShowVertices: toggleVerticesHandler,
    showVertices,
    isLayerStylingOpen: !layerStylingOpen,
    onLayerStylingToggle: toggleLayerStylingHandler,
    serviceAssignmentSlot: !isShareMapPage ? (
      <ServiceItemAssignment
        visible={serviceAssignmentOpen}
        onSIDropdownToggle={(open: boolean) => {
          trackEvents(CreateOrderEvt.ServiceItemListToggle, { visible: open });
          toggleServiceAssignmentHandler(open);
        }}
        isColorPaletteVisible={isColorPaletteVisible}
        layerName={selectedLayer.name}
      />
    ) : null,
    isPaidUser: currentPlan !== SubscriptionPlan.ESSENTIAL,
    onExplorePlans: () => openUrl(EXPLORE_SUBSCRIPTION_PLANS),
    onClose: handleClose,
    isLayerStylingDisabled: isGuest,
    onColorPaletteHideShow: toggleColorPalette,
    isEditorLoading,
    isSharePage: isShareMapPage,
  };

  return (
    <Box className='takeoff-action-center'>
      <MrTakeoffLayerActionCenter {...actionCenterProps} />
    </Box>
  );
};

export default TakeoffActionCenter;
