// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Crown2IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Crown2Icon(props: Crown2IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 18 18"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M3.603 12.72h10.8l-.19.718c-.121.47-.323.824-.606 1.06-.282.234-.684.352-1.206.352H5.6c-.522 0-.924-.12-1.206-.359-.278-.235-.478-.586-.6-1.053l-.19-.717zm-.208-.819l-1.22-4.695.607-.15L5.637 9.03c.105.072.191.1.259.084a.353.353 0 00.19-.144l2.766-3.954.303.012 2.74 3.924a.366.366 0 00.203.156c.08.016.172-.012.278-.084l2.911-2.01.518.281-1.187 4.606H3.395zm-.986-3.41c-.273 0-.526-.063-.757-.19a1.593 1.593 0 01-.55-.521A1.336 1.336 0 01.9 7.056c0-.26.067-.496.202-.712a1.49 1.49 0 01.543-.514 1.52 1.52 0 01.764-.198c.278 0 .531.066.758.198.228.127.409.299.544.514.134.216.202.453.202.712 0 .267-.068.508-.202.724a1.538 1.538 0 01-.544.52c-.227.128-.48.192-.758.192zm6.594-2.488c-.278 0-.532-.064-.764-.191a1.488 1.488 0 01-.543-.515 1.356 1.356 0 01-.202-.723c0-.264.067-.503.202-.718.135-.216.316-.387.543-.515.232-.127.486-.191.764-.191.274 0 .524.066.752.197.231.128.414.3.55.515.134.215.201.452.201.712 0 .263-.067.504-.202.723a1.474 1.474 0 01-.55.515 1.51 1.51 0 01-.75.191zm6.588 2.489c-.278 0-.531-.064-.758-.192a1.538 1.538 0 01-.543-.52 1.336 1.336 0 01-.203-.724c0-.26.068-.496.203-.712.134-.215.315-.387.543-.514.227-.132.48-.198.758-.198.277 0 .53.066.757.198.232.127.415.299.55.514.135.216.202.453.202.712 0 .267-.07.508-.208.724a1.523 1.523 0 01-1.301.712z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Crown2Icon;
/* prettier-ignore-end */
