export const UserTypes = {
  ADMIN: {
    roleId: 1,
    title: 'Admin',
  },
  CREATOR: {
    roleId: 2,
    title: 'Creator',
  },
  EDITOR: {
    roleId: 3,
    title: 'Editor',
  },
  VIEWER: {
    roleId: 4,
    title: 'Viewer',
  },
  GUEST: {
    roleId: 5,
    title: 'Guest',
  },
  ESTIMATOR: {
    roleId: 6,
    title: 'Estimator',
  },
};

export const SITERECON_EMAIL='siterecon.ai'