import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Tooltip } from 'antd';

import addIcon from '../../../../assets/add.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/search.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/check.svg';

import Text from '../../../elements/text/Text';
import { useSelector } from 'react-redux';

import './style.less';
import { _get } from '../../../../helpers/utilities/lodashUtils';
import { truncateString } from '../../../../helpers/utilities';
import { FeatureSubscriptionGuard } from '@/modules/subscription/guards/base';
import { SubscriptionFeature } from '@/modules/subscription/types';
import EmTakeOffLayerChips2 from '../../../PlasmicComponents/EmTakeOffLayerChips2';

const FeatureWrapper = ({ val, filterFeature, selectedName }) => (
  <div
    className={
      selectedName?.id === val.featureId ? 'name-list active-name' : 'name-list'
    }
    id={val.name + '-' + val.featureId}
    onClick={() => filterFeature(val.name, val.featureId, val.maintainer)}
  >
    <Text
      color='light-gray'
      className={selectedName?.id === val.featureId ? 'layer-name-active ' : ''}
      type='p23'
    >
      <Tooltip title={val.name}>{truncateString(val.name, 19)}</Tooltip>
    </Text>
    {/* {layerIndicatorStyle(_get(val, 'type'), _get(val, 'style'))} */}

    <EmTakeOffLayerChips2
      featureType={val.type}
      styleObject={{
        color: val.style.color,
        fillColor: val.style.fillColor,
        symbolColor: val.style.symbolColor,
        symbolType: val.style.symbolType,
        arrowColor: val.style.arrowColor,
      }}
    />
    
    <CheckIcon
      width={16}
      height={16}
      className={
        selectedName?.id === val.featureId ? 'check-icon' : 'check-icon hidden'
      }
    />
  </div>
);

export const FeatureList = (props) => {
  const [features, setFeatures] = useState(null);
  const [selectedName, setSelectedName] = useState(null);

  const listRef = useRef(null);

  const { data: featureListInfo } = useSelector(
    (state) => state.order.featureListInfo
  );

  const currentUserId = useSelector((state) => state.user.info.id);

  const attributeVisiblity = useSelector(
    (state) => state.feature.attributeModalVisible
  );

  useEffect(() => {
    setSelectedName(props.selectedName);

    if (attributeVisiblity && props.selectedName) {
      const { name, id } = props.selectedName;
      document.getElementById(name + '-' + id)?.scrollIntoView({
        behavior: 'smooth',
        left: 0,
        block: 'center',
      });
    } else {
      document.getElementById('siterecon-start')?.scrollIntoView({
        behavior: 'smooth',
        left: 0,
        block: 'center',
      });
    }
  }, [props.selectedName, attributeVisiblity]);

  useEffect(() => {
    setFeatures(featureListInfo);
  }, [featureListInfo]);

  const searchHandler = (e) => {
    const val = e.target.value;
    const len = val.length;
    if (val === '') {
      setFeatures(featureListInfo);
    } else {
      const newFeature = featureListInfo.filter((el) => {
        return el.name.toLowerCase().search(val.toLowerCase()) !== -1;
      });
      setFeatures(newFeature);
    }
  };

  const siteReconFeatures = features?.filter(
    (feature) =>
      feature?.maintainer === 'siterecon' && feature?.status === 'active'
  );

  const customFeatures = features?.filter(
    (feature) =>
      feature?.maintainer === 'organization' && feature?.status === 'active'
  );

  const userCustomFeatures = features?.filter(
    (feature) =>
      feature?.maintainer === 'user' &&
      feature?.status === 'active' &&
      feature?.userId === currentUserId
  );

  return (
    <div className='left-feature' ref={listRef}>
      <Text type='p0' color='dark-gray' className='library-heading'>
        Layer Library
      </Text>
      <div className='search-box pr-3 mto-10'>
        <Input
          suffix={<SearchIcon />}
          onChange={searchHandler}
          placeholder='Search Library'
        />
      </div>
      <div className='top-content mto-25'>
        <Text id='siterecon-start' type='p7' className='features-heading'>
          SiteRecon Layers{' '}
        </Text>
        {siteReconFeatures?.length > 0 ? (
          siteReconFeatures?.map((val, index) => {
            return (
              <FeatureWrapper
                key={index}
                val={val}
                filterFeature={props.filterFeature}
                selectedName={selectedName}
              />
            );
          })
        ) : (
          <Text type='p23' color='dark-gray'>
            No Siterecon Layers are present
          </Text>
        )}

        <Text type='p7' className='custom features-heading'>
          Workspace Custom Layers{' '}
        </Text>
        {customFeatures?.length > 0 ? (
          customFeatures?.map((val, index) => {
            return (
              <FeatureWrapper
                key={index}
                val={val}
                filterFeature={props.filterFeature}
                selectedName={selectedName}
              />
            );
          })
        ) : (
          <Text type='p23' color='dark-gray'>
            No Custom Layers saved in the library yet
          </Text>
        )}

        <Text type='p7' className='custom features-heading'>
          User Custom Layers{' '}
        </Text>
        {userCustomFeatures?.length > 0 ? (
          userCustomFeatures?.map((val, index) => {
            return (
              <FeatureWrapper
                key={index}
                val={val}
                filterFeature={props.filterFeature}
                selectedName={selectedName}
              />
            );
          })
        ) : (
          <Text type='p23' color='dark-gray'>
            No User Layers saved in the library yet
          </Text>
        )}
      </div>

      <FeatureSubscriptionGuard
        feature={SubscriptionFeature.CustomFeature}
        modal={{ title: 'Want to create a new custom layer?' }}
      >
        <Button
          className='create-new-but'
          onClick={() => props.switchToCreateFeatureForm(2)}
          type='default'
          // disabled={userInfo?.user_role_id !== 1}
        >
          <img src={addIcon} alt='add-icon' />
          Create Custom Layer
        </Button>
      </FeatureSubscriptionGuard>
    </div>
  );
};
