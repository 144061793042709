import { Feature } from 'ol';
import OlMap from 'ol/Map';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import { FeatureLike } from 'ol/Feature';
import { SingleComponent } from '../../api/types';
import {
  findLayerById,
  findLayerStyleFromComponent,
} from '@/helpers/utilities/index';
import { Store } from '@/store';
import { ID, NumericId } from '../../../common/types';
import { createGeoJsonFromFeature } from '@helpers/mapUtils/mapInit';
import { get } from 'lodash';
import { Layer } from 'ol/layer';
import { clickLayerHighlight } from '@helpers/mapUtils/featuresUtils';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setHighlightedComponents } from '@store/order/actions';
import { UseLayerSource } from './useLayers';
import { MapTool } from '../../../project/types';
import { TakeoffActionCenterType } from '../../components/ActionCenter/Takeoff/types';
import { store } from '../../../../jotai';
import {
  takeoffActionCenterLayerIdAtom,
  takeoffActionCenterTypeAtom,
} from '../../../../jotai/atoms/property/takeoff/action-center';

type FeatureClickFunction = (
  event: MapBrowserEvent,
  feature: Feature | null,
  component: SingleComponent | null
) => void;

const getLayerList = () => Store.getState().order.layerList ?? [];

const getFeatureList = () => Store.getState().order.featureListInfo?.data ?? [];

export const getHighlightedComponents = () =>
  Store.getState().order.highlighted ?? [];

let isEventListenerAdded = false;

const addMapClickEventListener = (
  map: OlMap,
  callback: FeatureClickFunction
) => {
  const handleMapClick = (event: MapBrowserEvent) => {
    let clickedFeature: FeatureLike | null = null;

    /** TODO: add `jotai` provider for App.js and get the following value from jotai directly */
    const activeTool = Store.getState().user.activeTool;
    if (activeTool && activeTool !== MapTool.Select) {
      return;
    }

    event.map.forEachFeatureAtPixel(event.pixel, (feature) => {
      if (clickedFeature) {
        return;
      }

      try {
        const properties = feature.getProperties();

        if (
          properties.Area ||
          properties['Surface Area'] ||
          properties['Ring Area'] ||
          properties.Length
        ) {
          clickedFeature = feature;
          callback(event, feature as Feature, properties as SingleComponent);
        } else {
          console.error('Something is wrong here ERR-45792', feature, {
            properties,
          });
        }
      } catch (e) {
        console.error('error', e);
      }
    });

    if (!clickedFeature) {
      callback(event, null, null);
    }
  };

  map.on('click', handleMapClick);
  return handleMapClick;
};

const createFeatureGeoJsonWithLayerStyle = (
  feature: Feature,
  layerId: NumericId
) => {
  let featureJson = createGeoJsonFromFeature(feature);
  featureJson.style = findLayerStyleFromComponent(layerId, getLayerList());
  featureJson.componentId =
    featureJson.properties.actualComponentId ??
    featureJson.properties.componentId;
  return featureJson;
};

export const highlight = (
  layer: Layer,
  featureId: ID,
  highlight: boolean = true
) => {
  /** TODO: Add `clicked=true` property to Feature when it is highlighted and `clicked=false` when it's un-highlighted */
  clickLayerHighlight(
    layer,
    featureId,
    highlight,
    getLayerList(),
    getFeatureList()
  );
};

const isMultiSelectEvent = (event: MapBrowserEvent) => {
  return (
    get(event, 'originalEvent.ctrlKey') || get(event, 'originalEvent.metaKey')
  );
};

export interface UseLayerHighlightOptions {
  onHighlightChange: (components: SingleComponent[]) => void;

  source?: UseLayerSource;
}

const useLayerHighlight = (map: OlMap, options?: UseLayerHighlightOptions) => {
  const source = options?.source ?? UseLayerSource.TAKEOFF;
  const dispatch = useDispatch();

  const setHighlighted = (components: SingleComponent[]) => {
    dispatch(setHighlightedComponents(components));
    options?.onHighlightChange(components);
  };

  const handleFeatureClick: FeatureClickFunction = useCallback(
    (event, feature, component) => {
      const highlighted = getHighlightedComponents();
      const unHighlightComponents = (components: SingleComponent[]) => {
        for (const _component of components) {
          const layerId = _component.layerId;
          let sourceLayerOfComponent = findLayerById(layerId, map);

          if (sourceLayerOfComponent) {
            highlight(sourceLayerOfComponent, _component.componentId, false);
          }
        }
      };

      if (!feature || !component) {
        // When no feature is selected, un-highlight all the components
        unHighlightComponents(highlighted);
        setHighlighted([]);
        return;
      }

      const componentId = component.componentId;

      // The OlSource for the layer selected feature belongs to
      let sourceLayer: Layer = findLayerById(component.layerId, map);

      const isAlreadySelected = !!highlighted.find(
        (_component) => componentId === _component.componentId
      );

      let isComponentFromDifferentLayer = highlighted.some(
        (_component) => _component.layerId !== component.layerId
      );

      let featureJson = createFeatureGeoJsonWithLayerStyle(
        feature,
        component.layerId
      );

      featureJson.id = featureJson.id || featureJson.componentId;

      store.set(takeoffActionCenterLayerIdAtom, null);
      // store.set(takeoffActionCenterTypeAtom, null);

      if (isMultiSelectEvent(event)) {
        if (isAlreadySelected) {
          highlight(sourceLayer, featureJson.id, false);
          setHighlighted(
            highlighted.filter(
              (_component) => _component.componentId !== componentId
            )
          );
          return;
        }

        if (isComponentFromDifferentLayer) {
          /** In multi select, if a component from different layer is selected, we don;t want to do any selection */
          return;
        }

        highlight(sourceLayer, featureJson.id, true);
        setHighlighted([...highlighted, component]);
        return;
      }

      if (isAlreadySelected && highlighted.length <= 1) {
        unHighlightComponents(highlighted);
        highlight(sourceLayer, featureJson.id, false);
        setHighlighted([]);
        return;
      }

      unHighlightComponents(highlighted);
      highlight(sourceLayer, featureJson.id, true);
      setHighlighted([component]);
    },
    []
  );

  useEffect(() => {
    /** TODO: As `useLayers` is called in multiple places. `useLayerHighlight` is also called multiple times.
     * It attaches multiple event listeners and causes issues. So, create a listener only if nothing is attached yet.
     * Find a better solution for this */
    if (isEventListenerAdded || source !== UseLayerSource.TAKEOFF) {
      return () => {};
    }

    isEventListenerAdded = true;

    const listener = addMapClickEventListener(map, handleFeatureClick);

    return () => {
      isEventListenerAdded = false;
      map.un('click', listener);
    };
  }, []);
};

export default useLayerHighlight;
