import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../../../../store/types';
import { ToolKey } from '../../types';
import { NumericId } from '../../../../../../../modules/common/types';

interface UseSyncOnLayerChangeParams {
  activeTool: any;
  chosenLayerId: NumericId;
  handleSaveUnSyncedModifications: () => void;
}

const useSyncOnLayerChange = ({
  activeTool,
  chosenLayerId,
  handleSaveUnSyncedModifications,
}: UseSyncOnLayerChangeParams) => {
  const unSyncedModifiedChanges = useSelector<IStore, any[]>(
    (state) => state.map.modifyDraftEvents
  );

  useEffect(() => {
    /** Sync when chosen layer is changed */
    if (activeTool == ToolKey.EDIT && unSyncedModifiedChanges.length > 0) {
      handleSaveUnSyncedModifications();
    }
  }, [chosenLayerId]);
};

export default useSyncOnLayerChange;
