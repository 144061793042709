import React, { useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getFeatureStyleCatalogue } from '../../api';
import { useFeatureStyle } from '../../../../jotai/atoms/features';
import { FeatureStyleCatalogueMap } from '../../types/layer.types';

const useFetchFeatureStyleCatalogue = () => {
  const [catalogue, setFeatureStyleCatalogue] = useFeatureStyle();

  const { data, refetch } = useQuery(
    ['getFeatureStyleCatalogue'],
    getFeatureStyleCatalogue
  );

  const featureStyleCatalogueMap: FeatureStyleCatalogueMap = useMemo(() => {
    const map = {};

    if (catalogue) {
      catalogue.forEach((featureStyle) => {
        map[featureStyle.featureId] = featureStyle.styles.sort((a, b) => {
          if (!a.styleId && b.styleId) {
            return -1;
          }
          if (a.styleId && !b.styleId) {
            return 1;
          }
          if (a.styleId && b.styleId) {
            return a.styleId - b.styleId;
          }
          return -1;
        });
      });
    }
    return map;
  }, [catalogue]);

  useEffect(() => {
    if (data) {
      setFeatureStyleCatalogue(data);
    }
  }, [data, setFeatureStyleCatalogue]);

  return { refetch, data, featureStyleCatalogueMap };
};

export default useFetchFeatureStyleCatalogue;
