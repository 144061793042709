import { ApiTopologyWarning, LayerWithComponents } from '../api/types';
import { transformTopologyWarnings } from './takeoff/useLayers';
import { cloneDeep } from 'lodash';
import { useAtom } from 'jotai/react';
import { topologyWarningsAtom } from '../../../jotai/atoms/property/takeoff/layer-panel';
import { store } from '../../../jotai';
import { NumericId } from '../../common/types';

/** TODO: Update following function to hook */
export const updateTopologyWarnings = (
  data: ApiTopologyWarning,
  layers: LayerWithComponents[],
  /** If provided, completely resets the layer component warnings with the API warnings. Otherwise works in "append" mode */
  layerId?: NumericId
) => {
  const topologyWarnings = store.get(topologyWarningsAtom);
  const updatedWarnings = transformTopologyWarnings(data, layers);
  const mutatedWarnings = cloneDeep(topologyWarnings);

  if (layerId) {
    const layer = layers.find((_layer) => _layer.layerId === layerId);

    if (layer) {
      for (const component of layer.components) {
        delete mutatedWarnings.overlapping[component.componentId];
        delete mutatedWarnings.self_intersecting[component.componentId];
        delete mutatedWarnings.null_geometry[component.componentId];
        delete mutatedWarnings.duplicate[component.componentId];
      }
    }
  }

  for (const [componentId, overlappedComponents] of Object.entries(
    updatedWarnings.overlapping
  )) {
    mutatedWarnings.overlapping[componentId] = overlappedComponents;
  }

  for (const [componentId, selfIntersectingComponents] of Object.entries(
    updatedWarnings.self_intersecting
  )) {
    mutatedWarnings.self_intersecting[componentId] = selfIntersectingComponents;
  }

  for (const [componentId, nullGeometryComponents] of Object.entries(
    updatedWarnings.null_geometry
  )) {
    mutatedWarnings.null_geometry[componentId] = nullGeometryComponents;
  }

  for (const [componentId, duplicateComponents] of Object.entries(
    updatedWarnings.duplicate
  )) {
    mutatedWarnings.duplicate[componentId] = duplicateComponents;
  }

  store.set(topologyWarningsAtom, mutatedWarnings);
};
