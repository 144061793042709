import { useQuery } from '@tanstack/react-query';
import { getServiceItems } from '../../api';
import useAuth from '../../../auth/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../store/types';
import { setAllServiceItems } from '@/store/attributeFeature/actions';
import { ID } from '../../../common/types';
import { useEffect, useState } from 'react';
import { ServiceItem } from '../../api/types';

export const useServiceItems = () => {
  const { organizationId } = useAuth();
  const [isFetching, setIsFetching] = useState(false);
  const dispatch = useDispatch();

  const reduxServiceItems = useSelector<IStore, any>(
    (state) => state.feature.serviceItemsList
  );

  const orderId = useSelector<IStore, ID>(
    (state: any) => state.order.orderData?.orderNo
  );

  const fetchServiceItems = async () => {
    setIsFetching(true);
    const serviceItems = await getServiceItems(
      Number(orderId),
      organizationId!
    );
    dispatch(setAllServiceItems(serviceItems));

    setTimeout(() => setIsFetching(false), 300);
    return serviceItems;
  };

  const { data } = useQuery<ServiceItem[]>(
    ['serviceItems', orderId, organizationId],
    fetchServiceItems,
    {
      enabled: !!orderId && !!organizationId && !reduxServiceItems?.length,
      initialData: reduxServiceItems,
      onSuccess: (data) => {
        if (!reduxServiceItems?.length) {
          dispatch(setAllServiceItems(data));
        }
      },
    }
  );

  return { serviceItems: data ?? [], isFetching };
};
