import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Modal } from 'antd';
import { CSSTransition } from 'react-transition-group';
import EmViewPanel from '../../../../PlasmicComponents/EmViewPanel2';
import CreateNewView from '../ViewAction/CreateNewView';
import { showNotification } from '../../../../storybook/NotificationToast/NotificationToast';
import { ESTIMATION_STATUS, OrderView, ViewCategory } from '../types';
import { NOTIFICATIONS_TYPES } from '../../../../storybook/NotificationToast/types';
import { replaceParams } from '../../../../../helpers/utilities/linkUtils';
import { getAxiosInstance } from '../../../../../helpers/utilities/api-utils';
import { trackEvents } from '../../../../../helpers/utilities';
import { useDispatch } from 'react-redux';
import { updateViewsByOrderId } from '@/store/order/thunks';
import { NotifyError } from '@/helpers/notification-utils';
import ActionModal from '@elements/ActionModal';
import question from '@/assets/Question-mark.svg';
import * as API_ENDPOINTS from '@/helpers/constants/APIEndpoints';
import { NumericId, OrderStatus, ViewType } from '@/modules/common/types';
import { saveIfAnyUnSyncedModifications } from '../../../../containers/map-tools-panel/stories/Toolbar/MapToolBar/helpers';
import { ViewSelectionPanelItem } from './types';
import { useElementPositioningContext } from '../../../../../Contexts/ElementPositioningContext';
import { WidgetBarTabs } from '../../../../types';
import { useWidgetBarTabContext } from '../../../../../modules/property/hooks/useWidgetBar';
import { extractNextViewName, vhMinusPx } from '../helpers';
import { useEstimationViewContext } from '../../../../../modules/property/context/EstimationView/context';
import { getPlasmicOrderStatus } from '../../utils/helpers';
import { CreateOrderEvt } from '../../../../../segment';
import { useRole } from '@/modules/auth/guards/RoleGuard';
import { useSwitchView } from '../../../../../modules/property/hooks/view/useSwitchView';
import useViewUtility from '../../../../../modules/property/hooks/view/useViewUtility';
import { HierarchicalView } from '../../../../../modules/property/types/view.types';
import { useDeleteView } from '../../../../../modules/property/hooks/view/useDeleteView';
import useViewList from '../../../../../jotai/atoms/views/useViewList';
import omit from 'lodash/omit';
import { useFetchViewList } from '../../../../../modules/property/hooks/view/useFetchViewList';
import {
  renameView,
  setDefaultView,
} from '../../../../../modules/property/api';
import { setOrderViewModal } from '../../../../../store/order/actions';
import { currentView as setCurrentViewData } from '../../../../../store/order/actions';
import useCurrentView from '../../../../../jotai/atoms/views/useCurrentView';
import './styles.less';
import { setBaseViewEditRestrictionModalData } from '../../../../../store/map-editor/actions';
import { useSelector } from 'react-redux';
import { BlockViewCreation } from '@siterecon/sr-component/dist/sr-component-library';
import useBetaRelease from '../../../../../modules/common/guards/BetaRelease/useBetaRelease';
import { BetaFeature } from '../../../../../modules/common/types';
import { IMapEditorAddLayerModal, IMapEditorBaseViewEditRestrictionModal, MapEditorModalType } from '../../../../../store/map-editor';
import { IStore } from '../../../../../store/types';

interface ViewSelectionPanelProps {
  isOpen: boolean;
  onClose: () => void;
  createNewView: (viewRes: any, isSelectedBaseView: boolean) => void;
  onChange: (view: OrderView) => void;
  onDelete: (view: OrderView) => void;
  orderViews: Array<OrderView>;
  setDataLoading: (arg: boolean) => void;
  currentView: OrderView;
  showAddNewViewAlertModal: boolean;
  setShowAddNewViewAlertModal: (arg: boolean) => void;
  orderStatus: OrderStatus;
  reloadViews: () => void;
  toggleViewpanelPin: (val: Boolean) => void;
  isViewpanelPinned: boolean;
  openFPanel: boolean;
  isShareLinkPage: boolean;
  isEstimationView: boolean;
  estimationStatus: string,
  isEstimationOrder: boolean,
  viewDropdownRef: React.RefObject<HTMLDivElement>;
}

const ViewSelectionPanel: React.FC<ViewSelectionPanelProps> = ({
  isOpen,
  onClose,
  createNewView,
  orderViews,
  setDataLoading,
  currentView,
  onChange,
  showAddNewViewAlertModal,
  setShowAddNewViewAlertModal,
  onDelete,
  orderStatus,
  estimationStatus,
  isEstimationOrder,
  reloadViews,
  toggleViewpanelPin,
  isViewpanelPinned,
  openFPanel,
  isShareLinkPage,
  isEstimationView,
  viewDropdownRef
}) => {

  const nodeRef = useRef<HTMLDivElement>(null);
  const { viewList } = useViewList();
  const [editViewId, setEditViewId] = useState<number>(0)
  const [EdititedNewName, setEdititedNewName] = useState('')
  const [isClickedOutside, setIsClickedOutside] = useState<boolean>(false)
  const [hasUserTyped, setHasUserTyped] = useState(false);

  const [viewsToRender, setViewsToRender] = useState<ViewSelectionPanelItem[]>(
    []
  );
  const { getViewByViewId } = useViewUtility();
  const { refetch } = useFetchViewList();
  const currentUserName = useSelector((state) => state.user.info.first_name);


  const [showCopyViewModal, setShowCopyViewModal] = useState(false);
  const [newCopiedViewName, setNewCopiedViewName] = useState('');
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [selectedViewForClone, setSelectedViewForClone] = useState<
    number | undefined
  >();
  const [showDeleteViewModal, setShowDeleteViewModal] = useState(false);
  const [toBeDeletedView, setToBeDeletedView] = useState<OrderView | null>(
    null
  );
  const [showViewCreationBlockedModal, setShowViewCreationBlockedModal] = useState(false);

  const { role: userRole, isEstimator } = useRole();

  const { setCurrentView } = useCurrentView();

  const { setIsViewPinned, setNormalLayerPanelOpen } =
    useElementPositioningContext();
  const {
    handleActiveWidgetBarTabs,
    activeTabs,
    handleWidgetBarTabChange,
    selectedTab,
  } = useWidgetBarTabContext();

  const { setData: setServiceItemsData } = useEstimationViewContext();
  const { switchView } = useSwitchView();
  const { deleteView } = useDeleteView();

  const axiosInstance = getAxiosInstance();
  const dispatch = useDispatch();

  const getSelectedViewForClone = useCallback((): number | undefined => {
    if (currentView?.viewType === ViewType.STATIC) {
      return currentView.viewId;
    }

    return orderViews.find((view) => view?.viewType === ViewType.STATIC)
      ?.viewId;
  }, [currentView, orderViews]);

  useEffect(() => {
    setViewsToRender(getViewsListToRender(viewList, currentUserName, userRole, isEstimationOrder, estimationStatus));
  }, [viewList]);

  useEffect(() => {
    if (openFPanel && currentView) {
      setNormalLayerPanelOpen(currentView.viewType === ViewType.STATIC);
    }
  }, [openFPanel]);

  useEffect(() => {
    if (isOpen) {
      handleActiveWidgetBarTabs([WidgetBarTabs.VIEWS, ...(activeTabs || [])]);
    }
  }, [isOpen]);

  useEffect(() => {
    setIsViewPinned(isViewpanelPinned);
  }, [isViewpanelPinned]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        nodeRef.current &&
        nodeRef.current instanceof Node &&
        !nodeRef.current.contains(event.target as Node) && !(viewDropdownRef.current && viewDropdownRef.current.contains(event.target as Node)) &&
        /** Pass the classname of element on click of which you dont want the view selection panel to close */
        !(event.target as HTMLElement).classList.contains(
          'ant-dropdown-menu-item'
        ) &&
        !(event.target as HTMLElement).classList.contains('vp-kb-menu-item')
      ) {
        const selectedView = getViewByViewId(editViewId)
        if (hasUserTyped) {
          setIsClickedOutside(true)
          if (selectedView) performEditViewName(selectedView, EdititedNewName)
        }
        if (!isViewpanelPinned) {
          onClose();
          if (activeTabs?.includes(WidgetBarTabs.VIEWS)) {
            // pass all tabs exceps views
            handleActiveWidgetBarTabs(
              activeTabs.filter((tab) => tab !== WidgetBarTabs.VIEWS)
            );
          }
        }

      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose, editViewId, EdititedNewName, hasUserTyped]);

  const isBaseViewEditRestrictionModalVisible = useSelector<IStore, boolean>(
    (state) => state.mapEditor.modals[MapEditorModalType.BASE_VIEW_EDIT_RESTRICTION].open
  );

  useEffect(() => {
    if (!isBaseViewEditRestrictionModalVisible) {
      setShowAddNewViewAlertModal(false);
    }
  }, [isBaseViewEditRestrictionModalVisible]);

  useEffect(() => {
    setNewCopiedViewName(checkForDraftName(orderViews));
    setSelectedViewForClone(getSelectedViewForClone());
    dispatch(setBaseViewEditRestrictionModalData({ open: showAddNewViewAlertModal }));
  }, [
    currentView?.viewId,
    currentView?.viewType,
    showAddNewViewAlertModal,
    orderStatus,
  ]);

  const handleViewClick = async (view: HierarchicalView) => {
    if (
      view.viewId !== currentView.viewId &&
      [ViewType.ESTIMATION, ViewType.DYNAMIC].includes(view.viewType)
    )
      setServiceItemsData(null);

    if (saveIfAnyUnSyncedModifications()) {
      /** If any unsyced modifications are there, we need to wait for some time to sync them before changing the view */
      await new Promise((r) => setTimeout(r, 1000));
    }

    switchView(view.viewId);
  };

  const setDataLoadingHandler = (arg) => {
    setIsDataLoading(arg);
    setDataLoading(arg);
  };

  const handleSetDefaultView = async (view: HierarchicalView) => {
    trackEvents(CreateOrderEvt.ViewPanelViewSetAsDefault, {
      orderId: view.orderId,
      viewId: view.viewId,
      isBaseView: view.isBaseView,
    });

    try {
      setDataLoading(true);
      await setDefaultView(view.orderId, view.viewId);
      await refetch();

      showNotification(
        NOTIFICATIONS_TYPES.SUCCESS,
        'Default view successfully updated'
      );
    } catch (error) {
      console.error('Error while setting default view:', error);
    } finally {
      setDataLoading(false);
    }
  };

  const handleCreateNewView = (
    viewCatergory?: ViewCategory,
    generateViewName?: boolean
  ) => {
    let generatedViewName = newCopiedViewName; // Default to the current state value
    let finalSelectedViewForClone = selectedViewForClone;

    // Generate a new view name if needed also update finalSelectedViewForClone
    // since weare directly creating view without selecting base view from modal from the current view
    if (generateViewName) {
      generatedViewName = extractNextViewName(orderViews, viewCatergory); // Generate the new view name
      setNewCopiedViewName(generatedViewName); // Update the state (optional if you only use the local variable)
      if (viewCatergory === ViewCategory.ESTIMATION && currentView.viewId) {
        finalSelectedViewForClone = currentView.linkedView;
      }
    }

    // Use the local variable `generatedViewName` for further logic
    if (!generatedViewName || generatedViewName.length === 0) {
      showNotification(NOTIFICATIONS_TYPES.WARNING, 'Please enter View Name');
      return;
    }
    let reqData = {
      name: generatedViewName,
    };

    setDataLoadingHandler(true);
    let isSelectedBaseView = false;
    let items = orderViews
      .filter((e) => e.viewId === finalSelectedViewForClone)
      .map((x) => x.isBaseView);
    if (items) {
      isSelectedBaseView = items[0];
    }

    let url: string;
    if (viewCatergory === ViewCategory.ESTIMATION) {
      url = API_ENDPOINTS.CLONE_ESTIMATION_VIEW;
      setServiceItemsData(null);
    } else {
      url = API_ENDPOINTS.CLONE_VIEW;
    }

    axiosInstance
      .post<OrderView>(
        replaceParams(url, {
          ':viewId': finalSelectedViewForClone,
        }),
        reqData,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      )
      .then(async (res) => {
        trackEvents(CreateOrderEvt.ViewPanelNewView, {
          linkedView: selectedViewForClone,
          viewType:
            viewCatergory === ViewCategory.ESTIMATION ? 'estimation' : 'static',
        });

        await refetch();
        if (res.data.viewId) {
          switchView(res.data.viewId);
        }
        setNewCopiedViewName('');
        dispatch(updateViewsByOrderId(currentView.orderId));
        createNewView(res.data, isSelectedBaseView);

        if (viewCatergory === ViewCategory.ESTIMATION) {
          handleWidgetBarTabChange(WidgetBarTabs.ESTIMATION);
        } else {
          handleWidgetBarTabChange(WidgetBarTabs.LAYERS);
        }
      })
      .catch((error) => {
        if (error.errorCode === 'SR-BIZ-ERROR-22') {
          NotifyError(error.errorDesc);
        }
      })
      .finally(() => {
        setDataLoadingHandler(false);
        setShowCopyViewModal(false);
        setShowAddNewViewAlertModal(false);
      });
  };

  const handleDeleteViewClick = (viewId: NumericId) => {
    const view = getViewByViewId(viewId);
    if (!view) return;

    if (view.isBaseView) {
      showNotification(NOTIFICATIONS_TYPES.ERROR, "Base view can't be deleted");
      return;
    }

    setShowDeleteViewModal(true);
    setToBeDeletedView(view);
  };

  const performEditViewName = async (
    view: HierarchicalView,
    newViewName: string
  ) => {
    trackEvents(CreateOrderEvt.ViewPanelRenameView, {
      orderId: view.orderId,
      viewId: view.viewId,
      isBaseView: view.isBaseView,
      oldName: view.name,
      newName: newViewName,
    });

    try {
      setDataLoadingHandler(true);
      await renameView(view.orderId, view.viewId, {
        name: newViewName,
      });
      const updatedView = { ...view, name: newViewName };
      dispatch(setCurrentViewData(updatedView));
      setCurrentView(updatedView);
      await refetch();

      showNotification(
        NOTIFICATIONS_TYPES.SUCCESS,
        'Information updated successfully'
      );
    } catch {
      console.error('Error while renaming view:', error);
    } finally {
      setDataLoadingHandler(false);
      setHasUserTyped(false);
    }
  };

  const performDeleteView = async () => {
    if (!toBeDeletedView) return;

    trackEvents(CreateOrderEvt.ViewPanelDeleteView, {
      orderId: toBeDeletedView?.orderId,
      viewId: toBeDeletedView?.viewId,
      isBaseView: toBeDeletedView?.isBaseView,
    });

    try {
      setDataLoadingHandler(true);
      await deleteView(toBeDeletedView!.viewId);

      if (toBeDeletedView.isDefault) {
        handleSetDefaultView(viewList.find((view) => view.isBaseView)!);
      }
    } finally {
      setDataLoadingHandler(false);
      setShowDeleteViewModal(false);
      setToBeDeletedView(null);
    }
  };

  const deleteViewModal = (
    <ActionModal
      isVisible={showDeleteViewModal}
      setModalVisible={setShowDeleteViewModal}
      title={'Confirmation'}
      icon={question}
      content={
        <p>
          Deleting <strong>"{toBeDeletedView?.name}"</strong> will delete all
          the notes, tags, and other elements associated to this view of the
          property. Do you want to delete this view ?
        </p>
      }
      onOK={performDeleteView}
      loading={isDataLoading}
    />
  );

  const checkForDraftName = (viewList: Array<OrderView>) => {
    let draftCount = 1;
    if (viewList.length) {
      viewList.forEach((val, index) => {
        if (
          val.name.substring(0, 5) === 'Draft' ||
          val.name.substring(0, 5) === 'draft'
        ) {
          draftCount = draftCount + 1;
        }
      });
    }

    return `Draft ${draftCount}`;
  };

  const copyViewModal = (
    <CreateNewView
      isDataLoading={isDataLoading}
      orderViews={viewList.map((view) => omit(view, 'subViews'))}
      currentView={currentView}
      visiblity={showCopyViewModal}
      setVisibility={setShowCopyViewModal}
      handleCreateNewView={handleCreateNewView}
      selectedViewForClone={selectedViewForClone}
      setSelectedViewForClone={setSelectedViewForClone}
      newCopiedViewName={newCopiedViewName}
      setNewCopiedViewName={setNewCopiedViewName}
    />
  );

  const handleViewSelectionClick = (viewId: number) => {
    const selectedView = orderViews.find((view) => view.viewId === viewId);

    trackEvents(CreateOrderEvt.ViewPanelViewChange, {
      orderId: selectedView?.orderId,
      viewId: viewId,
      isBaseView: selectedView?.isBaseView,
    });

    if (selectedView) {
      if (selectedTab === WidgetBarTabs.ESTIMATION) {
        if (selectedView.viewType === ViewType.ESTIMATION) {
          // handleStaticViewClick();
          handleWidgetBarTabChange(WidgetBarTabs.ESTIMATION, selectedTab);
        } else {
          handleWidgetBarTabChange(WidgetBarTabs.LAYERS, selectedTab);
        }
      }

      handleViewClick(selectedView);
    }
    if (!isViewpanelPinned) onClose();
  };

  const isEstimationsWorkspace = useBetaRelease(BetaFeature.Estimations);

  const createNewViewRoot = (estimationStatus, isUserEstimator) => {
    let canCreateViews = true;

    if (isEstimationsWorkspace && isEstimationView && isEstimationOrder === true) {
      if (estimationStatus === ESTIMATION_STATUS.SELF_ESTIMATED ||
        estimationStatus === ESTIMATION_STATUS.ESTIMATION_DELIVERED ||
        isUserEstimator) {
        canCreateViews = true;
      } else {
        canCreateViews = false
      }
    }

    if (canCreateViews) {
      setShowCopyViewModal(true);
    } else {
      setShowViewCreationBlockedModal(true);
    }
  };

  return createPortal(
    <>
      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames='view-selection-menu'
        unmountOnExit
      >
        <div
          className='view-selection-menu'
          ref={nodeRef}
          style={{ border: isViewpanelPinned ? '0.5px solid #DDD' : 'none' }}
        >
          <div className='view-selection-menu-content h-100'>
            <EmViewPanel
              isEstimationWorkspace={isEstimationsWorkspace}
              isSharedLinkPage={isShareLinkPage}
              isPricingInProgress={estimationStatus === 'estimation_in_progress'}
              viewPanelHeight={vhMinusPx(100, 98)}
              viewList2={
                viewsToRender.map((item) => ({
                  viewId: item.viewId,
                  isBaseView: item.isBaseView,
                  isDefault: item.isDefault,
                  viewName: item.viewName,
                  linkedViews: item.linkedViews,
                  isSIAssignmentLocked: item.isSIAssignmentLocked,
                  isLockedByUser: item.isLockedByUser,
                  isInternalView: item.isInternalView,
                  isEditable: item.isEditable,
                  userRole: userRole,
                })) as ViewSelectionPanelItem[]
              }
              onCreateNewView={() => createNewViewRoot(estimationStatus, isEstimator)}
              onPinViewPanel={() => {
                if (isViewpanelPinned) {
                  trackEvents(CreateOrderEvt.ViewPanelViewUnpinned);
                } else {
                  trackEvents(CreateOrderEvt.ViewPanelViewPinned);
                }
                toggleViewpanelPin(!isViewpanelPinned);

                if (isViewpanelPinned) {
                  onClose();
                  if (activeTabs?.includes(WidgetBarTabs.VIEWS)) {
                    handleActiveWidgetBarTabs(
                      activeTabs.filter((tab) => tab !== WidgetBarTabs.VIEWS)
                    );
                  }
                }
              }}
              isViewPanelPinned={isViewpanelPinned}
              onViewChange={(viewId: number) => {
                const selectedView = getViewByViewId(viewId);

                trackEvents(CreateOrderEvt.ViewPanelViewChange, {
                  orderId: selectedView?.orderId,
                  viewId: viewId,
                  isBaseView: selectedView?.isBaseView,
                });

                if (selectedView?.viewType === ViewType.ESTIMATION) {
                  handleWidgetBarTabChange(WidgetBarTabs.ESTIMATION, selectedTab);
                } else {
                  handleWidgetBarTabChange(WidgetBarTabs.LAYERS, selectedTab);
                }

                if (selectedView) {
                  handleViewClick(selectedView);
                }

                if (!isViewpanelPinned) onClose();
              }}
              onViewDelete={handleDeleteViewClick}
              onSetDefault={(viewId: number) => {
                const selectedView = getViewByViewId(viewId);
                if (selectedView) {
                  handleSetDefaultView(selectedView);
                }
              }}
              selectedViewIndex={currentView?.viewId}
              defaultId={orderViews.find((view) => view.isDefault)?.viewId}
              onRenameView={(viewId: number, newName: string) => {
                const selectedView = getViewByViewId(viewId);
                if (selectedView) performEditViewName(selectedView, newName);
              }}
              orderStatus={getPlasmicOrderStatus(orderStatus)}
              estimationStatus={estimationStatus}
              userRole={userRole}
              onRenameInputChange={(viewId: number, newName: string) => {
                setEditViewId(viewId)
                setEdititedNewName(newName)
                setHasUserTyped(true);
              }}
              isClickedOutside={isClickedOutside}
              onClickOutside={(value: boolean) => {
                setIsClickedOutside(value)
              }}
            />
          </div>
        </div>
      </CSSTransition>
      {deleteViewModal}
      {copyViewModal}

      {showViewCreationBlockedModal &&
        <Modal
          closable={false}
          visible={showViewCreationBlockedModal}
          centered
          footer={null}
          bodyStyle={{ padding: 0 }}
          maskStyle={{ background: 'rgba(0, 0, 0, 0.5)' }}
        >
          <BlockViewCreation onOk={() => { setShowViewCreationBlockedModal(false) }} />
        </Modal>}
    </>,
    document.body
  );
};

const getViewsListToRender = (views: HierarchicalView[], currentUserName: string, userRole: string, isEstimationOrder, estimationStatus) => {

  const getIsEditableStatus = (userRole, view) => {
    if (userRole === 'sr_estimator' && view.isInternalView === true) {
      return true
    }
    if (userRole === 'sr_estimator') {
      return false
    }
    if (view.isEditable) {
      return true
    }
    if (userRole !== 'sr_estimator' && view.isSIAssignmentLocked === false) {
      return true
    }
    return view.isEditable;
  }

  const getViewData = (view: HierarchicalView) => ({
    viewId: view.viewId,
    isBaseView: view.isBaseView,
    isDefault: view.isDefault,
    viewName: view.name,

    isSIAssignmentLocked: view.isSIAssignmentLocked,
    estimationSheetStatus: view.estimationSheetStatus,
    isInternalView: view.isInternalView,
    isEditable: getIsEditableStatus(userRole, view),
    isPricingLocked: view.isPricingLocked,
    siLockDetails: view.siLockDetails,
    isLockedByUser: view.siLockDetails?.lockedBy === currentUserName,
    userRole,
    isDisabled: userRole !== 'sr_estimator' && isEstimationOrder && ['estimation_in_progress', 'estimation_pending'].includes(estimationStatus),
    // const isEstimatedByPricingTeam = orderTileData.estimationStatus === 'estimation_delivered' && isPricingLocked === true && siLockDetails?.lockSource === 'pricing_team';
    tag: '', // will do it later // isEstimatedByPricingTeam ? 'pricingDone' : '',
  });

  return views.map((view) => {
    return {
      ...getViewData(view),
      linkedViews: view.subViews.map(getViewData),
    };
  });
};

export default ViewSelectionPanel;
