import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import { IStore } from '../../../../store/types';
import {
  IMapEditorBaseViewEditRestrictionModal,
  MapEditorModalType,
} from '../../../../store/map-editor';
import { ViewType } from '../../../../modules/common/types';
import { setBaseViewEditRestrictionModalData } from '../../../../store/map-editor/actions';
import { useMapEditor } from '../../../../modules/property/hooks/useMapEditor';
import EodTakeoffEstimationNewViewCreation from '../../../PlasmicComponents/EodTakeoffEstimationNewViewCreation';
import { HierarchicalView } from '../../../../modules/property/types/view.types';
import useViewSelectionOrCreationUtil from '../../../../modules/property/hooks/useViewSelectionOrCreationUtil';
import { showNotification } from '../../../storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../../storybook/NotificationToast/types';
import useViewList from '../../../../jotai/atoms/views/useViewList';
import { useSwitchView } from '../../../../modules/property/hooks/view/useSwitchView';
import './style.less';
import { ESTIMATION_STATUS } from './types';
import { BlockViewCreation } from '@siterecon/sr-component/dist/sr-component-library';
import { useCanCreateViews } from '../../../../modules/property/hooks/view/useCanCreateViews';
import { useRole } from '../../../../modules/auth/guards/RoleGuard';

const MapEditorBaseViewEditRestrictionModal = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showViewCreationBlockedModal, setShowViewCreationBlockedModal] = React.useState(false);
  const data = useSelector<IStore, IMapEditorBaseViewEditRestrictionModal>(
    (state) =>
      state.mapEditor.modals[MapEditorModalType.BASE_VIEW_EDIT_RESTRICTION]
  );

  const { isGuest } = useRole();

  const currentUserName = useSelector(
    (state: any) => state.user.info.first_name
  );

  const { handleCreateNewView } = useViewSelectionOrCreationUtil();

  const canCreateViews = useCanCreateViews();

  const { viewList } = useViewList();
  const { switchView } = useSwitchView();

  const currentView = useSelector<IStore, HierarchicalView>(
    (state) => state.order.currentViewData
  );

  function flattenViews(views) {
    let result = [];

    function processView(view) {
      // Create a shallow copy of the view without subViews to avoid mutation
      let { subViews, ...rest } = view;

      // Add the current view to the result
      result.push({
        ...rest,
        subViews: [],
      });

      // Recursively process each subView
      if (subViews && subViews.length > 0) {
        subViews.forEach((subView) => processView(subView));
      }
    }

    // Process each view in the input array
    views.forEach((view) => processView(view));

    return result;
  }

  const finalViewList = flattenViews(viewList);
  const currentViewData = {
    ...currentView,
    isLockedByUser: currentView?.siLockDetails?.lockedBy === currentUserName,
  };

  const getOrderViewsList = () => {
    let newOrderViews = [];
    finalViewList.map((view) => {
      const isLockedByUser = view?.siLockDetails?.lockedBy === currentUserName;
      view.isLockedByUser = isLockedByUser;
      newOrderViews.push(view);
    });
    return newOrderViews;
  };

  if (!data.open || !currentViewData) {
    return null;
  }

  const handleModalClose = () => {
    dispatch(
      setBaseViewEditRestrictionModalData({
        open: false,
      })
    );
  };

  const handleCreateDraftView = async (makeCopy) => {
    setLoading(true);

    await handleCreateNewView(currentView.viewType, makeCopy);

    showNotification(NOTIFICATIONS_TYPES.SUCCESS, 'View created successfully!');

    handleModalClose();
    setLoading(false);
  };

  return (
    <Modal
      visible={data.open}
      closable={false}
      footer={null}
      className='map-editor-base-view-edit-restriction-modal'
    >
      {!canCreateViews ? (
        <BlockViewCreation onOk={handleModalClose} />
      ) : (
        <EodTakeoffEstimationNewViewCreation
          isLoading={loading}
          onCancel={handleModalClose}
          onCreateNewView={() => handleCreateDraftView(false)}
          onCreateCopy={() => handleCreateDraftView(true)}
          currentView={currentViewData}
          isTakeoff={currentView.viewType === ViewType.STATIC}
          linkedView={currentView.linkedView}
          viewsData={getOrderViewsList()}
          onSwitchToExistingView={(data) => {
            switchView(data.viewId);
            handleModalClose();
          }}
          isGuestUser={isGuest}
        />
      )}
    </Modal>
  );
};

export default MapEditorBaseViewEditRestrictionModal;
