import { MapCommandFunction, UndoableMapCommandFunction } from '../types';
import { copyComponent } from '../../api';
import { NumericId } from '../../../common/types';
import {
  addLayerComponent,
  removeLayerComponent,
} from '../../hooks/takeoff/useLayerRender';
import { Store } from '@/store';
import { addLayerComponents } from '@/store/order/actions';

const OFFSET = 0.00006;

export interface CommandOptions {
  componentIds: NumericId[];
  layerId: NumericId;
}

export const DuplicateComponentCommand: UndoableMapCommandFunction<
  CommandOptions
> = (map, viewId) => {
  let _options: CommandOptions;

  let _duplicatedComponentIds: NumericId[] = [];

  return {
    init: (options) => {
      _options = options;
    },

    execute: async () => {
      if (!_options) return;
      const { componentIds, layerId } = _options;

      try {
        const { data: response } = await copyComponent(viewId, layerId, {
          componentId: componentIds,
          targetLayerId: layerId,
          offsetX: OFFSET,
          offsetY: OFFSET,
        });

        for (const component of response.data) {
          _duplicatedComponentIds.push(component.componentId);
          addLayerComponent(layerId, component);
        }

        Store.dispatch(
          addLayerComponents({
            layerId: layerId,
            components: response.data,
          })
        );
      } catch (error) {
        console.error('Error while duplicating the component');
      }
    },

    unExecute: async () => {
      if (!_options || _duplicatedComponentIds.length === 0) return;

      /** Add API to Remove components */
      for (const componentId of _duplicatedComponentIds) {
        removeLayerComponent(_options.layerId, { componentId });
      }

      _duplicatedComponentIds = [];
    },
  };
};
