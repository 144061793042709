import { NumericId } from '../../../common/types';
import { LayerWithComponents, SingleComponent } from '../../api/types';
import OlMap from 'ol/Map';
import MapContext from '@/components/pages/project/MapContext';
import { useContext, useRef } from 'react';
import VectorLayer from 'ol/layer/Vector';
import {
  hoveredFeatureStyle,
  pointHoveredFeatureStyle,
  updatePathStyleFunction,
} from '@/helpers/mapGlobals/styles';
import { Feature } from 'ol';
import { StyleLike } from 'ol/style/Style';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../store/types';
import { FeatureType } from '../../../../components/feature-attributes/types';
import { Store } from '@/store';
import { mapLayers } from '../../helpers/layer.data';
import { highlight } from './useLayerHighlight';

export interface HoverChangeFunctionPayload {
  id: NumericId | 0;
  info: 'serviceId' | 'componentId' | '';
}

export type HoverChangeFunction = (data: HoverChangeFunctionPayload) => void;

interface HoveredFeature {
  feature: Feature;
  style: StyleLike;
}

export let recentSelectedItemIds: NumericId[] = [];

export const setRecentSelectedItemIds = (id: NumericId | NumericId[]) => {
  if (!Array.isArray(id)) {
    id = [id];
  }

  recentSelectedItemIds = id;
};

const getHighlightedComponents = () => Store.getState().order.highlighted ?? [];

export const useLayerItemHover = () => {
  const mapRef: OlMap = useContext(MapContext);
  const dispatch = useDispatch();

  const hoveredFeatures = useRef<HoveredFeature[]>([]);
  const layers = useSelector<IStore, LayerWithComponents[]>(
    (state) => state.order.layerList
  );

  const handleLayerHover = (id: NumericId) => {
    if (!layers) return;

    const layer = layers.find((_layer) => _layer.layerId === id);

    if (!layer) return;

    handleHoverOut();

    const componentIds = layer.components.map(
      (component) => component.componentId
    );

    const hovered: HoveredFeature[] = [];

    /** We need to update the hover style for all the features under this component */
    mapRef.getLayers().forEach((layer) => {
      if (!layer.getClassName().includes('SrLayer')) {
        return;
      }

      const source = (layer as VectorLayer).getSource();

      source.getFeatures().forEach((feature) => {
        const featureComponentId =
          feature.getId() ?? feature.getProperties().componentId;

        if (!componentIds.includes(Number(featureComponentId))) {
          return;
        }

        hovered.push({ feature, style: feature.getStyle()! });
        setFeatureHoveredStyle(feature);
      });
    });

    hoveredFeatures.current = hovered;
  };

  const handleComponentHover = (id: NumericId) => {
    if (!layers) return;

    if (recentSelectedItemIds.includes(id)) {
      handleHoverOut();
      return;
    }

    handleHoverOut();

    mapRef.getLayers().forEach((layer) => {
      if (!layer.getClassName().includes('SrLayer')) {
        return;
      }

      const source = (layer as VectorLayer).getSource();

      source.getFeatures().forEach((feature) => {
        const featureComponentId =
          feature.getId() ?? feature.getProperties().componentId;

        if (Number(featureComponentId) === id) {
          hoveredFeatures.current = [{ feature, style: feature.getStyle()! }];
          setFeatureHoveredStyle(feature);
        }
      });
    });
  };

  const handleHoverOut = () => {
    setRecentSelectedItemIds([]);

    const highlightedComponents = getHighlightedComponents();
    const highlightedComponentIds = highlightedComponents.map(
      (component) => component.componentId
    );

    const highlightedComponentsMap: Record<NumericId, SingleComponent> = {};

    for (const component of highlightedComponents) {
      highlightedComponentsMap[component.componentId] = component;
    }

    for (const { feature, style } of hoveredFeatures.current) {
      /** When removing style for hover out, we need to check if the component is highlighted with click action. If yes, we don't want to apply hover out style */

      const componentId =
        feature.getProperties().componentId ?? feature.getId();

      if (
        highlightedComponentIds.includes(componentId)
        //  || getTakeoffActionCenterType() === TakeoffActionCenterType.LAYER
      ) {
        const component = highlightedComponentsMap[componentId];
        if (mapLayers[component.layerId]) {
          highlight(mapLayers[component.layerId], componentId, true);
        }

        continue;
      }

      feature.setStyle(undefined);
    }
  };

  const handleHover: HoverChangeFunction = ({ id, info }) => {
    if (!id) {
      handleHoverOut();
      return;
    }

    if (info === 'serviceId') {
      handleLayerHover(id);
      return;
    }

    handleComponentHover(id);
  };

  return { handleHover };
};

const getFeatureStyle = (feature: Feature) => {
  const layerId = feature.getProperties().layerId;
  const featureStyle = feature.getProperties().style ?? feature.getStyle();

  if (!layerId || !mapLayers[layerId]) {
    return featureStyle;
  }

  const olLayer = mapLayers[layerId];
  return olLayer.getProperties().style ?? featureStyle;
};

const setFeatureHoveredStyle = (feature: Feature) => {
  const style = getFeatureStyle(feature);

  const featureType: FeatureType =
    feature.getProperties().featureType?.toLowerCase() ?? null;

  if (featureType === FeatureType.POINT) {
    feature.setStyle(pointHoveredFeatureStyle(style));
    return;
  }

  if (featureType === FeatureType.PATH) {
    updatePathStyleFunction([feature], {
      ...style,
      color: hoveredFeatureStyle.getStroke().getColor(),
      fillColor: hoveredFeatureStyle.getFill().getColor(),
      arrowColor: hoveredFeatureStyle.getStroke().getColor(),
    });
  }

  feature.setStyle(hoveredFeatureStyle);
};
