import OlMap from 'ol/Map';
import { NumericId } from '../../common/types';
import { DuplicateComponentCommand } from './component/DuplicateComponentCommand';
import { DeleteComponentCommand } from './component/DeleteComponentCommand';
import { CopyLayerCommand } from './layer/CopyLayerCommand';
import { DeleteLayerCommand } from './layer/DeleteLayerCommand';
import { useSwitchViewCommand } from './view/useSwitchViewCommand';
import { useChangeToolCommand } from './tools/useChangeToolCommand';
import { MoveComponentToLayerCommand } from './component/MoveComponentToLayerCommand';
import { ChangeLayerStyleCommand } from './layer/ChangeLayerStyleCommand';

type BaseOptions = Record<string, any>;

export enum CommandType {
  DUPLICATE = 'duplicate',
  DELETE = 'delete',
  COPY_LAYER = 'copyLayer',
  DELETE_LAYER = 'deleteLayer',
  SWITCH_VIEW = 'switchView',
  CHANGE_TOOL = 'changeTool',
  MOVE_TO_LAYER = 'moveToLayer',
  CHANGE_LAYER_STYLE = 'changeLayerStyle',
}

export interface MapCommand<Options = BaseOptions> {
  init: (options: Options) => void;

  /** Defines what should happen when this command is executed */
  execute: () => Promise<void>;
}

export interface UndoableMapCommand<Options = BaseOptions>
  extends MapCommand<Options> {
  /** Defines what should happen when this command is undo-ed */
  unExecute: () => Promise<void>;
}

export type MapCommandFunction<Options extends BaseOptions = BaseOptions> = (
  map: OlMap,
  viewId: NumericId
) => MapCommand<Options>;

export type MapCommandHook<Options extends BaseOptions = BaseOptions> = (
  map: OlMap,
  viewId: NumericId
) => MapCommand<Options>;

export type UndoableMapCommandFunction<
  Options extends BaseOptions = BaseOptions
> = (map: OlMap, viewId: NumericId) => UndoableMapCommand<Options>;

export type MapCommandStack = UndoableMapCommand<any>[];

interface MapCommandFunctionMap {
  [CommandType.DUPLICATE]: typeof DuplicateComponentCommand;
  [CommandType.DELETE]: typeof DeleteComponentCommand;
  [CommandType.COPY_LAYER]: typeof CopyLayerCommand;
  [CommandType.DELETE_LAYER]: typeof DeleteLayerCommand;
  [CommandType.SWITCH_VIEW]: typeof useSwitchViewCommand;
  [CommandType.CHANGE_TOOL]: typeof useChangeToolCommand;
  [CommandType.MOVE_TO_LAYER]: typeof MoveComponentToLayerCommand;
  [CommandType.CHANGE_LAYER_STYLE]: typeof ChangeLayerStyleCommand;
}

export type ArgumentTypes<F extends Function> = F extends (
  ...args: infer A
) => any
  ? A
  : never;

export type ExecuteFunction = <T extends CommandType>(
  type: T,
  args: ArgumentTypes<ReturnType<MapCommandFunctionMap[T]>['init']>[0],

  /** Set this to `false` when the command supports undo but we need to explicitly define it should not be added to the UNDO stack */
  undoable?: boolean
) => Promise<void>;
