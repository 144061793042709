import { useMemo } from 'react';
import { ESTIMATION_STATUS } from '../../../../components/pages/project/projectComponents/types';
import { useSelector } from 'react-redux';
import { useRole } from '../../../auth/guards/RoleGuard';
import useBetaRelease from '../../../common/guards/BetaRelease/useBetaRelease'
import { BetaFeature } from '../../../common/types';

export const useCanCreateViews = () => {
    const estimationStatus = useSelector((state) => state.order.orderData.estimationStatus);
    const isEstimationOrder = useSelector((state) => state.order.orderData.isEstimationOrder);
    const { isEstimator } = useRole();
    const isEstimationsWorkspace = useBetaRelease(BetaFeature.Estimations); 

    if (!isEstimationsWorkspace) return true;

    const canCreateViews = useMemo(() =>
        estimationStatus === ESTIMATION_STATUS.SELF_ESTIMATED ||
        estimationStatus === ESTIMATION_STATUS.ESTIMATION_DELIVERED ||
        !isEstimationOrder ||
        isEstimator,
        [estimationStatus, isEstimator]
    );

    return canCreateViews;
};