import { atom } from 'jotai/vanilla';
import { NumericId } from '../../../modules/common/types';
import { getWritableAtom, useAtom } from '../../useAtom';
import { SingleComponent } from '../../../../modules/property/api/types';

export type PanelLayersState = Record<NumericId, boolean>;

export type PanelComponentsState = Record<
  NumericId,
  Record<NumericId, boolean>
>;

export const expandedLayersAtom = atom<PanelLayersState>({});

export const selectedLayerIdAtom = atom<NumericId>(null);

export const checkedLayersAtom = atom<PanelLayersState>({});

export const checkedComponentsAtom = atom<PanelComponentsState>({});

export const selectedComponentsAtom = atom<PanelComponentsState>({});

export const topologyWarningsAtom = atom<any>({});

/** When a component is deleted all the logic regarding undo-redo is written in `TakeoffMapToolbar` component. But TakeoffLayerPanel is outside that component. So, there is no direct way to call that logic from TakeoffLayerPanel. This stores the componentId needs to be deleted when layer panel delete is clicked. TODO: Find better way for this */
export const componentsToBeDeletedAtom = atom<SingleComponent[] | null>(null);

export const writableCheckedLayersAtom = getWritableAtom(checkedLayersAtom);

export const writableCheckedComponentsAtom = getWritableAtom(
  checkedComponentsAtom
);

export const writableSelectedComponentsAtom = getWritableAtom(
  selectedComponentsAtom
);

export const useExpandedLayers = () => useAtom(expandedLayersAtom);

export const useCheckedLayers = () => useAtom(checkedLayersAtom);

export const useSelectedLayerId = () => useAtom(selectedLayerIdAtom);

export const useCheckedComponents = () => useAtom(checkedComponentsAtom);

export const useSelectedComponents = () => useAtom(selectedComponentsAtom);

export const useTopologyWarnings = () => useAtom(topologyWarningsAtom);

export const useComponentsToBeDeleted = () =>
  useAtom(componentsToBeDeletedAtom);
