import OSMView from '../../assets/osm-view.png';
import SatelliteView from '../../assets/satellite-view.png';

import { TileDebug } from 'ol/source.js';

import { Overlay } from 'ol';
import { GeoJSON } from 'ol/format';
import OlLayerTile from 'ol/layer/Tile';
import OlMap from 'ol/Map';
import { fromLonLat, toLonLat, transform, transformExtent } from 'ol/proj';
import OlSourceOSM from 'ol/source/OSM';
import OlView from 'ol/View';

import { defaults as defaultControls, ScaleLine } from 'ol/control.js';

import { getGeocode, getZipCode } from 'use-places-autocomplete';
import {
  MAP_DEFAULT_CENTER,
  MAP_DEFAULT_PROJECTION,
  MAP_DEFAULT_ZOOM,
} from '../constants/mapConstants';
import { catchError } from '../utilities/api-utils';
import { copyToClipboard } from '../utilities/clickBoardUtils';
import { _get } from '../utilities/lodashUtils';
import { trackEvents } from '../utilities';

// TODO: Ankit: 08-12-2023 -> I have seen this variable with similar constants declared else where. make sure we don't declare smae code twice in the codebase
export const scaleControl = new ScaleLine({
  units: 'us',
  bar: true,
  steps: 2,
  text: false,
  minWidth: 200,
});

let defaultLayers = [];
export const defaultView = new OlView({
  center: MAP_DEFAULT_CENTER,
  zoom: MAP_DEFAULT_ZOOM,
  projection: MAP_DEFAULT_PROJECTION,
  padding: [100, 0, 10, 200],
});

export const initMap = function (layers, view) {
  let map = new OlMap({
    layers: layers,
    view: defaultView,
    target: 'ol-map--project',
    controls: defaultControls({
      zoom: false,
    }).extend([scaleControl]),
  });
  map.setView(defaultView);
  return map;
};

export const destroyMap = function (mapRef) {
  mapRef.setView(defaultView);
  mapRef.setTarget(null);
};
export const getMapDefaultLayers = function () {
  return defaultLayers;
};

export const createSatelliteLayer = (isVisible = true) =>
  new OlLayerTile({
    source: new OlSourceOSM({
      url: 'https://mt{0-3}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
      crossOrigin: 'Anonymous',
    }),
    name: 'Satellite',
    value: 'raster',
    visible: isVisible,
  });

export const addDefaultBaseLayers = function (mapRef) {
  defaultLayers = [];

  defaultLayers.push({
    name: 'Satellite',
    value: 'raster',
    icon: SatelliteView,
    layer: createSatelliteLayer(),
  });
  defaultLayers.push({
    name: 'Street',
    value: 'street',
    icon: OSMView,
    layer: new OlLayerTile({
      source: new OlSourceOSM({
        url: 'https://mt{0-3}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
        crossOrigin: 'Anonymous',
      }),
      visible: false,
      name: 'Street',
      value: 'street',
    }),
  });
  defaultLayers.forEach(function (layerObj) {
    mapRef.addLayer(layerObj.layer);
    layerObj.layer.setZIndex(-1);
  });

  return defaultLayers;
};

export const toggleBaseLayerVisibility = function (layerName, mapRef) {
  let satelliteLayer = null;
  let streetLayer = null;
  
  if(!mapRef) return;

  mapRef.getLayers().forEach(function (layerRef) {
    if (layerRef.getProperties()?.name === 'Satellite') {
      satelliteLayer = layerRef;
    }
    if (layerRef.getProperties()?.name === 'Street') {
      streetLayer = layerRef;
    }
  });
  if (!satelliteLayer || !streetLayer) {
    return;
  }
  if (layerName === 'Satellite') {
    satelliteLayer.setVisible(true);
    streetLayer.setVisible(false);
  } else {
    satelliteLayer.setVisible(false);
    streetLayer.setVisible(true);
  }
  return layerName;
};

export const removeAllMapLayers = (mapRef, callback, clearNearMap = false) => {
  if (!mapRef) {
    return;
  }
  const layers = [...mapRef.getLayers().getArray()];
  layers.forEach((layer) => {
    if (
      !clearNearMap &&
      _get(layer.getProperties(), 'name', '').includes('nearmap')
    )
      return;
    mapRef.removeLayer(layer);
  });
  addDefaultBaseLayers(mapRef);
  if (callback) {
    callback();
  }
};

export const downloadMapImage = (mapRef, filename, uploadToServer) => {
  mapRef.once('rendercomplete', async () => {
    let mapCanvas = document.createElement('canvas');
    let mapSize = mapRef.getSize();
    mapCanvas.width = mapSize[0];
    mapCanvas.height = mapSize[1];
    let mapContext = mapCanvas.getContext('2d');
    Array.prototype.forEach.call(
      document.querySelectorAll(
        '.ol-layer canvas, .SrParcelLayer canvas, .SrLayer canvas'
      ),
      function (canvas) {
        if (canvas.width > 0) {
          let opacity = canvas.parentNode.style.opacity;
          mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
          let transform = canvas.style.transform;
          // Get the transform parameters from the style's transform matrix
          let matrix = transform
            .match(/^matrix\(([^\(]*)\)$/)[1]
            .split(',')
            .map(Number);
          // Apply the transform to the export map context
          CanvasRenderingContext2D.prototype.setTransform.apply(
            mapContext,
            matrix
          );
          mapContext.drawImage(canvas, 0, 0);
        }
      }
    );
    if (navigator.msSaveBlob) {
      // link download attribuute does not work on MS browsers
      navigator.msSaveBlob(mapCanvas.msToBlob(), filename);
    } else {
      let link = document.getElementById('image-download');
      link.href = mapCanvas.toDataURL();
      link.download = filename;
      let blob = b64toBlob(link.href);
      let fileOfBlob = new File([blob], filename);
      uploadToServer(fileOfBlob);
    }
  });
  mapRef.renderSync();
};

export const b64toBlob = (dataURI) => {
  let byteString = atob(dataURI.split(',')[1]);
  let ab = new ArrayBuffer(byteString.length);
  let ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/png' });
};

export const rasterMaPLayer = new OlLayerTile({
  source: new OlSourceOSM({
    url: 'https://mt{0-3}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
    crossOrigin: 'Anonymous',
  }),
});

export const streetMaPLayer = new OlLayerTile({
  source: new OlSourceOSM({
    url: 'https://mt{0-3}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
    crossOrigin: 'Anonymous',
  }),
  visible: false,
});

export const toTitleCase = (str) => {
  if (str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
};

export const transformCRS3857ToEPSG4326 = (coordinate = []) => {
  return transform(coordinate, 'EPSG:3857', 'EPSG:4326');
};

export const transformCRS4326ToEPSG3857 = (coordinate = []) => {
  return transform(coordinate, 'EPSG:4326', 'EPSG:3857');
};

export const createGeoJsonFromFeature = (feature) => {
  let featureJson = new GeoJSON();
  return JSON.parse(featureJson.writeFeature(feature));
};

export const getPostalCode = (address) => {
  return getGeocode(address)
    .then((results) => getZipCode(results[0], false))
    .then((zipCode) => {
      return zipCode;
    })
    .catch(catchError);
};

export const getLatLongClipboard = (mapRef) => {
  const container = document.getElementById('popup');
  const content = document.getElementById('lat-long');
  const latLongOverlay = new Overlay({
    element: container,
    autoPan: {
      animation: {
        duration: 250,
      },
    },
    id: 'lat-long',
    offset: [0, -15],
    positioning: 'bottom-left',
  });

  mapRef.getViewport().addEventListener('contextmenu', function (evt) {
    evt.preventDefault();
    mapRef.addOverlay(latLongOverlay);
    const coordinate = mapRef.getEventCoordinate(evt);
    content.innerHTML = `${toLonLat(coordinate)[1].toFixed(5)}, ${toLonLat(
      coordinate
    )[0].toFixed(5)}`;

    latLongOverlay.setPosition(coordinate);
  });

  if (!content) return;

  content.onclick = function (e) {
    copyToClipboard(e.target.innerHTML);
    latLongOverlay.setPosition(undefined);
  };

  mapRef.on('click', () => {
    latLongOverlay.setPosition(undefined);
  });
};

export const setActiveDeActiveInteraction = (
  mapRef,
  interactionName,
  status
) => {
  mapRef.getInteractions().forEach(function (interaction) {
    if (interaction instanceof interactionName) {
      interaction.setActive(status);
    }
  });
};

export const toggleMapRotateAbility = (rotationEnabled, map) => {
  map.getView().setProperties({
    enableRotation: rotationEnabled,
  });

  map.getInteractions().forEach((interaction) => {
    const interactionName = interaction.constructor.name;

    if (interactionName === 'DragRotate' || interactionName === 'PinchRotate') {
      interaction.setActive(rotationEnabled);
    }
  });
};

// Function to rotate the map by 90 degrees
export const rotateMapBy90 = (olMapRef) => {
  trackEvents('map-page__rotate-map-by-90');
  const currentRotation = olMapRef.getView().getRotation();
  const newRotation = currentRotation + Math.PI / 2; // Rotate by 90 degrees (in radians)
  olMapRef.getView().setRotation(newRotation - 0.001);
  olMapRef.getView().setRotation(newRotation);
};

// Function to reset view to parcel
export const resetToParcel = (
  olMapRef,
  orderBasicDetails,
  isBulkView,
  center = [0, 0]
) => {
  trackEvents('map-page__reset-to-parcel');
  const { minLong, minLat, maxLong, maxLat } = orderBasicDetails;

  if (!minLong || !minLat || !maxLong || !maxLat) {
    /** We don't have min and max coordinates available, so we'll use bbox from center */

    olMapRef.getView().animate({
      center: fromLonLat(center),
      duration: 2000,
      zoom: 18,
    });
    return;
  }

  let extent = [minLong, minLat, maxLong, maxLat];
  let projectedExtent = transformExtent(extent, 'EPSG:4326', 'EPSG:3857');
  olMapRef.getView().fit(projectedExtent, {
    size: olMapRef.getSize(),
    padding: isBulkView ? [100, 0, 10, 400] : [100, 0, 10, 200],
  });
};

// Function to set snap view
export const resetToSnapView = (olMapRef, orderBasicDetails, isBulkView) => {
  const { minLong, minLat, maxLong, maxLat } = orderBasicDetails;
  const extent = [minLong, minLat, maxLong, maxLat];
  const projectedExtent = transformExtent(extent, 'EPSG:4326', 'EPSG:3857');

  const mapView = olMapRef.getView();
  const size = olMapRef.getSize();

  mapView.fit(projectedExtent, {
    size: size,
    padding: isBulkView ? [10, 600, 100, 0] : [10, 600, 100, 0],
  });
};

export const createTileDebugLayer = () => {
  return new OlLayerTile({
    source: new TileDebug(),
    name: 'tile-debug',
  });
};

export const removeTileDebugLayer = (mapRef) => {
  mapRef
    .getLayers()
    .getArray()
    .forEach((layer) => {
      if (layer.get('name') === 'tile-debug') {
        mapRef.removeLayer(layer);
      }
    });
};

export const toggleNearmap = (map, isChecked) => {
  let nearmapLayerExists = false;
  const layers = map.getLayers();
  layers.forEach(function (layer) {
    if (layer && layer.getProperties().name === 'nearmap') {
      nearmapLayerExists = true;
      layer.setVisible(Boolean(isChecked));
    }
  });

  if (nearmapLayerExists) {
    trackEvents('layerpanel__nearmap-toggle', {
      val: isChecked ? 'show' : 'hide',
    });
  }
};

export const toggleBasemap = (map, show, selectedBasemap) => {
  const layers = map.getLayers();
  const satelliteLayerToRemove = layers
      .getArray()
      .find((layer) => layer.get('name')?.toLowerCase() === 'satellite');
  const streetLayerToRemove = layers
      .getArray()
      .find((layer) => layer.get('name')?.toLowerCase() === 'street');
  if (!show) {
    if (satelliteLayerToRemove) {
      satelliteLayerToRemove.setVisible(false);
    }
    if (streetLayerToRemove) {
      streetLayerToRemove.setVisible(false);
    }
  } else {
    if (satelliteLayerToRemove) {
      satelliteLayerToRemove.setVisible(selectedBasemap === 'satellite');
    }
    if (streetLayerToRemove) {
      streetLayerToRemove.setVisible(selectedBasemap === 'street');
    }
  }
};
