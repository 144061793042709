import React, { useEffect, useMemo, useState } from 'react';
import { Popover } from 'antd';
import './style.less';
import classNames from 'classnames';
import { Box, Typography } from '@siterecon/sr-styles/dist/elements';
import Down from '../../assets/icons/DownArrow';
import { MapToolItemData, MapToolProps } from '../MapTool/MapTool';
import MoreTool from '../../assets/tools/MoreTool';
import Info from '../../assets/icons/Info';
import { getWarningMsg } from '../utils';
import LayerData from '../MapTool/LayerData';
import NoLayerText from '../MapTool/NoLayerText';
import { DRAW_TOOLS, NO_LAYER_POPUP_TOOLS } from '../constant';
import { SubscriptionPlanIcons } from '../../../../../../modules/subscription/components/Chips/constant';

export interface layerProps {
  attributeIds: number[];
  attributes: any;
  componentWarningStatus: string;
  featureId: number;
  featureType: 'point' | 'polygon' | 'line';
  isEditable: boolean;
  layerId: number;
  name: string;
  status: string;
  style: any;
  topologyWarning: any;
  unit: string;
}

interface ToolbarMenuProps {
  selected?: string;
  expand?: boolean;
  options: MapToolItemData[];
  clickAction?: (key: string) => void;
  activeTool?: string;
  isEssential: boolean;
  disableTool: boolean;
  chosenLayer: layerProps;
  layers: layerProps[];
  changeLayerClick?: (layer: any, key: string) => void;
  addLayerHandler?: () => void;
  SubscriptionChip;
}

export const ToolbarMenu = ({
  selected = '',
  expand,
  options,
  clickAction,
  activeTool,
  isEssential,
  disableTool,
  chosenLayer,
  layers,
  changeLayerClick,
  addLayerHandler,
  SubscriptionChip,
}: ToolbarMenuProps) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({ title: 'More', icon: <MoreTool /> });
  const [optionKeys, setOptionKeys] = useState<string[]>([]);
  const [popoverVisible, setPopoverVisible] = useState({});

  const handleMenuClick = (element) => {
    setData({ title: element.title, icon: element.icon });
    clickAction?.(element.keyVal);

    if (!NO_LAYER_POPUP_TOOLS.includes(element.keyVal)) {
      setPopoverVisible((prevState) => ({
        ...prevState,
        [element.keyVal]: true,
      }));
    } else {
      setOpen(false);
      setPopoverVisible({});
    }
  };

  const onChange = (id, key) => {
    let foundLayer = layers.find((item) => item.layerId == id);
    changeLayerClick?.(foundLayer, key);

    setOpen(false);
    setPopoverVisible({});
  };

  const handlePopoverVisibleChange = (
    visible: boolean,
    itemId: string,
    item: MapToolProps
  ) => {
    if (!visible) {
      setPopoverVisible({});
      return;
    }

    if (
      (!item.viableLayers?.includes(chosenLayer.featureType) ||
        !chosenLayer.isEditable) &&
      !NO_LAYER_POPUP_TOOLS.includes(item.keyVal)
    ) {
      setPopoverVisible((prevState) => ({
        ...prevState,
        [itemId]: visible,
      }));
    }
  };

  useEffect(() => {
    let subs = [];

    let baseOptions = options.map((item) => {
      if (item.sub) {
        item.sub.map((subEle) => {
          subs.push(subEle.keyVal);
        });
      } else {
        return item.keyVal;
      }
    });

    baseOptions = baseOptions.concat(subs);

    setOptionKeys(baseOptions);

    if (!baseOptions.includes(selected[0])) {
      setData({ title: 'More', icon: <MoreTool /> });
    } else {
      let element = options.find((item) => {
        if (item.sub) {
          return false;
        } else {
          return item.keyVal == selected[0];
        }
      });

      if (element) {
        setData({ title: element.title, icon: element.icon });
      }
    }
  }, [selected, options]);

  const sortedLayers = useMemo(() => {
    if (!layers) return [];
    return [...layers].sort(
      (a, b) => Number(b.isEditable) - Number(a.isEditable)
    );
  }, [layers]);

  const getWrongLayerContent = (item: MapToolProps) => {
    return (
      <Box className='wrong-layer-container'>
        {!DRAW_TOOLS.includes(item.keyVal) &&
          !chosenLayer?.isEditable &&
          item.viableLayers.length !== 4 && (
            <Box className='header'>
              <Box className='header-inner'>
                <Box style={{ width: '16px', height: '16px' }}>
                  <Info />
                </Box>

                <Typography fontSize={12} fontWeight={400} lineHeight={'18px'}>
                  {item.title} tool can be used on{' '}
                  {getWarningMsg(item.viableLayers)}
                </Typography>
              </Box>
            </Box>
          )}
        <Box className='select-layer-section w-100'>
          {DRAW_TOOLS.includes(item.keyVal) && chosenLayer?.isEditable && (
            <Box className='select-layer-header'>
              <Typography
                fontWeight={400}
                fontSize={14}
                lineHeight={'16px'}
                color='neutral-400'
              >
                Draw {item.title} in
              </Typography>
            </Box>
          )}
          <Box className='select-layer-content'>
            {chosenLayer?.isEditable ? (
              <>
                {layers.filter((element) =>
                  item.viableLayers?.includes(element.featureType)
                ).length ? (
                  <>
                    {!DRAW_TOOLS.includes(item.keyVal) && (
                      <Box className='select-layer-header'>
                        <Typography
                          fontWeight={500}
                          fontSize={14}
                          lineHeight={'21px'}
                          color='#666'
                        >
                          Select Layer
                        </Typography>
                      </Box>
                    )}
                    <Box className='radio-set'>
                      {sortedLayers
                        .filter((element) =>
                          item.viableLayers?.includes(element.featureType)
                        )
                        .map((layer) => {
                          return (
                            <LayerData
                              layer={layer}
                              chosenLayer={chosenLayer}
                              onChange={() =>
                                onChange(layer.layerId, item.keyVal)
                              }
                              keyVal={item.keyVal}
                            />
                          );
                        })}
                    </Box>
                  </>
                ) : (
                  <NoLayerText />
                )}
              </>
            ) : (
              <>
                {sortedLayers.length ? (
                  <>
                    <Box className='select-layer-header'>
                      <Typography
                        fontWeight={500}
                        fontSize={14}
                        lineHeight={'21px'}
                        color='#666'
                      >
                        Select Layer
                      </Typography>
                    </Box>

                    <Box className='radio-set'>
                      {sortedLayers.length ? (
                        sortedLayers.map((layer) => {
                          return (
                            <LayerData
                              layer={layer}
                              chosenLayer={chosenLayer}
                              onChange={() =>
                                onChange(layer.layerId, item.keyVal)
                              }
                              keyVal={item.keyVal}
                            />
                          );
                        })
                      ) : (
                        <NoLayerText />
                      )}
                    </Box>
                  </>
                ) : (
                  <NoLayerText />
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  const Dropdown = (
    <Box>
      {options &&
        options.map((ele) => {
          return ele.sub ? (
            ele.sub.map((item) => {
              return (
                <Popover
                  key={item.keyVal}
                  visible={Boolean(popoverVisible[item.keyVal])}
                  onVisibleChange={(visible) =>
                    handlePopoverVisibleChange(visible, item.keyVal, ele)
                  }
                  content={getWrongLayerContent(item)}
                  trigger='click'
                  placement='leftTop'
                  overlayClassName='menu-wrong-layer'
                >
                  <Box
                    className={
                      data.title == item.title
                        ? 'dropdown selected'
                        : 'dropdown'
                    }
                    onClick={() => {
                      if (
                        ele.viableLayers.includes(chosenLayer.featureType) &&
                        (chosenLayer.isEditable ||
                          NO_LAYER_POPUP_TOOLS.includes(ele.keyVal))
                      ) {
                        handleMenuClick(item);
                      }
                    }}
                    key={item.title}
                  >
                    <span
                      style={{
                        display: 'flex',
                        filter:
                          data.title == item.title ? 'brightness(100)' : '',
                      }}
                    >
                      {item.icon}
                    </span>
                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      lineHeight={'16px'}
                    >
                      {item.title}
                    </Typography>

                    {isEssential && (
                      <span
                        style={{
                          display: 'flex',
                          position: 'absolute',
                          right: '10px',
                          alignItems: 'center',
                          filter:
                            data.title == item.title ? 'brightness(100)' : '',
                        }}
                      >
                        <SubscriptionChip
                          iconProp={SubscriptionPlanIcons.PROFESSIONAL}
                        />
                      </span>
                    )}
                  </Box>
                </Popover>
              );
            })
          ) : (
            <Popover
              key={ele.keyVal}
              visible={Boolean(popoverVisible[ele.keyVal])}
              onVisibleChange={(visible) =>
                handlePopoverVisibleChange(visible, ele.keyVal, ele)
              }
              content={getWrongLayerContent(ele)}
              trigger='click'
              placement='leftTop'
              overlayClassName='menu-wrong-layer'
            >
              <Box
                className={
                  data.title == ele.title ? 'dropdown selected' : 'dropdown'
                }
                key={ele.title}
                onClick={() => {
                  if (
                    ele.viableLayers.includes(chosenLayer.featureType) &&
                    (chosenLayer.isEditable ||
                      NO_LAYER_POPUP_TOOLS.includes(ele.keyVal))
                  ) {
                    handleMenuClick(ele);
                  }
                }}
              >
                <>
                  <span
                    style={{
                      display: 'flex',
                      filter: data.title == ele.title ? 'brightness(100)' : '',
                    }}
                  >
                    {ele.icon}
                  </span>
                  <Typography
                    fontSize={12}
                    fontWeight={500}
                    lineHeight={'16px'}
                  >
                    {ele.title}
                  </Typography>
                </>
              </Box>
            </Popover>
          );
        })}
    </Box>
  );

  const handleOpenChange = (newOpen: boolean) => {
    if (!disableTool) {
      setOpen(newOpen);
    }
  };

  return (
    <Box
      className='toolbar-menu-wrapper'
      style={{ opacity: disableTool ? 0.4 : 1 }}
    >
      <Popover
        content={Dropdown}
        placement='bottomRight'
        trigger='click'
        visible={open}
        onVisibleChange={handleOpenChange}
        overlayClassName='toolbar-menu-popover'
      >
        <Box
          className={classNames('tool', {
            selected: optionKeys.includes(selected[0]),
            disabled: disableTool,
          })}
          style={{
            background: `${open && data.title === 'More' ? '#E9E9E9' : ''}`,
          }}
        >
          <Box className='inner-box'>
            <span
              style={{
                display: 'flex',
                filter:
                  optionKeys && optionKeys.includes(selected[0])
                    ? 'brightness(100)'
                    : '',
              }}
            >
              {data.icon}
            </span>
            {expand && (
              <Typography fontSize={12} fontWeight={500} lineHeight={'16px'}>
                {data.title}
              </Typography>
            )}
            <span
              style={{
                display: 'flex',
                filter:
                  optionKeys && optionKeys.includes(selected[0])
                    ? 'brightness(100)'
                    : '',
              }}
            >
              <Down />
            </span>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};
