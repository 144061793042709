import { Snap } from 'ol/interaction';

var snapInteractions = [];

export const resetCrossLayerSnap = (mapRef) => {
  try {
    snapInteractions.forEach(function (interaction) {
      mapRef.removeInteraction(interaction);
    });
  } catch (e) {}
  snapInteractions.length = 0;
};

export const addCrossLayerSnap = (
  mapRef,
  layerRefs,
  otherParams = { pixelTolerance: 10 }
) => {
  resetCrossLayerSnap(mapRef);
  const filteredLayerRefs = Object.fromEntries(
    Object.entries(layerRefs).filter(([key, value]) => value !== undefined)
  );

  Object.values(filteredLayerRefs).forEach((layerRef) => {
    let snap = new Snap({
      source: layerRef.getSource(),
      ...otherParams,
    });
    mapRef.addInteraction(snap);
    snapInteractions.push(snap);
  });
};
