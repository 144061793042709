// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type DeleteIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function DeleteIcon(props: DeleteIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M9.625 7.08H9.5a.123.123 0 00.125-.12v.12h4.75v-.12c0 .066.056.12.125.12h-.125v1.08H15.5v-1.2c0-.53-.448-.96-1-.96h-5c-.552 0-1 .43-1 .96v1.2h1.125V7.08zM17.5 8.16h-11a.49.49 0 00-.5.48v.48c0 .066.056.12.125.12h.944l.386 7.845c.025.511.465.915.998.915h7.094c.534 0 .973-.402.998-.915l.386-7.845h.944A.123.123 0 0018 9.12v-.48a.49.49 0 00-.5-.48zm-2.073 8.76H8.573l-.378-7.68h7.61l-.378 7.68z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default DeleteIcon;
/* prettier-ignore-end */
