import { useContext } from 'react';
import { NumericId } from '../../../common/types';
import OlMap from 'ol/Map';
import MapContext from '@/components/pages/project/MapContext';
import VectorLayer from 'ol/layer/Vector';
import {
  addMapLayer,
  addSourceFeatures,
  getFeatureByComponent,
  removeMapLayerById,
  removeSourceFeatureById,
} from '../../helpers/layer-render.helpers';
import { mapLayers } from '../../helpers/layer.data';
import { LayerWithComponents, SingleComponent } from '../../api/types';
import { useKmlLayers } from '../../../../jotai/atoms/property/takeoff/navbar';

type AddLayerFunction = (
  layer: LayerWithComponents,
  visibleComponents?: NumericId[]
) => void;

type HandleToggleComponentFunction = (
  checked: boolean,
  layerId: NumericId,
  component: SingleComponent
) => void;

type HandleToggleLayerFunction = (
  checked: boolean,
  layer: LayerWithComponents
) => void;

export const useLayerRender = () => {
  const mapRef: OlMap = useContext(MapContext);
  const [, setKmlLayerList] = useKmlLayers();

  const addLayer: AddLayerFunction = (layer, visibleComponents) => {
    if (mapLayers[layer.layerId]) {
      mapRef.removeLayer(mapLayers[layer.layerId]);
    }

    const olLayer = addMapLayer(mapRef, layer, visibleComponents);
    
    setKmlLayerList((prev) => {
      return {
        ...prev,
        [layer.name]: olLayer,
      };
    });
    mapLayers[layer.layerId] = olLayer;
  };

  const handleToggleComponent: HandleToggleComponentFunction = (
    checked,
    layerId,
    component: SingleComponent
  ) => {
    if (!checked) {
      removeLayerComponent(layerId, component);
      return;
    }

    addLayerComponent(layerId, component);
  };

  const handleToggleLayer: HandleToggleLayerFunction = (
    checked,
    layer: LayerWithComponents
  ) => {
    if (!checked) {
      const olLayer: VectorLayer = mapLayers[layer.layerId];
      if (!olLayer) return;

      /** When we are toggling off the layer, just remove the data from source */
      olLayer.getSource().clear();
      // delete mapLayers[layer.layerId];
      // removeMapLayerById(mapRef, layer.layerId as NumericId);
      return;
    }

    delete mapLayers[layer.layerId];
    removeMapLayerById(mapRef, layer.layerId as NumericId);
    mapLayers[layer.layerId] = addMapLayer(mapRef, layer);
  };

  return { addLayer, handleToggleComponent, handleToggleLayer };
};

export const removeLayerById = (map: OlMap, layerId: NumericId) => {
  if (mapLayers[layerId]) {
    map.removeLayer(mapLayers[layerId]);
  }

  delete mapLayers[layerId];
};

/** Removes all map layers except default layers: Parcel, Nearmap, Basemap */
export const removeAllLayersExceptDefault = (map: OlMap) => {
  if (!map) return;

  for (const olLayer of Object.values(mapLayers)) {
    map.removeLayer(olLayer);
  }

  map.getLayers().forEach((layer) => {
    if (layer.getClassName().includes('SrLayer')) {
      map.removeLayer(layer);
    }
  });

  const keys = Object.keys(mapLayers);
  for (const key of keys) {
    delete mapLayers[key];
  }
};

export const addLayerComponent = (
  layerId: NumericId,
  component: SingleComponent
) => {
  const olLayer: VectorLayer = mapLayers[layerId];
  if (!olLayer) return;

  addSourceFeatures(
    olLayer.getSource(),
    [getFeatureByComponent(component)],
    layerId
  );
};

export const removeLayerComponent = (
  layerId: NumericId,
  component: Partial<SingleComponent>
) => {
  const olLayer: VectorLayer = mapLayers[layerId];
  if (!olLayer) return;

  removeSourceFeatureById(olLayer.getSource(), component.componentId!);
};

export default useLayerRender;
