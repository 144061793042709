import VectorSource from 'ol/source/Vector';
import { NumericId } from '../../common/types';
import OlMap from 'ol/Map';
import { feature, Feature, featureCollection } from '@turf/turf';
import { GeoJSON } from 'ol/format';
import VectorLayer from 'ol/layer/Vector';
import {
  convertJSONStyleToOlStyle,
  verticesStyle,
} from '../../../helpers/mapUtils/layerUtils';
import { updatePathStyleFunction } from '../../../helpers/mapGlobals/styles';
import { Coordinate } from 'ol/coordinate';
import { transform } from 'ol/proj';
import { LayerWithComponents, SingleComponent } from '../api/types';
import BaseLayer from 'ol/layer/Base';
import { GeoJSONFeature } from 'ol/format/GeoJSON';
import { Polygon } from 'geojson';
import omit from 'lodash/omit';
import { FeatureType } from '../../../components/feature-attributes/types';
import { mapLayers } from './layer.data';

export type LayerData = LayerWithComponents;

const transformCoordinates = (
  coordinates: Coordinate | Coordinate[] | Coordinate[][]
) => {
  if (typeof coordinates[0] === 'number') {
    return transform(coordinates as Coordinate, 'EPSG:4326', 'EPSG:3857');
  }

  return coordinates.map((coordinateCollection) =>
    transformCoordinates(coordinateCollection)
  );
};

export const getLayerZIndex = (featureType: string) => {
  switch (featureType) {
    case 'line':
      return 80;

    case 'point':
      return 100;

    case 'path':
      return 90;

    default:
      return 70;
  }
};

export const getLayerComponent = (
  data: LayerData[],
  layerId: NumericId,
  componentId: NumericId
) => {
  let component: SingleComponent | null = null;

  for (const layer of data) {
    if (Number(layer.layerId) === layerId) {
      component =
        layer.components.find(
          (_component) => Number(_component.componentId) === componentId
        ) ?? null;
    }
  }

  return component;
};

export const getFeatureByComponent = (component: SingleComponent) => {
  const geoJsonFeature: GeoJSONFeature = JSON.parse(component.geoJson);

  if ((geoJsonFeature.geometry as Polygon).coordinates) {
    geoJsonFeature.geometry.coordinates = transformCoordinates(
      geoJsonFeature.geometry.coordinates
    );
  }

  const properties = {
    ...geoJsonFeature.properties,
    ...omit(component, 'geometry'),
    layerName: component.name,
    id: component.componentId,
  };

  return feature(geoJsonFeature.geometry, properties, {
    id: component.componentId,
  });
};

export const addSourceFeatures = (
  source: VectorSource,
  features: Feature[],
  id: NumericId
) => {
  const collection = new GeoJSON().readFeatures(
    featureCollection(features, {
      id: id,
    })
  );

  source.addFeatures(collection);
  return collection;
};

export const removeSourceFeatureById = (
  source: VectorSource,
  id: NumericId
) => {
  let feature = source.getFeatureById(id);
  if (feature) {
    source.removeFeature(feature);
  }
};

export const addMapLayer = (
  mapRef: OlMap,
  layer: LayerData,
  componentIds: NumericId[] = [],

  /** When `OlLayer` is provided, this function will clear the source and re-render the features */
  existingOlLayer: VectorLayer | null = null
) => {
  const features: Feature[] = [];

  for (const component of layer.components) {
    if (
      componentIds.length > 0 &&
      !componentIds.includes(component.componentId)
    ) {
      continue;
    }

    features.push(getFeatureByComponent(component));
  }

  const layerCollection = featureCollection(features, {
    id: layer.layerId,
  });

  if (existingOlLayer) {
    const source = existingOlLayer.getSource();
    source.clear();

    layerCollection.features.forEach((feature) => {
      source.addFeature(new GeoJSON().readFeature(feature));
    });

    return existingOlLayer;
  }

  const featureTemplate = layerCollection.features[0];

  const featureType =
    featureTemplate?.properties?.featureType ??
    layer.featureType ??
    FeatureType.POLYGON;
  const geometryType = featureTemplate?.geometry.type ?? featureType;
  const featureStyle = featureTemplate?.properties?.style || layer?.style;

  if (!featureType || !geometryType || !featureStyle) return null;

  const source = new VectorSource({
    features: new GeoJSON().readFeatures(layerCollection),
  });

  let style: any = convertJSONStyleToOlStyle(geometryType, featureStyle);

  const olLayer = new VectorLayer({
    className: 'SrLayer',
    source: source,
    style: style,
    zIndex: getLayerZIndex(featureType),
  });

  olLayer.setZIndex(getLayerZIndex(featureType));

  if (featureType === 'path') {
    style = updatePathStyleFunction(source.getFeatures(), featureStyle);
    olLayer.setStyle(style);
  }

  olLayer.setProperties(
    omit(layer, ['components', 'warningStatus', 'attributeIds'])
  );

  /** TODO: Update the `properties.style` whenever user updates the layer style */
  olLayer.set('olStyle', style);

  mapRef.addLayer(olLayer);
  return olLayer;
};

export const removeMapLayerById = (mapRef: OlMap, layerId: NumericId) => {
  let matchingLayer: BaseLayer | null = null;

  mapRef.getLayers().forEach((layer) => {
    const olLayerId = layer.getProperties()?.layerId;

    if (olLayerId && Number(olLayerId) === layerId) {
      matchingLayer = layer;
    }
  });

  if (matchingLayer) {
    mapRef.removeLayer(matchingLayer);
  }
};

export const showVertices = (layerId: NumericId) => {
  let layerStyle: any = [];
  const layer = mapLayers[layerId];
  if (!layer) return;

  layerStyle = layer.getStyle();

  const style = Array.isArray(layerStyle) ? layerStyle[0] ?? null : layerStyle;
  if (!style) return;

  const strokeColor = style?.getStroke()?.getColor();
  const strokeWidth = style?.getStroke()?.getWidth();
  const fillColor = style?.getFill()?.getColor();

  layer.setStyle(verticesStyle(strokeColor, strokeWidth, fillColor, 4));
};
