import { getAxiosInstance } from '@/helpers/utilities/api-utils';
import {
  BulkUpdateComponentPayload,
  CopyComponentPayload,
  CopyComponentResponse,
  CopyComponentResponseSummary,
  FeatureListResponse,
  GetComponentsResponse,
  GetCoverageRequest,
  GetCoverageResponse,
  GetLayersResponse,
  GetQueueResponse,
  LayerDetailsResponse,
  OrderProductResponse,
  ReviewTagResponse,
  SaveOrderPayload,
  SaveOrderResponse,
  SaveOrderResponseV2,
  ServiceItemApiResponse,
  UpdateOrderDetailsRequest,
  UpdateOrderDetailsResponse,
  UpdateOrderPayload,
  UpdateOrderStatusResponse,
  ValidateParcelResponse,
  ViewListResponse,
} from './schema';
import * as API_ENDPOINTS from '../../../helpers/constants/APIEndpoints';
import { replaceParams } from '../../../helpers/utilities/linkUtils';
import {
  AddressDetails,
  OrderProductItem,
  ServiceItem,
  ServiceItemAssignment,
  Tag,
  TileDataItem,
  User,
} from './types';
import { NumericId, ViewType } from '../../common/types';
import { AxiosResponse } from 'axios';
import { da } from '@faker-js/faker';
import { transformApiServiceItems } from '../transformers/service-item.transformers';
import { getToken } from '@/helpers/auth';
import { sample } from 'lodash';
import { transformComponentProperties } from './transformers';
import { postOrderRating } from '@project/components/OrderRating/api';
import { getSupabaseApiKey } from '../../../components/pages/project/utils/helpers';
import { trackEvents } from '../../../helpers/utilities';
import { CreateOrderEvt } from '../../../segment';
import {
  HierarchicalView,
  HierarchicalViewListResponse,
} from '../types/view.types';
import {
  FeatureStyleCatalogueResponse,
  LayerStyle,
} from '../types/layer.types';
import { ApiParcelStyle } from '../types/order.types';
import { NotifyError } from '../../../helpers/notification-utils';

const axiosInstance = getAxiosInstance();

// Address confirmation flow
export const saveOrder = async (
  payload: SaveOrderPayload
): Promise<SaveOrderResponse> => {
  const bodyFormData = new FormData();
  bodyFormData.append('userId', payload.userId);
  bodyFormData.append('orgId', payload.orgId);
  bodyFormData.append('address', payload.address);
  bodyFormData.append('latitude', payload.lat.toString());
  bodyFormData.append('longitude', payload.lng.toString());
  bodyFormData.append('postalCode', payload.postalCode);

  const response = await axiosInstance.post(
    API_ENDPOINTS.SAVE_ORDER,
    bodyFormData
  );
  return response.data as SaveOrderResponse;
};

// New Address confirmation flow
export const saveOrderV2 = async (
  payload: SaveOrderPayload
): Promise<SaveOrderResponseV2> => {
  const body = {
    address: payload.address,
    latitude: payload.lat,
    longitude: payload.lng,
    pincode: payload.postalCode,
  };

  const response = await axiosInstance.post(API_ENDPOINTS.SAVE_ORDER_V2, body);

  return response.data as SaveOrderResponseV2;
};

export const getOrderProductDetails = async (
  orderHash: number
): Promise<OrderProductResponse> => {
  const response = await axiosInstance.get(
    replaceParams(API_ENDPOINTS.GET_ORDER_DETAILS, {
      ':orderHashed': orderHash,
    })
  );
  const responseData = response.data as any[];

  const orderProductItems = responseData.filter(
    (item) => 'orderId' in item
  ) as OrderProductItem[];
  const addressDetails = responseData.find(
    (item) => 'address' in item
  ) as AddressDetails;
  const tileData = responseData.filter(
    (item) => 'tileData' in item
  ) as TileDataItem[];

  return {
    orderProductItems,
    addressDetails,
    tileData,
  };
};

export const getViewList = async (
  orderId: number
): Promise<ViewListResponse> => {
  const response = await axiosInstance.get(
    replaceParams(API_ENDPOINTS.ORDER_VIEW, { ':orderId': orderId })
  );
  return response.data as ViewListResponse;
};

/** Not being used */
export const getNotesList = async (
  orderId: number,
  viewId: number,
  isBaseView: boolean
): Promise<any> => {
  const response = await axiosInstance.get(
    replaceParams(API_ENDPOINTS.NOTES_LIST, {
      ':orderId': orderId,
      ':viewId': viewId,
      ':isBaseView': isBaseView ? 1 : 0,
    })
  );
  return response.data as any;
};

export const getQueue = async (): Promise<GetQueueResponse> => {
  const response = await axiosInstance.get(API_ENDPOINTS.GET_ORDER_QUEUE);
  return response.data as GetQueueResponse;
};

export const getNearmapSurveys = async (
  payload: GetCoverageRequest
): Promise<GetCoverageResponse> => {
  const response = await axiosInstance.post(
    replaceParams(API_ENDPOINTS.GET_NEARMAP_SURVEYS, {
      ':coordinates': payload.coordinates,
    }),
    payload.orderId
  );
  return response.data as GetCoverageResponse;
};

export const updateOrderDetails = async (
  payload: UpdateOrderDetailsRequest
): Promise<UpdateOrderDetailsResponse> => {
  const response = await axiosInstance.post(
    API_ENDPOINTS.UPDATE_ORDER_DETAILS,
    payload
  );
  return response.data as UpdateOrderDetailsResponse;
};

// Parcel confirmation
export const validateParcel = async (
  payload: any
): Promise<ValidateParcelResponse> => {
  const response = await axiosInstance.post(
    API_ENDPOINTS.VALIDATE_COMPONENT,
    payload
  );
  return response.data as ValidateParcelResponse;
};

export const checkIfParcelExceedsLimit = async (
  orderId: number,
  geoJson: any
): Promise<void> => {
  await axiosInstance.post(
    replaceParams(API_ENDPOINTS.CHECK_PARCEL_EXCEEDS_LIMIT, {
      ':orderId': orderId,
    }),
    {
      geoJson: geoJson,
    }
  );
};

// Order Card - Select feature & get measurement
export const getFeatureList = async (): Promise<FeatureListResponse> => {
  const response = await axiosInstance.get(API_ENDPOINTS.GET_FEATURE_LIST);
  return response.data as FeatureListResponse;
};

export const updateOrderStatus = async (
  payload: UpdateOrderPayload
): Promise<UpdateOrderStatusResponse> => {
  const bodyFormData = new FormData();
  bodyFormData.append('nearMapImage', payload.nearMapImage);
  bodyFormData.append('orderId', payload.orderId);
  bodyFormData.append('nearMapDate', payload.nearMapDate);
  bodyFormData.append('credit', String(payload.credit));

  const response = await axiosInstance.post(
    API_ENDPOINTS.UPDATE_ORDER_STATUS,
    bodyFormData
  );

  if (response.data.status === 200) {
    const {
      remainingTime,
      tileData,
      minLong,
      minLat,
      maxLong,
      maxLat,
      parcelJson,
    } = response.data;

    return {
      status: 200,
      remainingTime,
      tileData,
      minLong,
      minLat,
      maxLong,
      maxLat,
      parcelJson,
    };
  } else {
    throw new Error('Failed to update order status');
  }
};

// Action center
export const getLayerComponents = async (
  view: HierarchicalView,
  layerId: number
): Promise<GetComponentsResponse> => {
  const response: AxiosResponse<GetComponentsResponse> =
    await axiosInstance.get(
      replaceParams(API_ENDPOINTS.VIEW_LAYERS_COMPONENT_WITHOUT_PAGINATION, {
        ':viewId': view.viewId,
        ':layerId': layerId,
        // ':offset': '0',
        // ':limit': process.env.REACT_APP_FEATURE_PAGINATION_LIMIT || 100,
      }),
      {
        params: {
          showTopologyWarning:
            view.isEditable && view.viewType === ViewType.STATIC
              ? true
              : (
                  process.env.REACT_APP_FEATURE_PAGINATION_LIMIT || ''
                ).toLowerCase() === 'true',
        },
      }
    );

  response.data.data = transformComponentProperties(response.data.data);

  return response.data as GetComponentsResponse;
};

export const getLayers = async (viewId: number): Promise<GetLayersResponse> => {
  const response = await axiosInstance.get(
    replaceParams(API_ENDPOINTS.VIEW_LAYERS, { ':viewId': viewId })
  );
  return response.data as GetLayersResponse;
};

export const getFeatureById = async (
  featureId: number
): Promise<LayerDetailsResponse> => {
  const response = await axiosInstance.get(
    replaceParams(API_ENDPOINTS.PARTICULAR_FEATURE, { ':featureId': featureId })
  );
  return response.data as LayerDetailsResponse;
};

// Fetch on page load (Use these endpoints on specific clicks instead)
export const getAllWorkspaceUsers = async ( // not being used
  workspaceId: number
): Promise<User[]> => {
  const response = await axiosInstance.get(
    replaceParams(API_ENDPOINTS.GET_ALL_USERS, {
      ':organizationId': workspaceId,
    })
  );
  return response.data as User[];
};

export const getAssets = async (): Promise<any> => {
  const response = await axiosInstance.get(API_ENDPOINTS.GLOBAL_ASSET);
  return response.data;
};

export const getAllTags = async (): Promise<Tag[]> => {
  const response = await axiosInstance.get<{ tag: Tag[] }>(
    API_ENDPOINTS.GET_ALL_TAGS
  );
  return response.data.tag;
};

/** Not being used */
export const getReviewTags = async (
  organizationId: number,
  orderid: number
): Promise<ReviewTagResponse> => {
  const response = await axiosInstance.get(
    replaceParams(API_ENDPOINTS.GET_REVIEW_TAGS, {
      ':organizationId': organizationId,
      ':orderId': orderid,
    })
  );
  return response.data as ReviewTagResponse;
};

export const getServiceItems = async (
  orderId: NumericId,
  workspaceId: NumericId
): Promise<ServiceItem[]> => {
  const { apiKey, supabaseProjectKey } = getSupabaseApiKey();

  const { data } = (await axiosInstance.post(
    `https://${supabaseProjectKey}.supabase.co/rest/v1/rpc/fetch_service_items_for_workspace_id`,
    {
      property_id: orderId,
      token: 'Token ' + getToken(),
      workspace_id_input: workspaceId,
    },
    {
      headers: {
        apikey: apiKey,
      },
    }
  )) as AxiosResponse<ServiceItemApiResponse>;

  return transformApiServiceItems(data.content);
};

export const getServicesList = async (workspaceId: NumericId): Promise<any> => {
  const { apiKey, supabaseProjectKey } = getSupabaseApiKey();

  const { data } = await axiosInstance.post(
    `https://${supabaseProjectKey}.supabase.co/rest/v1/rpc/get_onboarding_service_selection_data`,
    {
      token: 'Token ' + getToken(),
      workspace_id_input: workspaceId,
    },
    {
      headers: {
        apikey: apiKey,
      },
    }
  );

  return data.content;
};

// export const estimationNotifyMe = async (
//   cta: string,
//   userEmail: string,
//   workspaceName: string,
//   username: string
// ): Promise<ServiceItem[]> => {
//   const { data } = await axiosInstance.post(
//     `https://9je746.buildship.run/estimations/notify`,
//     {
//       cta,
//       userEmail,
//       workspaceName,
//       username,
//     }
//   );

//   return data;
// };

export enum CTAType {
  RequestCustomLayer = 'request_custom_layer',
  RequestCatalogueLayer = 'request_catalogue_layer',
  RequestCatalogueUpdate = 'request_catalogue_update',

  EnableEstimations = 'enable_estimations', // not useful
  EnableEstimationsAnalytics = 'enable_estimations_analytics', // not useful
}

export const notifyCTA = async (cta: CTAType): Promise<void> => {
  await axiosInstance.post(API_ENDPOINTS.NOTIFY_CTA, { cta });
};

export const assignServiceItems = async (
  viewId: NumericId,
  assignmentList: ServiceItemAssignment[],
  isUnassignRequest: boolean = false
): Promise<any> => {
  const { data } = (await axiosInstance.request({
    url: replaceParams(API_ENDPOINTS.SERVICE_ITEM_ASSIGNMENT, {
      ':viewId': viewId,
    }),
    data: {
      assignments: assignmentList,
    },
    method: isUnassignRequest ? 'DELETE' : 'POST',
  })) as AxiosResponse<ServiceItemApiResponse>;

  return data;
};

export const orderRatingSubmit = async (orderId: number, ratings: number) => {
  const data = {
    totalRating: 5,
    userRating: ratings,
  };

  try {
    const response = await postOrderRating(data, orderId);
    trackEvents(CreateOrderEvt.OrderHistoryRatingSuccess, { rating: ratings });
    return response;
  } catch (error) {
    throw error;
  }
};

export const copyComponent = async (
  viewId: NumericId,
  layerId: NumericId,
  data: CopyComponentPayload
): Promise<AxiosResponse<CopyComponentResponse>> => {
  return axiosInstance.post(
    replaceParams(API_ENDPOINTS.COPY_COMPONENT, {
      ':viewId': viewId,
      ':layerId': layerId,
    }),
    data
  );
};

export const bulkUpdateComponent = async (
  viewId: NumericId,
  layerId: NumericId,
  data: Partial<BulkUpdateComponentPayload>
) => {
  return axiosInstance.post(
    replaceParams(API_ENDPOINTS.BULK_UPDATE_COMPONENT, {
      ':viewId': viewId,
      ':layerId': layerId,
    }),
    data
  );
};

export const updateLayer = async (
  viewId: number,
  layerId: number,
  payload: any
) => {
  return axiosInstance.patch(
    replaceParams(API_ENDPOINTS.VIEW_LAYER_DETAILS, {
      ':viewId': viewId,
      ':layerId': layerId,
    }),
    payload
  );
};

export const deleteLayer = async (viewId: NumericId, layerId: NumericId) => {
  return axiosInstance.delete(
    replaceParams(API_ENDPOINTS.VIEW_LAYER_DETAILS, {
      ':viewId': viewId,
      ':layerId': layerId,
    })
  );
};

export const cloneView = async (viewId: NumericId, data: any) => {
  return axiosInstance.post(
    replaceParams(API_ENDPOINTS.CLONE_VIEW, {
      ':viewId': viewId,
    })
  );
};

export const getHierarchicalViewList = async (
  orderId: number
): Promise<HierarchicalViewListResponse> => {
  const response = await axiosInstance.get(
    replaceParams(API_ENDPOINTS.GET_HIERARCHICAL_VIEW_LIST, {
      ':orderId': orderId,
    })
  );
  return response.data as HierarchicalViewListResponse;
};

export const deleteViewByViewId = async (
  orderId: NumericId,
  viewId: NumericId
) => {
  return axiosInstance.delete(
    replaceParams(API_ENDPOINTS.ORDER_VIEW_DETAILS, {
      ':orderId': orderId,
      ':viewId': viewId,
    })
  );
};

export const setDefaultView = async (orderId: NumericId, viewId: NumericId) => {
  return axiosInstance.put(
    replaceParams(API_ENDPOINTS.ORDER_VIEW_DETAILS, {
      ':orderId': orderId,
      ':viewId': viewId,
    }),
    { isDefault: true }
  );
};

export const renameView = async (
  orderId: NumericId,
  viewId: NumericId,
  data: any
) => {
  return axiosInstance.put(
    replaceParams(API_ENDPOINTS.ORDER_VIEW_DETAILS, {
      ':orderId': orderId,
      ':viewId': viewId,
    }),
    data
  );
};

export const updateOrderDetailsV2 = async (orderId: number, data: any) => {
  try {
    const response = await axiosInstance.patch(
      replaceParams(API_ENDPOINTS.UPDATE_ORDER_DETAILS_V2, {
        ':orderId': orderId,
      }),
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const moveComponentToLayer = async (
  viewId: NumericId,
  layerId: NumericId,
  data: any
) => {
  return axiosInstance.post(
    replaceParams(API_ENDPOINTS.MOVE_COMPONENT, {
      ':viewId': viewId,
      ':layerId': layerId,
    }),
    data
  );
};

export const getFeatureStyleCatalogue =
  async (): Promise<FeatureStyleCatalogueResponse> => {
    const response = await axiosInstance.get(
      API_ENDPOINTS.GET_FEATURE_STYLE_CATALOGUE
    );
    return response.data.data as FeatureStyleCatalogueResponse;
  };

export const addFeatureStyleToCatalogue = async (
  featureId: number,
  styleObj: LayerStyle,
): Promise<FeatureStyleCatalogueResponse> => {
  const response = await axiosInstance.post(
    replaceParams(API_ENDPOINTS.ADD_FEATURE_STYLE_TO_CATALOGUE, {
      ':featureId': featureId,
    }),
    styleObj
  );
  return response.data.data as FeatureStyleCatalogueResponse;
};

export const updateFeatureStyleCatalogue = async (
  featureId: number,
  styleId: number,
  styleObj: LayerStyle,
): Promise<FeatureStyleCatalogueResponse> => {
  const response = await axiosInstance.put(
    replaceParams(API_ENDPOINTS.UPDATE_FEATURE_STYLE_CATALOGUE, {
      ':featureId': featureId,
      ':styleId': styleId,
    }),
    styleObj
  );
  return response.data.data as FeatureStyleCatalogueResponse;
};

export const updateParcelStyle = async (
  orderHash: string,
  style: ApiParcelStyle
) => {
  const bodyFormData = new FormData();
  bodyFormData.append('orderHashed', orderHash);
  bodyFormData.append('style', JSON.stringify({ ['Parcel']: { ...style } }));

  try {
    await axiosInstance.post(API_ENDPOINTS.UPDATE_PARCEL_STYLE, bodyFormData);
  } catch (error) {
    NotifyError("Parcel style couldn't be saved.", {
      api: 'update-parcel-style',
      orderHash,
    });
    console.error(error);
  }
};
