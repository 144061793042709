import React, { createContext, useState, useContext, useEffect } from 'react';
import useElementPositioningAndVisibility from '../modules/property/hooks/useElementPositioningAndVisibility';
import {
  State as PositioningAndVisibilityState,
  ElementName,
} from '../modules/property/types/element-position-visibility.types';

interface ContextValue {
  elementStates: {
    [key: string]: PositioningAndVisibilityState[ElementName];
  };
  normalLayerPanelOpen: boolean;
  tertiaryPanelOpen: boolean;
  setTertiaryPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setNormalLayerPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  orderDetailsPanelOpen: boolean;
  setOrderDetailsPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  estimationViewServiceMapTabOpen: boolean;
  setEstimationViewServiceMapTabOpen: React.Dispatch<React.SetStateAction<boolean>>;
  estimationViewEstimationTabOpen: boolean;
  setEstimationViewEstimationTabOpen: React.Dispatch<React.SetStateAction<boolean>>;
  estimationTabBreakdownTabOpen: boolean;
  setEstimationTabBreakdownTabOpen: React.Dispatch<React.SetStateAction<boolean>>;
  estimationTabInvoiceTabOpen: boolean;
  setEstimationTabInvoiceTabOpen: React.Dispatch<React.SetStateAction<boolean>>;
  notesReportsSnapsPanelOpen: boolean;
  setNotesReportsSnapsPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isViewPinned: boolean;
  setIsViewPinned: React.Dispatch<React.SetStateAction<boolean>>;
  setAllPanelsVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  setEstimationSheetExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const ElementPositioningContext = createContext<ContextValue | undefined>(
  undefined
);

const ElementPositioningProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [normalLayerPanelOpen, setNormalLayerPanelOpen] = useState<boolean>(false);
  const [tertiaryPanelOpen, setTertiaryPanelOpen] = useState<boolean>(false);
  const [orderDetailsPanelOpen, setOrderDetailsPanelOpen] = useState<boolean>(false);
  const [estimationViewServiceMapTabOpen, setEstimationViewServiceMapTabOpen] = useState<boolean>(false);
  const [estimationViewEstimationTabOpen, setEstimationViewEstimationTabOpen] = useState<boolean>(false);
  const [estimationTabBreakdownTabOpen, setEstimationTabBreakdownTabOpen] = useState<boolean>(false);
  const [estimationTabInvoiceTabOpen, setEstimationTabInvoiceTabOpen] = useState<boolean>(false);
  const [estimatonSheetExpanded, setEstimationSheetExpanded] = useState<boolean>(false);
  const [notesReportsSnapsPanelOpen, setNotesReportsSnapsPanelOpen] = useState<boolean>(false);
  const [isViewPinned, setIsViewPinned] = useState<boolean>(false);
  const [allPanelsVisibility, setAllPanelsVisibility] = useState<boolean>(true);

  const state = useElementPositioningAndVisibility(
    normalLayerPanelOpen,
    orderDetailsPanelOpen,
    estimationViewServiceMapTabOpen,
    estimationViewEstimationTabOpen,
    estimationTabBreakdownTabOpen,
    estimationTabInvoiceTabOpen,
    notesReportsSnapsPanelOpen,
    estimatonSheetExpanded,
    isViewPinned,
    tertiaryPanelOpen,
    allPanelsVisibility
  );

  const contextValue: ContextValue = {
    elementStates: {
      [ElementName.MapControlsPosition]: state[ElementName.MapControlsPosition],
      [ElementName.NearmapDropdownPosition]:
        state[ElementName.NearmapDropdownPosition],
      [ElementName.ActionCenterPosition]:
        state[ElementName.ActionCenterPosition],
      [ElementName.MapToolBarPosition]: state[ElementName.MapToolBarPosition],
      [ElementName.OrderDetailsPanelPosition]: state[ElementName.OrderDetailsPanelPosition],
      [ElementName.NotesReportsSnapsPanelPosition]: state[ElementName.NotesReportsSnapsPanelPosition],
    },
    normalLayerPanelOpen,
    tertiaryPanelOpen,
    setTertiaryPanelOpen,
    orderDetailsPanelOpen,
    setOrderDetailsPanelOpen,
    setNormalLayerPanelOpen,
    estimationViewServiceMapTabOpen,
    setEstimationViewServiceMapTabOpen,
    estimationViewEstimationTabOpen,
    setEstimationViewEstimationTabOpen,
    estimationTabBreakdownTabOpen,
    setEstimationTabBreakdownTabOpen,
    setEstimationSheetExpanded,
    estimationTabInvoiceTabOpen,
    setEstimationTabInvoiceTabOpen,
    notesReportsSnapsPanelOpen,
    setNotesReportsSnapsPanelOpen,
    isViewPinned,
    setIsViewPinned,
    setAllPanelsVisibility,
  };

  return (
    <ElementPositioningContext.Provider value={contextValue}>
      {children}
    </ElementPositioningContext.Provider>
  );
};

const useElementPositioningContext = (): ContextValue => {
  const context = useContext(ElementPositioningContext);
  if (!context) {
    throw new Error(
      'useElementPositioningContext must be used within an ElementPositioningProvider'
    );
  }
  return context;
};

export { ElementPositioningProvider, useElementPositioningContext };
