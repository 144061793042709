import { NotifyError } from '@/helpers/notification-utils';
import * as turf from '@turf/turf';
import moment from 'moment';
import { Draw } from 'ol/interaction';
import {
  CUT_SPLIT_STYLE_MODE,
  CUT_STYLE_MODE,
  cutInteractionStyle,
} from '../../mapGlobals/styles';
import { addCrossLayerSnap, resetCrossLayerSnap } from './snapping';
import {
  addFeaturesToMap,
  createDrawnPolygon,
  featureToPolygon,
  generateTempId,
} from './utilities';
import { generatePropertiesFromFeature } from '../autoSave/utils';
import { updateGeoJsonPropertiesInFeature } from './doughnutTool';

let parcelLayer;
let cutInteraction;

export const cutInteractionActive = (
  sourceLayer,
  mapRef,
  type,
  callback,
  allLayerRefsObj,
  hasToolStart,
  args
) => {
  parcelLayer = sourceLayer;
  if (cutInteraction) {
    mapRef.removeInteraction(cutInteraction);
    resetCrossLayerSnap(mapRef);
  }
  cutInteraction = new Draw({
    source: sourceLayer.getSource(),
    type: 'Polygon',
    style: cutInteractionStyle,
  });
  mapRef.addInteraction(cutInteraction);
  addCrossLayerSnap(mapRef, allLayerRefsObj);
  // parcelLayer.setStyle(CUTTING_MODE_STYLE);
  if (parcelLayer && type) {
    parcelLayer.setStyle(CUT_SPLIT_STYLE_MODE);
    parcelLayer.setStyle(CUT_STYLE_MODE(type, parcelLayer.getStyle()));
  }
  //This function will be used to maintain Point level history
  args.interactionsDrawingPointLevelTracking(cutInteraction);
  onDrawStart(cutInteraction, hasToolStart);
  onDrawEnd(
    cutInteraction,
    mapRef,
    callback,
    type,
    allLayerRefsObj,
    hasToolStart
  );
};

export const removeCutInteraction = (mapRef) => {
  mapRef?.getInteractions().forEach((interaction) => {
    if (interaction === cutInteraction) {
      mapRef.removeInteraction(cutInteraction);
    }
  });
};

const onDrawStart = (cutInteraction, hasToolStart) => {
  cutInteraction.on('drawstart', (event) => {
    hasToolStart(true);
  });
};

const onDrawEnd = (
  cutInteraction,
  mapRef,
  callback,
  type,
  allLayerRefsObj,
  hasToolStart
) => {
  cutInteraction.on('drawend', (event) => {
    let timeStamp = moment();
    let drawnPolygon = createDrawnPolygon(event.feature);

    // Check if drawn polygon is invalid
    const kinks = turf.kinks(drawnPolygon);
    let isKinky = false; // :)
    if (kinks.features.length !== 0) {
      isKinky = true;
      NotifyError('Cannot draw self intersecting polygons', { tool: 'cut' });
    }

    mapRef.removeLayer(parcelLayer);

    let listOfPolygons = [];
    let listOfIntersectingPolygons = [];
    let callbackReqData = [];
    let vectorSource = null;
    let initialParcelLayerFeatures = parcelLayer.getSource().getFeatures();

    initialParcelLayerFeatures.forEach((feature) => {
      let old_properties = { ...feature.getProperties() };
      const parcelPolygon = featureToPolygon(feature);
      if (turf.booleanIntersects(parcelPolygon, drawnPolygon) && !isKinky) {
        listOfIntersectingPolygons.push(feature);
      } else {
        let new_polygon = turf.feature(parcelPolygon.geometry, {
          ...old_properties,
        });
        listOfPolygons.push(new_polygon);
      }
    });

    listOfIntersectingPolygons.forEach((feature) => {
      let properties = { ...feature.getProperties() };
      delete properties.geometry;
      try {
        const parcelPolygon = featureToPolygon(feature);
        // commenting out as part of CU-2prdb28
        // let kinks = turf.kinks(parcelPolygon);
        // if (kinks.features.length !== 0) {
        //   let reqData = {
        //     geoJson: parcelPolygon,
        //     timeStamp,
        //     type: 'deleteInteraction',
        //     componentId: properties.actualComponentId,
        //   };
        //   if (callback) {
        //     callbackReqData.push(reqData);
        //     return;
        //   }
        // }
        if (turf.booleanContains(drawnPolygon, parcelPolygon)) {
          /** Case where the polygon is truly inside cut tool polygon */
          let reqData = {
            geoJson: parcelPolygon,
            timeStamp,
            type: 'deleteInteraction',
            componentId: properties.actualComponentId ?? properties.componentId,
          };
          if (callback) {
            callbackReqData.push(reqData);
            return;
          }
        }

        let difference = turf.difference(parcelPolygon, drawnPolygon);
        if (difference) {
          if (
            turf.booleanOverlap(parcelPolygon, drawnPolygon) ||
            turf.booleanContains(parcelPolygon, drawnPolygon)
          ) {
            /** Case where the cut tool is overlapping or making a hole into polygon */
            if (difference.geometry.type === 'MultiPolygon') {
              difference.geometry.coordinates.forEach((coord, index) => {
                let tempId = generateTempId();
                const componentId = !index
                  ? properties.actualComponentId ?? properties.componentId
                  : tempId;

                let polygonT = turf.polygon(
                  coord,
                  !index
                    ? {
                        ...properties,
                        componentId,
                        ID: componentId,
                        id: componentId,
                      }
                    : {
                        ...properties,
                        temp_id: tempId,
                        tempId: tempId,
                        name: undefined,
                        componentId: componentId,
                        id: componentId,
                        ID: componentId,
                      }
                );
                polygonT = updateGeoJsonPropertiesInFeature(polygonT);
                listOfPolygons.push(polygonT);
                let reqData = {
                  geoJson: polygonT,
                  timeStamp,
                  componentId: !index
                    ? properties.actualComponentId ?? properties.componentId
                    : componentId,
                };
                if (callback) {
                  callbackReqData.push(reqData);
                }
              });
            } else {
              const tempId = generateTempId();
              const componentId =
                  properties.actualComponentId ?? properties.componentId ?? tempId;
              let polygonT = turf.feature(difference.geometry, {
                ...properties,
                tempId: componentId,
                temp_id: componentId,
                componentId,
                ID: componentId,
                id: componentId,
                ...generatePropertiesFromFeature(difference),
              });

              polygonT = updateGeoJsonPropertiesInFeature(polygonT);
              listOfPolygons.push(polygonT);

              let reqData = {
                // name:"ID1",
                geoJson: difference,
                timeStamp,
                componentId:
                  properties.actualComponentId ?? properties.componentId,
              };
              if (callback) {
                callbackReqData.push(reqData);
              }
            }
          }
        }
      } catch (e) {
        parcelLayer.getSource().clear();
        NotifyError(
          `Something went wrong with ${properties.componentIndexing}`,
          { tool: 'cut' }
        );
      }
    });
    if (callbackReqData.length && callback) {
      callback('bulkInteraction', callbackReqData);
    }
    hasToolStart(false);
    addFeaturesToMap(listOfPolygons, parcelLayer, CUT_SPLIT_STYLE_MODE);
    mapRef.addLayer(parcelLayer);
  });
};
