// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Group9IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Group9Icon(props: Group9IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 12 12"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M6 0a6 6 0 110 12A6 6 0 116 0zM4.422 5.163c-.009-.6 1.366-.931 1.989-.766 1.58.418-.707 4.095-.312 4.639.22.303 1.22.065 1.22.339 0 .276-.678.99-1.833.753-1.156-.239-.844-1.046-.688-1.697.155-.65.825-2.448.825-2.76 0-.977-1.216-.25-1.201-.508zm2.254-3.338a.902.902 0 11.001 1.804.902.902 0 01-.001-1.804z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Group9Icon;
/* prettier-ignore-end */
