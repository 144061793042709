// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon89IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon89Icon(props: Icon89IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 16 16"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M10.664 10.667V12a1.333 1.333 0 01-1.333 1.334H3.997A1.333 1.333 0 012.664 12V6.667a1.333 1.333 0 011.333-1.333h1.334V4a1.333 1.333 0 011.333-1.333h5.333A1.333 1.333 0 0113.331 4v5.334a1.333 1.333 0 01-1.334 1.333h-1.333z"
        }
        stroke={"#666"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      ></path>

      <path
        d={
          "M6.661 5.334H5.328v1.333m0 2.667v1.333h1.333m2.667-5.333h1.334v1.333m0 2.667v1.333H9.328"
        }
        stroke={"#666"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      ></path>
    </svg>
  );
}

export default Icon89Icon;
/* prettier-ignore-end */
