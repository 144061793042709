import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import { IStore } from '../../../../store/types';
import {
  IMapEditorDeleteConfirmationModal,
  MapEditorModalType,
} from '../../../../store/map-editor';
import { Nullable } from '../../../../modules/common/types';
import { LayerWithComponents } from '../../../../modules/property/api/types';
import { setDeleteConfirmationModalData } from '../../../../store/map-editor/actions';
import { useMapEditor } from '../../../../modules/property/hooks/useMapEditor';
import { CommandType } from '../../../../modules/property/commands/types';
import MrDeleteLayerPopup from '../../../PlasmicComponents/MrDeleteLayerPopup';

/**
 * TODO: We don't really need a redux state and following to display confirmation modal details.
 * Just create a utility `await` helper for confirmation and once the confirmation is done call the DeleteLayerCommand there only
 **/
const MapEditorDeleteConfirmationModal = () => {
  const dispatch = useDispatch();
  const { execute } = useMapEditor();
  const [loading, setLoading] = React.useState<boolean>(false);
  const data = useSelector<IStore, IMapEditorDeleteConfirmationModal>(
    (state) => state.mapEditor.modals[MapEditorModalType.DELETE]
  );

  const currentView = useSelector<IStore, any>(
    (state) => state.order.currentViewData
  );

  const layer = useSelector<IStore, Nullable<LayerWithComponents>>((state) => {
    if (!data.layerId) return null;

    return (
      state.order.layerList.find((_layer) => _layer.layerId === data.layerId) ??
      null
    );
  });

  if (!data.open || !layer || !currentView) {
    return null;
  }

  const handleModalClose = () => {
    dispatch(
      setDeleteConfirmationModalData({
        open: false,
        layerId: null,
      })
    );
  };

  const handleDelete = async () => {
    setLoading(true);

    try {
      await execute(CommandType.DELETE_LAYER, {
        layerId: layer!.layerId,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={data.open}
      closable={false}
      footer={null}
      className='copy-layer'
    >
      <MrDeleteLayerPopup
        onCancel={handleModalClose}
        onDeleteLayer={handleDelete}
      />
    </Modal>
  );
};

export default MapEditorDeleteConfirmationModal;
