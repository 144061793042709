import * as turf from '@turf/turf';
import { Modify } from 'ol/interaction';
import {
  drawPathStyleFunction,
  EDIT_STYLE_MODE,
  STYLE_INTERNAL_9,
} from '../../mapGlobals/styles';
import { _get } from '../../utilities/lodashUtils';
import { addCrossLayerSnap, resetCrossLayerSnap } from './snapping';
import moment from 'moment';
import { createDrawnPolygon } from '@/helpers/mapUtils/tools/utilities';
import { Store } from '../../../store';
import {
  setModifyDraftEvents,
  modifySyncState as setModifySyncState,
} from '../../../store/map/actions';
import { ModifySyncState } from '../../../modules/common/types';

let parcelLayer;
let modifyInteraction;
let snap_draw;
let initialListOfFeatures;
let collectModifyEvents = [];
let timeout = null;
let apiCallback = null;

const setModifyDraftEventsInRedux = (events) => {
  Store.dispatch(setModifyDraftEvents(events));
};

export const executeModification = () => {
  if (collectModifyEvents.length && apiCallback) {
    apiCallback(
      'bulkInteraction',
      collectModifyEvents,
      null,
      false,
      parcelLayer
    );
  }
};

export const modifyInteractionActive = (
  layer,
  mapRef,
  type,
  callback,
  pathStyle = null,
  allLayerRefsObj,
  hasToolStart
) => {
  apiCallback = callback;
  parcelLayer = layer;
  if (parcelLayer && type) {
    if (type === 'path') {
      parcelLayer.setStyle(
        drawPathStyleFunction(parcelLayer.getSource().getFeatures(), pathStyle)
      );
    } else {
      parcelLayer.setStyle(
        EDIT_STYLE_MODE(type, parcelLayer.getStyle(), pathStyle)
      );
    }
  }

  if (modifyInteraction) {
    mapRef.removeInteraction(modifyInteraction);
    resetCrossLayerSnap(mapRef);
  }
  modifyInteraction = new Modify({
    source: parcelLayer.getSource(),
    style: [STYLE_INTERNAL_9],
  });
  mapRef.addInteraction(modifyInteraction);
  addCrossLayerSnap(mapRef, allLayerRefsObj);
  onModifyStart(modifyInteraction, hasToolStart);
  onModifyEnd(
    mapRef,
    modifyInteraction,
    callback,
    type,
    pathStyle,
    allLayerRefsObj,
    hasToolStart
  );
};

export const removeModifyInteraction = (mapRef) => {
  if (modifyInteraction) {
    collectModifyEvents = [];
    setModifyDraftEventsInRedux(collectModifyEvents);
    timeout = null;
    mapRef.getInteractions().forEach((interaction) => {
      if (interaction === modifyInteraction) {
        mapRef.removeInteraction(modifyInteraction);
      }
    });
    mapRef.getInteractions().forEach((interaction) => {
      if (interaction === snap_draw) {
        mapRef.removeInteraction(snap_draw);
      }
    });
  }
};

const onModifyStart = (modifyInteraction, hasToolStart) => {
  modifyInteraction.on('modifystart', (event) => {
    hasToolStart(true);
    initialListOfFeatures = [...parcelLayer.getSource().getFeatures()];
  });
};

const onModifyEnd = (
  mapRef,
  modifyInteraction,
  callback,
  type,
  pathStyle,
  allLayerRefsObj,
  hasToolStart
) => {
  modifyInteraction.on('modifyend', (event) => {
    let timeStamp = moment();
    event.features.forEach((feature) => {
      let drawnPolygon = createDrawnPolygon(feature);
      let properties = feature.getProperties();
      let totalFeature = parcelLayer?.getSource()?.getFeatures();
      let reqFeature = turf.feature(drawnPolygon, {
        componentIndexing: _get(properties, 'componentIndexing'),
      });
      feature.setProperties({
        componentIndexing: _get(properties, 'componentIndexing'),
      });
      totalFeature.push(event.feature);
      if (type === 'path') {
        parcelLayer.setStyle(
          drawPathStyleFunction(
            parcelLayer.getSource().getFeatures(),
            pathStyle
          )
        );
      }
      let reqData = {
        geoJson: reqFeature,
        timeStamp,
        componentId: properties.actualComponentId ?? properties.componentId,
      };
      collectModifyEvents.push(reqData);

      setModifyDraftEventsInRedux([
        ...Store.getState().map.modifyDraftEvents,
        reqData,
      ]);

      Store.dispatch(setModifySyncState(ModifySyncState.UNSYNCED));

      hasToolStart(false);
    });
  });
};

export const resetModification = (unSyncedChangesCount) => {
  collectModifyEvents = collectModifyEvents.slice(0, -unSyncedChangesCount);
};
