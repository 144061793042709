import { MapCommandFunction } from '../types';
import { NumericId } from '../../../common/types';
import { bulkUpdateComponent, moveComponentToLayer } from '../../api';
import { removeLayerComponent } from '../../hooks/takeoff/useLayerRender';
import { Store } from '@/store';
import {
  removeLayerComponentsById,
  setHighlightedComponents,
} from '../../../../store/order/actions';
import { showNotification } from '../../../../components/storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../../../components/storybook/NotificationToast/types';

export interface CommandOptions {
  componentIds: NumericId[];
  layerId: NumericId;
}

export const MoveComponentToLayerCommand: MapCommandFunction<CommandOptions> = (
  map,
  viewId
) => {
  let _options: CommandOptions;

  return {
    init: (options) => {
      _options = options;
    },

    execute: async () => {
      if (!_options) return;

      /**
       * TODO: Add ability return data from `execute` function
       */
      await moveComponentToLayer(viewId, _options.layerId, {
        componentId: _options.componentIds,
        targetLayerId: _options.layerId,
      });

      showNotification(
        NOTIFICATIONS_TYPES.SUCCESS,
        'Item deleted successfully'
      );
    },
  };
};
