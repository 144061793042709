// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon90IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon90Icon(props: Icon90IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 16 16"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M3.87 8.922a1.206 1.206 0 110 2.411 1.206 1.206 0 010-2.411zm4.125 0a1.206 1.206 0 110 2.411 1.206 1.206 0 010-2.411zm4.133 0a1.206 1.206 0 110 2.412 1.206 1.206 0 010-2.412zM3.87 4.46a1.206 1.206 0 110 2.413 1.206 1.206 0 010-2.412zm4.125 0a1.206 1.206 0 110 2.413 1.206 1.206 0 010-2.412zm4.133 0a1.206 1.206 0 110 2.413 1.206 1.206 0 010-2.412z"
        }
        fill={"#999"}
      ></path>
    </svg>
  );
}

export default Icon90Icon;
/* prettier-ignore-end */
