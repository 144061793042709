import { atom } from 'jotai/vanilla';
import { TakeoffActionCenterType } from '../../../../modules/property/components/ActionCenter/Takeoff/types';
import { useAtom } from 'jotai/react';
import { store } from '../../../index';
import { NumericId } from '../../../../modules/common/types';

export const takeoffActionCenterLayerIdAtom = atom<NumericId | null>(null);

export const useTakeoffActionCenterLayerId = () =>
  useAtom(takeoffActionCenterLayerIdAtom);

export const takeoffActionCenterTypeAtom =
  atom<TakeoffActionCenterType | null>();

export const useTakeoffActionCenterType = () =>
  useAtom(takeoffActionCenterTypeAtom);

/** Use this to get `takeoffActionCenterTypeAtom` outside functional components */
export const getTakeoffActionCenterType = () =>
  store.get(takeoffActionCenterTypeAtom);
