import { atom, useAtom } from 'jotai';
import {
  FeatureStyleCatalogueResponse,
  PlasmicStyleOption,
} from '../../../modules/property/types/layer.types';
import { getWritableAtom, useWritableAtom } from '../useAtom';

export const featureStyleCatalogueAtom = atom<FeatureStyleCatalogueResponse>(
  []
);

export const currentFeatureStyleAtom = atom<PlasmicStyleOption | null>(null);

export const writableFeatureStyleCatalogueAtom = getWritableAtom(
  featureStyleCatalogueAtom
);

export const useFeatureStyle = () => useAtom(featureStyleCatalogueAtom);

export const useCurrentFeatureStyle = () => useAtom(currentFeatureStyleAtom);
