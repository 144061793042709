import { useAtom } from 'jotai';
import { currentViewAtom } from '.';
import { HierarchicalView } from '../../../modules/property/types/view.types';

/** Returns current view data */
const useCurrentView = () => {
  const [currentView, setCurrentView] =
    useAtom<HierarchicalView>(currentViewAtom);

  return {
    currentView: currentView as Omit<HierarchicalView, 'subViews'>,
    setCurrentView,
  };
};

export default useCurrentView;
