import { MapCommandFunction } from '../types';
import { NumericId } from '../../../common/types';
import { bulkUpdateComponent } from '../../api';
import { removeLayerComponent } from '../../hooks/takeoff/useLayerRender';
import { Store } from '@/store';
import {
  removeLayerComponentsById,
  setHighlightedComponents,
} from '../../../../store/order/actions';
import { showNotification } from '../../../../components/storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../../../components/storybook/NotificationToast/types';
import { updateTopologyWarnings } from '../../hooks/useUpdateTopologyWarnings';

export interface CommandOptions {
  componentIds: NumericId[];
  layerId: NumericId;
}

export const DeleteComponentCommand: MapCommandFunction<CommandOptions> = (
  map,
  viewId
) => {
  let _options: CommandOptions;

  return {
    init: (options) => {
      _options = options;
    },

    execute: async () => {
      if (!_options) return;

      try {
        const { data } = await bulkUpdateComponent(viewId, _options.layerId, {
          delete: _options.componentIds,
        });

        for (const componentId of _options.componentIds) {
          removeLayerComponent(_options.layerId, {
            componentId,
          });
        }

        Store.dispatch(setHighlightedComponents([]));

        Store.dispatch(
          removeLayerComponentsById({
            layerId: _options.layerId,
            componentIds: _options.componentIds,
          })
        );

        updateTopologyWarnings(
          data.topologyWarning ?? {},
          Store.getState().order.layerList,
          _options.layerId
        );

        showNotification(
          NOTIFICATIONS_TYPES.SUCCESS,
          'Item deleted successfully'
        );
      } catch (error) {
        console.error('Error while deleting component:', error);
      }
    },
  };
};
