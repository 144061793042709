import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Divider, Layout, Modal, Row, Spin, Tooltip } from 'antd';
import { Box, Typography } from '@siterecon/sr-styles/dist/elements';
import { ReactComponent as Cross } from '../../../../assets/action-center/close_icon_cross.svg';

import {
  useHistory,
  useLocation,
  useParams,
  withRouter,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as API_ENDPOINTS from '../../../../helpers/constants/APIEndpoints';
import { NotifyError } from '@/helpers/notification-utils';
import { getAxiosInstance } from '@/helpers/utilities/api-utils';
import { replaceParams } from '@/helpers/utilities/linkUtils';
import { _filter, _get } from '@/helpers/utilities/lodashUtils';
import { trackEvents } from '@/helpers/utilities';

import OrderStatusGuard from '@/modules/project/guards/OrderStatus';
import { OrderStatus, ViewType } from '@/modules/common/types';
import { SHARE_MAP_PAGE } from '../../../constants';
import { showNotification } from '../../../storybook/NotificationToast/NotificationToast';
import { NOTIFICATIONS_TYPES } from '../../../storybook/NotificationToast/types';
import { WidgetBarTabs } from '../../../types';
import { EstimationTabs } from './types';

import {
  baseView,
  currentView as setCurrentView,
  editableView,
  redViews,
  setAttributePanelVisibility,
  setOrderViews,
  setVisibleAttributesInLayerPanel,
} from '@/store/order/actions';
import { useBetaRelease } from '@/modules/common/guards/BetaRelease';
import { BetaFeature } from '@common/types';
import {
  toggleOrdersListPanelVisibility,
  toggleSecondaryPanelVisibility,
} from '@/store/ui/actions';
import { IStore } from '../../../../store/types';

import EstimationTabsPanel from '../../../../modules/property/components/LayerPanel/Estimation';
import TakeOffLayerPanel from '../../../../modules/property/components/LayerPanel/Takeoff/EmLayerPanel';
import EmViewDropDown from '../../../PlasmicComponents/EmViewDropDown2';
import EmLegacyEstimationViewAlert from '../../../PlasmicComponents/EmLegacyEstimationViewAlert';
import CoFeedbackStatus from '../../../PlasmicComponents/CoFeedbackStatus';
import ViewPanel from './ViewPanel';
import MapMeasurementFailed from './MapMeasurementFailed';
import MapEditorCopyModal from './MapEditorCopyModal';
import MapEditorDeleteConfirmationModal from './MapEditorDeleteConfirmationModal';
import MapEditorAddLayerModal from './MapEditorAddLayerModal';
import MapEditorBaseViewEditRestrictionModal from './MapEditorBaseViewEditRestrictionModal';

import withElementPositioningAndVisibility from '../../../../modules/property/hoc/withElementsPositioningAndVisibility';
import withViewSelectionOrCreationUtil from '../../../../modules/property/hoc/withViewSelectionOrCreationUtil';
import { withWidgetBarContext } from '../../../../modules/property/hoc/withWidgetBar';

import { useWidgetBarTabContext } from '../../../../modules/property/hooks/useWidgetBar';
import { useSwitchView } from '../../../../modules/property/hooks/view/useSwitchView';
import useViewList from '../../../../jotai/atoms/views/useViewList';
import useCurrentView from '../../../../jotai/atoms/views/useCurrentView';
import { useFetchViewList } from '../../../../modules/property/hooks/view/useFetchViewList';

import { getPlasmicOrderStatus } from '../utils/helpers';
import { getIsoTimeDifference } from './helpers';
import { layerPanelStaticP } from '../../../../modules/property/helpers/position.constant';
import { CreateOrderEvt } from '../../../../segment';
import { LayerWithComponents } from '../../../../modules/property/api/types';
import EODPricingStatusWrapper from './PricingFlow/EODPricingStatusWrapper';
import PricingDeliveryModal from './PricingFlow/PricingSuccessModal';
import {
  SWITCH_TO_STATIC_VIEW_SOURCE,
  VIEW_PANEL_SOURCE,
} from '../../../../events/constants';

import { useElementPositioningContext } from '../../../../Contexts/ElementPositioningContext';

import '../style.less';
import '../../../containers/feature-hover-panel/style.less';
import classNames from 'classnames';
import { useRole } from '../../../../modules/auth/guards/RoleGuard';
import { useParcelLayerToggle } from '../../../../modules/property/hooks/useParcelLayerToggle';

const { Sider } = Layout;
const axiosInstance = getAxiosInstance();

const ProjectFeaturePanel = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { switchView } = useSwitchView();
  const { elementStates } = useElementPositioningContext();
  const { activeTabs } = useWidgetBarTabContext();
  const viewDropdownRef = useRef<HTMLDivElement>(null);

  const layers = useSelector<IStore, LayerWithComponents[]>(
    (state) => state.order.layerList
  );

  const isEstimationWorkspace = useBetaRelease(BetaFeature.Estimations);

  const isServiceItemsVisible = useSelector<IStore, boolean>(
    (state: any) => state.order.attributePanelVisible
  );
  const currentUserName = useSelector(
    (state: any) => state.user.info.first_name
  );

  const [state, setState] = useState({
    isDataLoading: false,
    viewsList: [],
    selectedView: null,
    showAddViewModal: false,
    isDeleteView: [],
    isRenameView: [],
    selectedViewCpy: [],
    showAddLayerModal: false,
    showAddViewAfterLayer: false,
    isEditableBase: false,
    showApprovedOrderDetails: props.showApprovedOrderDetails,
    fromBaseView: false,
    current: location.pathname,
    noOfExtraCols: 0,
    panelWidth: layerPanelStaticP.width,
    matches: window.matchMedia('(min-width: 768px)').matches,
    lastSavedTimeText: '',
    activeEstimationTab: EstimationTabs.SERVICE_MAP,
    isViewpanelPinned: false,
    showLegacyEstimationViewModal: false,
    estimationSheetExpanded: false,
  });

  const {
    orderStatus,
    estimationStatus,
    isLayerPanel,
    isNotificationBarVisible,
  } = useSelector((state: any) => ({
    orderStatus: state.order.orderStatus,
    estimationStatus: state.order.estimationStatus,
    isLayerPanel: state.ui.isLayerPanel,
    isNotificationBarVisible: state.ui.notificationBar.visible,
  }));

  const { handleToggle: handleParcelToggle } = useParcelLayerToggle();

  const isShareMapPage = window.location.pathname.includes(SHARE_MAP_PAGE);

  const { isEstimator } = useRole();

  useFetchViewList();
  const { currentView: currentViewData } = useCurrentView();
  const { viewList } = useViewList();

  const { orderSummary, orderTileData, openFPanel, mapRef } = props;

  const { handleCloseWidgetBarTab } = useWidgetBarTabContext();

  useEffect(() => {
    loadOrderViews();
    state.matches ? setToggleFPanel(props.isLayerPanel) : '';
    if (props.isBulkView) {
      dispatch(toggleSecondaryPanelVisibility(true));
    } else {
      dispatch(toggleSecondaryPanelVisibility(false));
    }
  }, []);

  useEffect(() => {
    if (orderStatus === OrderStatus.MeasurementOngoing) {
      /** For other status initial parcel render would be done from TakeoffLayerPanel */
      handleParcelToggle(true);
    }
  }, []);

  useEffect(() => {
    if (props.showApprovedOrderDetails !== state.showApprovedOrderDetails) {
      setState((prev) => ({
        ...prev,
        showApprovedOrderDetails: props.showApprovedOrderDetails,
      }));
    }
    if (props.isLayerPanel !== isLayerPanel) {
      setToggleFPanel(props.isLayerPanel);
    }

    if (props.attributePanelVisible) {
      setState((prev) => ({ ...prev, noOfExtraCols: 1 }));
    }

    setState((prev) => ({
      ...prev,
      panelWidth: layerPanelStaticP.width,
    }));

    if (props.orderSummary.bulkOrderId) {
      if (props.orderSummary?.orderId !== props.orderSummary.orderId) {
        loadOrderViews();
      }
    }

    // Handle widget bar tab changes
    if (
      [
        WidgetBarTabs.SNAPSHOT,
        WidgetBarTabs.REPORTS,
        WidgetBarTabs.NOTES,
      ].includes(props.selectedWidgetBarTab) ||
      props.selectedWidgetBarTab === null
    ) {
      setToggleFPanel(false);
    } else {
      if (props.selectedWidgetBarTab !== WidgetBarTabs.VIEWS) {
        setToggleFPanel(true);
      }
    }

    if (props.selectedWidgetBarTab !== WidgetBarTabs.LAYERS) {
      props.toggleApprovedOrderDetails(false);
      props.setOrderDetailsPanelOpen(false);
      props.setNormalLayerPanelOpen(false);
      dispatch(toggleOrdersListPanelVisibility(false));
    }

    if (viewList?.length !== props.orderViews?.length) {
      setState((prev) => ({ ...prev, viewsList: viewList }));
    }
  }, [
    props.showApprovedOrderDetails,
    props.isLayerPanel,
    props.attributePanelVisible,
    props.orderSummary,
    props.selectedWidgetBarTab,
    viewList,
  ]);

  const loadOrderViews = useCallback(
    (shouldChangeUrlViewId = false) => {
      const orderId = props.orderSummary.orderId;

      setState((prev) => ({ ...prev, isDataLoading: true }));

      axiosInstance
        .get(replaceParams(API_ENDPOINTS.ORDER_VIEW, { ':orderId': orderId }))
        .then((res) => {
          const data = res.data;
          const searchQuery = new URLSearchParams(location.search);
          const viewId = searchQuery.get('viewId');
          let currentView = viewId
            ? _filter(data, (view) => +view.viewId === +viewId)
            : data;

          dispatch(setOrderViews(data));

          const hasBaseView =
            _filter(currentView, (view) => view.isBaseView).length > 0;
          const hasDefaultView =
            _filter(currentView, (view) => view.isDefault).length > 0;
          const staticViews = _filter(
            currentView,
            (view) => view.viewType === 'static'
          );

          const urlViewId = params.viewId;

          const selectedView =
            urlViewId &&
              urlViewId.length &&
              _filter(currentView, (view) => view.viewId === Number(urlViewId))
                .length > 0
              ? _filter(
                currentView,
                (view) => view.viewId === Number(urlViewId)
              )[0]
              : hasDefaultView
                ? _filter(currentView, (view) => view.isDefault)[0]
                : hasBaseView
                  ? _filter(currentView, (view) => view.isBaseView)[0]
                  : isShareMapPage
                    ? currentView[0]
                    : staticViews[staticViews.length - 1];

          if (shouldChangeUrlViewId) {
            switchView(selectedView.viewId);
          }

          if (
            ![OrderStatus.Draft, OrderStatus.MeasurementOngoing].includes(
              orderStatus
            )
          ) {
            props.handleWidgetBarTabChange(
              [ViewType.ESTIMATION, ViewType.DYNAMIC].includes(
                selectedView.viewType
              )
                ? WidgetBarTabs.ESTIMATION
                : WidgetBarTabs.LAYERS,
              props.selectedWidgetBarTab
            );
          }

          setState((prev) => ({
            ...prev,
            isDataLoading: false,
            viewsList: data,
            selectedView: selectedView,
          }));

          dispatch(
            baseView({
              isBaseView: _filter(
                currentView,
                (view) => view.viewId === selectedView.viewId
              )[0].isBaseView,
            })
          );
          dispatch(
            editableView({
              isEditableView: _filter(
                currentView,
                (view) => view.viewId === selectedView.viewId
              )[0].isEditable,
            })
          );
          dispatch(setCurrentView(selectedView));
          dispatch(redViews(data));
        })
        .catch((err) => {
          setState((prev) => ({ ...prev, isDataLoading: false }));
          NotifyError('Data Error', { api: 'load-views', orderId: orderId });
        });
    },
    [props.orderSummary, location, params, orderStatus, dispatch]
  );

  const setDataLoading = useCallback((loadingState) => {
    setState((prev) => ({ ...prev, isDataLoading: loadingState }));
  }, []);

  const reloadViews = useCallback(() => {
    loadOrderViews();
  }, [loadOrderViews]);

  const setToggleFPanel = useCallback(
    (option, shouldCloseAllFeatureCard = false) => {
      if (props.isBulkView) {
        dispatch(toggleSecondaryPanelVisibility(!!option));
      }
      props.toggleFeaturePanel(!!option, shouldCloseAllFeatureCard);
    },
    [props.isBulkView, dispatch, props.toggleFeaturePanel]
  );

  const toggleAddNewLayer = useCallback(
    (option) => {
      trackEvents('featurepanel__add-layer-click', {
        isBaseView: currentViewData?.isBaseView,
        orderId: currentViewData?.orderId,
        viewId: currentViewData?.viewId,
      });
      setState((prev) => ({ ...prev, showAddLayerModal: !!option }));
    },
    [currentViewData]
  );

  const toggleAddAfterLayer = useCallback(
    (option, isEditableBase = false, fromBaseView = false) => {
      setState((prev) => ({
        ...prev,
        showAddViewAfterLayer: !!option,
        isEditableBase: !!isEditableBase,
        fromBaseView,
      }));
    },
    []
  );

  const createNewView = useCallback(
    (data, isSelectedBaseView) => {
      showNotification(
        NOTIFICATIONS_TYPES.SUCCESS,
        `A new view named ${_get(data, 'name')} is successfully created.`
      );

      setState((prev: any) => {
        let updateView = [{ ...data }, ...prev.viewsList];
        dispatch(redViews(updateView));
        return {
          ...prev,
          viewsList: updateView,
          isDataLoading: false,
          showAddViewAfterLayer: false,
          selectedView: data,
        };
      });

      switchView(data.viewId);

      dispatch(editableView({ isBaseView: data.isBaseView }));
      dispatch(baseView({ isEditableView: data.isEditable }));

      trackEvents('featurepanel__new-view', {
        orderId: data?.orderId,
        viewId: data?.viewId,
        viewName: data?.name,
        viewCount: state.viewsList.length,
        isCopiedFromBaseView: isSelectedBaseView,
      });
    },
    [state.viewsList, switchView, dispatch]
  );

  const linkedViewClickHandler = useCallback(
    (viewId) => {
      const view = state.viewsList.find((v: any) => v.viewId === viewId);
      switchView(viewId.viewId);
    },
    [state.viewsList, switchView]
  );

  const setLastSavedTimeText = useCallback((text) => {
    setState((prev) => ({ ...prev, lastSavedTimeText: text }));
  }, []);

  const toggleEstimationSheetExpanded = (val) => {
    props.setEstimationSheetExpanded(val);
    setState((prev) => ({ ...prev, estimationSheetExpanded: val }));
  };

  const setActiveEstimationTab = useCallback((tab) => {
    setState((prev) => ({ ...prev, activeEstimationTab: tab }));
    if (tab === EstimationTabs.SERVICE_MAP)
      toggleEstimationSheetExpanded(false);
  }, []);

  const toggleViewpanelPin = useCallback(
    (val) => {
      setState((prev) => ({ ...prev, isViewpanelPinned: val }));
      if (!val) {
        props.showViewSelectionPanel(false);
      }
    },
    [props]
  );

  const setShowLegacyEstimationViewAlert = useCallback((show) => {
    setState((prev) => ({ ...prev, showLegacyEstimationViewModal: show }));
  }, []);

  const switchToLinkedViewHandler = useCallback(() => {
    trackEvents(CreateOrderEvt.ViewPanelSwitchToLinkedView, {
      source: SWITCH_TO_STATIC_VIEW_SOURCE.VIEW_DROPDOWN_LINKED_VIEW_CLICK,
    });
    props.handleStaticViewClick();
    props.handleWidgetBarTabChange(
      WidgetBarTabs.LAYERS,
      props.selectedWidgetBarTab
    );
  }, [props]);

  const createNewEstimationViewHandler = useCallback(() => {
    props.handleEstimationViewClick();
    props.handleWidgetBarTabChange(
      WidgetBarTabs.ESTIMATION,
      props.selectedWidgetBarTab
    );
    setShowLegacyEstimationViewAlert(false);
  }, [props, setShowLegacyEstimationViewAlert]);

  const getContainerStyle = () => {
    const { openFPanel, showApprovedOrderDetails, orderStatus } = props;
    const { isViewpanelPinned } = state;

    const baseStyle = {
      display: [OrderStatus.Draft, OrderStatus.MeasurementOngoing].includes(
        orderStatus
      )
        ? 'none'
        : 'flex',
      flexDirection: 'column',
      overflow: 'initial',
      borderTop: '0.5px solid #DDD',
    };

    const leftStyle = isViewpanelPinned ? { left: '304px' } : { left: '56px' };
    const widthStyle =
      openFPanel && !showApprovedOrderDetails
        ? { width: 'fit-content' }
        : { width: 0 };

    const topStyle = { top: isShareMapPage ? 0 : '50px' };

    return { ...baseStyle, ...leftStyle, ...widthStyle, ...topStyle };
  };

  const setLayerPanelStyle = () => {
    const isAAorRF =
      props.orderSummary.status === OrderStatus.AwaitingApproval ||
      props.orderSummary.status === OrderStatus.ResolvingFeedback;

    if (isAAorRF) {
      return {
        height: isNotificationBarVisible
          ? 'calc(100vh - 132px)'
          : 'calc(100vh - 88px)',
      };
    } else if (isNotificationBarVisible) {
      return { height: 'calc(100vh - 196px)' };
    } else {
      return { height: 'calc(100vh - 146px)' };
    }
  };

  if (!currentViewData) {
    return null;
  }

  const panelWidth = layerPanelStaticP.width;
  const showLayerPanel = activeTabs?.some(
    (tab) => tab === WidgetBarTabs.ESTIMATION || tab === WidgetBarTabs.LAYERS
  );

  return (
    <div
      className={classNames('timeline-container timeline-detail p-relative', {
        'd-none': !showLayerPanel,
      })}
      style={getContainerStyle()}
    >
      {!isEstimator && (
        <PricingDeliveryModal
          orderTileData={orderTileData}
          handleWidgetBarTabChange={props.handleWidgetBarTabChange}
          selectedWidgetBarTab={props.selectedWidgetBarTab}
          setActiveEstimationTab={setActiveEstimationTab}
        />
      )}

      {/* Render order ID and cross icon for static views */}
      {currentViewData &&
        currentViewData.viewType === 'static' &&
        showLayerPanel && (
          <div
            className='feature-hover-panel-body'
            style={{
              width: panelWidth,
            }}
          >
            <OrderStatusGuard except={[OrderStatus.BoltFailed]}>
              {!state.showApprovedOrderDetails && (
                <>
                  {openFPanel && (
                    <div
                      className='p-absolute feature-panel-cross'
                      style={{
                        left: panelWidth - 36,
                      }}
                    >
                      <Button
                        type={'link'}
                        icon={
                          <Cross
                            cursor='pointer'
                            onClick={() => {
                              setToggleFPanel(false, true);
                              dispatch(setAttributePanelVisibility(false));
                              dispatch(setVisibleAttributesInLayerPanel([]));
                              handleCloseWidgetBarTab(WidgetBarTabs.LAYERS);
                            }}
                          />
                        }
                        style={{ width: 24, height: 24, display: 'flex' }}
                        className={'flex-justify-align-center cross-icon'}
                      />
                    </div>
                  )}
                </>
              )}
            </OrderStatusGuard>

            {openFPanel && !state.showApprovedOrderDetails && (
              <Box>
                <div
                  className='d-flex align-center pl-4 pr-2'
                  style={{ height: 44 }}
                >
                  <Typography
                    fontSize={12}
                    lineHeight={'16px'}
                    style={{
                      color: '#2563EB',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={props.handleOrderHistoryOpen}
                  >
                    Order Id: {orderSummary.orderId}
                  </Typography>
                </div>

                {state.current.split('/')[1] !== SHARE_MAP_PAGE && (
                  <div style={{ borderTop: '1px solid #E8E8E8' }} />
                )}
              </Box>
            )}
          </div>
        )}

      {/* Render view dropdown and other controls */}
      {props.openFPanel && showLayerPanel && (
        <Row
          align='middle'
          className='py-2'
          justify='space-between'
          style={{ paddingLeft: 10, paddingRight: 10 }}
        >
          <div
            style={{
              width: currentViewData?.viewType === ViewType.STATIC ? 339 : 320,
            }}
          >
            <EmViewDropDown
              isLockedByUser={
                currentViewData?.siLockDetails?.lockedBy === currentUserName
              }
              isPricingInProgress={false}
              ref={viewDropdownRef}
              isBaseView={currentViewData?.isBaseView}
              isPricingLocked={currentViewData?.isPricingLocked}
              isSiAssignmentLocked={currentViewData?.isSIAssignmentLocked}
              siLockDetails={currentViewData?.siLockDetails}
              onSelectView={linkedViewClickHandler}
              onDropDown={() => {
                if (!props.viewSelectionPanelVisible) {
                  trackEvents(CreateOrderEvt.ViewPanelVisible, {
                    source: VIEW_PANEL_SOURCE.VIEW_DROPDOWN,
                    orderStatus: props.orderStatus,
                  });
                }
                props.showViewSelectionPanel(!props.viewSelectionPanelVisible);
              }}
              activeViewName={currentViewData?.name}
              className='w-100'
              linkedView={
                [ViewType.DYNAMIC, ViewType.ESTIMATION].includes(
                  currentViewData?.viewType
                ) &&
                // find linked view with id 'linkedView' and pass the name
                currentViewData?.linkedView &&
                state.viewsList.find(
                  (v: any) => v.viewId === currentViewData.linkedView
                )?.name
              }
              id={currentViewData?.viewId}
              onLinkedViewClicked={switchToLinkedViewHandler}
            />
          </div>

          {currentViewData && currentViewData.viewType === ViewType.ESTIMATION && (
            <Row
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              {[EstimationTabs.ESTIMATION, EstimationTabs.BREAKDOWN].includes(
                state.activeEstimationTab
              ) && (
                  <div>
                    <Tooltip
                      placement='bottom'
                      title={state.estimationSheetExpanded ? "Show Side Map" : "Show Full Screen"}
                      overlayClassName='thumbnail-tooltip'
                    >
                      {(() => {
                        const isExpanded = state.estimationSheetExpanded;
                        const iconProps = {
                          src: isExpanded
                            ? 'https://storage.googleapis.com/siterecon-public-assets/common-assets/svg/half-screen-est-sheet-icon.svg'
                            : 'https://storage.googleapis.com/siterecon-public-assets/common-assets/svg/full-screen-est-sheet-icon.svg',
                          alt: isExpanded ? 'collapse' : 'expand',
                          onClick: () => toggleEstimationSheetExpanded(!isExpanded),
                          style: {
                            width: '18px',
                            height: '18px',
                            cursor: 'pointer',
                          },
                        };

                        return <img {...iconProps} />;
                      })()}
                    </Tooltip>
                  </div>
                )}
              <div>
                <Button
                  type={'link'}
                  icon={
                    <Cross
                      cursor='pointer'
                      onClick={() => {
                        dispatch(setAttributePanelVisibility(false));
                        dispatch(setVisibleAttributesInLayerPanel([]));
                        setToggleFPanel(false);
                        props.handleCloseWidgetBarTab(WidgetBarTabs.ESTIMATION);
                      }}
                    />
                  }
                  style={{ width: 24, height: 24, display: 'flex' }}
                  className={'flex-justify-align-center'}
                />
              </div>
            </Row>
          )}
        </Row>
      )}

      {/* Render view selection panel */}
      <ViewPanel
        showAddNewViewAlertModal={state.showAddViewAfterLayer}
        isOpen={props.viewSelectionPanelVisible}
        onClose={() => props.showViewSelectionPanel(false)}
        createNewView={createNewView}
        orderViews={state.viewsList.map((item: any) => ({
          isBaseView: item.isBaseView,
          isDefault: item.isDefault,
          isEditable: item.isEditable,
          name: item.name,
          orderId: item.orderId,
          viewId: item.viewId,
          viewType: item.viewType,
          linkedView: item.linkedView,
        }))}
        setDataLoading={setDataLoading}
        currentView={currentViewData}
        onChange={(view) => switchView(view.viewId)}
        setShowAddNewViewAlertModal={(arg) =>
          setState((prev) => ({ ...prev, showAddViewAfterLayer: arg }))
        }
        reloadViews={reloadViews}
        toggleViewpanelPin={toggleViewpanelPin}
        isViewpanelPinned={state.isViewpanelPinned}
        openFPanel={props.openFPanel}
        handleWidgetBarTabChange={props.handleWidgetBarTabChange}
        orderStatus={orderStatus}
        estimationStatus={orderTileData.estimationStatus}
        isEstimationOrder={orderTileData.isEstimationOrder}
        isShareLinkPage={props.isShareLinkPage || false}
        viewDropdownRef={viewDropdownRef}
        isEstimationView={isEstimationWorkspace}
      />

      {/* Render order status widget for static views */}
      {props.openFPanel && currentViewData?.viewType === ViewType.STATIC && (
        <OrderStatusGuard
          status={[
            OrderStatus.AwaitingApproval,
            OrderStatus.ResolvingFeedback,
            OrderStatus.BoltInVerification,
          ]}
        >
          <>
            <Divider className='mb-2 mt-0' />
            {!props.isShareLinkPage && (
              <Box className='px-2 mb-2' style={{ width: state.panelWidth }}>
                {isEstimationWorkspace ? (
                  <EODPricingStatusWrapper
                    orderTileData={orderTileData}
                    orderStatus={getPlasmicOrderStatus(orderSummary.status)}
                    feedbackCount={
                      props.feedbackData?.open_count ||
                      props.feedbackData?.closed_count
                    }
                    onClick={() => {
                      props.setIsFeedbackPanelOpen(false);
                      props.setShowAllFeedbackCard(true);
                    }}
                    boltInVerificationTimer={getIsoTimeDifference(
                      props.boltEstimatedDeliveryTime,
                      null,
                      false
                    )}
                    feedbackType={
                      props.feedbackData?.open_count ? 'open' : 'closed'
                    }
                    takeoffDone={
                      [3, 4, 5].includes(orderSummary.status) &&
                      (orderTileData.estimationStatus ===
                        'estimation_pending' ||
                        orderTileData.estimationStatus ===
                        'estimation_in_progress')
                    }
                    pricingDone={
                      [3, 4, 5].includes(orderSummary.status) &&
                      orderTileData.estimationStatus === 'estimation_delivered'
                    }
                    withoutChevron={false}
                  />
                ) : (
                  <CoFeedbackStatus
                    orderStatus={getPlasmicOrderStatus(orderSummary.status)}
                    feedbackCount={
                      props.feedbackData?.open_count ||
                      props.feedbackData?.closed_count
                    }
                    onOpenFeedbackModal={() => {
                      props.setIsFeedbackPanelOpen(false);
                      props.setShowAllFeedbackCard(true);
                    }}
                    boltInVerificationTimer={getIsoTimeDifference(
                      props.boltEstimatedDeliveryTime,
                      null,
                      false
                    )}
                    feedbackType={
                      props.feedbackData?.open_count ? 'open' : 'closed'
                    }
                  />
                )}
              </Box>
            )}
          </>
        </OrderStatusGuard>
      )}

      {/* Render layer panel for static views */}
      {currentViewData && currentViewData.viewType === ViewType.STATIC && (
        <div>
          <OrderStatusGuard
            except={[
              OrderStatus.Draft,
              OrderStatus.MeasurementOngoing,
              OrderStatus.BoltFailed,
            ]}
            fallback={<MapMeasurementFailed />}
          >
            <Sider
              id='feature-panel-sider'
              trigger={null}
              collapsible
              collapsedWidth={0}
              theme='light'
              width={
                props.openFPanel && !state.showApprovedOrderDetails ? '100%' : 0
              }
            >
              <div id='feature-hover-panel'>
                {state.isDataLoading || !currentViewData ? (
                  <div
                    style={{
                      padding: '15px 20px',
                      width: '270px',
                      height: '92vh',
                    }}
                  >
                    <Spin />
                  </div>
                ) : (
                  <div>
                    <div
                      className={'feature-track d-flex'}
                      style={{
                        flexDirection: 'column',
                        borderTop: '1px solid #E8E8E8',
                        borderRight: '1px solid #E8E8E8',
                        ...setLayerPanelStyle(),
                      }}
                    >
                      <TakeOffLayerPanel
                        openFPanel={props.openFPanel}
                        showApprovedOrderDetails={
                          state.showApprovedOrderDetails
                        }
                        shortcutRef={props.shortCutRef}
                        selectedFeature={props.selectedFeature}
                        multiData={props.multiData}
                        clickFeatureAction={props.clickFeatureAction}
                        setNoteActions={props.setNoteActions}
                        toggleAddAfterLayer={toggleAddAfterLayer}
                        orderTileData={props.orderTileData}
                        popoverRef={props.popoverRef}
                        isViewPanelPinned={props.isViewPanelPinned}
                        isOrderHistoryPanelVisible={
                          props.isOrderHistoryPanelVisible
                        }
                        updateLayerAreaInfo={props.updateLayerAreaInfo}
                      />
                    </div>
                  </div>
                )}
              </div>
            </Sider>
          </OrderStatusGuard>
        </div>
      )}

      {/* Render estimation view panel */}
      {currentViewData &&
        // activeTabs?.includes(WidgetBarTabs.ESTIMATION) && //TODO: Review
        [ViewType.DYNAMIC, ViewType.ESTIMATION].includes(
          currentViewData.viewType
        ) && (
          <div className='p-relative' style={{ flex: 1 }}>
            <EstimationTabsPanel
              orderSummary={orderSummary}
              orderTileData={orderTileData}
              currentViewData={currentViewData}
              viewsList={state.viewsList}
              showViewSelectionPanel={props.showViewSelectionPanel}
              setLastSavedTimeText={setLastSavedTimeText}
              activeEstimationTab={state.activeEstimationTab}
              setActiveEstimationTab={setActiveEstimationTab}
              setShowLegacyEstimationViewAlert={
                setShowLegacyEstimationViewAlert
              }
              estimationSheetExpanded={state.estimationSheetExpanded}
              openFPanel={props.openFPanel}
              toggleViewpanelPin={toggleViewpanelPin}
              linkedView={
                [ViewType.DYNAMIC, ViewType.ESTIMATION].includes(
                  currentViewData?.viewType
                ) &&
                currentViewData?.linkedView &&
                state.viewsList.find(
                  (v) => v.viewId === currentViewData.linkedView
                )?.name
              }
              switchToLinkedViewHandler={switchToLinkedViewHandler}
            />
          </div>
        )}

      {/* Render legacy estimation view alert modal */}
      {state.showLegacyEstimationViewModal && (
        <Modal
          className='legacy-estimation-alert'
          visible={state.showLegacyEstimationViewModal}
          footer={null}
          closable={false}
          width={560}
          centered
        >
          <EmLegacyEstimationViewAlert
            onStayOnCurrentView={() => setShowLegacyEstimationViewAlert(false)}
            onCreateNewEstimationView={createNewEstimationViewHandler}
          />
        </Modal>
      )}

      <MapEditorCopyModal />

      <MapEditorDeleteConfirmationModal />

      <MapEditorBaseViewEditRestrictionModal
        setShowAddNewViewAlertModal={(arg) =>
          setState((prev) => ({ ...prev, showAddViewAfterLayer: arg }))
        }
      />

      <MapEditorAddLayerModal
        orderViews={state.viewsList.map((item: any) => ({
          isBaseView: item.isBaseView,
          isDefault: item.isDefault,
          isEditable: item.isEditable,
          name: item.name,
          orderId: item.orderId,
          viewId: item.viewId,
          viewType: item.viewType,
          linkedView: item.linkedView,
          lastFetchedTime: item.lastFetchedTime,
        }))}
      />
    </div>
  );
};

export default withRouter(
  withElementPositioningAndVisibility(
    withViewSelectionOrCreationUtil(withWidgetBarContext(ProjectFeaturePanel))
  )
);
