import { SingleComponent } from '../../api/types';
import { Layer } from 'ol/layer';
import { ID, NumericId } from '../../../common/types';
import { Store } from '@/store';
import { clickLayerHighlight } from '@helpers/mapUtils/featuresUtils';
import { findLayerById } from '@helpers/utilities';
import OlMap from 'ol/Map';
import { useDispatch } from 'react-redux';
import { setHighlightedComponents } from '@store/order/actions';
import {
  useTakeoffActionCenterLayerId,
  useTakeoffActionCenterType,
} from '../../../../jotai/atoms/property/takeoff/action-center';
import { setRecentSelectedItemIds } from './useLayerItemHover';

const getLayerList = () => Store.getState().order.layerList ?? [];

const getFeatureList = () => Store.getState().order.featureListInfo?.data ?? [];

const getHighlightedComponents = () => Store.getState().order.highlighted ?? [];

export const highlight = (
  layer: Layer,
  featureId: ID,
  highlight: boolean = true,
  shouldStyle: boolean = true
) => {
  if (!layer) return;

  /** TODO: Add `clicked=true` property to Feature when it is highlighted and `clicked=false` when it's un-highlighted */
  clickLayerHighlight(
    layer,
    featureId,
    highlight,
    getLayerList(),
    getFeatureList(),
    shouldStyle
  );
};

/** Used to control select/unselect from LayerPanel. TODO: Merge this and `useLayerHighlight` */
const useLayerSelect = (map: OlMap) => {
  const dispatch = useDispatch();
  const [, setTakeoffActionCenterType] = useTakeoffActionCenterType();
  const [, setTakeoffActionCenterLayerId] = useTakeoffActionCenterLayerId();
  const highlighted = getHighlightedComponents();
  const unHighlightComponents = (components: SingleComponent[]) => {
    for (const _component of components) {
      const layerId = _component.layerId;
      let sourceLayerOfComponent = findLayerById(layerId, map);

      if (sourceLayerOfComponent) {
        highlight(sourceLayerOfComponent, _component.componentId, false);
      }
    }
  };

  const handleAllFeatureUnHighlight = () => {
    unHighlightComponents(highlighted);
    dispatch(setHighlightedComponents([]));
  };

  const handleLayerHighlight = (layerId: NumericId) => {
    const sourceLayer: Layer | undefined = findLayerById(layerId, map);
    if (!sourceLayer) return;

    handleAllFeatureUnHighlight();
    setTakeoffActionCenterLayerId(layerId);
  };

  const handleFeatureHighlight = (
    component: SingleComponent | NumericId,
    layerId: NumericId,
    selected: boolean = true,
    isMultiSelect: boolean = false
  ) => {
    const sourceLayer: Layer | undefined = findLayerById(layerId, map);
    if (!sourceLayer) return;

    const componentId =
      typeof component === 'object' ? component.componentId : component;

    const componentData: Partial<SingleComponent> =
      typeof component === 'object'
        ? component
        : {
            componentId,
            layerId,
          };

    setTakeoffActionCenterLayerId(null);
    // setTakeoffActionCenterType(null);

    setRecentSelectedItemIds(componentId);

    if (isMultiSelect) {
      if (!selected) {
        highlight(sourceLayer, componentId, false);
        dispatch(
          setHighlightedComponents(
            highlighted.filter(
              (_component) => _component.componentId !== componentId
            )
          )
        );
        return;
      }

      highlight(sourceLayer, componentId, true);
      dispatch(setHighlightedComponents([...highlighted, componentData]));
      return;
    }

    if (!selected) {
      unHighlightComponents(highlighted);
      dispatch(setHighlightedComponents([]));
      return;
    }

    highlight(sourceLayer, componentId, true);
    unHighlightComponents(highlighted);
    dispatch(setHighlightedComponents([componentData]));
  };

  return {
    handleFeatureHighlight,
    handleLayerHighlight,
    handleAllFeatureUnHighlight,
  };
};

export default useLayerSelect;
