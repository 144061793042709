import { useSwitchViewCommand } from './view/useSwitchViewCommand';
import { CommandType, MapCommand } from './types';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../../store/types';
import { NumericId } from '../../common/types';
import MapContext from '@components/pages/project/MapContext';
import { useChangeToolCommand } from './tools/useChangeToolCommand';

export const hookCommandMap: Partial<Record<CommandType, string>> = {
  [CommandType.SWITCH_VIEW]: 'SwitchViewCommand',
  [CommandType.CHANGE_TOOL]: 'ChangeToolCommand',
};

export type HookCommandMapValues = Exclude<
  typeof hookCommandMap[keyof typeof hookCommandMap],
  undefined
>;

type HookCommandResponse = Record<HookCommandMapValues, MapCommand>;

export const useHookCommands = (): HookCommandResponse => {
  const map = useContext(MapContext);

  const currentViewId = useSelector<IStore, NumericId>(
    (state) => state.order.currentViewData?.viewId
  );

  const SwitchViewCommand = useSwitchViewCommand(map, currentViewId);
  const ChangeToolCommand = useChangeToolCommand(map, currentViewId);

  return {
    SwitchViewCommand,
    ChangeToolCommand,
  };
};
