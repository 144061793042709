import React, { useEffect, useState } from "react";
import { getAxiosInstance } from "../../../../../helpers/utilities/api-utils";
import useViewSelectionOrCreationUtil from "../../../../../modules/property/hooks/useViewSelectionOrCreationUtil";
import EmPricingDeliveredModal from "../../../../PlasmicComponents/EmPricingDeliveredModal";
import { WidgetBarTabs } from "../../../../types";
import useBidConsume from "../hooks/useBidConsume";
import { EstimationTabs } from "../types";
import * as API_ENDPOINTS from '../../../../../helpers/constants/APIEndpoints';
import { replaceParams } from "../../../../../helpers/utilities/linkUtils";
import { Modal } from "antd";
import { useSwitchView } from "../../../../../modules/property/hooks/view/useSwitchView";
import useViewList from "../../../../../jotai/atoms/views/useViewList";
import { useEstimationViewContext } from "../../../../../modules/property/context/EstimationView/context";

const axiosInstance = getAxiosInstance();

const PricingDeliveryModal = ({
  handleWidgetBarTabChange,
  selectedWidgetBarTab,
  setActiveEstimationTab,
  orderTileData,
}) => {

  const [visible, setVisible] = useState(false);
  const { handleEstimationViewClick } = useViewSelectionOrCreationUtil();
  const { switchView } = useSwitchView();
  const { viewList } = useViewList();
  const bidConsume = useBidConsume();

  useEffect(() => {
    if (orderTileData.estimationStatus === "estimation_delivered" && orderTileData.isEstimationReviewed === false) {
      setVisible(true);
    }
  }, [])

  const markEstimationAsReviewedOnBackend = () => {
    axiosInstance
      .post(
        replaceParams(API_ENDPOINTS.REVIEW_ESTIMATION, {
          ':orderId': orderTileData.orderNo,
        })
      )
      .then((res) => { })
      .catch((err) => { });
  }

  function findEstimatorViewId(data) {
    for (const view of data) {
      // Loop through subviews
      if (view.subViews && view.subViews.length > 0) {
        for (const subView of view.subViews) {
          // Check if the subview matches the given conditions
          if (!subView.isBaseView &&
            subView.siLockDetails &&
            subView.siLockDetails.lockSource === "pricing_team") {
            return subView.viewId;
          }
        }
      }
    }
    return null;
  }

  const onReviewEstimates = () => {
    handleWidgetBarTabChange(
      WidgetBarTabs.ESTIMATION,
      selectedWidgetBarTab
    );
    let viewId = findEstimatorViewId(viewList);
    if (viewId) {
      switchView(viewId);
    }
    handleEstimationViewClick();
    setTimeout(() => {
      setActiveEstimationTab(EstimationTabs.ESTIMATION)
    }, 600);
    markEstimationAsReviewedOnBackend();
    setVisible(false);
  }


  const onClose = () => {
    markEstimationAsReviewedOnBackend();
    setVisible(false);
  }

  const onRemindMeLater = () => {
    setVisible(false);
  }

  const pricingDelivered = {
    "totalService": orderTileData.serviceIds ? orderTileData.serviceIds.length : 0, // Check if serviceIds is not null
    "bidValue": orderTileData.bidValue,
    "grossMarginInPercentage": orderTileData.grossMarginPercentage,
    "deductedBidValue": orderTileData.bidValue,
    "workspaceBidCreated": bidConsume.workspaceBidCreated,
    "teamNotes": orderTileData.estimationNote,
  };
  
  if (!visible) return null;

  return (
    <Modal
      closable={false}
      visible={true}
      centered
      footer={null}
      bodyStyle={{ padding: 0 }}
      maskStyle={{ background: 'rgba(0, 0, 0, 0.5)' }}
      style={{ borderRadius: "50px" }}
    >
      <EmPricingDeliveredModal
        onClose={onClose}
        onReviewEstimates={onReviewEstimates}
        onRemindMeLater={onRemindMeLater}
        bidConsume={bidConsume}
        pricingDelivered={pricingDelivered}
      />
    </Modal>
  );
};

export default PricingDeliveryModal;
