// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon4IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon4Icon(props: Icon4IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 12 12"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g
        clipPath={"url(#9Qhm9olbLK2Ea)"}
        stroke={"#666"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      >
        <path
          d={"M6 1L1 3.5 6 6l5-2.5L6 1zM1 8.5L6 11l5-2.5M1 6l5 2.5L11 6"}
        ></path>
      </g>

      <defs>
        <clipPath id={"9Qhm9olbLK2Ea"}>
          <path fill={"#fff"} d={"M0 0h12v12H0z"}></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon4Icon;
/* prettier-ignore-end */
