import { useFeatureStyle } from '../../../../jotai/atoms/features';
import { NumericId } from '../../../common/types';
import { addFeatureStyleToCatalogue } from '../../api';
import {
  FeatureStyle,
  FeatureStyleCatalogueResponse,
  LayerStyle,
} from '../../types/layer.types';

const useAddFeatureStyle = () => {
  const [, setFeatureStyleCatalogue] = useFeatureStyle();

  const addStyle = async (featureId: NumericId, styleObj: LayerStyle) => {
    try {
      const response: FeatureStyleCatalogueResponse =
        await addFeatureStyleToCatalogue(featureId, styleObj);

      let style: FeatureStyle | null = null;

      const updatedResponse = response.map((feature) => {
        if (feature.featureId === featureId) {
          const styles = feature.styles.sort((a, b) => {
            if (!a.styleId && b.styleId) {
              return -1;
            }
            if (a.styleId && !b.styleId) {
              return 1;
            }
            if (a.styleId && b.styleId) {
              return a.styleId - b.styleId;
            }
            return -1;
          });

          if (styles.length > 0) {
            style = styles[styles.length - 1];
            styles[styles.length - 1].isEditing = true;
          }

          return { ...feature, styles: styles };
        }

        return feature;
      });

      setTimeout(() => setFeatureStyleCatalogue(updatedResponse), 100);

      return style;
    } catch (error) {
      console.error('Error adding feature style:', error);
    }
  };

  return { addStyle };
};

export default useAddFeatureStyle;
