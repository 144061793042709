import { useSelector } from 'react-redux';
import { IStore } from '../../../store/types';
import { useRole } from '../../auth/guards/RoleGuard';
import { ESTIMATION_STATUS } from '../../../components/pages/project/projectComponents/types';
import useBetaRelease from '../../common/guards/BetaRelease/useBetaRelease';
import { BetaFeature } from '../../common/types';

const useCanAssignServiceItems = () => {
  const { isEstimator } = useRole();
  const isEstimationsWorkspace = useBetaRelease(BetaFeature.Estimations);
  const {
    currentViewData,
    orderData: { estimationStatus, isEstimationOrder }
  } = useSelector((state: IStore) => state.order);

  const isSIAssignmentLocked = currentViewData?.isSIAssignmentLocked ?? false;
  const isInternalView = currentViewData?.isInternalView ?? false;

  const isEstimationCompletedOrSelfEstimated = [
    ESTIMATION_STATUS.ESTIMATION_DELIVERED,
    ESTIMATION_STATUS.SELF_ESTIMATED
  ].includes(estimationStatus);

  let canAssignServiceItems = true;

  if (!isEstimationsWorkspace) return (!currentViewData.isSIAssignmentLocked);

  console.log('canAssignServiceItems 1', estimationStatus, canAssignServiceItems)

  if (!isEstimator) {
    canAssignServiceItems = isEstimationCompletedOrSelfEstimated;
  }

  console.log('canAssignServiceItems 2', canAssignServiceItems)

  if (isInternalView && !isEstimator) {
    canAssignServiceItems = false;
  }

  console.log('canAssignServiceItems 3', canAssignServiceItems)

  if (canAssignServiceItems === true && isSIAssignmentLocked === true && !isEstimator) {
    // over-ride canAssignServiceItems in case for client
    canAssignServiceItems = false;
  }

  console.log('canAssignServiceItems 4', canAssignServiceItems)

  if (currentViewData.isBaseView && currentViewData.isInternalView && isEstimator) {
    // over-ride canAssignServiceItems for base view and internalview in case for estimator
    canAssignServiceItems = true;
  }

  if (isEstimator && currentViewData.isInternalView === false && currentViewData.isBaseView === false) {
    // don't allow estimator to access client created views 
    canAssignServiceItems = false;
  }

  if (isEstimator && currentViewData.isInternalView === true && currentViewData.isSIAssignmentLocked === true && currentViewData?.siLockDetails?.lockSource === 'pricing_team') {
    // don't allow estimator to access slef shipped views 
    canAssignServiceItems = false;
  }

  if(!isEstimationOrder) {
    canAssignServiceItems = true;
  }

  console.log('canAssignServiceItems 5', isEstimator, currentViewData, canAssignServiceItems)

  return canAssignServiceItems;
};

export default useCanAssignServiceItems;
