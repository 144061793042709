import { useMemo } from 'react';
import useViewList from '../../../../jotai/atoms/views/useViewList';
import { HierarchicalView, ViewIdMap, ViewMap } from '../../types/view.types';

const useViewMap = () => {
  const { viewList } = useViewList();

  const viewIdMap: ViewIdMap = useMemo(() => {
    return viewList.reduce((map, view) => {
      map[view.viewId] = view.subViews.map(
        (subView: HierarchicalView) => subView.viewId
      );
      return map;
    }, {});
  }, [viewList]);

  const viewMap: ViewMap = useMemo(() => {
    return viewList.reduce((map, view) => {
      map[view.viewId] = view.subViews.map(
        (subView: HierarchicalView) => subView
      );
      return map;
    }, {});
  }, [viewList]);

  return {
    viewIdMap,
    viewMap,
  };
};

export default useViewMap;
