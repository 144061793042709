import { MapCommandFunction } from '../types';
import { NumericId } from '../../../common/types';
import { Store } from '@/store';
import { LayerStyle } from '../../types/layer.types';
import { mapLayers } from '../../helpers/layer.data';
import { convertJSONStyleToOlStyle } from '@helpers/mapUtils/layerUtils';
import { LayerWithComponents } from '../../api/types';
import { FeatureType } from '../../../../components/feature-attributes/types';
import { updatePathStyleFunction } from '@helpers/mapGlobals/styles';
import { setOrderLayers } from '@store/order/actions';

export interface CommandOptions {
  layerId: NumericId;
  style: LayerStyle;
}

export const ChangeLayerStyleCommand: MapCommandFunction<CommandOptions> = (
  map,
  viewId
) => {
  let _options: CommandOptions;

  return {
    init: (options) => {
      _options = options;
    },

    execute: async () => {
      if (!_options) return;
      const { layerId } = _options;
      const style = JSON.parse(JSON.stringify(_options.style));

      try {
        const olLayer = mapLayers[layerId];
        const layers: LayerWithComponents[] = Store.getState().order.layerList;

        const layer = layers.find((_layer) => _layer.layerId === layerId);

        if (!layer || !olLayer || !layer.featureType) return;
        const { featureType } = layer;

        let olStyle: any = convertJSONStyleToOlStyle(featureType, style);

        if (featureType === FeatureType.PATH) {
          olStyle = updatePathStyleFunction(
            olLayer.getSource().getFeatures(),
            style
          );
        }
        olLayer.setStyle(olStyle);
        olLayer.set('style', style);

        /** We need to update the style in redux layers also */
        const updatedLayers = layers.map((layer) => {
          if (layer.layerId === layerId) {
            return { ...layer, style: style };
          }
          return layer;
        });
        Store.dispatch(setOrderLayers(updatedLayers));
      } catch (error) {
        console.error('Error while changing layer style');
      }
    },

    unExecute: async () => {},
  };
};
