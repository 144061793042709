export enum MapEditorActionTypes {
  ADD_TO_UNDO_STACK = 'ADD_TO_UNDO_STACK',
  POP_FROM_UNDO_STACK = 'POP_FROM_UNDO_STACK',
  ADD_TO_REDO_STACK = 'ADD_TO_REDO_STACK',
  POP_FROM_REDO_STACK = 'POP_FROM_REDO_STACK',

  SET_COPY_MODAL_DATA = 'SET_COPY_MODAL_DATA',
  SET_DELETE_CONFIRMATION_MODAL_DATA = 'SET_DELETE_CONFIRMATION_MODAL_DATA',
  SET_ADD_LAYER_MODAL_DATA = 'SET_ADD_LAYER_MODAL_DATA',
  SET_BASE_VIEW_EDIT_RESTRICTION_MODAL_DATA = 'SET_BASE_VIEW_EDIT_RESTRICTION_MODAL_DATA',
}
