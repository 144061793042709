import { useSelector } from 'react-redux';
import { IStore } from '../../../../../store/types';
import { LayerWithComponents, SingleComponent } from '../../../api/types';
import { useTakeoffActionCenterLayerId } from '../../../../../jotai/atoms/property/takeoff/action-center';
import { useMemo } from 'react';

export const useActionCenterComponents = () => {
  const [takeoffActionCenterLayerId] = useTakeoffActionCenterLayerId();

  const layers = useSelector<IStore, LayerWithComponents[]>(
    (state) => state.order.layerList
  );

  const highlightedComponents = useSelector<IStore, SingleComponent[]>(
    (state) => state.order.highlighted
  );

  const selectedLayer = layers.find(
    (layer) => layer.layerId === takeoffActionCenterLayerId
  );

  const selectedComponents = useMemo(() => {
    if (takeoffActionCenterLayerId) {
      return selectedLayer?.components ?? [];
    }

    return highlightedComponents;
  }, [takeoffActionCenterLayerId, highlightedComponents]);

  return {
    selectedComponents,
    selectedLayer,
    isEmptyLayerSelected:
      selectedLayer && selectedLayer.components.length === 0,
  };
};
