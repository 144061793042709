import ServiceCatalogue, {
  ServiceCatalogueProps,
} from '../../service-item/ServiceCatalogue';
import { getServiceItemAssignmentsByLayerAndComponents } from '../../../transformers/service-item.transformers';
import {
  Layer,
  LayerWithComponents,
  SingleComponent,
} from '../../../api/types';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../../store/types';
import { NumericId } from '../../../../common/types';
import { produce } from 'immer';
import { setOrderLayers, setHighlightedComponents } from '@store/order/actions';
import { useActionCenterComponents } from './useActionCenterComponents';
import { mapLayers } from '../../../helpers/layer.data';
import { Feature } from 'ol';
import { Store } from '@/store';

interface ServiceItemAssignmentProps
  extends Pick<
    ServiceCatalogueProps,
    'onSIDropdownToggle' | 'visible' | 'isColorPaletteVisible' | 'layerName'
  > {}

type UpdatedComponentMap = Record<NumericId, SingleComponent>;

let refSelectedComponents: SingleComponent[] = [];

const ServiceItemAssignment = (props: ServiceItemAssignmentProps) => {
  const dispatch = useDispatch();
  const layers = useSelector<IStore, LayerWithComponents[]>(
    (state) => state.order.layerList
  );

  const { selectedComponents } = useActionCenterComponents();

  useEffect(() => {
    refSelectedComponents = selectedComponents;
  }, [selectedComponents]);

  const componentsWithFeatureId = useMemo(() => {
    if (selectedComponents.length === 0) return [];
    const layerId = selectedComponents[0].layerId;

    const layer = layers.find((layer) => layer.layerId === Number(layerId));
    if (!layer) return [];

    return selectedComponents.map((component) => ({
      ...component,
      featureId: layer.featureId,
    }));
  }, [selectedComponents, layers.length]);

  const handleApply = (updatedComponents: UpdatedComponentMap) => {
    /** When service item assignments are updated, we need to update the `serviceItemIds` for layer.components stored in redux */
    const updatedLayers = produce(Store.getState().order.layerList, (draft) => {
      for (const layer of draft) {
        const olLayer = mapLayers[layer.layerId];
        const olFeaturesMap: Record<NumericId, Feature> = {};

        if (olLayer?.getSource()) {
          olLayer
            .getSource()
            .getFeatures()
            .forEach((feature) => {
              const componentId =
                feature.getId() ?? feature.getProperties()?.componentId;

              if (componentId in updatedComponents) {
                olFeaturesMap[componentId] = feature;
              }
            });
        }

        for (const component of layer.components) {
          if (updatedComponents.hasOwnProperty(component.componentId)) {
            const itemIds =
              updatedComponents[component.componentId].serviceItemIds;
            component.serviceItemIds = itemIds;

            /** We need to also update properties for OlMap feature */
            if (olFeaturesMap[component.componentId]) {
              olFeaturesMap[component.componentId].set(
                'serviceItemIds',
                itemIds
              );
            }
          }
        }
      }
    });

    dispatch(setOrderLayers(updatedLayers));

    /** We need to also updated `serviceItemIds` in highlighted components data */
    const updatedSelectedComponents = refSelectedComponents.map((component) => {
      if (updatedComponents.hasOwnProperty(component.componentId)) {
        return {
          ...component,
          serviceItemIds:
            updatedComponents[component.componentId].serviceItemIds,
        };
      }

      return component;
    });

    dispatch(setHighlightedComponents(updatedSelectedComponents));
  };

  if (layers.length === 0) return null;

  return (
    <ServiceCatalogue
      components={componentsWithFeatureId}
      initialAssignments={getServiceItemAssignmentsByLayerAndComponents(
        layers as LayerWithComponents[]
      )}
      onApply={handleApply}
      {...props}
    />
  );
};

export default ServiceItemAssignment;
