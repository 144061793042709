import { useQuery } from '@tanstack/react-query';
import { getHierarchicalViewList } from '../../api';
import useOrderId from '../useOrderId';
import useViewList from '../../../../jotai/atoms/views/useViewList';
import { useEffect } from 'react';

export const useFetchViewList = () => {
  const orderId: number = useOrderId();
  const { setViewList } = useViewList();

  const { data, refetch } = useQuery(
    ['getHierarchicalViewList', orderId],
    () => getHierarchicalViewList(orderId),
    {
      enabled: !!orderId,
    }
  );

  useEffect(() => {
    if (data) {
      setViewList(data);
    }
  }, [data]);

  return { refetch };
};
