import React, { useMemo } from 'react';
import DraggableWrapper from '../../../../common/components/DraggableWrapper';
import { trackEvents } from '../../../../../helpers/utilities';
import { CreateOrderEvt } from '../../../../../segment';
import { ACTION_CENTER_TYPE } from '../../../../../events/constants';
import TakeoffActionCenter, { TakeoffActionCenterProps } from './index';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../../store/types';
import { LayerWithComponents, SingleComponent } from '../../../api/types';
import { useActionCenterComponents } from './useActionCenterComponents';
import useCurrentView from '../../../hooks/useCurrentView';
import { useBetaRelease } from '@/modules/common/guards/BetaRelease';
import { BetaFeature } from '../../../../common/types';

interface DraggableTakeoffActionCenterProps extends TakeoffActionCenterProps {}

const DraggableTakeoffActionCenter = (
  props: DraggableTakeoffActionCenterProps
) => {
  const currentView = useCurrentView();
  const {
    selectedComponents,
    selectedLayer: layerFromComponents,
    isEmptyLayerSelected,
  } = useActionCenterComponents();

  const isEstimationWorkspace = useBetaRelease(BetaFeature.Estimations);

  const layers = useSelector<IStore, LayerWithComponents[]>(
    (state) => state.order.layerList
  );

  const hideActionCenter =
    !isEmptyLayerSelected &&
    selectedComponents &&
    selectedComponents.length === 0;

  const activeLayer = useMemo(() => {
    if (hideActionCenter) return null;

    return isEmptyLayerSelected
      ? layerFromComponents
      : layers.find(
          (_layer) => _layer.layerId === selectedComponents[0]?.layerId
        );
  }, [layers, selectedComponents]);

  if (!currentView || !activeLayer || hideActionCenter) return null;

  return (
    <DraggableWrapper
      dragHandlerClassName='drag-handle'
      bounds='window'
      initialPosition={{
        x: window.innerWidth - 282 - 16,
        y: isEstimationWorkspace ? 0 : -65,
      }} // temp fix for positioning
      initialSize={{ width: 282, height: 213 }}
      onDragStart={() =>
        trackEvents(CreateOrderEvt.ActionCenterDragStart, {
          type: ACTION_CENTER_TYPE.NONESTIMATION,
        })
      }
      onDragStop={() =>
        trackEvents(CreateOrderEvt.ActionCenterDragStop, {
          type: ACTION_CENTER_TYPE.NONESTIMATION,
        })
      }
    >
      <TakeoffActionCenter {...props} />
    </DraggableWrapper>
  );
};

export default DraggableTakeoffActionCenter;
