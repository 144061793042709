import { Box, Typography } from '@siterecon/sr-styles/dist/elements';
import { Col, Row } from 'antd';
import classNames from 'classnames';
import React from 'react';
import EmTakeOffLayerChips2 from '../../../../../PlasmicComponents/EmTakeOffLayerChips2';
import './style.less';

interface LayerDataProps {
  layer: any;
  chosenLayer: any;
  onChange: (layerId, keyVal) => void;
  keyVal: any;
}

const LayerData = ({
  layer,
  chosenLayer,
  onChange,
  keyVal,
}: LayerDataProps) => {
  return (
    <Box
      className={classNames('layer', {
        'layer--disabled': !layer.isEditable,
      })}
      style={{
        backgroundColor: `${
          chosenLayer && layer.layerId === chosenLayer.layerId ? '#FFF9E5' : ''
        }`,
      }}
      onClick={() => onChange(layer.layerId, keyVal)}
      key={layer.layerId}
    >
      <Row className='w-100' align='middle'>
        <Col span={layer.isEditable ? 24 : 17} className='d-flex'>
          <Box
            className='mr-2'
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <EmTakeOffLayerChips2
              featureType={layer.featureType}
              styleObject={{
                color: layer.style.color,
                fillColor: layer.style.fillColor,
                symbolColor: layer.style.symbolColor,
                symbolType: layer.style.symbolType,
                arrowColor: layer.style.arrowColor,
              }}
            />
          </Box>
          <Box className='text-ellipsis layer-title'>
            <Typography fontWeight={500} fontSize={14} lineHeight={1.5}>
              {layer.name}
            </Typography>
          </Box>
        </Col>
        {!layer.isEditable && (
          <Col span={7} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Typography
              fontWeight={500}
              fontSize={10}
              lineHeight={1.5}
              color='neutral-400'
            >
              Not editable
            </Typography>
          </Col>
        )}
      </Row>
    </Box>
  );
};

export default LayerData;
