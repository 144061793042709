import OlMap from 'ol/Map';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import { FeatureLike } from 'ol/Feature';
import { Feature } from 'ol';
import { SingleComponent } from '../../api/types';
import { Store } from '@/store';
import { MapTool } from '../../../project/types';
import { useCallback, useEffect } from 'react';
import { mapLayers } from '../../helpers/layer.data';
import { FeatureType } from '../../../../components/feature-attributes/types';
import AreaIconHover from '@/assets/action-center/AreaIconHover.svg';
import {
  hoveredFeatureStyle,
  pointHoveredFeatureStyle,
  updatePathStyleFunction,
} from '@helpers/mapGlobals/styles';
import { UseLayerSource } from './useLayers';
import { highlight } from './useLayerHighlight';
import { store } from '../../../../jotai';
import { hoveredComponentIdAtom } from '../../../../jotai/atoms/property/estimation/service-item';
import { getTakeoffActionCenterType } from '../../../../jotai/atoms/property/takeoff/action-center';
import { TakeoffActionCenterType } from '../../components/ActionCenter/Takeoff/types';

let hoveredFeature: FeatureLike | null = null;

const NO_PARCEL_ADDRESS_PIN = 'NO_PARCEL_ADDRESS_PIN';

type FeatureHoverFunction = (
  event: MapBrowserEvent,
  feature: Feature | null,
  component: SingleComponent | null
) => void;

let isEventListenerAdded = false;

const addMapHoverEventListener = (
  map: OlMap,
  hoverInCallback: FeatureHoverFunction,
  hoverOutCallback: FeatureHoverFunction
) => {
  const handlePointerMove = (event: MapBrowserEvent) => {
    const activeTool: MapTool =
      Store.getState().user.activeTool ?? MapTool.Select;

    if (activeTool !== MapTool.Select) return;

    const previousHoveredFeature = hoveredFeature;
    let eventHoveredFeature: FeatureLike | null = null;

    let shouldUnHighlight = true;
    let shouldHidePopover = true;

    map.forEachFeatureAtPixel(event.pixel, (feature) => {
      shouldHidePopover = false;

      if (eventHoveredFeature) {
        return;
      }

      // Don't remove the address pin when there's no parcel for the property
      if (feature.getProperties().name === NO_PARCEL_ADDRESS_PIN) {
        return;
      }

      eventHoveredFeature = feature;
      const properties = feature.getProperties();

      if (
        hoveredFeature &&
        properties.componentId === hoveredFeature.getProperties().componentId
      ) {
        /** Current highlighted feature is same as the hovered feature. So no need to do anything */
        shouldUnHighlight = false;
        return;
      }

      const updatePopover = (
        value?: number | string,
        unit: string = 'sq ft',
        iconSrc = AreaIconHover
      ) => {
        /** TODO: Remove `!.` and add proper conditions */
        document.getElementById('name')!.innerHTML =
          properties.name ?? properties.layerName;
        document.getElementById('featureId')!.innerHTML =
          properties.componentName;
        document.getElementById('featureIcon')!.src = iconSrc;
        document.getElementById('unit')!.innerHTML = unit;
        document.getElementById('hoverArea')!.innerHTML = String(value) || '--';
      };

      try {
        if (
          properties.Area ||
          properties['Surface Area'] ||
          properties['Ring Area'] ||
          properties.Length
        ) {
          hoverInCallback(
            event,
            feature as Feature,
            properties as SingleComponent
          );
        } else {
          console.error('Something is wrong here ERR-45792', { properties });
        }

        /** TODO: Add the code to update popover content and visibility */
      } catch (e) {
        console.error('error', e);
      }
    });

    hoveredFeature = eventHoveredFeature;

    if (shouldUnHighlight && previousHoveredFeature) {
      hoverOutCallback(event, previousHoveredFeature as Feature, null);
    }
  };

  map.on('pointermove', handlePointerMove);
  return handlePointerMove;
};

const useLayerHover = (
  map: OlMap,
  source: UseLayerSource = UseLayerSource.TAKEOFF
) => {
  const handlePopoverContentUpdate = (component: SingleComponent) => {};

  const handleFeatureHover: FeatureHoverFunction = useCallback(
    (event, feature, component) => {
      if (!feature || !component) return;

      const olLayer = mapLayers[component.layerId];
      if (!olLayer) return;

      const layerProperties = olLayer.getProperties();
      const { style } = layerProperties;

      if (!style) return;

      setTimeout(() =>
        store.set(hoveredComponentIdAtom, component.componentId)
      );

      switch (layerProperties.featureType) {
        case FeatureType.POINT:
          feature.setStyle(pointHoveredFeatureStyle(style));
          break;

        case FeatureType.PATH:
          updatePathStyleFunction([feature], {
            ...style,
            color: hoveredFeatureStyle.getStroke().getColor(),
            fillColor: hoveredFeatureStyle.getFill().getColor(),
            arrowColor: hoveredFeatureStyle.getStroke().getColor(),
          });
          break;

        default:
          feature.setStyle(hoveredFeatureStyle);
      }
    },
    []
  );

  const handleFeatureHoverOut: FeatureHoverFunction = useCallback(
    (event, feature) => {
      if (!feature) return;

      const layerId = feature.getProperties().layerId;
      if (!layerId) return;

      const olLayer = mapLayers[layerId];
      if (!olLayer) return;

      const layerProperties = olLayer.getProperties();
      const { style } = layerProperties;

      store.set(hoveredComponentIdAtom, null);

      switch (layerProperties.featureType) {
        case FeatureType.PATH:
          if (style) {
            updatePathStyleFunction([feature], style);
          }
          break;

        default:
          feature.setStyle(undefined);
          break;
      }

      if (
        feature.getProperties().clicked
        // && getTakeoffActionCenterType() !== TakeoffActionCenterType.LAYER
      ) {
        /** If the feature is highlighted because of `click` event, we don't want to un-highlight it */
        // const selectedStyle = feature
        //   .getStyle()
        //   ?.getFill()
        //   ?.setColor('rgba(255,255,255,0.3)');
        //
        // feature.setStyle(selectedStyle);

        /** TODO: Change the following logic to just change fill color instead of calling `highlight` again */
        highlight(
          olLayer,
          feature.getProperties().componentId ??
            feature.getProperties().id ??
            feature.getId()
        );
        return;
      }
    },
    []
  );

  useEffect(() => {
    if (isEventListenerAdded) {
      return () => {};
    }

    isEventListenerAdded = true;

    const listener = addMapHoverEventListener(
      map,
      handleFeatureHover,
      handleFeatureHoverOut
    );

    return () => {
      isEventListenerAdded = false;
      map.un('pointermove', listener);
    };
  }, []);
};

export default useLayerHover;
