import {
  getAxiosInstance,
  getCancelToken,
} from '@/helpers/utilities/api-utils';
import { CONSTANTS } from './contants';
import * as API_ENDPOINTS from '@/helpers/constants/APIEndpoints';

const axiosInstance = getAxiosInstance();

// Axios Cancel Token
const CancelToken = getCancelToken();
let cancel;

const PropertyApi = {
  getCreatedByMe: async (params) => {
    return await axiosInstance.get(
      API_ENDPOINTS.GET_DASHBOARD_ORDER_LIST,
      {
        params,
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      }
    );
  },

  getCreatedByMeV2: async (params) => {
    return await axiosInstance.get(
      API_ENDPOINTS.GET_PROPERTY_LIST,
      {
        params,
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      }
    );
  },

  getAllCoordinates: async (params = {}) => {
    return await axiosInstance.get(API_ENDPOINTS.GET_ALL_COORDINATES, {
      params: params,
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      }),
    });
  },

  getShareWithMe: async (params) => {
    return await axiosInstance.get(`${CONSTANTS.SHRED_ROUTE}/share`, {
      params,
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      }),
    });
  },

  getBulkData: async () => {
    return await axiosInstance.get(`${CONSTANTS.BULK_ROUTE}/details`, {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      }),
    });
  },

  deleteProperty: async (orderId) => {
    return await axiosInstance.delete(`${CONSTANTS.BASE_PATH}/${orderId}`);
  },

  unArchiveProperty: async (orderId) => {
    return await axiosInstance.put(
      `${CONSTANTS.BASE_PATH}/unarchive/${orderId}`
    );
  },

  archiveProperty: async (orderId) => {
    return await axiosInstance.delete(`${CONSTANTS.BASE_PATH}/${orderId}`);
  },

  duplicateProperty: async (orderId) => {
    const data = new FormData();
    data.append('orderId', orderId);
    return await axiosInstance.post('/api/order/cloneDraftOrder/', data);
  },

  getAddress: async () => {
    return await axiosInstance.get(`${CONSTANTS.SHRED_ROUTE}?fields=address`);
  },

  getCreatedByMeExport: async (
    params,
    organizationId,
    progressEventHandler
  ) => {
    return await axiosInstance.get(
      `${CONSTANTS.ORDER_ROUTE_V2}/export`,
      {
        params,

        'Content-Type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        responseType: 'arraybuffer',
        onDownloadProgress: (progressEvent) => {
          progressEventHandler(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          );
        },
      }
    );
  },

  getBulkOrderExport: async (params, bulkOrderId, progressEventHandler) => {
    return await axiosInstance.get(
      `${CONSTANTS.BULK_ORDER_ROUTE}/export/${bulkOrderId}`,
      {
        params,

        'Content-Type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        responseType: 'arraybuffer',
        onDownloadProgress: (progressEvent) => {
          progressEventHandler(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          );
        },
      }
    );
  },

  getShareWithMeExport: async (params, progressEventHandler) => {
    return await axiosInstance.get(`${CONSTANTS.SHRED_ROUTE}/share`, {
      params,

      onDownloadProgress: (progressEvent) => {
        progressEventHandler(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
      },
    });
  },

  getBulkDataExport: async (params, progressEventHandler) => {
    return await axiosInstance.get(`${CONSTANTS.BULK_ROUTE}/details`, {
      params,

      onDownloadProgress: (progressEvent) => {
        progressEventHandler(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
      },
    });
  },

  editCustomerApi: async (data) => {
    const tempData = new FormData();
    tempData.append('orderNo', data?.orderNo);
    tempData.append('propertyName', data?.propertyName);
    tempData.append('customerName', data?.customerName);
    tempData.append('customerId', data?.customerId);
    tempData.append('description', data?.description);

    return await axiosInstance.post('/api/order/updateOrderDetails/', tempData);
  },

  addTagApi: async (tagArray, orderId) => {
    const tempData = new FormData();
    tempData.append('tag', JSON.stringify(tagArray));
    return await axiosInstance.post(
      `${CONSTANTS.ORDER_ROUTE}/${orderId}/tag`,
      tempData
    );
  },

  addTagApiBeforeOrderPlaced: async (tagArray, orderId) => {
    const tempData = new FormData();
    tempData.append('tag', JSON.stringify(tagArray));
    return await axiosInstance.post(
      `/api/emporio/v1/corder/${orderId}/tag`,
      tempData
    );
  },

  getSampleProperties: async () => {
    return await axiosInstance.get('/api/order/getSampleOrders/');
  },

  /** Not being used */
  getAllPeopleOfOrganization: async (organizationId) => {
    return await axiosInstance.get(
      `/api/v1/organization/users/${organizationId}?psz=50`
    );
  },

  duplicateCardBeforeOrderPlaceBulk: async (bulkOrderId, orderId) => {
    return await axiosInstance.put(
      `/api/bulk/v1/border/${bulkOrderId}/corder/${orderId}/clone`
    );
  },

  editCustomerDetailsBeforeOrderPlaceBulk: async (
    bulkOrderId,
    orderId,
    payload
  ) => {
    return await axiosInstance.put(
      `/api/bulk/v1/border/${bulkOrderId}/corder/${orderId}`,
      payload
    );
  },

  deleteCardBeforeOrderPlace: async (bulkOrderId, orderId) => {
    return await axiosInstance.delete(
      `/api/bulk/v1/border/${bulkOrderId}/corder/${orderId}`
    );
  },

  cancelToken: () => {
    if (cancel) cancel();
    cancel = null;
  },
};

export default PropertyApi;
