import { FeatureType } from '../components/feature-attributes/types';
import {
  AUTH_PAGE,
  SIGNUP_PAGE,
  WORKSPACE_LIST_PAGE,
} from '../modules/common/constants/urls';
import { Organization } from '../store/teamManagement/state';
import { format, parse } from 'date-fns';

/** LazyRetry Function */
export const lazyRetry = (componentImport: any) => {
  return new Promise((resolve, reject) => {
    /** Check if the window has already been refreshed */
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    );

    /** Try importing the component */
    componentImport()
      .then((component: any) => {
        /** Success- Reset the refresh */
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
        resolve(component);
      })
      .catch((error: any) => {
        if (!hasRefreshed) {
          /** Error- Window has not already been refreshed yet */

          /** We are now going to refresh */
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
          /** Refresh the page */
          return window.location.reload();
        }

        /** Already tried refreshing, so default error behaviour */
        reject(error);
      });
  });
};

export const isAuthPage = () => {
  const currLocation = window.location.pathname;
  const PATH_WITH_NO_WORKSPACE_ID = [
    WORKSPACE_LIST_PAGE,
    AUTH_PAGE,
    SIGNUP_PAGE,
  ];

  return PATH_WITH_NO_WORKSPACE_ID.includes(currLocation);
};

export const getFileExtension = (link: string) => {
  // Get the last part of the URL after the last dot
  const parts = link.split('.');
  const extension = parts[parts.length - 1];

  return extension.toLowerCase(); // Return in lowercase for consistency
};

export const downloadFile = (
  fileUrl: string,
  fileName: string,
  completionHandler: () => void
) => {
  fetch(fileUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const a = document.createElement('a');
      a.href = url;
      const fileExtension = getFileExtension(fileUrl);
      a.download = `${fileName}.${fileExtension}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      completionHandler();
    })
    .catch((error) => {
      console.error('Error downloading file:', error);
    });
};

export const decodeRedirectURI = (
  url: string,

  /** If the URL has workspaceId, do we want to remove it? */
  persistWorkspaceId: boolean = true,

  /** Do we need to remove the domain name from URL? Mostly this would be used for react router `history.push()` */
  persistDomain: boolean = true
) => {
  try {
    const uri = new URL(decodeURI(url));

    if (!persistDomain) {
      /** We want URL without the domain name. */
      const searchParams = new URLSearchParams(uri.search);

      if (!persistWorkspaceId && searchParams.get('workspaceId')) {
        /** We need to remove the `workspaceId` from query string params */
        searchParams.delete('workspaceId');
      }

      let pathName = uri.pathname;

      if (searchParams.toString()) {
        pathName += `?${searchParams.toString()}`;
      }

      return pathName;
    }

    return uri.href;
  } catch {
    return url;
  }
};

export const getSearchParamFromUrl = (url: string | null, param: string) => {
  if (!url) return null;

  const uri = new URL(decodeURI(url));
  const searchParams = new URLSearchParams(uri.search);
  return searchParams.get(param);
};

/** Returns workspaceId from given url */
export const getWorkspaceIdFromUrl = (url: string | null) => {
  return getSearchParamFromUrl(url, 'workspaceId');
};

/** Checks if workspaceId exists in given list of organizations */
export const isWorkspaceIdValid = (
  organizations: Organization[],
  workspaceId: string | number
) => {
  return !!organizations.find(
    (organization) => organization.id === Number(workspaceId)
  );
};

/** Returns the redirection URL from given URL */
export const getRedirectionUrl = (search: any) => {
  if (!search.toString().includes('redirect=')) {
    return null;
  }

  const toFind = 'redirect=';
  const searchString = search.toString();

  return searchString.slice(searchString.indexOf(toFind) + toFind.length);
};

export const getLayerUnit = (featureType: FeatureType) => {
  let unit = '';

  switch (featureType) {
    case FeatureType.POINT:
      unit = 'N';
      break;
    case FeatureType.LINE:
    case FeatureType.PATH:
      unit = 'ft';
      break;
    case FeatureType.POLYGON:
      unit = 'sf';
      break;
  }
  return unit;
};

export const addEllipsis = (text: string, noOfCharacters: number = 20) =>
  text.length > noOfCharacters ? text.slice(0, noOfCharacters) + '...' : text;

export const getMMMMDYYYYFormattedDate = (inputDate) => {

  if (inputDate === null || inputDate === "") return '';
  const imagerydateString = inputDate.toString();
  const date = parse(imagerydateString, 'yyyyMMdd', new Date());

  return format(date, 'MMMM d, yyyy');
};
