import { useAtom } from 'jotai';
import { activeToolAtom } from '.';
import { MapTool } from '../../../modules/project/types';

/** Returns hierarchical view list */
const useActiveTool = () => {
  const [activeTool, setActiveTool] = useAtom<MapTool>(activeToolAtom);
  return { activeTool, setActiveTool };
};

export default useActiveTool;
