import { SidebarItemTypeWithRole } from '@/components/pages/profile/types';
import { BetaFeature, UserRole } from '@common/types';
import { useRole } from '@/modules/auth/guards/RoleGuard';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import {
  OnTabChangeFunction,
  SidebarItemType,
} from '@/modules/storybook/Settings/types';
import omit from 'lodash/omit';
import { useBetaRelease } from '@common/guards/BetaRelease';
import { useHistory } from 'react-router-dom';
import useAuth from '@/modules/auth/hooks';
import { useDispatch } from 'react-redux';
import { openOrderQueueModal } from '@/store/orderQueue/actions';
import { MenuItem, getProfileDropdownSegmentEvt } from './util';
import { trackEvents } from '../../../../helpers/utilities';
import {
  SUBSCRIPTION_HOME_URL,
  TEAM_MANAGEMENT_URL,
} from '@common/constants/urls';

export interface UseProfileDropdownMenuHookResponse {
  visible: boolean;

  setVisible: Dispatch<SetStateAction<boolean>>;

  items: SidebarItemType<MenuItem>[];

  handleMenuItemClick: OnTabChangeFunction;
}

const useProfileDropdownMenu = (): UseProfileDropdownMenuHookResponse => {
  const { logout } = useAuth();
  const dispatch = useDispatch();
  const { role } = useRole();
  const history = useHistory();
  const isOrderQueueVisible = useBetaRelease(BetaFeature.OrderQueue);

  const [visible, setVisible] = useState<boolean>(false);

  const items: SidebarItemTypeWithRole<MenuItem>[] = [
    {
      label: MenuItem.EditProfile,
      icon: 'https://fragmentor.siterecon.ai/subscription-assets/svg/edit_profile_panel.svg',
      link: '/settings/account',
    },
    {
      label: MenuItem.OrderQueues,
      icon: 'https://fragmentor.siterecon.ai/subscription-assets/svg/order_queue.svg',
      except: [UserRole.Viewer, UserRole.Guest, UserRole.Estimator],
      visible: isOrderQueueVisible,
    },
    {
      label: role === UserRole.Viewer ? 'My Team' : MenuItem.ManageTeam,
      icon: 'https://fragmentor.siterecon.ai/subscription-assets/svg/mng_team.svg',
      link: TEAM_MANAGEMENT_URL,
      target: '_blank',
      except: [UserRole.Guest, UserRole.Estimator],
    },
    {
      label: MenuItem.ManageSubscription,
      icon: 'https://fragmentor.siterecon.ai/subscription-assets/svg/crd_card.svg',
      link: SUBSCRIPTION_HOME_URL,
      target: '_blank',
      except: [UserRole.Guest, UserRole.Estimator],
    },
    {
      label: MenuItem.Settings,
      icon: 'https://fragmentor.siterecon.ai/subscription-assets/svg/settings_icon.svg',
      link: '/settings/organisation',
      except: [UserRole.Guest, UserRole.Estimator],
    },
    {
      label: MenuItem.SignOut,
      icon: 'https://fragmentor.siterecon.ai/subscription-assets/svg/logout_icon.svg',
    },
  ];

  const isItemAllowed = (item: SidebarItemTypeWithRole<MenuItem>) => {
    if (item.visible === false) return false;

    if (item.roles) {
      return item.roles.includes(role!);
    }

    if (item.except) {
      return !item.except.includes(role!);
    }

    return true;
  };

  const filterItems = useCallback(
    (
      items: SidebarItemTypeWithRole<MenuItem>[]
    ): SidebarItemType<MenuItem>[] => {
      return items
        .filter((item) => isItemAllowed(item))
        .map((item) => omit(item, ['roles', 'except']));
    },
    [role]
  );

  const handleMenuItemClick: OnTabChangeFunction = async (item) => {
    const event = getProfileDropdownSegmentEvt(item.label);
    trackEvents(event);

    setVisible(false);

    switch (item.label as MenuItem) {
      case MenuItem.OrderQueues:
        dispatch(openOrderQueueModal());
        return;
      case MenuItem.SignOut:
        await logout();
        return;
    }

    if (item.target === '_blank') {
      /** We have to open this URL in new tab */
      window.open(item.link, item.target);
      return;
    }

    if (item.link) {
      history.push(item.link);
    }
  };

  return {
    visible,
    setVisible,
    items: filterItems(items),
    handleMenuItemClick,
  };
};

export default useProfileDropdownMenu;
